import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import React from 'react';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  // responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
  scales: {
    y: {
      ticks: {
        display: false,
        beginAtZero: true,
      },
      // to remove the y-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
};

type Props = {
  data: any[];
};

export default function DataPieChart({ data }: Props) {
  const dataSet = {
    labels: ['-', '내원율', '동의율'],
    datasets: [
      {
        label: '효율분석',
        data: [...data, 100],
        backgroundColor: [
          'rgba(67, 24, 235, 1)',
          'rgba(106, 210, 255, 1)',
          'rgba(239, 244, 251, 1)',
        ],
        borderColor: ['rgba(67, 24, 235, 1)', 'rgba(106, 210, 255, 1)', 'rgba(239, 244, 251, 1)'],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={dataSet} options={options} />;
}
