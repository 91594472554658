import { Empty } from 'antd';
import { find } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';

import EditableCallHistoryCard from './EditableCallHistoryCard';
import PatientHistory from './PatientHistory';
import Loader from 'src/components/Loader';
import useCallHistory from 'src/hooks/useCallHistory';
import { getNrc, getTagsEmoji } from 'src/libs/common';
import { authState, isRightPermissionState } from 'src/recoil/auth.recoil';

type Props = {
  patientId: string;
};

// 콜 위젯: 콜 내역
export default function CallHistoryWidget({ patientId }: Props) {
  const {
    meh: { id },
  } = useRecoilValue(authState);
  const isRightPermission = useRecoilValue(isRightPermissionState('콜 관리 편집'));
  const { getAllItemList, isFetching } = useCallHistory(patientId);
  const historyList = getAllItemList();

  return (
    <div className="call-history-widget mt-5 relative min-h-[200px] pb-4">
      <div className="text-lg text-[#768396] font-bold mb-3">내역</div>
      {isFetching && <Loader />}
      {historyList.length === 0 ? (
        <Empty description="내역이 없습니다" />
      ) : (
        historyList.map((history) =>
          isRightPermission || history.assignHuserId === id ? (
            <EditableCallHistoryCard key={history.id} {...history} />
          ) : (
            <PatientHistory key={history.id} {...history} />
          )
        )
      )}
    </div>
  );
}

export const DisplayTagNrc = ({ data }: any) => {
  const nrc = data?.nrc;
  const tags = find(data, 'tags');

  return (
    <div>
      {nrc && (
        <div
          className={`absolute inline-flex items-center justify-center w-6 h-6 text-s font-bold text-white border-2 border-white rounded-full -top-2 -left-2 dark:border-gray-900 z-[101]`}
          style={{
            backgroundColor: `#${getNrc(nrc)?.color.split(',')[1]}`,
            color: `#${getNrc(nrc)?.color.split(',')[0]}`,
          }}
        >
          {getNrc(nrc)?.label}
        </div>
      )}
      {tags && getTagsEmoji(tags)}
    </div>
  );
};
