import { QueryClient } from '@tanstack/react-query';
import { createBrowserRouter } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import Accounts from './Accounts';
import Call from './Call';
import Consulting from './Consulting';
import ContentManage from './ContentManage';
import ContentStudio from './ContentStudio';
import CS from './CS';
import Dashboard from './Dashboard';
import ManageDB from './ManageDB';
import ManageGross from './ManageGross';
import PatientBoard from './PatientBoard';
import Reservation from './Reservation';
import Signin from './Signin';
import ErrorPage from 'src/components/ErrorPage';
import MainLayout from 'src/components/Layout/MainLayout';
import RequireAuth, { checkAuthLoader } from 'src/components/RequireAuth';

const queryClient = new QueryClient();

export const rootRouter = createBrowserRouter([
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/',
    element: (
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    ),
    loader: checkAuthLoader(queryClient),
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Navigate to="/dashboard" />,
        index: true,
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/reservation',
        element: <Reservation />,
      },
      {
        path: '/reservation/:info',
        element: <Reservation />,
      },
      {
        path: '/call',
        element: <Call />,
      },
      {
        path: '/call/:info',
        element: <Call />,
      },
      {
        path: '/consulting',
        element: <Consulting />,
      },
      {
        path: '/consulting/:info',
        element: <Consulting />,
      },
      {
        path: '/db',
        element: <ManageDB />,
      },
      {
        path: '/db/:patientId',
        element: <ManageDB />,
      },
      {
        path: '/manage-gross',
        element: <ManageGross />,
      },
      {
        path: '/manage-gross/:grossId',
        element: <ManageGross />,
      },
      {
        path: '/cs',
        element: <CS />,
      },
      {
        path: '/accounts',
        element: <Accounts />,
      },
      {
        path: '/content/studio',
        element: <ContentStudio />,
      },
      {
        path: '/content/manage',
        element: <ContentManage />,
      },
      {
        path: '/patient/:id',
        element: <PatientBoard />,
      },
    ],
  },
]);
