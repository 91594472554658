import { Empty, Tooltip } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isEmpty } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import TooltipInsideForm from './TooltipInsideForm';
import useHuserList from 'src/hooks/useHuserList';
import useStatus from 'src/hooks/useStatus';
import { getNrc, getTagsEmoji } from 'src/libs/common';
import { ScheduleData } from 'src/libs/TimeTableDrawer';
import { reservationFilterState } from 'src/recoil/reservationFilter.recoil';

dayjs.extend(relativeTime);

type Props = {
  group: '상담' | '진료';
  schedulesByHuserId: {
    [hUserId: string]: (ScheduleData | null)[][];
  };
};

export default function GroupSpecified({ group, schedulesByHuserId }: Props) {
  const setReservation = useSetRecoilState(reservationFilterState);
  const { getUserById } = useHuserList();
  const getStatusColor = useStatus();
  const navigate = useNavigate();

  const onClickTick = (cell: ScheduleData | null) => {
    if (!cell) return;
    const info = cell.crmListId + '_' + cell.patient.id;
    navigate(`/reservation/${info}`);
    console.log('cell', cell);
    setReservation((prev) => ({
      ...prev,
      scheduleData: {
        showDetailDrawer: true,
        listId: cell.crmListId,
        patientId: cell.patient.id,
        assignHuserId: cell.assignHuserId,
        title: cell.title,
        memo: cell.latestCallMemo,
        startTime: cell.startTime,
        endTime: cell.endTime,
        statusId: cell.statusId,
        nrc: cell.nrc,
        tags: cell.tags,
      },
    }));
  };

  return (
    <>
      {isEmpty(schedulesByHuserId) && (
        <div className="flex py-1">
          <div className="w-[120px] text-sm font-bold flex flex-col justify-center items-center">
            <div className="mb-1">{group}</div>
          </div>
          <div className="flex-1">
            <Empty description="" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      )}

      {Object.entries(schedulesByHuserId).map(([hUserId, schedules]) => (
        <div key={hUserId} className="flex py-3">
          <div className="w-[120px] text-sm font-bold flex flex-col justify-center items-center">
            <div className="mb-1">{group}</div>
            <div className="text-xs flex items-center leading-[24px] ml-2">
              <div>{getUserById(hUserId).name}</div>
            </div>
          </div>

          <div className="flex-1 flex flex-col">
            {schedules.map((row, rowIndex) => (
              <div key={rowIndex} className="flex justify-between mb-1 last:mb-0">
                {row.map((cell, cellIndex) => (
                  <div
                    key={cellIndex}
                    className="flex-1 aspect-square aspect-ratio-timecell relative"
                  >
                    {cell && cell.isFirst && (
                      <>
                        <div
                          className="flex text-[12px] h-full z-[99] absolute"
                          style={{ width: `${cell.length * 100}%` }}
                        >
                          <Tooltip
                            overlayInnerStyle={{ background: '#fff' }}
                            overlayStyle={{ width: 320, maxWidth: 320 }}
                            arrow={false}
                            zIndex={999}
                            trigger="contextMenu"
                            title={
                              <TooltipInsideForm
                                id={cell.id}
                                statusId={cell.statusId}
                                listId={cell.crmListId}
                                assignHuserId={cell.assignHuserId}
                              />
                            }
                          >
                            <div
                              className="h-full flex flex-1 cursor-pointer justify-center rounded-lg mr-1 px-2 flex-col min-w-0"
                              style={{
                                backgroundColor: getStatusColor(cell.crmStatusId).bg,
                                color: getStatusColor(cell.crmStatusId).color,
                                opacity: cell.isCurrent ? 1 : 0.4,
                              }}
                              onClick={(e) => onClickTick(cell)}
                            >
                              <div className="whitespace-nowrap overflow-hidden text-ellipsis font-bold text-white">
                                {dayjs(cell.startTime).format('HH:mm')} {getTagsEmoji(cell.tags)}
                              </div>
                              <div className="whitespace-nowrap overflow-hidden text-ellipsis font-bold text-white">
                                {cell.patient.name} ({{ f: '여', m: '남' }[cell.patient.gender]})
                              </div>
                              <div className="whitespace-nowrap overflow-hidden text-ellipsis text-white">
                                {cell.latestCallMemo}
                                {dayjs(cell.patient.birth)
                                  .fromNow()
                                  .replace(/[^0-9]/g, '')}
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                        {/*<!-- nrc badge -->*/}
                        {cell?.nrc && (
                          <div
                            className={`absolute inline-flex items-center justify-center w-6 h-6 text-s font-bold text-white border-2 border-white rounded-full -top-2 -left-2 dark:border-gray-900 z-[101]`}
                            style={{
                              backgroundColor: `#${getNrc(cell?.nrc)?.color.split(',')[1]}`,
                              color: `#${getNrc(cell?.nrc)?.color.split(',')[0]}`,
                            }}
                          >
                            {getNrc(cell?.nrc)?.label}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}
