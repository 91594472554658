import { useMutation } from '@tanstack/react-query';
import { Button, Input } from 'antd';
import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { signinRequestAPI } from 'src/api/auth.api';
import ImageLogo from 'src/assets/images/logo2.png';
import TokenManager from 'src/libs/TokenManager';
import { authState } from 'src/recoil/auth.recoil';

type Form = {
  email: string;
  password: string;
};

export default function Signin() {
  const navigate = useNavigate();
  const setAuth = useSetRecoilState(authState);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      /*email: 'mario@h.com', password: '1234qwer'*/
    },
  });

  const signin = useMutation(signinRequestAPI, {
    onSuccess: ({ login }) => {
      TokenManager.setToken(login.token);
      setAuth(login);
      navigate('/dashboard', { replace: true });
    },
  });

  const onSubmit: SubmitHandler<Form> = (data) => {
    signin.mutate(data);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-80">
        <img src={ImageLogo} className="w-[80%]" />
      </div>
      <div className="w-80">
        <Controller
          name="email"
          control={control}
          rules={{ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i }}
          render={({ field }) => (
            <Input
              {...field}
              type="email"
              className="w-full"
              placeholder="이메일"
              status={errors.email ? 'error' : ''}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input.Password
              {...field}
              className="w-full mt-3"
              placeholder="패스워드"
              status={errors.password ? 'error' : ''}
            />
          )}
        />
        <Button
          type="primary"
          className="w-full mt-3"
          onClick={handleSubmit(onSubmit)}
          loading={signin.isLoading}
        >
          로그인
        </Button>
      </div>
    </div>
  );
}
