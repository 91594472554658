import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { getCrmPatientHistoryAPI } from 'src/api/patient.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { CrmContent } from 'src/types/crm.types';
import { CrmPatientHistory } from 'src/types/patient.types';

const initialState = {
  id: '',
  createdAt: '',
  hospitalId: '',
  registerNumber: '0',
  referral: '',
  therapy: '',
  isNewVisit: true,
  tmId: '',
  csId: '',
  drId: '',
  status: [],
  paid: null,
  cc: null,
};

export default function usePatientHistory(patientId: string, isConsult?: boolean) {
  const [crmPatientHistory, setCrmPatientHistory] = useState<CrmPatientHistory>(initialState);

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CRM_PATIENT_HISTORY, { patientId }],
    () => getCrmPatientHistoryAPI({ id: patientId }),
    {
      enabled: !!patientId,
    }
  );

  useEffect(() => {
    if (data) {
      const newCrmPatientHistory = data.crmPatientHistory.reduce((acc, history) => {
        const { status, ...others } = history;
        const result = { ...acc, ...others };

        if (result['status']) {
          result['status'] = [...result['status'], ...history.status];
        } else {
          result['status'] = history.status;
        }

        return result;
      }, {} as CrmPatientHistory);

      setCrmPatientHistory(isEmpty(newCrmPatientHistory) ? initialState : newCrmPatientHistory);
    }
  }, [data]);

  const getStatusItemList = (crmStatusId: string, isCall?: boolean) => {
    if (isCall) {
      return crmPatientHistory.status.filter(
        (history) => history.crmStatusId === '1' || history.crmStatusId === '2'
      );
    }

    return crmPatientHistory.status.filter((history) => history.crmStatusId === crmStatusId);
  };

  const getAllItemList = () => {
    const itemList = crmPatientHistory.status.reduce((acc, value) => {
      acc.push(...value.contents);
      return acc;
    }, [] as CrmContent[]);

    const sortedItemList = itemList.sort((a, b) => {
      const dateA = dayjs(a.createdAt);
      const dateB = dayjs(b.createdAt);
      return dateB.isBefore(dateA) ? -1 : 1;
    });

    if (isConsult) {
      return sortedItemList.filter(
        (item) => item.statusId === '40' || item.statusId === '41' || item.statusId === '42'
      );
    }

    return sortedItemList;
  };

  return {
    crmPatientHistory,
    getStatusItemList,
    getAllItemList,
    isFetching: isFetching || isLoading,
  };
}
