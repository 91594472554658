import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, Radio, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { updateCrmPatientAPI } from 'src/api/patient.api';
import usePatient from 'src/hooks/usePatient';
import { getGender } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { authState, isRightPermissionState } from 'src/recoil/auth.recoil';

type PatientInfo = {
  registerNumber: string;
  name: string;
  birth: number;
  gender: string;
  phone: string;
  address: string;
  therapy: string;
  referral: string;
};

export default function EditablePatientInfoWidget() {
  const { patientId } = useParams<{ patientId: string }>();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [isEdit, setIsEdit] = useState(false);
  const auth = useRecoilValue(authState);
  const { isMaster } = auth.meh;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<PatientInfo>();

  const isCallPermission = useRecoilValue(isRightPermissionState('콜 관리 편집'));
  const isConsultPermission = useRecoilValue(isRightPermissionState('상담 관리 편집'));
  const isRightPermission = isCallPermission || isConsultPermission;
  const { patient } = usePatient(patientId || '');

  useEffect(() => {
    if (isEdit) {
      setValue('registerNumber', patient.registerNumber);
      setValue('name', patient.name);
      setValue('birth', patient.birth);
      setValue('gender', patient.gender);
      setValue('phone', patient.phone);
      setValue('address', patient.address);
    }
  }, [isEdit, patient, setValue]);

  const updateCrmPatient = useMutation(updateCrmPatientAPI, {
    onSuccess: (res) => {
      api.success({ message: '환자 정보 수정 완료.' });
      onClickCancelEdit();
      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_PATIENT, { patientId }]);
    },
    onError: () => {
      api.error({ message: '환자 정보 수정 실패.' });
    },
  });

  const onClickEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const onClickCancelEdit = () => {
    setIsEdit(false);
    reset();
  };

  const onSubmit: SubmitHandler<PatientInfo> = (data) => {
    const toNumber = { ...data, birth: Number(data.birth) };
    const resData = Object.fromEntries(
      Object.entries(toNumber).filter(([_, value]) => Boolean(value))
    );
    const payload = { ...resData, id: patientId || '' };

    updateCrmPatient.mutate(payload);
  };

  return (
    <div>
      {contextHolder}
      <div className="flex items-center justify-between h-10">
        <div className="text-lg text-[#768396] font-bold">환자 정보</div>
        {(isMaster || isRightPermission) && !isEdit && (
          <Button type="text" icon={<EditOutlined />} onClick={onClickEdit} />
        )}
      </div>
      <div className="rounded-lg shadow-lg bg-white p-5">
        <div className="flex min-h-[32px] mb-2">
          <div className="flex items-center text-[#768396] font-bold w-24">등록번호</div>
          <div className="text-[#2B3674] font-medium flex-1 flex items-center">
            {isEdit ? (
              <Controller
                name="registerNumber"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input {...field} status={errors.registerNumber && 'error'} />
                )}
              />
            ) : (
              patient.registerNumber
            )}
          </div>
        </div>
        <div className="flex min-h-[32px] mb-2">
          <div className="flex items-center text-[#768396] font-bold w-24">이름 / 출생년도</div>
          <div className="text-[#2B3674] font-medium flex-1 flex items-center">
            {isEdit ? (
              <div>
                <div className="flex mb-2">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input {...field} placeholder="이름" status={errors.name && 'error'} />
                    )}
                  />
                  <Controller
                    name="birth"
                    control={control}
                    rules={{ minLength: 4, maxLength: 4 }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="출생년도"
                        className="ml-3"
                        status={errors.birth && 'error'}
                      />
                    )}
                  />
                </div>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Radio.Group onChange={(e) => onChange(e.target.value)} value={value}>
                      <Radio value="f">여자</Radio>
                      <Radio value="m">남자</Radio>
                    </Radio.Group>
                  )}
                />
              </div>
            ) : (
              `${patient.name} / ${patient.birth} / ${getGender(patient.gender)}`
            )}
          </div>
        </div>
        <div className="flex min-h-[32px] mb-2">
          <div className="flex items-center text-[#768396] font-bold w-24">연락처</div>
          <div className="text-[#2B3674] font-medium flex-1 flex items-center">
            {isEdit ? (
              <Controller
                name="phone"
                rules={{
                  required: true,
                  pattern: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
                }}
                control={control}
                render={({ field }) => <Input {...field} status={errors.phone && 'error'} />}
              />
            ) : (
              patient.phone
            )}
          </div>
        </div>
        <div className="flex min-h-[32px] mb-2">
          <div className="flex items-center text-[#768396] font-bold w-24">주소</div>
          <div className="text-[#2B3674] font-medium flex-1 flex items-center">
            {isEdit ? (
              <Controller
                name="address"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            ) : (
              patient.address
            )}
          </div>
        </div>
        {isEdit && (
          <div className="flex mt-3">
            <Button className="flex-1" onClick={onClickCancelEdit}>
              취소
            </Button>
            <Button
              type="primary"
              className="flex-1 ml-2"
              onClick={handleSubmit(onSubmit)}
              disabled={updateCrmPatient.isLoading}
            >
              저장
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
