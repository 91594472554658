import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import React from 'react';
import { useRecoilState } from 'recoil';

import { callManagementState } from 'src/recoil/callManagement.recoil';

const { RangePicker } = DatePicker;

export default function CallFilter() {
  const [filter, setFilter] = useRecoilState(callManagementState);

  const onChangePicker = (dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;
    setFilter((prev) => ({ ...prev, startDate, endDate }));
  };

  return (
    <div>
      <RangePicker
        locale={locale}
        onChange={(_, dateStrings) => onChangePicker(dateStrings)}
        allowClear={false}
        value={[dayjs(filter.startDate), dayjs(filter.endDate)]}
        presets={[{ label: '오늘', value: [dayjs(), dayjs()] }]}
        disabledDate={(current) => {
          return current && current < dayjs().add(-90, 'd');
        }}
      />
    </div>
  );
}
