import { Button, Drawer, Tabs, TabsProps } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import CommonForm from './CommonForm';
import CallHistoryWidget from './Container/CallHistoryWidget';
import EditablePatientInfoWidget from './Container/EditablePatientInfoWidget';
import PaymentDone from '../Consult/Container/PaymentDone';
import PaymentForm from '../Consult/Container/PaymentForm';
import PaymentRefund from '../Consult/Container/PaymentRefund';
import PaymentWaiting from '../Consult/Container/PaymentWaiting';
import { isRightPermissionState } from 'src/recoil/auth.recoil';

type Props = { info: string };

export default function CallManagementDrawer({ info }: Props) {
  const isRightPermission = useRecoilValue(isRightPermissionState('콜 관리 편집'));
  const isEditablePaymentPermission = useRecoilValue(isRightPermissionState('결제 관리 편집'));
  const navigate = useNavigate();
  const [listId, patientId] = info.split('_');

  const items: TabsProps['items'] = [
    {
      key: '콜관리',
      label: `콜관리`,
      children: (
        <>
          <EditablePatientInfoWidget />
          {isRightPermission && <CommonForm />}
          <CallHistoryWidget patientId={patientId} />
        </>
      ),
    },
    {
      key: '결제',
      label: `결제`,
      children: (
        <>
          <EditablePatientInfoWidget />
          {isEditablePaymentPermission && <PaymentForm />}
          {/* <PaymentHistory patientId={patientId} /> */}
          <PaymentWaiting patientId={patientId} />
          <PaymentDone patientId={patientId} />
          <PaymentRefund patientId={patientId} />
        </>
      ),
    },
  ];

  const onClose = () => {
    navigate(-1);
  };

  const onClickNavigate = () => {
    navigate(`/patient/${patientId}`);
  };

  const isOpen = !!patientId && !!listId;

  return (
    <Drawer closable={false} open={isOpen} onClose={onClose} width={600}>
      <div className="bg-white rounded-lg h-full">
        <Tabs
          defaultActiveKey="콜관리"
          items={items}
          tabBarExtraContent={{
            right: (
              <Button onClick={onClickNavigate}>
                <div className="flex justify-center items-center">
                  <div className="text-[#768396] font-bold">펼쳐보기</div>
                </div>
              </Button>
            ),
          }}
        />
      </div>
    </Drawer>
  );
}
