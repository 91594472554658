import { Spin } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';

import StatusCircle from '../StatusCircle';
import usePatient from 'src/hooks/usePatient';
import { getStatusListByGroupStatus } from 'src/recoil/status.recoil';

type Props = { patientId: string };

export default function VisitStatus({ patientId }: Props) {
  const processStatusList = useRecoilValue(getStatusListByGroupStatus('process'));
  const orderedProcessStatusList = [...processStatusList].sort((a, b) => a.order - b.order);
  const {
    isFetching,
    patient: { latestStatusId },
  } = usePatient(patientId);

  return (
    <Spin spinning={isFetching}>
      <div className="bg-white p-5 rounded-lg shadow-lg w-[480px] min-h-[165px] ml-5">
        <div className="text-[#2B3674] font-bold">내원상태</div>
        <div className="mt-3">
          <div className="flex justify-between items-center relative">
            <div className="h-[1px] w-full absolute border border-dashed border-[#A3AED0]" />
            {orderedProcessStatusList.map((status, idx) => (
              <div key={status.id} className="flex items-center z-10">
                <StatusCircle bg={latestStatusId === status.id ? '#05CD99' : '#768396'} />
              </div>
            ))}
          </div>
          <div className="flex justify-between items-start mt-3">
            {orderedProcessStatusList.map((status) => (
              <span
                key={status.id}
                className={`pl-[2px] text-xs w-4 ${
                  latestStatusId === status.id ? 'text-[#05CD99] font-bold' : 'text-[#768396]'
                }`}
              >
                {status.status}
              </span>
            ))}
          </div>
        </div>
      </div>
    </Spin>
  );
}
