import { Button, Drawer, Tabs, TabsProps } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import EditableBookForm from './EditableBookForm';
import PatientInfoWidget from './PatientInfoWIdget';
import CallHistoryWidget from '../Call/Container/CallHistoryWidget';
import PaymentDone from '../Consult/Container/PaymentDone';
import PaymentForm from '../Consult/Container/PaymentForm';
import PaymentRefund from '../Consult/Container/PaymentRefund';
import PaymentWaiting from '../Consult/Container/PaymentWaiting';
import { isRightPermissionState } from 'src/recoil/auth.recoil';
import { initialScheduleData, reservationFilterState } from 'src/recoil/reservationFilter.recoil';

export default function EditableScheduleDrawer() {
  const navigate = useNavigate();
  const [{ scheduleData }, setReservation] = useRecoilState(reservationFilterState);
  const isEditablePaymentPermission = useRecoilValue(isRightPermissionState('결제 관리 편집'));

  const onClose = () => {
    setReservation((prev) => ({ ...prev, scheduleData: initialScheduleData }));
  };

  const onClickNavigate = () => {
    navigate(`/patient/${scheduleData.patientId}`);
  };

  const items: TabsProps['items'] = [
    {
      key: '환자상세',
      label: `환자상세`,
      children: (
        <>
          <PatientInfoWidget patientId={scheduleData.patientId} />
          <EditableBookForm />
          <CallHistoryWidget patientId={scheduleData.patientId} />
        </>
      ),
    },
    {
      key: '결제',
      label: `결제`,
      children: (
        <>
          <PatientInfoWidget patientId={scheduleData.patientId} />
          {isEditablePaymentPermission && <PaymentForm />}
          {/* <PaymentHistory patientId={patientId} /> */}
          <PaymentWaiting patientId={scheduleData.patientId} />
          <PaymentDone patientId={scheduleData.patientId} />
          <PaymentRefund patientId={scheduleData.patientId} />
        </>
      ),
    },
  ];

  return (
    <Drawer
      open={scheduleData.showDetailDrawer}
      onClose={onClose}
      closable={false}
      width={600}
      destroyOnClose
    >
      <div className="bg-white rounded-lg h-full">
        <Tabs
          defaultActiveKey="환자상세"
          items={items}
          tabBarExtraContent={{
            right: (
              <Button onClick={onClickNavigate}>
                <div className="flex justify-center items-center">
                  <div className="text-[#768396] font-bold">펼쳐보기</div>
                </div>
              </Button>
            ),
          }}
        />
      </div>
    </Drawer>
  );
}
