// 목표관리 페이지 api

// 목표관리 리스트
export const queryManageGrossList = `
query($filter: CrmManageGrossListFilter) {
  crmManageGrossList(filter: $filter) {
      id
      title
      indicator
      type
      gross
      dueDate
      authorId
      grossEntry {
        id
        crmHuserId
      }
      # isDelete
      privacy
      createdAt
    }
  }
`;

// 목표관리 상세
export const queryManageGross = `
  query($id: ID!) {
    crmManageGross(id: $id) {
      id
      title
      indicator
      type
      gross
      dueDate
      entry
      # isDelete
      privacy
    }
  }
`;

//
export const queryManageGrossUserResult = `
  query($filter: CrmManageGrossResultFilter) {
    crmManageGrossUserResult(filter: $filter) {
      huserId
        profit
        roas
        firstDBCount
        visitRate
        visitCnt
        bookCnt
        agreeRate
        paidCnt
    }
  }
`;

export const queryManageGrossByUserId = `
  query($id: ID!) {
    crmManageGrossByUserId(id: $id) {
      id
      title
      gross
      type
      authorId
      dueDate
      createdAt
      indicator
      grossEntry {
          id
          crmHuserId
      }
    }
  }
`;

// 목표관리 생성
export const mutationAddManageGross = `
  mutation($input: AddCrmManageGrossInput){
    addCrmManageGross(input: $input) {
      ok
      data {
        id
        title
        indicator
        type
        gross
        dueDate
        # entry
      }
      error {
        path
        message
      }
    }
  }
`;

// 목표관리 수정 /  삭제 (is_delete: true)
export const mutationUpdateManageGross = `
  mutation($input: UpdateCrmManageGrossInput){
    updateCrmManageGross(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

// 목표관리 공개설정(id)
export const mutationUpdateManageGrossPrivacy = `
  mutation($input: UpdateCrmManageGrossPrivacyInput){
    updateCrmManageGrossPrivacy(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const queryCrmManageGrossSuggest = `
query($filter: CrmManageGrossSuggestFilter) {
  crmManageGrossSuggest(filter: $filter) {
     indicators
  }
}
`;

export const mutationAddCrmManageGrossSuggest = `
mutation AddCrmManageGrossSuggest($input: AddCrmManageGrossSuggestInput!) {
  addCrmManageGrossSuggest(input: $input) {
      ok
      data {
          id
          title
          indicator
          type
          gross
          dueDate
          # entry
      }
      error {
          path
          message
      }
  }
}
`;
