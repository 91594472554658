import { PlusOutlined, BulbOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Table,
  Input,
  notification,
  Switch,
  Select,
  Button,
  Progress,
  Avatar,
  Tooltip,
  Space,
  ConfigProvider,
  Popconfirm,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { find } from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import GrossDetailModal from './GrossDetailModal';
import { addCrmItemByPatientIdsAPI } from 'src/api/crm.api';
import {
  getManageGrossListAPI,
  addManageGrossAPI,
  getManageGrossUserResultAPI,
  addCrmManageGrossSuggestAPI,
} from 'src/api/gross.api';
import useHuserList from 'src/hooks/useHuserList';
import useManageGrossList, { useManageGrossSuggest } from 'src/hooks/useManageGrossList';
import { sortDate, numberFormat, getAvatarPath } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { GROSS_IC_LIST } from 'src/libs/constants';
import { authState } from 'src/recoil/auth.recoil';
import { manageDBState } from 'src/recoil/manageDB.recoil';
import { grossState } from 'src/recoil/manageGross.recoil';
import { getStatusNameMapState } from 'src/recoil/status.recoil';
import { CrmVisit } from 'src/types/crm.types';

const { Search } = Input;

const grossIndicatorList = [
  {
    key: 'IC_01',
    label: '매출',
    h_type: '12345',
    order: 1,
  },
];

type TableData = CrmVisit;

const sum = (arr: number[], initial: number = 0) => {
  const reducer = (accumulator: number, currentValue: number) =>
    Number(accumulator) + Number(currentValue);

  return arr.reduce(reducer, initial);
};

const avg = (arr: number[], initial: number = 0) => {
  return sum(arr, initial) / arr.length;
};

const calGrossResult = ({ key, arr }: any) => {
  if (key === 'sum') {
    return sum(arr);
  }

  if (key === 'avg') {
    return avg(arr);
  }
  return 0;
};

export default function GrossMain() {
  const { meh } = useRecoilValue(authState);

  console.log('test');

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [grossListData, setGrossListData] = useState<any[]>([]);
  const [patientIdList, setPatientIdList] = useState<string[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [{ startDate, endDate, isVisit }, setFilter] = useRecoilState(manageDBState);
  const { hUserList, isFetching: loadingHuserList } = useHuserList();
  const [search, setSearch] = useState('');
  const statusMap = useRecoilValue(getStatusNameMapState);
  const [hUserIds, setHuserIds] = useState<string[]>([]);

  const [arrow, setArrow] = useState('Show');
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  useEffect(() => {
    if (!loadingHuserList && hUserList.length > 0) {
      setHuserIds(hUserList.filter((hUser) => hUser.type === 1).map(({ id }) => id));
    }
  }, [hUserList, loadingHuserList]);

  const createMarkup = (value: string) => {
    if (search === '') {
      return { __html: value };
    }

    return { __html: value.replaceAll(search, (match) => `<mark>${match}</mark>`) };
  };

  const { grossList: data, isFetching, isLoading } = useManageGrossList();
  // const { data, isFetching, isLoading } = useQuery(
  //   [QUERY_KEYS.GET_CRM_VISIT_LIST, { startDate, endDate, isVisit, hUserIds }],
  //   () => getManageGrossListAPI({})
  // );

  const { grossSuggest } = useManageGrossSuggest();

  // 제안 목표(관리) 추

  const addManageGrossSuggest = useMutation(addCrmManageGrossSuggestAPI, {
    onSuccess: (res) => {
      api.success({ message: '신규 목표관리 추가 성공' });

      return queryClient.invalidateQueries([QUERY_KEYS.ADD_CRM_MANAGE_GROSS_SUGGEST]);
    },
    onError: () => {
      api.error({ message: '신규 목표관리 추가 실패' });
    },
    onSettled: () => {
      // onClose();
    },
  });

  // 목표(관리) 추가
  const addManageGross = useMutation(addManageGrossAPI, {
    onSuccess: (res) => {
      api.success({ message: '신규 목표관리 추가 성공' });

      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_ISSUE_LIST]);
    },
    onError: () => {
      api.error({ message: '신규 목표관리 추가 실패' });
    },
    onSettled: () => {
      // onClose();
    },
  });

  const addCrmItemByPatientIds = useMutation(addCrmItemByPatientIdsAPI, {
    onSuccess: (res) => {
      api.success({ message: '콜대기 이동 성공' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_VISIT_LIST,
        { startDate, endDate, isVisit, hUserIds },
      ]);
    },
    onError: () => {
      api.error({ message: '콜대기 이동 실패' });
    },
    onSettled: () => {
      setPatientIdList([]);
    },
  });

  const onClickRow = (record: CrmVisit) => {
    const { id } = record.patient;
    navigate(`/db/${id}`);
  };

  const onChangeFilter = (checked: boolean) => {
    setFilter((prev) => ({ ...prev, isVisit: checked }));
  };

  const onClickMove = () => {
    addCrmItemByPatientIds.mutate({ patientIds: patientIdList });
  };

  const [state, setState] = useRecoilState(grossState);

  const onClickAddButton = () => {
    setState((prev: any) => ({ ...prev, showAddGrossDrawer: true }));
  };

  if (isLoading) {
    return <div>loading</div>;
  }

  let today = dayjs();

  const confirm = () =>
    // onClickAddSUggestButton
    new Promise((resolve) => {
      // setTimeout(() => resolve(null), 3000);
      if (grossSuggest && grossSuggest?.indicators.length > 0) {
        // addManageGrossSuggest
        addManageGrossSuggest.mutate({
          indicators: grossSuggest.indicators,
          dueDate: dayjs(data.dueDate).add(7, 'day').format(),
        });
      }
    });

  return (
    <div className="mb-10">
      <Space size={100} direction="vertical" style={{ display: 'flex' }}>
        <div className="w-full mt-3 p-5 rounded-lg bg-[#fff]">
          <div className="text-2xl font-bold mb-10">진행중</div>
          <div className="grid grid-cols-4 gap-4">
            {data
              .filter((o: any) => {
                const expired = dayjs(o.dueDate);
                return Math.ceil(expired.diff(today, 'day', true)) >= 0;
              })
              .filter(({ privacy, authorId }: any) => {
                if (!privacy || privacy.length === 0) return true;
                return privacy.includes(meh.id) || authorId == meh.id;
              })
              .map((o: any, i: any) => (
                <GrossListCard data={o} key={i} />
              ))}

            <div className="p-6 h-[190px] shadow-[rgba(17,_17,_26,_0.1)_0px_0px_5px] rounded-lg overflow-hidden">
              <div className="flex w-full h-full items-center justify-center">
                <Tooltip placement="top" title={'목표 만들기'} arrow={mergedArrow}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    size={'large'}
                    onClick={onClickAddButton}
                  />
                </Tooltip>
              </div>
            </div>
            {grossSuggest && grossSuggest?.indicators.length > 0 && (
              <div className="p-6 h-[190px] shadow-[rgba(17,_17,_26,_0.1)_0px_0px_5px] rounded-lg overflow-hidden">
                <div className="flex w-full h-full items-center justify-center">
                  <Tooltip placement="top" title={'추천 제안 목표 만들기'} arrow={mergedArrow}>
                    <Popconfirm
                      title="추천 목표 제안"
                      description="추천된 목표를 생성하겠습니까?"
                      onConfirm={confirm}
                      onOpenChange={() => console.log('open change')}
                      okText="네"
                      cancelText="아니오"
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<BulbOutlined />}
                        size={'large'}
                        danger
                      />
                    </Popconfirm>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-full mt-3 p-5 rounded-lg bg-[#fff]">
          <div className="text-2xl font-bold mb-10">완료 목록</div>
          <div className="grid grid-cols-4 gap-4">
            {data
              .filter((o: any) => {
                const expired = dayjs(o.dueDate);
                return Math.ceil(expired.diff(today, 'day', true)) < 0;
              })
              .filter(({ privacy, authorId }: any) => {
                if (!privacy || privacy.length === 0) return true;
                return privacy.includes(meh.id) || authorId == meh.id;
              })
              .map((o: any, i: any) => (
                <GrossListDoneCard data={o} key={i} />
              ))}
          </div>
        </div>
      </Space>
      <GrossDetailModal />
    </div>
  );
}

const EntryList = ({ entry }: any) => {
  const testEntry = [1, 2, 3];
  const entryList = [...entry];

  const { hUserList, isFetching: loadingHuserList } = useHuserList();

  const parsedHuserList = entry.map((h: any) => find(hUserList, { id: h.crmHuserId }));

  return (
    <div>
      {/* {entry.map((e: any) => (
        <div>{e.huserId}</div>
      ))} */}

      <Avatar.Group
        maxCount={3}
        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
        size="small"
      >
        {parsedHuserList.map(({ name, avatar }: any, i: number) => (
          <Tooltip
            placement="top"
            // title={getUserById(v.ccHuserId) ? getUserById(v.ccHuserId).name : ''}
            title={name}
            key={i}
          >
            <Avatar src={getAvatarPath(avatar)} size={30} />
          </Tooltip>
        ))}
      </Avatar.Group>
    </div>
  );
};
//  title: '제목',
// indicator: 'test indicator',
// type: 'type',
// gross: 100,
// dueDate: '2024-02-11T01:00:00.000Z',
// entry: [],
const GrossListCard = ({ data: grossData }: any) => {
  const { id, title, gross, indicator, grossEntry, dueDate, createdAt, privacy } = grossData;
  const content = find(GROSS_IC_LIST, { key: indicator });

  const [gState, setGrossState] = useRecoilState(grossState);

  const payload = {
    key: indicator,
    huserIds: grossEntry.map(({ crmHuserId }: any) => crmHuserId),
    startDate: createdAt,
    endDate: dueDate,
  };

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CRM_USER_RESULT, { ...payload }],
    () =>
      getManageGrossUserResultAPI({
        ...payload,
      })
  );

  //   const sum = num10.reduce((a,b) => (a+b));
  // document.write('reduce : ', sum);

  if (isLoading) {
    return <div>hi</div>;
  }

  const sum = data.crmManageGrossUserResult
    .map((o: any) =>
      Object.values(o).filter((v) => {
        return typeof v === 'number';
      })
    )
    .reduce((a: Number, b: any) => a + b[0], 0);

  const percent = (sum / gross) * 100;

  const onClickItem = (id: string) => {
    setGrossState((prev) => ({
      ...prev,
      detailModal: {
        id: id,
        grossData: {
          title,
          gross,
          indicator,
          grossEntry,
          dueDate,
          createdAt,
          privacy,
        },
        visible: true,
      },
    }));
  };

  const ur = calGrossResult({
    key: content?.cal || 'sum',
    arr: data.crmManageGrossUserResult.map((o: any) =>
      Object.values(o).filter((v) => {
        return typeof v === 'number';
      })
    ),
  });

  return (
    <>
      <div
        className="p-6 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-lg overflow-hidden"
        onClick={() => {
          onClickItem(id);
        }}
      >
        <div className="w-full flex flex-col">
          <div className="w-full flex mb-2">
            <span className="text-xl font-bold  leading-none">{title}</span>
            <span className="text-xs text-zinc-400 font-normal ml-auto">
              {dayjs(dueDate).format('YYYY-MM-DD')}
            </span>
          </div>

          <div className="flex w-full">
            <div className="flex-auto">
              <div className="font-base text-sm text-zinc-400">목표 {content?.label}</div>
              <div className="text-3xl ">
                {numberFormat(gross)}
                <span className="text-sm text-zinc-400 font-light">{content?.unit}</span>
              </div>
            </div>
            <div className="flex-none">
              <Progress
                type="circle"
                percent={ur}
                size={80}
                strokeColor={{ '0%': '#602BF8', '100%': '#602BF8' }}
                format={(c) => <div className="">{Math.min(100, ur)}%</div>}
                strokeWidth={10}
              />
            </div>
          </div>
          <div className="w-full">
            <EntryList entry={grossEntry || []} />
          </div>
        </div>
      </div>
    </>
  );
};

const GrossListSuccessCard = ({ data: grossData }: any) => {
  const { id, title, gross, indicator, grossEntry } = grossData;
  const content = find(GROSS_IC_LIST, { key: indicator });

  const [gState, setGrossState] = useRecoilState(grossState);

  const { data, isFetching, isLoading } = useQuery([QUERY_KEYS.GET_CRM_VISIT_LIST, {}], () =>
    getManageGrossUserResultAPI({
      key: 'IC_01',
      huserIds: ['10', '12'],
      startDate: '2024-02-01T01:00:00.000Z',
      endDate: '2024-02-13T01:00:00.000Z',
    })
  );

  //   const sum = num10.reduce((a,b) => (a+b));
  // document.write('reduce : ', sum);

  const sum = grossEntry
    .map((o: any) =>
      Object.values(o).filter((v) => {
        return typeof v === 'number';
      })
    )
    .reduce((a: Number, b: any) => a + b[0], 0);

  const percent = (sum / gross) * 100;

  const onClickItem = (id: string) => {
    setGrossState((prev) => ({ ...prev, detailModal: { id: id, grossData: {}, visible: true } }));
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Progress: {
            circleTextColor: '#602BF8',
          },
        },
      }}
    >
      <div
        style={{ backgroundColor: '#602BF8' }}
        className="p-6 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-lg overflow-hidden"
        onClick={() => {
          onClickItem(id);
        }}
      >
        <div className="w-full flex flex-col">
          <div className="w-full text-xl font-bold mb-2 text-white">{title}</div>
          <div className="flex w-full">
            <div className="flex-auto">
              <div className="font-base text-slate-400" style={{ color: '#FFF621' }}>
                목표달성!{content?.label}
              </div>
              <div className="text-3xl text-white">{gross}</div>
            </div>
            <div className="flex-none">
              <Progress
                type="circle"
                percent={100}
                size={80}
                strokeColor={{ '0%': '#602BF8', '100%': '#602BF8' }}
                format={(c) => <div className="">{percent}%</div>}
              />
            </div>
          </div>
          <div className="w-full">
            <EntryList entry={grossEntry || []} />
          </div>
        </div>
      </div>
      <GrossDetailModal />
    </ConfigProvider>
  );
};

const GrossListDoneCard = ({ data: grossData }: any) => {
  const { id, title, gross, indicator, grossEntry, dueDate, createdAt } = grossData;
  const content = find(GROSS_IC_LIST, { key: indicator });

  const [gState, setGrossState] = useRecoilState(grossState);

  const payload = {
    key: indicator,
    huserIds: grossEntry.map(({ crmHuserId }: any) => crmHuserId),
    startDate: createdAt,
    endDate: dueDate,
  };

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CRM_VISIT_LIST, { ...payload }],
    () => getManageGrossUserResultAPI(payload)
  );

  if (isLoading) {
    return <div>hi</div>;
  }

  const ur = calGrossResult({
    key: content?.cal || 'sum',
    arr: data.crmManageGrossUserResult.map((o: any) =>
      Object.values(o).filter((v) => {
        return typeof v === 'number';
      })
    ),
  });

  const sum = data.crmManageGrossUserResult
    .map((o: any) =>
      Object.values(o).filter((v) => {
        return typeof v === 'number';
      })
    )
    .reduce((a: Number, b: any) => a + b[0], 0);

  const percent = (sum / gross) * 100;

  const onClickItem = (id: string) => {
    setGrossState((prev) => ({ ...prev, detailModal: { id: id, grossData: {}, visible: true } }));
  };

  const isSuccess = Number(ur) >= Number(gross);

  const OuterComponent = isSuccess ? SuccessComponent : FailureComponent;
  return (
    <div
      onClick={() => {
        onClickItem(id);
      }}
    >
      <OuterComponent>
        <div className="w-full flex flex-col">
          <div className={`w-full text-xl font-bold mb-2 ${isSuccess && 'text-white'}`}>
            {title}
          </div>
          <div className="flex w-full">
            <div className="flex-auto">
              <div className="font-base text-sm text-slate-400">목표 {content?.label}</div>
              <div className={`text-3xl ${isSuccess && 'text-white'}`}>
                {numberFormat(gross)}
                {content?.unit}
              </div>
            </div>
            <div className="flex-none">
              <Progress
                size={80}
                strokeColor={{ '0%': '#fff', '100%': '#fff' }}
                format={(c) => <div className="text-white">{percent}%</div>}
                type="circle"
                percent={percent}
                strokeWidth={10}
              />
            </div>
          </div>
          <div className="w-full">
            <EntryList entry={grossEntry || []} />
          </div>
        </div>
      </OuterComponent>
    </div>
  );
};

const SuccessComponent = ({ children }: any) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Progress: {
            circleTextColor: '#fff',
          },
        },
      }}
    >
      <div
        style={{ backgroundColor: '#602BF8' }}
        className="p-6 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] rounded-lg overflow-hidden"
      >
        {children}
      </div>
    </ConfigProvider>
  );
};

const FailureComponent = ({ children }: any) => {
  return (
    <div className="p-6 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] relative rounded-lg overflow-hidden">
      {/* 실패 - blackout */}
      <div className="absolute left-0 top-0 text-white w-full h-full bg-black bg-opacity-75 z-20">
        <div className="flex justify-center items-center h-full">
          <span className="text-5xl">🥴</span>
        </div>
      </div>
      {children}
    </div>
  );
};
