import Http from 'src/@http';
import {
  queryAddCrmCallHistory,
  queryAddCrmCallReserve,
  queryAddCrmHistoryCc,
  queryAddCrmItemByPatientIds,
  queryAddCrmItemHistory,
  queryAddCrmPaid,
  queryAddCrmPaidHistory,
  queryAddCrmRefToHistory,
  queryCrmBoardItemSummary,
  queryCrmBoardList,
  queryCrmCallList,
  queryCrmConsultList,
  queryCrmItem,
  queryCrmItemWithHistory,
  queryCrmList,
  queryCrmListWithHistory,
  queryCrmManageList,
  queryCrmPaidList,
  queryCrmPatientCallHistory,
  queryCrmPatientConstultHistory,
  queryCrmVisitList,
  queryGetCrmHistory,
  queryRemoveCrmHistoryCc,
  queryRemoveCrmItemHistory,
  queryRemoveCrmListHistory,
  queryRemoveCrmPaid,
  queryRemoveCrmPaidHistory,
  queryUpdateAssignHuserCrmItem,
  queryUpdateCallHistoryStatus,
  queryUpdateCrmBoardItem,
  queryUpdateCrmCallStatus,
  queryUpdateCrmItem,
  queryUpdateCrmItemHistory,
  queryUpdateCrmItemStatus,
  queryUpdateCrmPaid,
  queryUpdateCrmPaidHistory,
  queryAddCrmDeposit,
} from 'src/graphql/crm.query';
import { queryAddCrmItem } from 'src/graphql/patient.query';
import { errorResponse, successResponse } from 'src/libs/response';
import {
  AddCrmCallHistoryRequest,
  AddCrmCallHistoryResponse,
  AddCrmCallReserveRequest,
  AddCrmCallReserveResponse,
  AddCrmHistoryCcRequest,
  AddCrmHistoryCcResponse,
  AddCrmItemByPatientIdsRequest,
  AddCrmItemByPatientIdsResponse,
  AddCrmItemHistoryRequest,
  AddCrmItemHistoryResponse,
  AddCrmItemRequest,
  AddCrmItemResponse,
  AddCrmPaidHistoryRequest,
  AddCrmPaidHistoryResponse,
  AddCrmPaidRequest,
  AddCrmPaidResponse,
  AddCrmRefToHistoryRequest,
  AddCrmRefToHistoryResponse,
  GetCrmBoardItemSummaryRequest,
  GetCrmBoardItemSummaryResponse,
  GetCrmBoardListRequest,
  GetCrmBoardListResponse,
  GetCrmCallListRequest,
  GetCrmCallListResponse,
  GetCrmConsultListRequest,
  GetCrmConsultListResponse,
  GetCrmHistoryRequest,
  GetCrmHistoryResponse,
  GetCrmItemRequest,
  GetCrmItemResponse,
  GetCrmItemWithHistoryRequest,
  GetCrmItemWithHistoryResponse,
  GetCrmListRequest,
  GetCrmListResponse,
  GetCrmListWithHistoryRequest,
  GetCrmListWithHistoryResponse,
  GetCrmManageListRequest,
  GetCrmManageListResponse,
  GetCrmPaidListRequest,
  GetCrmPaidListResponse,
  GetCrmPatientCallHistoryRequest,
  GetCrmPatientCallHistoryResponse,
  GetCrmPatientConsultHistoryRequest,
  GetCrmPatientConsultHistoryResponse,
  GetCrmVisitListRequest,
  GetCrmVisitListResponse,
  RemoveCrmHistoryCcRequest,
  RemoveCrmHistoryCcResponse,
  RemoveCrmItemHistoryRequest,
  RemoveCrmItemHistoryResponse,
  RemoveCrmListHistoryRequest,
  RemoveCrmListHistoryResponse,
  RemoveCrmPaidHistoryRequest,
  RemoveCrmPaidHistoryResponse,
  RemoveCrmPaidRequest,
  RemoveCrmPaidResponse,
  UpdateAssignHuserCrmItemRequest,
  UpdateAssignHuserCrmItemResponse,
  UpdateCallHistoryStatusRequest,
  UpdateCallHistoryStatusResponse,
  UpdateCrmBoardItemRequest,
  UpdateCrmBoardItemResponse,
  UpdateCrmCallStatusRequest,
  UpdateCrmCallStatusResponse,
  UpdateCrmHistoryItemRepsonse,
  UpdateCrmHistoryItemRequest,
  UpdateCrmItemRequest,
  UpdateCrmItemResponse,
  UpdateCrmItemStatusRequest,
  UpdateCrmItemStatusResponse,
  UpdateCrmPaidHistoryRequest,
  UpdateCrmPaidHistoryResponse,
  UpdateCrmPaidRequest,
  UpdateCrmPaidResponse,
  AddCrmDepositRequest,
  AddCrmDepositResponse,
} from 'src/types/crm.types';
import { ApiResponse } from 'src/types/response';

export async function getCrmListAPI({ status }: GetCrmListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmListResponse>>('', {
      operationName: 'CrmList',
      query: queryCrmList,
      variables: {
        status,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmListAPI'));
}

export async function getCrmListWithHistoryAPI(payload: GetCrmListWithHistoryRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmListWithHistoryResponse>>('', {
      query: queryCrmListWithHistory,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmListWithHistoryAPI'));
}

export async function getCrmItemAPI({ id }: GetCrmItemRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmItemResponse>>('', {
      query: queryCrmItem,
      variables: {
        id,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmItemAPI'));
}

export async function getCrmItemWithHistoryAPI({ id, status }: GetCrmItemWithHistoryRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmItemWithHistoryResponse>>('', {
      operationName: 'CrmItemWithHistory',
      query: queryCrmItemWithHistory,
      variables: {
        id,
        status,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmItemWithHistoryAPI'));
}

export async function updateCrmItemAPI(payload: UpdateCrmItemRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmItemResponse>>('', {
      query: queryUpdateCrmItem,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmItemAPI'));
}

export async function updateCrmItemStatusAPI({ id, statusId }: UpdateCrmItemStatusRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmItemStatusResponse>>('', {
      operationName: 'UpdateCrmItemStatus',
      query: queryUpdateCrmItemStatus,
      variables: {
        id,
        statusId,
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmItemStatusAPI'));
}

export async function addCrmItemAPI(payload: AddCrmItemRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmItemResponse>>('', {
      query: queryAddCrmItem,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmItemAPI'));
}

export async function getCrmCallListAPI(payload: GetCrmCallListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmCallListResponse>>('', {
      query: queryCrmCallList,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmCallListAPI'));
}

export async function updateCrmItemHistoryAPI(payload: UpdateCrmHistoryItemRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmHistoryItemRepsonse>>('', {
      query: queryUpdateCrmItemHistory,
      variables: {
        input: payload,
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmItemHistoryAPI'));
}

export async function updateCallHistoryStatusAPI({ id, status }: UpdateCallHistoryStatusRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCallHistoryStatusResponse>>('', {
      operationName: 'UpdateCallHistoryStatus',
      query: queryUpdateCallHistoryStatus,
      variables: {
        id,
        status,
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCallHistoryStatusAPI'));
}

export async function addCrmRefToHistoryAPI({ id, huserId }: AddCrmRefToHistoryRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmRefToHistoryResponse>>('', {
      operationName: 'AddCrmRefToHistory',
      query: queryAddCrmRefToHistory,
      variables: {
        id,
        huserId,
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmRefToHistoryAPI'));
}

export async function addCrmCallReserveAPI(payload: AddCrmCallReserveRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmCallReserveResponse>>('', {
      operationName: 'AddCrmCallReserve',
      query: queryAddCrmCallReserve,
      variables: {
        payload,
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmCallReserveAPI'));
}

export async function updateCrmCallStatusAPI(payload: UpdateCrmCallStatusRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmCallStatusResponse>>('', {
      operationName: 'UpdateCrmCallStatus',
      query: queryUpdateCrmCallStatus,
      variables: {
        payload,
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmCallStatusAPI'));
}

export async function removeCrmListHistoryAPI({ id }: RemoveCrmListHistoryRequest) {
  return Http.instance
    .post<ApiResponse<RemoveCrmListHistoryResponse>>('', {
      operationName: 'RemoveCrmListHistory',
      query: queryRemoveCrmListHistory,
      variables: {
        id,
      },
    })
    .then(successResponse)
    .catch(errorResponse('removeCrmListHistoryAPI'));
}

export async function addCrmCallHistoryAPI(payload: AddCrmCallHistoryRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmCallHistoryResponse>>('', {
      query: queryAddCrmCallHistory,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmCallHistoryAPI'));
}

export async function getCrmHistoryAPI(payload: GetCrmHistoryRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmHistoryResponse>>('', {
      query: queryGetCrmHistory,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmHistoryAPI'));
}

export async function updateAssignHuserCrmItemAPI(payload: UpdateAssignHuserCrmItemRequest) {
  return Http.instance
    .post<ApiResponse<UpdateAssignHuserCrmItemResponse>>('', {
      query: queryUpdateAssignHuserCrmItem,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateAssignHuserCrmItemAPI'));
}

export async function addCrmItemHistoryAPI(payload: AddCrmItemHistoryRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmItemHistoryResponse>>('', {
      query: queryAddCrmItemHistory,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmItemHistoryAPI'));
}

export async function removeCrmItemHistoryAPI({ id }: RemoveCrmItemHistoryRequest) {
  return Http.instance
    .post<ApiResponse<RemoveCrmItemHistoryResponse>>('', {
      query: queryRemoveCrmItemHistory,
      variables: {
        input: { id },
      },
    })
    .then(successResponse)
    .catch(errorResponse('removeCrmItemHistoryAPI'));
}

export async function getCrmConsultListAPI(payload: GetCrmConsultListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmConsultListResponse>>('', {
      query: queryCrmConsultList,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmConsultListAPI'));
}

export async function getCrmPaidListAPI({ patientId }: GetCrmPaidListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmPaidListResponse>>('', {
      query: queryCrmPaidList,
      variables: {
        filter: { patientId },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmPaidListAPI'));
}

export async function addCrmPaidAPI(payload: AddCrmPaidRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmPaidResponse>>('', {
      query: queryAddCrmPaid,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmPaidAPI'));
}

export async function updateCrmPaidAPI(payload: UpdateCrmPaidRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmPaidResponse>>('', {
      query: queryUpdateCrmPaid,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmPaidAPI'));
}

export async function removeCrmPaidAPI({ id }: RemoveCrmPaidRequest) {
  return Http.instance
    .post<ApiResponse<RemoveCrmPaidResponse>>('', {
      query: queryRemoveCrmPaid,
      variables: {
        input: { id },
      },
    })
    .then(successResponse)
    .catch(errorResponse('removeCrmPaidAPI'));
}

export async function getCrmBoardListAPI(payload: GetCrmBoardListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmBoardListResponse>>('', {
      query: queryCrmBoardList,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmBoardListAPI'));
}

export async function getCrmBoardItemSummaryAPI({ listId }: GetCrmBoardItemSummaryRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmBoardItemSummaryResponse>>('', {
      query: queryCrmBoardItemSummary,
      variables: {
        filter: { listId },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmBoardItemSummaryAPI'));
}

export async function getCrmManageListAPI(payload: GetCrmManageListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmManageListResponse>>('', {
      query: queryCrmManageList,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmManageListAPI'));
}

export async function getCrmVisitListAPI(payload: GetCrmVisitListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmVisitListResponse>>('', {
      query: queryCrmVisitList,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmVisitListAPI'));
}

export async function updateCrmBoardItemAPI(payload: UpdateCrmBoardItemRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmBoardItemResponse>>('', {
      query: queryUpdateCrmBoardItem,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmBoardItemAPI'));
}

export async function getCrmPatientCallHistoryAPI({ id }: GetCrmPatientCallHistoryRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmPatientCallHistoryResponse>>('', {
      query: queryCrmPatientCallHistory,
      variables: {
        id,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmPatientCallHistoryAPI'));
}

export async function getCrmPatientConsultHistoryAPI({ id }: GetCrmPatientConsultHistoryRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmPatientConsultHistoryResponse>>('', {
      query: queryCrmPatientConstultHistory,
      variables: {
        id,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmPatientConsultHistoryAPI'));
}

export async function addCrmHistoryCcAPI(payload: AddCrmHistoryCcRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmHistoryCcResponse>>('', {
      query: queryAddCrmHistoryCc,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmHistoryCcAPI'));
}

export async function removeCrmHistoryCcAPI(payload: RemoveCrmHistoryCcRequest) {
  return Http.instance
    .post<ApiResponse<RemoveCrmHistoryCcResponse>>('', {
      query: queryRemoveCrmHistoryCc,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('removeCrmHistoryCcAPI'));
}

export async function addCrmPaidHistoryAPI(payload: AddCrmPaidHistoryRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmPaidHistoryResponse>>('', {
      query: queryAddCrmPaidHistory,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmPaidHistoryAPI'));
}

export async function updateCrmPaidHistoryAPI(payload: UpdateCrmPaidHistoryRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmPaidHistoryResponse>>('', {
      query: queryUpdateCrmPaidHistory,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmPaidHistoryAPI'));
}

export async function addCrmDepositAPI(payload: AddCrmDepositRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmDepositResponse>>('', {
      query: queryAddCrmDeposit,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmPaidHistoryAPI'));
}

export async function removeCrmPaidHistoryAPI(payload: RemoveCrmPaidHistoryRequest) {
  return Http.instance
    .post<ApiResponse<RemoveCrmPaidHistoryResponse>>('', {
      query: queryRemoveCrmPaidHistory,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('removeCrmPaidHistoryAPI'));
}

export async function addCrmItemByPatientIdsAPI({ patientIds }: AddCrmItemByPatientIdsRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmItemByPatientIdsResponse>>('', {
      query: queryAddCrmItemByPatientIds,
      variables: {
        input: { patientIds },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmItemByPatientIdsAPI'));
}
