import React from 'react';
import { useRecoilValue } from 'recoil';

import AccountManagement from 'src/components/Accounts/AccountManagement';
import ChargeManagement from 'src/components/Accounts/ChargeManagement';
import ChargeTable from 'src/components/Accounts/ChargeTable';
import CommunityManagement from 'src/components/Accounts/CommunityManagement';
import DefaultInfo from 'src/components/Accounts/DefaultInfo';
import EditableAccountInfo from 'src/components/Accounts/EditableAccountInfo';
import NormalAccountInfo from 'src/components/Accounts/NormalAccountInfo';
import TaggedIssue from 'src/components/Accounts/TaggedIssue';
import { authState } from 'src/recoil/auth.recoil';

export default function Accounts() {
  const auth = useRecoilValue(authState);
  const { isMaster } = auth.meh;

  return (
    <div>
      {isMaster ? (
        <>
          <div className="flex">
            <DefaultInfo />
            <AccountManagement />
          </div>
          <div className="flex">
            <ChargeManagement />
            <ChargeTable />
          </div>
        </>
      ) : (
        <>
          <div className="flex">
            <NormalAccountInfo />
            <TaggedIssue />
          </div>
          <div className="flex mt-5">
            <EditableAccountInfo />
            <CommunityManagement />
          </div>
        </>
      )}
    </div>
  );
}
