import { faker } from '@faker-js/faker';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  barPercentage: 0.3,
  borderSkipped: false,
  // borderRadius: { topLeft: 7, topRight: 7 },
  // borderRadius: [0, 0, { topLeft: 7, topRight: 7 }],
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Bar Chart - Stacked',
    },
    legend: {
      position: 'top' as const,
      display: false,
    },
  },
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        display: false,
        beginAtZero: true,
      },
      // to remove the y-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
  maintainAspectRatio: false,
};

type Props = {
  data?: any[];
};

export default function DataGroupChart({ data }: Props) {
  const labels = data?.map(({ date }) => dayjs(date).format('MM-DD')).reverse() || [];

  const dataSet = {
    labels,
    datasets: [
      {
        label: '예약', // bookCount
        // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        data: data?.map(({ bookCount }) => bookCount).reverse() || [],
        backgroundColor: 'rgba(67, 24, 235, 1)',
        stack: 'Stack 0',
      },
      {
        label: '내원', // visitCount
        data: data?.map(({ visitCount }) => visitCount).reverse() || [],
        backgroundColor: 'rgba(106, 210, 255, 1)',
        stack: 'Stack 0',
      },
      {
        label: 'DB 갯수', // allDBCount
        data: data?.map(({ allDBCount }) => allDBCount).reverse() || [],
        backgroundColor: 'rgba(239, 244, 251, 1)',
        stack: 'Stack 0',
        borderRadius: { topLeft: 7, topRight: 7 },
      },
    ],
  };

  return <Bar options={options} data={dataSet} />;
}
