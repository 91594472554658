import { atom } from 'recoil';

type State = {
  showAddGrossDrawer: boolean;
  detailModal: {
    id: string | null;
    visible: boolean;
    grossData: any;
  };
};

const initialState: any = {
  showAddGrossDrawer: false,
  detailModal: {
    id: null,
    grossData: {},
    visible: false,
  },
};

export const grossState = atom<State>({
  key: 'grossState',
  default: initialState,
});
