import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Upload,
  UploadFile,
  notification,
  Progress,
  Select,
  Space,
  message,
  Skeleton,
  Avatar,
  Tooltip,
  ConfigProvider,
  Popover,
  TreeSelect,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Axios from 'axios';
import dayjs from 'dayjs';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { signS3API } from 'src/api/common.api';
import { getManageGrossUserResultAPI, updateManageGrossPrivacyApi } from 'src/api/gross.api';
import useHuserList from 'src/hooks/useHuserList';
import useIssueList from 'src/hooks/useIssueList';
import useManageGrossList from 'src/hooks/useManageGrossList';
import { getAvatarPath, numberFormat } from 'src/libs/common';
import { QUERY_KEYS, GROSS_IC_LIST } from 'src/libs/constants';
import { HUSER_TREE_DATA } from 'src/libs/constants';
import { grossState } from 'src/recoil/manageGross.recoil';

const { Dragger } = Upload;
const { TextArea } = Input;

const { SHOW_PARENT } = TreeSelect;

type Form = {
  title: string;
  content: string;
};

type TableData = {
  id: string;
  huserId: string;
  createdAt: string;
  comment: string;
};

const TreeSelectHuser = () => {
  const [gState, setGrossState] = useRecoilState(grossState);
  const { hUserList } = useHuserList();
  const huserTreeSet = HUSER_TREE_DATA.map((tree: any) => {
    return {
      ...tree,
      children: hUserList
        .filter(({ type }) => type === tree.type)
        .map(({ id, name }) => ({
          title: name,
          value: id,
          // key: `${tree.key}-${id}`,
          key: id,
        })),
    };
  });

  // 공개설정 변경
  const updatePrivacy = useMutation(updateManageGrossPrivacyApi, {
    onSuccess: (res) => {
      message.success('공개 설정 변경 완료');

      // return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_ISSUE_LIST]);
    },
    onError: () => {
      message.success('공개 설정 변경 실패');
    },
    onSettled: () => {
      // onClose();
    },
  });
  const [value, setValue] = useState([...(gState.detailModal.grossData.privacy?.split(',') || [])]);

  const onChange = (newValue: string[]) => {
    setValue(newValue);
  };

  const tProps = {
    treeData: huserTreeSet,
    value: value,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: '참가자를 선택해 주세요',
    style: {
      width: '100%',
    },
  };

  return (
    <div>
      <TreeSelect onChange={onChange} {...tProps} />
      <Button
        type="primary"
        onClick={() => {
          const b = value.map((v) => {
            if (v.match(/0-/gi)) {
              return find(huserTreeSet, { key: v })?.children.map((cv: any) => cv.value);
            }

            return v;
          });

          const payload = { id: gState.detailModal.id, privacy: b.toString() };
          updatePrivacy.mutate(payload);
        }}
      >
        변경
      </Button>
    </div>
  );
};

const PrivacySetting = ({ grossId }: any) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={<TreeSelectHuser />}
      title="Title"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button type="primary">공개설정</Button>
    </Popover>
  );
};

const ModalBody = ({ id }: any) => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const { getUserById, hUserList } = useHuserList();
  const [images, setImages] = useState<any[]>([]);
  const [ccList, setCcList] = useState<any[]>([]);

  const { getGrossData } = useManageGrossList();
  const grossData = getGrossData(id || '');

  const payload = {
    key: grossData.indicator,
    huserIds: grossData.grossEntry.map(({ crmHuserId }: any) => crmHuserId),
    startDate: grossData.createdAt,
    endDate: grossData.dueDate,
  };

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CRM_USER_RESULT, { ...payload }],
    () => getManageGrossUserResultAPI(payload)
  );

  if (isLoading) return <div>hi</div>;

  const content = find(GROSS_IC_LIST, { key: grossData.indicator });

  // useEffect(() => {
  //   if (issue) {
  //     setValue('title', issue.title);

  //   }
  // }, [issue, setValue]);
  const huserResult = data.crmManageGrossUserResult;

  const sum = huserResult
    .map((o: any) =>
      Object.values(o).filter((v) => {
        return typeof v === 'number';
      })
    )
    .reduce((a: Number, b: any) => a + b[0], 0);

  const author = getUserById(grossData.authorId);

  return (
    <ConfigProvider
      theme={{
        components: {
          Progress: {
            circleTextColor: '#602BF8',
          },
        },
      }}
    >
      <div className="p-5 rounded-lg bg-[#fff]">
        {isLoading && <Skeleton.Button size={'large'} shape={'square'} active={true} />}
        {!isLoading && (
          <>
            <div className="gm-d-header flex w-full mb-10">
              <div className="text-2xl font-bold">{content?.label}</div>
              <div className="flex items-center ml-auto gap-7">
                <div className="flex items-center gap-1">
                  <Avatar.Group
                    maxCount={3}
                    maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                    size="small"
                  >
                    <Tooltip
                      placement="top"
                      // title={getUserById(v.ccHuserId) ? getUserById(v.ccHuserId).name : ''}
                      title={author.name}
                    >
                      <Avatar src={getAvatarPath(author.avatar)} size={30} />
                    </Tooltip>
                  </Avatar.Group>
                  <div>{author.name}</div>
                </div>

                <div className="ml-2">기간 ~ {dayjs(grossData.dueDate).format('YYYY-MM-DD')}</div>
                <div className="font-bold">
                  <PrivacySetting grossId={id} />
                </div>
              </div>
            </div>
            {/* -- 목표 관리 컨텐츠 -- */}
            <div className="gm-d-content flex flex-col gap-3 w-full">
              <div className="">
                <div>목표</div>
                <div className="text-xl font-bold">
                  {numberFormat(grossData.gross)}
                  {content?.unit}
                </div>
              </div>
              {/*  */}
              <div className="grid grid-cols-[1fr_1fr_minmax(300px,_1fr)] gap-10">
                {/* 1 */}
                <div>
                  <div>전체달성율</div>
                  <div className="text-lg font-bold" style={{ color: '#602BF8' }}>
                    {sum}%
                  </div>
                  <div className="text-lg font-bold">/{grossData.gross}</div>
                </div>
                {/* 2 */}
                <div>
                  {' '}
                  <Progress
                    strokeColor={{ '0%': '#602BF8', '100%': '#602BF8' }}
                    type="circle"
                    percent={sum}
                    strokeWidth={10}
                  />
                </div>
                {/* 3 */}
                <div className="flex-auto">
                  {huserResult.map((o: any) => {
                    const huser = getUserById(o.crmHuserId);

                    return (
                      <div className="flex gap-5 items-center my-[-10px]">
                        <Avatar.Group
                          maxCount={1}
                          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                          size="small"
                        >
                          <Tooltip
                            placement="top"
                            // title={getUserById(v.ccHuserId) ? getUserById(v.ccHuserId).name : ''}
                            title={huser.name}
                          >
                            <Avatar src={getAvatarPath(huser.avatar)} size={30} />
                          </Tooltip>
                        </Avatar.Group>
                        <div className="w-full">
                          <div className="leading-none mb-[-5px] mt-[12px]">
                            {o[content?.name || 'profit'] || 0}
                          </div>
                          <Progress percent={o[content?.name || 'profit']} showInfo={false} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/*  */}
            </div>
          </>
        )}
      </div>
    </ConfigProvider>
  );
};

export default function GrossDetailModal() {
  const [api, contextHolder] = notification.useNotification();
  const [gState, setGrossState] = useRecoilState(grossState);
  const { id, visible } = gState.detailModal;

  const onClose = () => {
    setGrossState((prev) => ({
      ...prev,
      detailModal: { id: null, grossData: {}, visible: false },
    }));
  };

  return (
    <Modal
      open={visible}
      footer={null}
      closable={false}
      width={700}
      onCancel={onClose}
      destroyOnClose
    >
      <div className="">
        {contextHolder}
        <ModalBody id={id} />
      </div>
    </Modal>
  );
}
