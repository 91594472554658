import React from 'react';
import { useParams } from 'react-router-dom';

import ManageDBFilter from 'src/components/Filter/ManageDBFilter';
import FormDrawer from 'src/components/ManageDB/FormDrawer';
import ManageDBTable from 'src/components/ManageDB/ManageDBTable';

export default function ManageDB() {
  const { patientId } = useParams<{ patientId: string }>();

  return (
    <div>
      <div className="mb-10">
        <ManageDBFilter />
      </div>
      <div>
        <ManageDBTable />
      </div>

      <FormDrawer patientId={patientId || ''} />
    </div>
  );
}
