import {
  HomeOutlined,
  ShoppingCartOutlined,
  BarChartOutlined,
  LineChartOutlined,
  PieChartOutlined,
  CustomerServiceOutlined,
  LockOutlined,
  AppstoreOutlined,
  AppstoreAddOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { isRightPermissionState } from 'src/recoil/auth.recoil';
import { commonState } from 'src/recoil/common.recoil';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export default function StyledMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPathname = location.pathname;
  const setCommon = useSetRecoilState(commonState);

  const isDashboard = useRecoilValue(isRightPermissionState('대시보드 보기'));
  const isCall = useRecoilValue(isRightPermissionState('콜 관리 보기'));
  const isConsult = useRecoilValue(isRightPermissionState('상담 관리 보기'));

  const menuList = useMemo(
    () => [
      isDashboard ? getItem('대시보드', '/dashboard', <HomeOutlined />) : null,
      getItem('예약관리', '/reservation', <ShoppingCartOutlined />),
      isCall ? getItem('콜관리', '/call', <BarChartOutlined />) : null,
      isConsult ? getItem('상담관리', '/consulting', <LineChartOutlined />) : null,
      getItem('내원DB 관리', '/db', <PieChartOutlined />),
      // getItem('목표관리', '/manage-gross', <PieChartOutlined />),
      // getItem('CS/ISSUE', '/cs', <CustomerServiceOutlined />),
      getItem('계정설정', '/accounts', <LockOutlined />),
      // getItem('컨텐츠 스튜디오', '/content/studio', <AppstoreOutlined />),
      // getItem('컨텐츠 관리', '/content/manage', <AppstoreAddOutlined />),
    ],
    [isDashboard, isCall, isConsult]
  );

  useEffect(() => {
    const clickedMenuItem = menuList.find((item) => item?.key === currentPathname);
    if (clickedMenuItem && 'label' in clickedMenuItem) {
      const label = clickedMenuItem.label;
      setCommon((prev) => ({ ...prev, currentLabel: label as string }));
    }
  }, [currentPathname, setCommon, menuList]);

  const onClick: MenuProps['onClick'] = (e) => {
    const clickedMenuItem = menuList.find((item) => item?.key === e.key);
    if (clickedMenuItem && 'label' in clickedMenuItem) {
      const label = clickedMenuItem.label;
      setCommon((prev) => ({ ...prev, currentLabel: label as string }));
    }

    navigate(e.key);
  };

  return (
    <Menu
      onClick={onClick}
      defaultSelectedKeys={[currentPathname]}
      selectedKeys={[currentPathname]}
      mode="inline"
      items={menuList}
    />
  );
}
