import Http from 'src/@http';
import {
  queryAddCrmHuser,
  queryBalaceHistory,
  queryCrmHospitalInfo,
  queryCrmHuserList,
  queryUpdateCrmHuserAuthority,
  queryUpdateCrmHuserInfo,
  queryUpdateCrmHuserSchedule,
} from 'src/graphql/hospital.query';
import { errorResponse, successResponse } from 'src/libs/response';
import {
  AddCrmHuserRequest,
  AddCrmHuserResponse,
  GetCrmBalanceHistoryRequest,
  GetCrmBalanceHistoryResponse,
  GetCrmHospitalInfoResponse,
  GetCrmHuserListResponse,
  UpdateCrmHuserAuthorityRequest,
  UpdateCrmHuserAuthorityResponse,
  UpdateCrmHuserInfoRequest,
  UpdateCrmHuserInfoResponse,
  UpdateCrmHuserScheduleRequest,
  UpdateCrmHuserScheduleResponse,
} from 'src/types/hospital.types';
import { PaginationRequest } from 'src/types/pagination.types';
import { ApiResponse } from 'src/types/response';

export async function getCrmHospitalInfoAPI() {
  return Http.instance
    .post<ApiResponse<GetCrmHospitalInfoResponse>>('', { query: queryCrmHospitalInfo })
    .then(successResponse)
    .catch(errorResponse('getCrmHospitalInfoAPI'));
}

export async function getCrmHuserListAPI() {
  return Http.instance
    .post<ApiResponse<GetCrmHuserListResponse>>('', {
      query: queryCrmHuserList,
      variables: {
        filter: {},
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmHuserListAPI'));
}

export async function updateCrmHuserInfoAPI(payload: UpdateCrmHuserInfoRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmHuserInfoResponse>>('', {
      query: queryUpdateCrmHuserInfo,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmHuserInfoAPI'));
}

export async function updateCrmHuserAuthorityAPI(payload: UpdateCrmHuserAuthorityRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmHuserAuthorityResponse>>('', {
      query: queryUpdateCrmHuserAuthority,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmHuserAuthorityAPI'));
}

export async function updateCrmHuserScheduleAPI(payload: UpdateCrmHuserScheduleRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmHuserScheduleResponse>>('', {
      query: queryUpdateCrmHuserSchedule,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmHuserAuthorityAPI'));
}

export async function getHospitalBalanceHistoryAPI(
  payload: GetCrmBalanceHistoryRequest,
  pagination?: PaginationRequest
) {
  return Http.instance
    .post<ApiResponse<GetCrmBalanceHistoryResponse>>('', {
      query: queryBalaceHistory,
      variables: {
        filter: { ...payload },
        pagination,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getHospitalBalanceHistoryAPI'));
}

export async function addCrmHuserAPI(payload: AddCrmHuserRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmHuserResponse>>('', {
      query: queryAddCrmHuser,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmHuserAPI'));
}
