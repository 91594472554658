import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Table,
  Input,
  notification,
  Switch,
  Select,
  Button,
  Progress,
  Avatar,
  Tooltip,
  Space,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { addCrmItemByPatientIdsAPI, getCrmVisitListAPI } from 'src/api/crm.api';
import { getManageGrossListAPI, addManageGrossAPI } from 'src/api/gross.api';
import useHuserList from 'src/hooks/useHuserList';
import { getAvatarPath, sortDate } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { manageDBState } from 'src/recoil/manageDB.recoil';
import { getStatusNameMapState } from 'src/recoil/status.recoil';
import { CrmVisit } from 'src/types/crm.types';

const { Search } = Input;

type TableData = CrmVisit;

export default function ManageDBTable() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [grossListData, setGrossListData] = useState<any[]>([]);
  const [patientIdList, setPatientIdList] = useState<string[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [{ startDate, endDate, isVisit }, setFilter] = useRecoilState(manageDBState);
  const { hUserList, isFetching: loadingHuserList } = useHuserList();
  const [search, setSearch] = useState('');
  const statusMap = useRecoilValue(getStatusNameMapState);
  const [hUserIds, setHuserIds] = useState<string[]>([]);

  useEffect(() => {
    if (!loadingHuserList && hUserList.length > 0) {
      setHuserIds(hUserList.filter((hUser) => hUser.type === 1).map(({ id }) => id));
    }
  }, [hUserList, loadingHuserList]);

  console.log('hUserList', hUserList);

  const createMarkup = (value: string) => {
    if (search === '') {
      return { __html: value };
    }

    return { __html: value.replaceAll(search, (match) => `<mark>${match}</mark>`) };
  };

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CRM_VISIT_LIST, { startDate, endDate, isVisit, hUserIds }],
    () => getManageGrossListAPI({})
  );

  //
  const addManageGross = useMutation(addManageGrossAPI, {
    onSuccess: (res) => {
      api.success({ message: '신규 목표관리 추가 성공' });
      console.log('res', res);

      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_ISSUE_LIST]);
    },
    onError: () => {
      api.error({ message: '신규 목표관리 추가 실패' });
    },
    onSettled: () => {
      // onClose();
    },
  });

  const onClickRow = (record: CrmVisit) => {
    const { id } = record.patient;
    navigate(`/db/${id}`);
  };

  const onChangeFilter = (checked: boolean) => {
    setFilter((prev) => ({ ...prev, isVisit: checked }));
  };

  const onClickAddButton = () => {
    addManageGross.mutate({
      title: '제목',
      indicator: 'test indicator',
      type: 'type',
      gross: 100,
      dueDate: '2024-02-11T01:00:00.000Z',
      entry: [],
    });
  };

  const EntryList = ({ entry }: any) => {
    const testEntry = [1, 2, 3];
    const entryList = [...testEntry, ...entry];

    return (
      <div>
        {/* {entry.map((e: any) => (
          <div>{e.huserId}</div>
        ))} */}

        <Avatar.Group
          maxCount={3}
          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
          size="small"
        >
          {entryList.map((v: any) => (
            <Tooltip
              placement="top"
              // title={getUserById(v.ccHuserId) ? getUserById(v.ccHuserId).name : ''}
              title={'test'}
              key={v.id}
            >
              <Avatar src={getAvatarPath(v.avatar)} size={30} />
            </Tooltip>
          ))}
        </Avatar.Group>
      </div>
    );
  };

  //  title: '제목',
  // indicator: 'test indicator',
  // type: 'type',
  // gross: 100,
  // dueDate: '2024-02-11T01:00:00.000Z',
  // entry: [],
  const Card = ({ data }: any) => {
    const { title, gross, entry } = data;
    return (
      <div className="p-6 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
        <div className="w-full flex flex-col">
          <div className="w-full text-xl font-bold mb-2">{title}</div>
          <div className="flex w-full">
            <div className="flex-auto">
              <div className="font-base text-slate-400">목표</div>
              <div className="text-3xl ">{gross}</div>
            </div>
            <div className="flex-none">
              <Progress type="circle" percent={75} size={80} />
            </div>
          </div>
          <div className="w-full">
            <EntryList entry={entry || []} />
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div>loading</div>;
  }

  return (
    <div className="mb-10">
      <Space size={100} direction="vertical" style={{ display: 'flex' }}>
        <div className="mt-3 p-5 rounded-lg bg-[#fff]">
          <div className="gm-d-header flex w-full mb-10">
            <div className="text-2xl font-bold">매출</div>
            <div className="flex ml-auto">
              <div>슈퍼마리오</div>
              <div>기간 ~ 2024.02.25</div>
              <div className="font-bold">공개설정</div>
            </div>
          </div>
          {/* -- 목표 관리 컨텐츠 -- */}
          <div className="gm-d-content flex flex-col gap-3 w-full">
            <div className="">
              <div>목표</div>
              <div className="text-xl font-bold">10%</div>
            </div>
            <div className="flex gap-2">
              {/* 1 */}
              <div>
                <div>전체달성율</div>
                <div>8.9%</div>
                <div className="text-l font-bold">/10%</div>
              </div>
              {/* 2 */}
              <div>
                {' '}
                <Progress type="circle" percent={75} />
              </div>
              {/* 3 */}
              <div>
                <Progress percent={30} />
                <Progress percent={30} />
                <Progress percent={30} />
              </div>
            </div>
          </div>
          {/* -- 목표 관리 컨텐츠 -- */}
        </div>
        {/* */}

        <div className="w-full mt-3 p-5 rounded-lg bg-[#fff]">
          <div className="text-2xl font-bold mb-10">진행중</div>
          <div className="grid grid-cols-5 gap-4">
            {data.crmManageGrossList.map((o: any) => (
              <Card data={o} />
            ))}

            <div>
              <div className="flex w-full h-full items-center justify-center">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  size={'large'}
                  onClick={onClickAddButton}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-3 p-5 rounded-lg bg-[#fff]">
          <div className="text-2xl font-bold mb-10">완료 목록</div>
          <div className="grid grid-cols-5 gap-4">
            {data.crmManageGrossList.map((o: any) => (
              <Card data={o} />
            ))}
          </div>
        </div>
      </Space>
    </div>
  );
}
