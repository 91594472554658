import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, notification } from 'antd';
import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { addCrmIssueCommentAPI } from 'src/api/issue.api';
import { QUERY_KEYS } from 'src/libs/constants';

type Form = {
  comment: string;
};

type Props = {
  issueId: string;
  currentPage: number;
};

export default function AddComment({ issueId, currentPage }: Props) {
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>();

  const addCrmIssue = useMutation(addCrmIssueCommentAPI, {
    onSuccess: (res) => {
      api.success({ message: '코멘트 작성 완료' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_ISSUE_COMMENT_LIST,
        { id: issueId, currentPage },
      ]);
    },
    onError: () => {
      api.error({ message: '코멘트 작성 실패' });
    },
    onSettled: () => {
      reset();
    },
  });

  const onSubmit: SubmitHandler<Form> = (data) => {
    addCrmIssue.mutate({ comment: data.comment, issueId });
  };

  return (
    <div className="mt-3 flex">
      {contextHolder}
      <Controller
        name="comment"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Input {...field} placeholder="코멘트" status={errors.comment ? 'error' : ''} />
        )}
      />
      <Button className="ml-1" onClick={handleSubmit(onSubmit)}>
        작성
      </Button>
    </div>
  );
}
