import { useRecoilValue } from 'recoil';

import { statusState } from 'src/recoil/status.recoil';

export default function useStatus() {
  const statusList = useRecoilValue(statusState);

  const getStatusColor = (id: string) => {
    const [status] = statusList.filter((status) => status.id === id);

    if (status) {
      const [bg, color] = status.color.split(',');

      return { bg, color };
    }

    return { bg: '#FF9494', color: '#000' };
  };

  return getStatusColor;
}
