import { CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import CallHistory from 'src/components/PatientBoard/CallHistory';
import ConsultHistory from 'src/components/PatientBoard/ConsultHistory';
import PatientInfo from 'src/components/PatientBoard/PatientInfo';
import PaymentDone from 'src/components/PatientBoard/PaymentDone';
import PaymentSummary from 'src/components/PatientBoard/PaymentSummary';
import VisitHistory from 'src/components/PatientBoard/VisitHistory';
import VisitStatus from 'src/components/PatientBoard/VisitStatus';
import WillPayment from 'src/components/PatientBoard/WillPayment';

export default function PatientBoard() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  return (
    <div className="bg-white rounded-lg p-5">
      <div className="flex items-center justify-between mb-5">
        <div className="text-[#2B3674] text-lg font-bold">환자 대시보드</div>
        <div className="cursor-pointer" onClick={() => navigate(-1)}>
          <CloseCircleOutlined />
        </div>
      </div>

      <div className="flex">
        <PatientInfo patientId={id || ''} />
        <VisitStatus patientId={id || ''} />
      </div>

      <div className="flex mt-7">
        <div className="flex-1">
          <CallHistory patientId={id || ''} />
        </div>
        <div className="flex-1 ml-5">
          <VisitHistory patientId={id || ''} />
        </div>
        <div className="w-[480px] ml-5">
          <ConsultHistory patientId={id || ''} />
        </div>
      </div>

      <div className="flex mt-16">
        <div className="flex-1">
          <WillPayment patientId={id || ''} />
        </div>
        <div className="flex-1 ml-5">
          <PaymentDone patientId={id || ''} />
        </div>
        <div className="w-[480px] ml-5">
          <PaymentSummary patientId={id || ''} />
        </div>
      </div>
    </div>
  );
}
