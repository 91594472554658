import { useQueries } from '@tanstack/react-query';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme, ConfigProvider } from 'antd';
import React, { useState } from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import HeaderLayout from './HeaderLayout';
import PageFeedBack from '../Feedback/Message';
import AddReservationModal from '../Reservation/AddReservationModal';
import Logo from '../Sidebar/Logo';
import StyledMenu from '../Sidebar/StyledMenu';
import TagWrapper from '../Sidebar/TagWrapper';
import { getCrmStatusListAPI } from 'src/api/status.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { statusState } from 'src/recoil/status.recoil';
import { GetCrmStatusListResponse } from 'src/types/status.types';

const { Header, Content, Footer, Sider } = Layout;

export default function MainLayout() {
  const setStatus = useSetRecoilState(statusState);
  const location = useLocation();
  let state = location.state as { backgroundLocation?: Location };
  const [collapsed, setCollapsed] = useState(true);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useQueries({
    queries: [
      {
        queryKey: [QUERY_KEYS.GET_STATUS_LIST],
        queryFn: getCrmStatusListAPI,
        onSuccess: (res: GetCrmStatusListResponse) => {
          setStatus(res.crmStatusList);
        },
      },
    ],
  });

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            /* here is your component tokens */
            siderBg: '#fff',
            triggerBg: '#fefefe',
            triggerColor: '#1821ff',
            headerBg: '#fff',
          },
        },
      }}
    >
      <PageFeedBack />
      <Layout className="flex h-full">
        <Header style={{ padding: '0 16px' }}>
          <HeaderLayout />
        </Header>

        <Layout>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            defaultCollapsed={true}
            // style={{ backgroundColor: '#fff' }}
          >
            <StyledMenu />
            {/* <TagWrapper /> */}
          </Sider>
          <Layout>
            <Content style={{ margin: '0 16px' }}>
              <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Pages</Breadcrumb.Item>
                <Breadcrumb.Item>{location.pathname.split('/')[1]}</Breadcrumb.Item>
              </Breadcrumb>
              <Outlet />
              {state?.backgroundLocation && (
                <Routes>
                  <Route path="/reservation/:id" element={<AddReservationModal />} />
                </Routes>
              )}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}
