import { Empty } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';

import EditableConsultHistoryCard from './EditableConsultHistoryCard';
import PatientHistory from 'src/components/Call/Container/PatientHistory';
import Loader from 'src/components/Loader';
import useConsultHistory from 'src/hooks/useConsultHistory';
import { isRightPermissionState } from 'src/recoil/auth.recoil';

type Props = {
  patientId: string;
};

// 상담 위젯: 상담 내역
export default function ConsultHistoryWidget({ patientId }: Props) {
  const isRightPermission = useRecoilValue(isRightPermissionState('상담 관리 편집'));
  const { getAllItemList, isFetching } = useConsultHistory(patientId);
  const historyList = getAllItemList();

  return (
    <div className="mt-5 relative min-h-[200px]">
      <div className="text-lg text-[#768396] font-bold mb-3">내역</div>
      {isFetching && <Loader />}
      {historyList.length === 0 ? (
        <Empty description="내역이 없습니다" />
      ) : (
        historyList.map((history) =>
          isRightPermission ? (
            <EditableConsultHistoryCard key={history.id} {...history} />
          ) : (
            <PatientHistory key={history.id} {...history} />
          )
        )
      )}
    </div>
  );
}
