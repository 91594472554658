import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import AddItemModal from 'src/components/CS/AddItemModal';
import BacklogModal from 'src/components/CS/BacklogModal';
import DragBoard from 'src/components/CS/DragBoard';
import ItemModal from 'src/components/CS/ItemModal';
import { csState } from 'src/recoil/cs.recoil';

export default function CS() {
  const setCsState = useSetRecoilState(csState);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) return null;

  return (
    <div className="flex flex-col">
      <div className="mb-3">
        <Button onClick={() => setCsState((prev) => ({ ...prev, showAddItemModal: true }))}>
          신규CS 추가
        </Button>
        <Button
          onClick={() =>
            setCsState((prev) => ({
              ...prev,
              backlogModal: {
                id: '',
                visible: true,
              },
            }))
          }
        >
          숨김 항목 보기
        </Button>
      </div>
      <DragBoard />
      <AddItemModal />
      <ItemModal />
      <BacklogModal />
    </div>
  );
}
