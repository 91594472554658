import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Spin, Upload, message } from 'antd';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import ImgCrop from 'antd-img-crop';
import Axios from 'axios';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { signS3API } from 'src/api/common.api';
import { updateCrmHuserInfoAPI } from 'src/api/hospital.api';
import useHuserList from 'src/hooks/useHuserList';
import { CRM_IMAGE_BUCKET } from 'src/libs/constants';
import { authState } from 'src/recoil/auth.recoil';

export default function NormalAccountInfo() {
  const { loginUser, isFetching } = useHuserList();

  return (
    <div className="bg-white rounded-lg shadow-lg p-10 flex flex-col justify-center items-center w-[540px] h-[335px]">
      <Spin spinning={isFetching}>
        <div className="flex flex-col justify-center items-center">
          <div className="w-[100px] aspect-square rounded-full">
            {!isFetching && <ProfileImageUpload huser={loginUser} />}
          </div>

          <div className="text-[#2B3674] font-bold text-xl mt-3">
            {loginUser.name} {loginUser.position}
          </div>
          <div className="text-[#A3AED0] text-sm mt-1">{loginUser.department}</div>
          {/* <div className="flex mt-10">
            <div className="flex flex-col items-center">
              <div className="text-[#2B3674] font-bold text-xl">17</div>
              <div className="text-[#A3AED0] text-sm mt-1">포스팅 수</div>
            </div>
            <div className="flex flex-col items-center mx-10">
              <div className="text-[#2B3674] font-bold text-xl">9.8k</div>
              <div className="text-[#A3AED0] text-sm mt-1">접속 수</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-[#2B3674] font-bold text-xl">274</div>
              <div className="text-[#A3AED0] text-sm mt-1">활동지수</div>
            </div>
          </div> */}
        </div>
      </Spin>
    </div>
  );
}

const ProfileImageUpload = ({ huser }: any) => {
  const uploadImages = useMutation(signS3API);

  const { id } = huser;

  function getImage(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('JPG/PNG 형식의 이미지만 올릴 수 있어요!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('이미지 크기가 너무 큽니다! (2MB/1024*1024 이햐)');
    }
    return isJpgOrPng && isLt2M;
  }

  const [loading, setLoading] = useState(false);
  const [imagePath, setImagePath] = useState(CRM_IMAGE_BUCKET + huser.avatar);

  const setProfilePicture = (img: any) => {
    console.log('set profile picture');
  };

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getImage(info.file.originFileObj, (imageUrl: any) => {
        setImagePath(imageUrl);
        setLoading(false);
        setProfilePicture(imageUrl);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const aa = async (file: any) => {
    const res = await uploadImages.mutateAsync({
      file: 'avatar/upload/' + file?.name || '',
      type: file?.type || '',
    });

    return {
      ...res,
      filePath: 'avatar/upload/' + file?.name,
    };
  };

  const updateHuser = useMutation(updateCrmHuserInfoAPI, {
    onSuccess: () => {
      message.success('수정이 완료되었습니다.');
      // return queryClient.invalidateQueries([QUERY_KEYS.GET_HUSER_LIST]);
    },
    onError: () => {
      message.error('수정이 실패했습니다.');
    },
    // onSettled: () => {
    //   setIsEdit(false);
    //   reset();
    // },
  });

  return (
    <ImgCrop>
      <Upload
        name="avatar"
        listType="picture-circle"
        className="avatar-uploader"
        accept="image/png, image/jpeg, image/jpg"
        showUploadList={false}
        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        // customRequest={({ file, onError, onSuccess, onProgress }) => {
        customRequest={async ({ file, onSuccess }) => {
          if (typeof file !== 'string') {
            // if (typeof file === 'RcFile') {
            //   console.log(file.name);
            // }
            const { signS3, filePath } = await aa(file);

            const uploadImgRes = await Axios.put(signS3.signedRequest, file, {
              headers: {
                'Content-Type': file.type,
              },
            });

            const a = updateHuser.mutate({
              id: id,
              avatar: filePath,
            });

            if (onSuccess) {
              onSuccess({ body: 'ok' });
            }
          }

          // const asyncActions = uploadImages.mutateAsync({
          //   file: file.name || '',
          //   type: file?.type || '',
          // });
        }}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        <div>
          {imagePath ? (
            <div className="rounded-full overflow-hidden w-[90px] h-[90px]">
              <img src={imagePath} alt="avatar" style={{ width: '100%' }} />
            </div>
          ) : (
            uploadButton
          )}
        </div>
      </Upload>
    </ImgCrop>
  );
};
