import { Empty, Spin } from 'antd';
import React from 'react';

import EditableVisitHistoryCard from './EditableVisitHistoryCard';
import usePatientHistory from 'src/hooks/usePatientHistory';

type Props = { patientId: string };

export default function VisitHistory({ patientId }: Props) {
  const { getStatusItemList, isFetching } = usePatientHistory(patientId);
  const visitHistoryList = getStatusItemList('3');

  return (
    <Spin spinning={isFetching}>
      <div className="min-h-[320px]">
        <div className="text-[#768396] font-bold text-base mb-1">예약 이력</div>
        {visitHistoryList.length === 0 && (
          <Empty description="예약 내역이 없습니다." className="mt-16" />
        )}
        {visitHistoryList.map((visitHistory) =>
          visitHistory.contents.map((content) => (
            <EditableVisitHistoryCard key={content.id} {...content} />
          ))
        )}
      </div>
    </Spin>
  );
}
