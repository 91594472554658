import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { getCrmHuserListAPI } from 'src/api/hospital.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { accountState } from 'src/recoil/account.recoil';
import { authState } from 'src/recoil/auth.recoil';

const defaultUser = {
  id: '',
  email: '',
  crmAuthority: 0,
  name: '',
  title: '',
  department: '',
  position: '',
  phone: '',
  contact: '',
  isMaster: false,
  isDelete: false,
  avatar: '',
};

export default function useHuserList() {
  const { selectedAccount } = useRecoilValue(accountState);
  const {
    meh: { id },
  } = useRecoilValue(authState);
  const { data, isLoading, isFetching } = useQuery(
    [QUERY_KEYS.GET_HUSER_LIST],
    getCrmHuserListAPI,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  const [selectedUser] = (data?.crmHuserList ?? []).filter((hUser) => hUser.id === selectedAccount);
  const [loginUser] = (data?.crmHuserList ?? []).filter((hUser) => hUser.id === id);

  const getUserById = (id: string) => {
    const hUserList = data?.crmHuserList || [];
    const [hUser] = hUserList.filter((hUser) => hUser.id === id);

    if (!hUser) {
      return {
        id: '',
        email: '',
        crmAuthority: 0,
        name: '',
        title: '',
        department: '',
        position: '',
        phone: '',
        contact: '',
        isMaster: false,
        isDelete: false,
        avatar: '',
      };
    }
    return hUser;
  };

  return {
    isFetching: isLoading || isFetching,
    hUserList: data?.crmHuserList || [],
    selectedUser,
    loginUser: loginUser ?? defaultUser,
    getUserById,
  };
}
