import dayjs from 'dayjs';
import { atom } from 'recoil';

type Filter = {
  startDate: string;
  endDate: string;
  showSearchPatientDrawer: boolean; // 환자 검색 drawer
  showAddPatientDrawer: boolean; // 환자 추가 drawer
  showExcelModal: boolean; // 엑셀파일 추가 modal
  showPatientDrawer: boolean; // 테이블 클릭했을때 환자 상세 drawer
  selectedList: string[];
  isNewVisit: boolean;
  hasCallCount: boolean;
  rowId: string;
};

const initialState = {
  startDate: dayjs().subtract(7, 'day').format(),
  endDate: dayjs().format(),
  showSearchPatientDrawer: false,
  showAddPatientDrawer: false,
  showExcelModal: false,
  showPatientDrawer: false,
  selectedList: [],
  isNewVisit: true,
  hasCallCount: false,
  rowId: '',
};

export const callManagementState = atom<Filter>({
  key: 'callManagementState',
  default: initialState,
});
