import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { useParams } from 'react-router-dom';

import FormDrawer from 'src/components/ManageGross/FormDrawer';
import ManageGrossDetail from 'src/components/ManageGross/GrossDetail';
import ManageGrossMain from 'src/components/ManageGross/GrossMain';

export default function ManageGross() {
  const { grossId } = useParams<{ grossId: string }>();

  return (
    <div>
      {grossId ? (
        <ManageGrossDetail />
      ) : (
        <>
          <ManageGrossMain />
          <FormDrawer patientId={''} />
        </>
      )}
    </div>
  );
}
