export const queryCrmPatient = `
  query($id: ID!) {
    crmPatient(id: $id) {
        id
        name
        phone
        address
        gender
        birth
        registerNumber
        latestStatusId
        latestList {
            id
        }
    }
  }
`;

export const queryUpdateCrmPatient = `
mutation UpdateCrmPatient($input: UpdateCrmPatientInput!) {
  updateCrmPatient(input: $input) {
      ok
      error {
          path
          message
      }
  }
}
`;

export const queryCrmPatientHistory = `
  query($id: ID!) {
    crmPatientHistory(id: $id) {
        id
        createdAt
        hospitalId
        status{
            id
            crmStatusId
            crmListId
            createdAt
            contents {
                id
                authorId
                assignHuserId
                hospitalId
                title
                memo
                startTime
                endTime
                isDelete
                createdAt
                statusId
                cc {
                  id
                  crmContentId
                  huserId
                }
            }
        }
        registerNumber
        referral
        therapy
        isNewVisit
        tmId
        csId
        drId
        paid {
          id
          hospitalId
          huserId
          crmListId
          crmPatientId
          pay
          paySum
          memo
          status
          isDelete
          history {
            id
            hospitalId
            huserId
            crmPaidId
            amount
            type
            memo
            option
            isDelete
          }
        }
    }
  }
`;

export const queryUpdateCrmHistory = `
  mutation UpdateCrmHistory($input: Input) {
    updateCrmHistory(input: $input) {
      ok
    }
  }
`;

export const queryAddCrmItem = `
  mutation AddCrmItem($input: AddCrmItemInput!) {
    addCrmItem(input: $input) {
        ok
        error {
          path
          message
        }
    }
  }
`;

export const queryCrmPatientList = `
query($filter: CrmPatientListFilter!) {
  crmPatientList(filter: $filter) {
      id
      name
      phone
      address
      gender
      birth
      latestStatusId
      registerNumber
      latestList {
          id
      }
  }
}
`;
