import { useQuery } from '@tanstack/react-query';
import { Table, Space, Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState, FC } from 'react';

import { getCommunityByTagAPI } from 'src/api/common.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { CommunityData } from 'src/types/common.types';

type TableData = CommunityData;

const columns: ColumnsType<TableData> = [
  {
    title: '아이디',
    dataIndex: 'id',
    key: 'id',
    width: 60,
  },
  {
    title: '닉네임',
    dataIndex: 'nickname',
    key: 'nickname',
    width: 150,
    ellipsis: true,
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
  {
    title: '내용',
    dataIndex: 'content',
    key: 'content',
    ellipsis: true,
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(`https://v2-dev.odoctor.co.kr/community/post/${record.id}`, '_blank');
          }}
        >
          원글보기
        </a>
      </Space>
    ),
  },
];

export default function CommunityManagement() {
  const [page, setPage] = useState(1);
  const [modalConfig, setModalConfig] = useState<any>({
    isOpen: false,
    constent: {
      title: '',
      content: '',
      author: '',
    },
  });

  const onClose = () => {
    setModalConfig({
      ...modalConfig,
      isOpen: false,
    });
  };

  const { data, isFetching, isLoading } = useQuery([QUERY_KEYS.GET_COMMUNITY_LIST, { page }], () =>
    getCommunityByTagAPI({ currentPage: page, perPage: 5 })
  );

  return (
    <div className="p-5 rounded-lg shadow-lg bg-white flex-1 ml-5">
      <div className="text-[#2B3674] font-bold text-xl">커뮤니티 관리</div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={data?.communitByTag.rows}
          rowKey={(record) => record.id}
          size="small"
          pagination={{ pageSize: 5 }}
          rowClassName="cursor-pointer"
          loading={isFetching || isLoading}
          onRow={(record) => {
            return {
              onClick: () => {
                setModalConfig({
                  isOpen: true,
                  content: {
                    ...record,
                  },
                });
              },
            };
          }}
        />
      </div>
      <CommunityCommentModal
        open={modalConfig.isOpen}
        content={modalConfig.content}
        onClose={onClose}
      ></CommunityCommentModal>
    </div>
  );
}

type ContentType = {
  content: {
    id: string;
    title: string;
    content: string;
    nickname: string;
  };
};
interface SomeComponentProps {
  open: boolean;
  onClose: () => void;
}

const CommunityCommentModal: FC<SomeComponentProps & ContentType> = ({
  open = false,
  content,
  onClose,
}) => {
  if (content) {
    const { id, title, content: body, nickname } = content;
    return (
      <Modal open={open} onCancel={onClose}>
        <div>{id}</div>
        <div>{title}</div>
        <div>{body}</div>
        <div>{nickname}</div>
      </Modal>
    );
  }
  return null;
};
