import { useQuery } from '@tanstack/react-query';
import { Table, Select, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getCrmManageListAPI } from 'src/api/crm.api';
import useHuserList from 'src/hooks/useHuserList';
import { getGender } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { getPayStatus } from 'src/libs/payment';
import { reservationFilterState } from 'src/recoil/reservationFilter.recoil';
import { getStatusNameMapState } from 'src/recoil/status.recoil';
import { CrmManage } from 'src/types/crm.types';

const statusIds = ['10', '11', '12'];

export default function ClinicStatus() {
  const navigate = useNavigate();
  const { selectedDate } = useRecoilValue(reservationFilterState);
  const statusMap = useRecoilValue(getStatusNameMapState);
  const { hUserList, getUserById } = useHuserList();
  const allHuserIds = [
    'unspecified', // 미지정
    ...hUserList.filter((hUser) => hUser.type === 4).map(({ id }) => id),
  ];
  const [hUserIds, setHuserIds] = useState(allHuserIds);

  const { data } = useQuery(
    [QUERY_KEYS.GET_CRM_MANAGE_LIST, { selectedDate, hUserIds, statusIds }],
    () =>
      getCrmManageListAPI({
        date: dayjs(selectedDate).format(),
        statusIds,
        assignHuserIds: hUserIds,
      })
  );

  const onClickAll = () => {
    setHuserIds(allHuserIds);
  };

  const columns: ColumnsType<CrmManage> = [
    {
      title: '시간',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (_, row) => dayjs(row.startTime).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '등록번호',
      dataIndex: 'registerNumber',
      key: 'registerNumber',
      render: (_, record) => record.patient.registerNumber,
    },
    {
      title: '환자정보',
      dataIndex: 'patientInfo',
      key: 'patientInfo',
      render: (_, { patient }) => (
        <div>
          {patient.name} / {getGender(patient.gender)} / {patient.birth}
        </div>
      ),
    },
    {
      title: '예약메모',
      dataIndex: 'latestMemo',
      key: 'latestMemo',
    },
    {
      title: '담당Dr.',
      dataIndex: 'assignUser',
      key: 'assignUser',
      render: (_, row) => (
        <div>
          {row.drId ? getUserById(row.drId)?.name : '미지정'}
          {/*  예약: {getUserById(row.csId)?.name} 진료:  */}
        </div>
      ),
    },
    {
      title: '진료상태',
      dataIndex: 'latestStatusId',
      key: 'latestStatusId',
      render: (_, row) => statusMap[row.latestStatusId],
    },
    // {
    //   title: '결제상태',
    //   dataIndex: 'paid',
    //   key: 'paid',
    //   render: (_, row) => getPayStatus(row.paid),
    // },
    {
      title: '진료시간',
      dataIndex: 'paid',
      key: 'paid',
      render: (_, row) => <div>{row.twTime ? dayjs(row.twTime).format('HH:mm:ss') : '-'}</div>,
    },
  ];

  const onClickRow = (record: CrmManage) => {
    navigate(`/patient/${record.patient.id}`);
  };

  return (
    <div className="p-3 rounded-lg bg-white">
      <div className="flex justify-between items-center">
        <div className="text-[#2B3674] text-xl font-bold">진료현황</div>
        <div className="ml-10 items-center flex flex-1">
          <Button className="mr-3" onClick={onClickAll}>
            모든담당자
          </Button>
          <Select
            mode="multiple"
            placeholder="담당자 선택"
            options={[
              {
                value: 'unspecified',
                label: '미지정',
              },
              ...hUserList
                .filter((hUser) => hUser.type === 4)
                .map(({ id, name, position }) => ({
                  value: id,
                  label: `[${position}] ${name}`,
                })),
            ]}
            className="w-full min-w-[300px]"
            value={hUserIds}
            onChange={(value) => setHuserIds(value)}
          />
        </div>
      </div>

      <div className="mt-3">
        <Table<CrmManage>
          columns={columns}
          dataSource={data?.crmManageList}
          rowKey={(record) => record.id}
          rowClassName="clickable"
          onRow={(record, rowIndex) => ({
            onClick: () => onClickRow(record),
          })}
        />
      </div>
    </div>
  );
}
