import { atom } from 'recoil';

type State = {
  selectedAccount: string;
  startDate: string;
  endDate: string;
  openDrawer: boolean;
};

const initialState = {
  selectedAccount: '0',
  startDate: '',
  endDate: '',
  openDrawer: false,
};

export const accountState = atom<State>({
  key: 'accountState',
  default: initialState,
});
