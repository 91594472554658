export const queryCrmTmStatisticDaily = `
  query($filter: CrmStatisticFilter) {
    crmTmStatisticDaily(filter: $filter) {
      id
      totalCallCount
      totalBookCount
      todayCallCount
      todayBookCount
    }
  }
`;

export const queryCrmTmStatistic = `
  query($filter: CrmStatisticFilter) {
    crmTmStatistic(filter: $filter) {
      id
      callCount
      callListCount
      avgCallCount
      avgCallNum
      firstBookCount
      secondBookCount
      bookCount
      bookRate
      visitCount
      visitRate
      callCb
      saleCb
    }
  }
`;

export const queryCrmCsStatisticDaily = `
  query($filter: CrmStatisticFilter) {
    crmCsStatisticDaily(filter: $filter) {
      id
      totalConsultCount
      totalAgreeRate
      todayConsultCount
      todayAgreeRate
    }
  }
`;

export const queryCrmCsStatistic = `
  query($filter: CrmStatisticFilter) {
    crmCsStatistic(filter: $filter) {
      id
      sale
      consultCount
      paidCount
      unitPrice
      agreeRate
      returnSale
      returnRate
      unpaidTotal
      csCb
      saleCb
    }
  }
`;

export const queryCrmDashStatistic = `
  query($filter: CrmStatisticFilter) {
    crmDashStatistic(filter: $filter) {
      profit
      net
      roas
      expenditure
      allDBCount
      bookCount
      visitCount
      visitRate
      agreeRate
    }
  }
`;

export const queryCrmDashStatisticList = `
  query($filter: CrmStatisticFilter) {
    crmDashStatisticList(filter: $filter) {
      id
      date
      firstDBCount
      secondDBCount
      allDBCount
      bookCount
      visitCount
      visitRate
      agreeRate
      expenditure
      profit
      roas
      net
    }
  }
`;

export const queryCrmReferralCount = `
  query($filter: CrmStatisticFilter) {
    crmReferralCount(filter: $filter) {
      referral
      count
  }
}
`;
