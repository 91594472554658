import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Table, Select, Input, notification, Tooltip, Popover, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { isString } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getCrmCallListAPI, updateAssignHuserCrmItemAPI } from 'src/api/crm.api';
import { getCrmReferralCountAPI } from 'src/api/statistic.api';
import useHuserList from 'src/hooks/useHuserList';
import useReferralCount from 'src/hooks/useReferralCount';
import { columnSorter } from 'src/libs/common';
import { getGender } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { authState, isRightPermissionState } from 'src/recoil/auth.recoil';
import { callManagementState } from 'src/recoil/callManagement.recoil';
import { getStatusNameMapState } from 'src/recoil/status.recoil';
import { CrmCall } from 'src/types/crm.types';

const { Search } = Input;

type TableData = CrmCall;

const alias = [
  {
    url: 'contents.imweb.me|odoctor|unaging',
    name: '어뮤즈케어',
  },
  {
    url: '제이슨',
    name: '제이슨',
  },
  {
    url: '한걸음컴퍼니',
    name: '한걸음컴퍼니',
  },
  {
    url: 'ssmbio|ssmdiet|ssm',
    name: '신생명공학연구소',
  },
];

// 0 미납
// 1 완납
// 2 부분납부
// 3 부분환불
// 4 장기미납
// 5 결제취소
// 6 과오납
const paidStatus = (status: number = 0) => {
  return {
    0: '-',
    1: '완납',
    2: '부분납부',
    3: '부분환불',
    4: '장기미납',
    5: '결제취소',
    6: '과오납',
  }[status as number];
};

const mergeReferralWithAlias = (arr: any) => {
  const map: any = {};
  arr
    .map(({ referral, ...o }: any) => {
      const aliasName: any = alias.find(({ url }) => {
        const regex = new RegExp(`${url}`, 'g');
        return regex.test(referral);
      });

      return {
        ...o,
        referral: aliasName ? aliasName.name : referral,
      };
    })
    .forEach(({ referral, count }: any) => {
      map[referral] = (map[referral] || 0) + count;
    });

  return map;
};

const aliasReferral = (ref: string) => {
  if (isString(ref)) {
    const aliasName = alias.find(({ url }) => {
      const regex = new RegExp(`${url}`, 'g');
      return regex.test(ref);
    });

    if (aliasName) {
      const regex = new RegExp(`${aliasName.url}`, 'g');
      return ref
        .replace(/^https?|^http?/, '')
        .replace(regex, aliasName.name)
        .replace(/[&\/\#,+$~%.'":@^*?<>{}]/g, '');
    }
  }

  return ref;
};

export default function CallTableWidget() {
  const { hUserList, loginUser } = useHuserList();

  if (loginUser.id === '') {
    return <div></div>;
  }

  return <TableWidget loginUser={loginUser} />;
}

const ReferralCountDisplay = () => {
  const [callManagement] = useRecoilState(callManagementState);
  const { startDate, endDate } = callManagement;
  const auth = useRecoilValue(authState);
  const hospitalId = auth.meh.hospital.id;
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.GET_CALL_REFERRAL_COUNT, { hospitalId, startDate, endDate }],
    () =>
      getCrmReferralCountAPI({
        hospitalId,
        startDate: dayjs(startDate).startOf('day').format(),
        endDate: dayjs(endDate).endOf('day').format(),
      })
  );
  if (isLoading) return <Spin />;
  if (!data) return <div></div>;

  const mc = mergeReferralWithAlias(data.crmReferralCount);

  return (
    <div>
      {Object.entries(mc).map(([key, v], i) => {
        return (
          <div key={i}>
            {key}: {`${v}`}
          </div>
        );
      })}
    </div>
  );
};

// popever
const ReferralHeader = ({ children }: any) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={open && <ReferralCountDisplay />}
      title="유입경로 분석"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      {children}
    </Popover>
  );
};

function TableWidget({ loginUser }: any) {
  const auth = useRecoilValue(authState);
  const hospitalId = auth.meh.hospital.id;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [callManagement, setCallManagement] = useRecoilState(callManagementState);
  const statusMap = useRecoilValue(getStatusNameMapState);
  const isRightPermission = useRecoilValue(isRightPermissionState('콜 담당 지정'));
  const { isNewVisit, hasCallCount, startDate, endDate } = callManagement;
  const { hUserList } = useHuserList();

  const { rc } = useReferralCount(
    hospitalId,
    dayjs(startDate).startOf('day').format(),
    dayjs(endDate).endOf('day').format()
  );

  const r_mc = mergeReferralWithAlias(rc);

  const [search, setSearch] = useState('');

  const createMarkup = (value: string) => {
    if (search === '') {
      return { __html: value };
    }

    return { __html: value?.replaceAll(search, (match) => `<mark>${match}</mark>`) };
  };

  const columns: ColumnsType<TableData> = [
    {
      title: '번호',
      width: '5rem',
      dataIndex: 'registerNumber',
      key: 'registerNumber',
      render: (_, record) => (
        <div dangerouslySetInnerHTML={createMarkup(record.patient.registerNumber)} />
      ),
      // sorter: (a, b) => columnSorter(a, b, 'patient.registerNumber'),
    },
    {
      title: '날짜',
      width: '7rem',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => dayjs(record.createdAt).format('MM-DD HH:mm'),
      sorter: (a, b) => columnSorter(a, b, 'createdAt'),
    },
    {
      title: '이름',
      width: '5rem',
      dataIndex: 'name',
      key: 'name',
      // render: (_, record) => <div dangerouslySetInnerHTML={createMarkup(record.patient.name)} />,
      render: (_, record) => {
        return <div dangerouslySetInnerHTML={createMarkup(`${record.patient.name}`)} />;
      },
      // 이름 검색이 필요한 경우
      // https://4x.ant.design/components/table/#components-table-demo-custom-filter-panel 참고

      // onFilter: (value: any, record) => record.patient.gender.startsWith(value),
      // sorter: (a, b) => columnSorter(a, b, 'patient.name'),
    },
    {
      title: '나이',
      width: '3rem',
      dataIndex: 'age',
      key: 'age',
      // render: (_, record) => <div dangerouslySetInnerHTML={createMarkup(record.patient.name)} />,
      render: (_, record) => dayjs().year() - record.patient.birth,
    },
    {
      title: '성별',
      width: '4rem',
      dataIndex: 'gender',
      key: 'gender',
      // render: (_, record) => <div dangerouslySetInnerHTML={createMarkup(record.patient.name)} />,
      render: (_, record) => {
        const gender = record.patient.gender && `${getGender(record.patient.gender, 1)}`;
        return <div dangerouslySetInnerHTML={createMarkup(`${gender}`)} />;
      },
      // 이름 검색이 필요한 경우
      // https://4x.ant.design/components/table/#components-table-demo-custom-filter-panel 참고
      // filters: [
      //   {
      //     text: '남',
      //     value: 'm',
      //   },
      //   {
      //     text: '여',
      //     value: 'f',
      //   },
      // ],
      // onFilter: (value: any, record) => record.patient.gender.startsWith(value),
      // sorter: (a, b) => columnSorter(a, b, 'patient.name'),
    },
    {
      title: '연락처',
      dataIndex: 'contact',
      key: 'contact',
      width: 130,
      render: (_, record) => <div dangerouslySetInnerHTML={createMarkup(record.patient.phone)} />,
      // sorter: (a, b) => columnSorter(a, b, 'patient.phone'),
    },
    {
      title: '시술명',
      dataIndex: 'therapy',
      key: 'therapy',
      ellipsis: true,
      width: 100,
      render: (_, record) => record.therapy || '-',
      // sorter: (a, b) => columnSorter(a, b, 'therapy'),
    },
    {
      title: () => <ReferralHeader>유입경로</ReferralHeader>,
      dataIndex: 'referral',
      key: 'referral',
      ellipsis: true,
      width: 100,
      render: (_, record) => (record.referral ? aliasReferral(record.referral) : '-'),
      // sorter: (a, b) => columnSorter(a, b, 'referral'),
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            // console.log('onClick');
          },
        };
      },
      filters: [
        ...Object.entries(r_mc).map(([key, value]) => ({
          text: `${key}(${value})`,
          value: key,
        })),
      ],

      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value: any, record) => {
        return aliasReferral(record.referral).includes(value);
      },
    },
    {
      title: '차수',
      dataIndex: 'callCount',
      key: 'callCount',
      width: '4rem',
      sorter: {
        compare: (a, b) => a.callCount - b.callCount,
      },
      responsive: ['md'],
    },

    {
      title: '답변1',
      dataIndex: 'etc',
      key: 'answewr1',
      width: '3rem',
      render: (_, record) => {
        if (record.etc) {
          return (
            <Tooltip placement="top" title={record.etc.question.answer1}>
              {record.etc.question.answer1}
            </Tooltip>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: '답변2',
      dataIndex: 'etc',
      key: 'answewr2',
      width: '3rem',
      render: (_, record) => {
        if (record.etc) {
          return (
            <Tooltip placement="top" title={record.etc.question.answer2}>
              {record.etc.question.answer2}
            </Tooltip>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: '답변3',
      dataIndex: 'etc',
      key: 'answewr3',
      width: '3rem',
      render: (_, record) => {
        if (record.etc) {
          return (
            <Tooltip placement="top" title={record.etc.question.answer3}>
              {record.etc.question.answer3}
            </Tooltip>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: '메모',
      dataIndex: 'latestMemo',
      key: 'latestMemo',
      width: '13%',
      render: (_, record) => {
        if (record.memo) {
          return (
            <Tooltip placement="top" title={record.memo}>
              {record.latestMemo || record.memo || '-'}
            </Tooltip>
          );
        } else {
          return record.latestMemo || record.memo || '-';
        }
      },
      ellipsis: true,
    },
    {
      title: '담당자',
      dataIndex: 'tmId',
      key: 'tmId',
      width: '10rem',
      render: (_, record) => {
        const [user] = hUserList.filter((hUser) => hUser.id === record.tmId);
        if (user) {
          return user.name;
        }
        return '미지정';
      },
      sorter: (a, b) => columnSorter(a, b, 'tmId'),
      filters: [
        ...hUserList
          .filter(({ type }) => type === 1)
          .map(({ name, id }) => ({
            text: name,
            value: id,
          })),

        { text: '미지정', value: 'null' },
      ],
      ...(!loginUser.isMaster && { defaultFilteredValue: [loginUser.id] }),
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value: any, record) => {
        if (value === 'null') {
          return record.tmId === null;
        }
        return record.tmId === value;
      },
    },
    {
      title: '상태',
      width: '5rem',
      dataIndex: 'latestStatusId',
      key: 'latestStatusId',
      render: (_, { latestStatusId }) => statusMap[latestStatusId],
      sorter: (a, b) => columnSorter(a, b, 'tmId'),
      filters: [
        {
          text: '콜',
          value: '콜',
        },
        {
          text: '부재',
          value: '부재',
        },
        {
          text: '결번',
          value: '결번',
        },
      ],
      onFilter: (value: any, { latestStatusId }) => statusMap[latestStatusId].startsWith(value),
      // filterSearch: true,
    },
    {
      title: '결제',
      width: '5rem',
      dataIndex: 'paid',
      key: 'paid',
      render: (_, { paid }) => paidStatus(paid?.status || 0),
      sorter: (a, b) => columnSorter(a, b, 'tmId'),
      // filters: [
      //   {
      //     text: '콜',
      //     value: 1,
      //   },
      //   {
      //     text: '부재',
      //     value: '부재',
      //   },
      //   {
      //     text: '결번',
      //     value: '결번',
      //   },
      // ],
      // onFilter: (value: any, { latestStatusId }) => statusMap[latestStatusId].startsWith(value),
      // filterSearch: true,
    },
  ];

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CALL_LIST, { isNewVisit, hasCallCount, startDate, endDate }],
    () =>
      getCrmCallListAPI({
        isNewVisit,
        hasCallCount,
        startDate: dayjs(startDate).startOf('day').format(),
        endDate: dayjs(endDate).endOf('day').format(),
      })
  );

  const updateAssignHuserCrm = useMutation(updateAssignHuserCrmItemAPI, {
    onSuccess: (res) => {
      api.success({ message: '콜 담당 지정 완료.' });
      queryClient.invalidateQueries([QUERY_KEYS.GET_CALL_LIST, { isNewVisit, startDate, endDate }]);
    },
    onError: () => {
      api.error({ message: '콜 담당 지정 실패.' });
    },
    onSettled: () => {
      setCallManagement((prev) => ({ ...prev, rowKeys: [], selectedList: [] }));
    },
  });

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TableData[]) => {
      setCallManagement((prev) => ({ ...prev, selectedList: selectedRows.map(({ id }) => id) }));
    },
  };

  const onClickRow = (record: CrmCall, rowIndex: number | undefined) => {
    const info = record.id + '_' + record.patient.id;
    navigate(`/call/${info}`);
  };

  const onAssignment = (tmId: string) => {
    updateAssignHuserCrm.mutate({ ids: callManagement.selectedList, tmId });
  };

  const searchedList = data?.crmCallList.filter(
    (value) =>
      (value.patient.phone && value.patient.phone.includes(search)) ||
      (value.patient.name && value.patient.name.includes(search)) ||
      (value.therapy && value.therapy.includes(search)) ||
      (value.patient.registerNumber && value.patient.registerNumber.includes(search))
  );

  const displayTableName = () => {
    if (isNewVisit) {
      return hasCallCount ? '신규 콜 진행중' : '신규 콜 대기';
    } else {
      return hasCallCount ? '구 고객 재콜 진행중' : '구 고객 재콜 대기';
    }
  };

  return (
    <div className="mt-3 p-5 rounded-lg bg-[#fff]">
      {contextHolder}
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="text-[#2B3674] text-xl font-bold">{displayTableName()}</div>
          <div className="ml-5">
            {isRightPermission && (
              <Select
                style={{ width: 200 }}
                onChange={(value) => onAssignment(value)}
                options={hUserList
                  .filter((hUser) => hUser.type === 1)
                  .map((hUser) => ({ value: hUser.id, label: hUser.name }))}
                disabled={callManagement.selectedList.length === 0}
                placeholder="콜 담당 지정"
              />
            )}
          </div>
        </div>
        <div className="flex">
          <Search
            placeholder="등록번호, 이름, 연락처, 시술명 검색"
            onSearch={(value) => setSearch(value)}
            className="w-[300px]"
          />
        </div>
      </div>
      <div className="mt-5">
        <Table
          pagination={{ pageSize: 20 }}
          size="small"
          columns={columns}
          dataSource={searchedList}
          rowKey={(record) => record.id}
          rowClassName="clickable"
          rowSelection={
            isRightPermission
              ? {
                  type: 'checkbox',
                  ...rowSelection,
                }
              : undefined
          }
          onRow={(record, rowIndex) => ({
            onClick: () => onClickRow(record, rowIndex),
          })}
          loading={isFetching || isLoading}
          locale={{
            triggerDesc: '내림차순',
            triggerAsc: '오름차순',
            cancelSort: '정렬 취소',
            filterConfirm: '확인',
            filterReset: '전체보기',
          }}
        />
      </div>
    </div>
  );
}
