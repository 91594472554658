import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Select,
  DatePicker,
  Input,
  InputNumber,
  notification,
  Radio,
  Switch,
  ConfigProvider,
  Collapse,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { useForm, Controller, SubmitHandler, FormProvider } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import SearchForm from '../Call/SearchForm';
import AssignSelector from '../Form/Controllers/AssignHuserSelect';
import AssignTreeSelector from '../Form/Controllers/AssignHuserTreeSelect';
import GtypeSelector from '../Form/Controllers/GrossTypeSelect';
import ListTagsSelector from '../Form/Controllers/ListTagsSelect';
import NRCTypeSelector from '../Form/Controllers/NRCTypeSelect';
import { addCrmItemAPI } from 'src/api/crm.api';
import { addManageGrossAPI } from 'src/api/gross.api';
import useHuserList from 'src/hooks/useHuserList';
import { displayDateTime, getDisplayTime } from 'src/libs/common';
import { QUERY_KEYS, TIME_LENGTH_LIST } from 'src/libs/constants';
import TimeTableDrawer from 'src/libs/TimeTableDrawer';
import { callManagementState } from 'src/recoil/callManagement.recoil';
import { reservationFilterState } from 'src/recoil/reservationFilter.recoil';
import { getStatusListByGroupStatus } from 'src/recoil/status.recoil';
import { AddCrmItemRequest } from 'src/types/crm.types';

const { TextArea } = Input;

type Form = {
  title: string;
  indicator: string;
  type: string;
  gross: number;
  dueDate: string;
  entry: [];
};

const patientKeys = ['title', 'indicator', 'type', 'gross', 'dueDate', 'entry'];

export default function CreateGrossForm() {
  const [showAdditionalForm, setShowAdditionalForm] = useState(false);
  const queryClient = useQueryClient();
  const { hUserList } = useHuserList();
  const [api, contextHolder] = notification.useNotification();

  const methods = useForm<Form>({
    defaultValues: {},
  });

  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const addCrmItem = useMutation(addCrmItemAPI, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_BOARD_LIST, {}]);

      onClose();
    },
  });

  // 목표(관리) 추가
  const addManageGross = useMutation(addManageGrossAPI, {
    onSuccess: (res) => {
      reset({
        type: 'solo',
        dueDate: undefined,
        entry: undefined,
      });
      api.success({ message: '신규 목표관리 추가 성공' });

      return queryClient.invalidateQueries([QUERY_KEYS.ADD_CRM_MANAGE_GROSS]);
    },
    onError: () => {
      api.error({ message: '신규 목표관리 추가 실패' });
    },
    onSettled: () => {
      // onClose();
    },
  });

  const onClose = () => {
    // setFilter((prev) => ({ ...prev, showAddPatientDrawer: false }));
    reset();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    addManageGross.mutate({
      ...data,
      dueDate: dayjs(data.dueDate).format(),
      gross: Number(data.gross),
    });

    // const { postAddress, address, ...onlyPatient } = Object.fromEntries(
    //   Object.entries(data)
    //     .filter(([, value]) => value !== undefined && value !== '')
    //     .filter(([key]) => patientKeys.some((patientKey) => patientKey === key))
    // );
  };

  return (
    <FormProvider {...methods}>
      <div className="grid grid-cols-1 gap-4">
        <div className="mb-3 flex items-center">
          <span className="mr-3">목표관리 추가</span>
        </div>
        <div className="">
          <div className="text-[#768396] font-bold mb-1">제목</div>
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} />}
          />
        </div>

        <div className="">
          <div className="text-[#768396] font-bold mb-1">지표선택</div>

          <GtypeSelector />
        </div>

        <div className="">
          <div className="text-[#768396] font-bold mb-1">목표타입</div>
          <Controller
            name="type"
            control={control}
            defaultValue="solo"
            render={({ field: { onChange, value } }) => (
              <Radio.Group
                options={[
                  { label: '전체목표', value: 'group', disabled: true },
                  { label: '담당자별', value: 'solo' },
                ]}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                defaultValue={'solo'}
                optionType="button"
              />
            )}
          />
        </div>

        <div className="">
          <div className="text-[#768396] font-bold mb-1">목표수치</div>
          <Controller
            name="gross"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} />}
          />
        </div>

        <div className="">
          <div className="text-[#768396] font-bold mb-1">완료기간</div>

          <Controller
            name="dueDate"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <DatePicker
                className="w-full"
                locale={locale}
                placeholder="예약일 지정"
                disabledDate={(current) => current < dayjs().subtract(1, 'day')}
                format="YYYY-MM-DD"
                disabledTime={displayDateTime}
                showNow={false}
                onChange={(value, dateString) => onChange(dateString)}
                // status={errors ? 'error' : ''}
              />
            )}
          />
        </div>
        <div>
          <div className="text-[#768396] font-bold mb-1">참가자</div>
          <AssignTreeSelector />
        </div>
      </div>
      {/* <div className="mb-3">
        <div className="text-[#768396] font-bold mb-1">연락처</div>
        <Controller
          name="phone"
          control={control}
          rules={{ required: true, pattern: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/ }}
          render={({ field }) => (
            <Input {...field} placeholder="01012345678" status={errors.phone ? 'error' : ''} />
          )}
        />
      </div> */}

      {/* <div className="mb-3 flex">
        <div className="flex-1">
          <div className="text-[#768396] font-bold mb-1">유입경로</div>
          <Controller
            name="referral"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </div>
      </div> */}

      <div className="mt-3 flex justify-end">
        {/* <Button type="primary" disabled={!isEmpty(errors)} onClick={handleSubmit(onSubmit)}>
          확인
        </Button> */}
        <Button type="primary" onClick={handleSubmit(onSubmit)}>
          확인
        </Button>
      </div>
    </FormProvider>
  );
}
