import { Empty, Spin } from 'antd';

import EditablePaymentRefundForm from './EditablePaymentRefundForm';
import usePaidListById from 'src/hooks/usePaidListById';

type Props = {
  patientId: string;
};

export default function PaymentRefund({ patientId }: Props) {
  const { crmPaidList, isFetching } = usePaidListById(patientId);
  const payRefundList = crmPaidList.flatMap(({ history }) =>
    history.filter(({ amount }) => amount < 0)
  );

  return (
    <Spin spinning={isFetching}>
      <div className="mt-5">
        <div className="text-lg text-[#768396] font-bold mb-3">환불 및 기타</div>
        {payRefundList.length === 0 && <Empty description="내역이 없습니다." />}
        {payRefundList.map((refund) => (
          <EditablePaymentRefundForm {...refund} key={refund.id} patientId={patientId} />
        ))}
      </div>
    </Spin>
  );
}
