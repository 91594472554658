import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Table, Select, Input, notification, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getCrmConsultListAPI, updateAssignHuserCrmItemAPI } from 'src/api/crm.api';
import useHuserList from 'src/hooks/useHuserList';
import { columnSorter } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { isRightPermissionState } from 'src/recoil/auth.recoil';
import { consultManagementState } from 'src/recoil/consultManagement.recoil';
import { getStatusNameMapState } from 'src/recoil/status.recoil';
import { CrmCall } from 'src/types/crm.types';

const { Search } = Input;

type TableData = CrmCall & { latestVisitDate: string; latestContentStatusId: string };

export default function ConsultTableWidget() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [consult, setConsult] = useRecoilState(consultManagementState);
  const statusMap = useRecoilValue(getStatusNameMapState);
  const isRightPermission = useRecoilValue(isRightPermissionState('상담 담당 편집'));
  const { isNewVisit, startDate, endDate } = consult;
  const { hUserList } = useHuserList();
  const [search, setSearch] = useState('');

  const createMarkup = (value: string) => {
    if (search === '') {
      return { __html: value };
    }

    return { __html: value?.replaceAll(search, (match) => `<mark>${match}</mark>`) };
  };

  const columns: ColumnsType<TableData> = [
    {
      title: '등록번호',
      dataIndex: 'registerNumber',
      key: 'registerNumber',
      render: (_, record) => (
        <div dangerouslySetInnerHTML={createMarkup(record.patient.registerNumber)} />
      ),
      sorter: (a, b) => columnSorter(a, b, 'patient.registerNumber'),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <div dangerouslySetInnerHTML={createMarkup(record.patient.name)} />,
      sorter: (a, b) => columnSorter(a, b, 'patient.name'),
    },
    {
      title: '연락처',
      dataIndex: 'contact',
      key: 'contact',
      render: (_, record) => <div dangerouslySetInnerHTML={createMarkup(record.patient.phone)} />,
      sorter: (a, b) => columnSorter(a, b, 'patient.phone'),
    },
    {
      title: '시술명',
      dataIndex: 'therapy',
      key: 'therapy',
      ellipsis: true,
      width: 100,
      render: (_, record) => record.therapy || '-',
      sorter: (a, b) => columnSorter(a, b, 'therapy'),
    },
    {
      title: '메모',
      dataIndex: 'latestMemo',
      key: 'latestMemo',
      width: 400,
      render: (_, record) => {
        if (record.memo) {
          return (
            <Tooltip placement="top" title={record.memo}>
              {record.latestMemo || record.memo || '-'}
            </Tooltip>
          );
        } else {
          return record.latestMemo || record.memo || '-';
        }
      },
    },
    {
      title: '최근 방문일',
      dataIndex: 'latestVisitDate',
      key: 'latestVisitDate',
      render: (_, row) => row.latestVisitDate && dayjs(row.latestVisitDate).format('YYYY-MM-DD'),
      sorter: (a, b) => columnSorter(a, b, 'latestVisitDate'),
    },
    {
      title: '담당자',
      dataIndex: 'csId',
      key: 'csId',
      render: (_, record) => {
        const [user] = hUserList.filter((hUser) => hUser.id === record.csId);
        if (user) {
          return user.name;
        }
        return '미지정';
      },
      sorter: (a, b) => columnSorter(a, b, 'csId'),
    },
    {
      title: '상태',
      dataIndex: 'latestStatusId',
      key: 'latestStatusId',
      // render: (_, { latestStatusId }) => statusMap[latestStatusId],
      render: (_, { latestStatusId, latestContentStatusId }) => {
        // 14: 취소일땐 취소(진료미진행) 이런식으로 표시.
        // 7: 상담완료중 예약금(41)일때 상담완료(예약금)
        // 진료 미진행: latestContentStatusId = 42
        const sTxt = statusMap[latestStatusId];
        if (latestStatusId === '14') return sTxt + `(${statusMap[latestContentStatusId]})`;
        if (latestContentStatusId === '41') return sTxt + `(${statusMap[latestContentStatusId]})`;
        return sTxt;
      },
      sorter: (a, b) => columnSorter(a, b, 'latestStatusId'),
    },
  ];

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CONSULT_LIST, { isNewVisit, startDate, endDate }],
    () =>
      getCrmConsultListAPI({
        isNewVisit,
        startDate: dayjs(startDate).startOf('day').format(),
        endDate: dayjs(endDate).endOf('day').format(),
      })
  );

  const updateAssignHuserCrm = useMutation(updateAssignHuserCrmItemAPI, {
    onSuccess: (res) => {
      api.success({ message: '담당자 지정 완료.' });
      queryClient.invalidateQueries([QUERY_KEYS.GET_CALL_LIST, { isNewVisit, startDate, endDate }]);
    },
    onError: () => {
      api.error({ message: '담당자 지정 실패.' });
    },
    onSettled: () => {
      setConsult((prev) => ({ ...prev, rowKeys: [], selectedList: [] }));
    },
  });

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TableData[]) => {
      setConsult((prev) => ({ ...prev, selectedList: selectedRows.map(({ id }) => id) }));
    },
  };

  const onClickRow = (record: TableData, rowIndex: number | undefined) => {
    const info = record.id + '_' + record.patient.id;
    navigate(`/consulting/${info}`);
  };

  const onAssignment = (csId: string) => {
    updateAssignHuserCrm.mutate({ ids: consult.selectedList, csId });
  };

  const searchedList = data?.crmConsultList.filter(
    (value) =>
      (value.patient.phone && value.patient.phone.includes(search)) ||
      (value.patient.name && value.patient.name.includes(search)) ||
      (value.therapy && value.therapy.includes(search)) ||
      (value.patient.registerNumber && value.patient.registerNumber.includes(search))
  );

  return (
    <div className="mt-3 p-5 rounded-lg bg-[#fff]">
      {contextHolder}
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="text-[#2B3674] text-2xl font-bold">
            {isNewVisit ? '신규 상담(신환)' : '재 상담(구환)'}
          </div>
          <div className="ml-5">
            {isRightPermission && (
              <Select
                style={{ width: 200 }}
                onChange={(value) => onAssignment(value)}
                options={hUserList
                  .filter((hUser) => hUser.type === 2)
                  .map((hUser) => ({ value: hUser.id, label: hUser.name }))}
                disabled={consult.selectedList.length === 0}
                placeholder="담당자 지정"
              />
            )}
          </div>
        </div>
        <div className="flex">
          <Search
            placeholder="등록번호, 이름, 연락처, 시술명 검색"
            onSearch={(value) => setSearch(value)}
            className="w-[300px]"
          />
        </div>
      </div>
      <div className="mt-5">
        <Table
          columns={columns}
          dataSource={searchedList}
          rowKey={(record) => record.id}
          rowClassName="clickable"
          rowSelection={
            isRightPermission
              ? {
                  type: 'checkbox',
                  ...rowSelection,
                }
              : undefined
          }
          onRow={(record, rowIndex) => ({
            onClick: () => onClickRow(record, rowIndex),
          })}
          loading={isFetching || isLoading}
          locale={{
            triggerDesc: '내림차순',
            triggerAsc: '오름차순',
            cancelSort: '정렬 취소',
          }}
        />
      </div>
    </div>
  );
}
