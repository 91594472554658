import { useQuery } from '@tanstack/react-query';
import { Spin } from 'antd';
import React from 'react';

import { getCrmBalanceAPI } from 'src/api/common.api';
import { numberFormat } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';

export default function ChargeManagement() {
  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CURRENT_BALANCE],
    getCrmBalanceAPI
  );

  return (
    <div className="mt-10 w-[540px]">
      <div className="text-[#2B3674] font-bold text-3xl">충전금 관리</div>
      <div className="mt-5">
        <Spin spinning={isFetching || isLoading}>
          <div className="rounded p-5 bg-[#602BF8] flex justify-between">
            <div className="text-white font-bold text-lg">충전금</div>
            <div className="text-white font-bold text-lg">
              {numberFormat(data?.crmBalance.balance || 0)}원
            </div>
          </div>
        </Spin>
        <div className="mt-5 flex justify-between">
          <div className="cursor-pointer flex-1 text-[#2B3674] font-bold text-lg flex justify-center items-center py-3 px-5 rounded bg-white">
            충전하기
          </div>
          <div className="cursor-pointer flex-1 text-[#2B3674] font-bold text-lg flex justify-center items-center py-3 px-5 rounded bg-white mx-3">
            충전금 안내
          </div>
          <div className="cursor-pointer flex-1 text-[#2B3674] font-bold text-lg flex justify-center items-center py-3 px-5 rounded bg-white">
            광고상품 안내
          </div>
        </div>
      </div>
    </div>
  );
}
