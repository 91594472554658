import { DatePicker, Select, Button } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import 'dayjs/locale/ko';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import useHuserList from 'src/hooks/useHuserList';
import { GROUP_PART_LIST } from 'src/libs/constants';
import { authState } from 'src/recoil/auth.recoil';
import { dashboardFilterState } from 'src/recoil/dashboardFilter.recoil';

const { RangePicker } = DatePicker;

export default function DashboardFilter() {
  const [filter, setFilter] = useRecoilState(dashboardFilterState);
  const { hUserList, isFetching } = useHuserList();
  const { meh } = useRecoilValue(authState);
  const [husers, setHusers] = useState<any>([]);
  const [selectedIds, setSelectedIds] = useState<any>([]);

  const onChangePicker = (dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;
    setFilter((prev) => ({ ...prev, startDate, endDate }));
  };

  const onChange = (value: any) => {
    const filterd = hUserList.filter((hUser) => hUser.type === value);
    setHusers(
      filterd.map(({ id, name, position }) => ({
        value: id,
        label: name,
      }))
    );

    // setFilter((prev) => ({ ...prev, hUserIds: filterd.map(({ id }) => id) }));
    setSelectedIds(filterd.map(({ id }) => id));
  };

  useEffect(() => {
    console.log('selectedIds', selectedIds);
  }, [selectedIds]);

  const onSubmit = () => {
    setFilter((prev) => ({ ...prev, hUserIds: selectedIds.length ? selectedIds : null }));
  };

  return (
    <div className="flex">
      <div className="flex gap-1 items-center bg-white p-2 px-5 rounded-full shadow-lg">
        <div>
          <RangePicker
            locale={locale}
            onChange={(_, dateStrings) => onChangePicker(dateStrings)}
            value={[dayjs(filter.startDate), dayjs(filter.endDate)]}
            allowClear={false}
          />
        </div>
        <div className="">
          <Select
            options={[
              { value: '', label: '전체' },
              ...GROUP_PART_LIST.map(({ value, label }) => ({ value, label: `[${label}]그룹` })),
            ]}
            loading={isFetching}
            className="w-[200px]"
            defaultValue={''}
            onChange={onChange}
            placeholder="그룹 선택해주세요"
          />
        </div>
        <div className="">
          <Select
            mode="multiple"
            options={husers}
            loading={isFetching}
            className="w-[200px]"
            defaultValue={selectedIds}
            value={selectedIds}
            placeholder="사용자를 선택해주세요"
            onChange={(value) => {
              setSelectedIds(value);

              // setFilter((prev) => ({ ...prev, hUserIds: value }));
            }}
            // onSelect={onSelected}
            // onDeselect={onDeselected}
            maxTagCount="responsive"
          />
        </div>
        <Button type="primary" onClick={onSubmit}>
          확인
        </Button>
      </div>
    </div>
  );
}
