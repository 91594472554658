import dayjs from 'dayjs';

import { CrmBoard, Schedule } from 'src/types/crm.types';
import { CrmHuser } from 'src/types/hospital.types';

export type ScheduleData = Schedule & { startIndex: number; length: number; isFirst?: boolean };

type ScheduleGroup = {
  group_cs: { [hUserId: string]: (ScheduleData | null)[][] };
  group_cs_null: (ScheduleData | null)[][];
  group_dr: { [hUserId: string]: (ScheduleData | null)[][] };
  group_dr_null: (ScheduleData | null)[][];
};

class TimeTableDrawer {
  private readonly workingTimeStart = 540; // 09:00
  private readonly workingTimeEnd = 1380; // 23:00
  public readonly total = (this.workingTimeEnd - this.workingTimeStart) / 30;
  private table: (ScheduleData | null)[][] = [Array.from({ length: this.total }, () => null)];

  private initializeTable() {
    this.table = [Array.from({ length: this.total }, () => null)];
  }

  private convertToScheduleData(schedule: Schedule): ScheduleData {
    const [startHour, startMin] = dayjs(schedule.startTime).format('HH:mm').split(':');
    const [endHour, endMin] = dayjs(schedule.endTime).format('HH:mm').split(':');
    const startTime = Number(startHour) * 60 + Number(startMin);
    const endTime = Number(endHour) * 60 + Number(endMin);

    const startIndex = (startTime - this.workingTimeStart) / 30;
    const length = (endTime - startTime) / 30;

    return { ...schedule, startIndex, length };
  }

  private draw(schedule: ScheduleData, row = 0) {
    const { startIndex, length } = schedule;
    const isEmpty = this.table[row].slice(startIndex, startIndex + length).every((v) => v === null);

    if (isEmpty) {
      for (let col = startIndex; col < startIndex + length; col++) {
        this.table[row][col] = { ...schedule, isFirst: col === startIndex };
      }
      return;
    }

    if (!this.table[row + 1]) {
      this.table[row + 1] = Array.from({ length: this.total }, () => null);
    }

    this.draw(schedule, row + 1);
  }

  public createScheduleGroup(crmBoardList: CrmBoard[] | undefined, hUserList: CrmHuser[]) {
    let scheduleGroup: ScheduleGroup = {
      group_cs_null: [],
      group_dr_null: [],
      group_cs: {},
      group_dr: {},
    };

    if (!crmBoardList) return scheduleGroup;

    if (crmBoardList.length === 0) {
      return scheduleGroup;
    }

    const emptyData = { group: '', data: [] };
    const [cs] = crmBoardList.filter(({ group }) => group === 'csId') || [emptyData];
    const [dr] = crmBoardList.filter(({ group }) => group === 'drId') || [emptyData];

    const unSpecifiedCs = cs.data.filter(({ csId }) => csId === null);
    const unSpecifiedDr = dr.data.filter(({ drId }) => drId === null);

    scheduleGroup.group_cs_null = this.getTimeTable(unSpecifiedCs);
    scheduleGroup.group_dr_null = this.getTimeTable(unSpecifiedDr);

    hUserList.forEach(({ id }) => {
      if (cs.data.some(({ csId }) => csId === id)) {
        scheduleGroup.group_cs[id] = this.getTimeTable(cs.data.filter(({ csId }) => csId === id));
      }

      if (dr.data.some(({ drId }) => drId === id)) {
        scheduleGroup.group_dr[id] = this.getTimeTable(dr.data.filter(({ drId }) => drId === id));
      }
    });

    return scheduleGroup;
  }

  public getTimeTable(scheduleList: Schedule[]) {
    this.initializeTable();

    const updatedScheduleList = scheduleList.map((schedule) =>
      this.convertToScheduleData(schedule)
    );

    updatedScheduleList.forEach((schedule) => this.draw(schedule));

    return [...this.table];
  }

  public getWorkingTimeStart() {
    return this.workingTimeStart;
  }

  public getWorkingTimeEnd() {
    return this.workingTimeEnd;
  }

  public getTotalTimeLength() {
    return this.total;
  }
}

export default new TimeTableDrawer();
