import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, InputNumber, Popconfirm, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { removeCrmPaidAPI, updateCrmPaidAPI } from 'src/api/crm.api';
import WillPayFormModal from 'src/components/PatientBoard/WillPayFormModal';
import { numberFormat } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { isRightPermissionState } from 'src/recoil/auth.recoil';
import { CrmPaid, UpdateCrmPaidRequest } from 'src/types/crm.types';

const { TextArea } = Input;

type Props = CrmPaid;

type InputForm = {
  pay: number;
  memo: string;
  status: number;
};

export default function EditableWillPaymentHistoryCard(props: Props) {
  const queryClient = useQueryClient();
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const isRightPermission = useRecoilValue(isRightPermissionState('결제 관리 편집'));

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<InputForm>();

  useEffect(() => {
    if (isEdit) {
      setValue('pay', props.pay);
      setValue('memo', props.memo);
    }
  }, [isEdit, setValue, props]);

  const updateCrmPaid = useMutation(updateCrmPaidAPI, {
    onSuccess: (res) => {
      api.success({ message: '결제 수정이 완료되었습니다.' });
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PAID_LIST,
        { patientId: props.crmPatientId },
      ]);
    },
    onError: () => {
      api.error({ message: '결제 수정이 실패했습니다.' });
    },
    onSettled: () => {
      reset();
      setIsEdit(false);
    },
  });

  const removeCrmPaid = useMutation(removeCrmPaidAPI, {
    onSuccess: (res) => {
      api.success({ message: '결제 삭제가 완료되었습니다.' });
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PAID_LIST,
        { patientId: props.crmPatientId },
      ]);
    },
    onError: () => {
      api.error({ message: '결제 삭제가 실패했습니다.' });
    },
    onSettled: () => {
      setIsEdit(false);
    },
  });

  const onClickEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const onClickDelete = (id: string) => {
    removeCrmPaid.mutate({ id });
  };

  const onClickCancelEdit = () => {
    reset();
    setIsEdit(false);
  };

  const onSubmit: SubmitHandler<InputForm> = (data) => {
    const payload: UpdateCrmPaidRequest = { id: props.id, memo: data.memo };
    // 수정 전 금액과 수정 후 금액이 다를때만 pay보냄
    if (data.pay !== props.pay) {
      payload['pay'] = data.pay;
    }

    updateCrmPaid.mutate(payload);
  };

  return (
    <div className="rounded-lg shadow-lg bg-white p-5 mb-4">
      {contextHolder}
      {isEdit ? (
        <>
          <div className="flex mb-3">
            <Controller
              name="pay"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  value={value}
                  placeholder="숫자만 입력"
                  step={100}
                  addonBefore="결제금액"
                  addonAfter="원"
                  onChange={(digit) => onChange(digit || 0)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  status={errors.pay ? 'error' : ''}
                  className="w-full"
                />
              )}
            />
          </div>
          <Controller
            name="memo"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={3}
                placeholder="결제 내용을 입력하세요"
                className="mb-3"
                status={errors.memo ? 'error' : ''}
              />
            )}
          />
          <div className="flex justify-end">
            <Popconfirm
              title="결제 삭제"
              okText="예"
              cancelText="아니오"
              onConfirm={(e) => onClickDelete(props.id)}
            >
              <Button type="text" danger className="flex items-center">
                삭제
              </Button>
            </Popconfirm>
            <Button onClick={onClickCancelEdit} className="mr-2">
              취소
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              loading={updateCrmPaid.isLoading}
              type="primary"
            >
              확인
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="text-[#2B3674] font-bold">{numberFormat(props.pay)}원</div>
            </div>
            <div className="flex items-center">
              <Button onClick={() => setShowModal(true)}>결제</Button>
              {isRightPermission && (
                <Button
                  type="text"
                  className="ml-2"
                  icon={<EditOutlined />}
                  onClick={() => setIsEdit(true)}
                />
              )}
            </div>
          </div>
          <div className="flex justify-between mt-1">
            <div className="text-[#A3AED0] text-sm flex-1">{props.memo}</div>
          </div>
        </>
      )}
      <WillPayFormModal
        id={props.id}
        total={props.pay}
        patientId={props.crmPatientId}
        visible={showModal}
        onCancel={() => setShowModal(false)}
      />
    </div>
  );
}
