import Http from 'src/@http';
import {
  queryCrmHuserNotificationList,
  queryUpdateCrmHuserNotification,
} from 'src/graphql/notification.query';
import { errorResponse, successResponse } from 'src/libs/response';
import {
  GetCrmHuserNotificationListRequest,
  GetCrmHuserNotificationListResponse,
  UpdateHuserNotificationRequest,
  UpdateHuserNotificationResponse,
} from 'src/types/notification.types';
import { ApiResponse } from 'src/types/response';

export async function getCrmHuserNotificationListAPI({
  huserId,
}: GetCrmHuserNotificationListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmHuserNotificationListResponse>>('', {
      query: queryCrmHuserNotificationList,
      variables: {
        filter: { huserId },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmHuserNotificationListAPI'));
}

export async function updateCrmHuserNotificationAPI(payload: UpdateHuserNotificationRequest) {
  return Http.instance
    .post<ApiResponse<UpdateHuserNotificationResponse>>('', {
      query: queryUpdateCrmHuserNotification,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmHuserNotificationAPI'));
}
