import { message } from 'antd';
import React, { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type pageKey = 'call' | 'consulting';

const MESSAGE = {
  call: {
    text: '콜 목록은 14일뒤 내원DB 관리페이지로 이동 됩니다.',
    duration: 10,
  },
  consulting: {
    text: '상담 목록은 14일뒤 내원DB 관리페이지로 이동 됩니다.',
    duration: 10,
  },
};

interface LocationTypes {
  pathname: pageKey;
}

const PageFeedBack: React.FC = () => {
  const [history, setHistory] = useState([] as pageKey[]);
  const { pathname } = useLocation() as LocationTypes; // v6 generic -> as
  const currentPathname = pathname.replace('/', '') as pageKey;

  useEffect(() => {
    if (MESSAGE[currentPathname] && !history.includes(currentPathname)) {
      const { text, duration } = MESSAGE[currentPathname];
      message.success(text, duration);
      setHistory([currentPathname, ...history]);
    }
  }, [currentPathname, history]);

  return <></>;
};

export default PageFeedBack;
