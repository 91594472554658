import dayjs from 'dayjs';
import { atom } from 'recoil';

type Filter = {
  startDate: string;
  endDate: string;
  isVisit: boolean;
};

const initialState = {
  startDate: dayjs().subtract(3, 'month').format(),
  endDate: dayjs().format(),
  isVisit: false,
};

export const manageDBState = atom<Filter>({
  key: 'manageDBState',
  default: initialState,
});
