import React from 'react';
import { useRecoilValue } from 'recoil';

import { PERMISSIONS } from 'src/libs/constants';
import { isRightPermissionState } from 'src/recoil/auth.recoil';

type Props = {
  requiredPermission: (typeof PERMISSIONS)[number];
  children?: React.ReactNode;
};

export default function PermissionWrapper({ requiredPermission, children }: Props) {
  const isShow = useRecoilValue(isRightPermissionState(requiredPermission));

  return isShow ? (
    <>{children}</>
  ) : (
    <>
      <p>권한이 없습니다</p>
    </>
  );
}
