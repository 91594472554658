import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Drawer, Input, Select, Space, notification } from 'antd';
import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import InfoBox from './InfoBox';
import { addCrmHuserAPI } from 'src/api/hospital.api';
import { PART_LIST, QUERY_KEYS } from 'src/libs/constants';
import { accountState } from 'src/recoil/account.recoil';
import { authState } from 'src/recoil/auth.recoil';

type Huser = {
  email: string;
  password: string;
  name: string;
  // department: string;
  // position: string;
  phone: string;
  type: string;
};

export default function AddAcountDrawer() {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Huser>();
  const [{ openDrawer }, setAccount] = useRecoilState(accountState);
  const auth = useRecoilValue(authState);

  const addHuser = useMutation(addCrmHuserAPI, {
    onSuccess: (res) => {
      api.success({ message: '계정 추가 완료.' });
      return queryClient.invalidateQueries([QUERY_KEYS.GET_HUSER_LIST]);
    },
    onError: () => {
      api.error({ message: '계정 추가 실패.' });
    },
    onSettled: () => {
      onClose();
    },
  });

  const onClose = () => {
    reset();
    setAccount((prev) => ({ ...prev, openDrawer: false }));
  };

  const onSubmit: SubmitHandler<Huser> = (data) => {
    const hospitalId = auth.meh.hospital.id;
    addHuser.mutate({ ...data, hospitalId });
  };

  return (
    <Drawer
      title="계정 추가"
      onClose={onClose}
      open={openDrawer}
      bodyStyle={{ paddingBottom: 80 }}
      closable={false}
      width={600}
      extra={
        <Space>
          <Button onClick={onClose}>취소</Button>
          <Button type="primary" className="mr-3 bg-[#1EA7FF]" onClick={handleSubmit(onSubmit)}>
            <div className="text-[#F5F5F5] font-bold">확인</div>
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <div>
        <InfoBox title="이메일" className="shadow-none p-0">
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="이메일을 입력하세요"
                status={errors.email ? 'error' : ''}
              />
            )}
          />
        </InfoBox>
        <InfoBox title="비밀번호" className="shadow-none p-0">
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="비밀번호를 입력하세요"
                status={errors.password ? 'error' : ''}
              />
            )}
          />
        </InfoBox>
        <InfoBox title="이름" className="shadow-none p-0">
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="이름을 입력하세요"
                status={errors.name ? 'error' : ''}
              />
            )}
          />
        </InfoBox>
        <InfoBox title="파트" className="shadow-none p-0">
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={PART_LIST}
                className="w-full"
                placeholder="파트를 선택하세요"
                status={errors.type ? 'error' : ''}
              />
            )}
          />
        </InfoBox>
        {/* <InfoBox title="직급" className="shadow-none p-0">
          <Controller
            name="position"
            control={control}
            render={({ field }) => <Input {...field} placeholder="직급을 입력하세요" />}
          />
        </InfoBox> */}
        <InfoBox title="연락처" className="shadow-none p-0">
          <Controller
            name="phone"
            control={control}
            render={({ field }) => <Input {...field} placeholder="연락처를 입력하세요" />}
          />
        </InfoBox>
      </div>
    </Drawer>
  );
}
