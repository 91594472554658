export const queryCrmHuserNotificationList = `
  query {
    crmHuserNotifications {
      id
      type
      actionId
      authorId
      isRead
      isDelete
    }
  }
`;

export const queryUpdateCrmHuserNotification = `
mutation($input: UpdateCrmNotiInput) {
  updateCrmHuserNotifications(input: $input) {
      ok
      error {
          path
          message
      }
  }
}
`;
