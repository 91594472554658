import { useQuery } from '@tanstack/react-query';
import { Button, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useRecoilState } from 'recoil';

import CallFilter from '../Filter/CallFilter';
import { getCrmCallListAPI } from 'src/api/crm.api';
import IconNewPerson from 'src/assets/images/icon-new-person.svg';
import { QUERY_KEYS } from 'src/libs/constants';
import { exportToExcel } from 'src/libs/exportXlsx';
import { callManagementState } from 'src/recoil/callManagement.recoil';

export default function CallButtonWidget() {
  const [{ isNewVisit, hasCallCount }, setCallManagement] = useRecoilState(callManagementState);

  const [callManagement] = useRecoilState(callManagementState);
  const { startDate, endDate } = callManagement;

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CALL_LIST, { isNewVisit, hasCallCount, startDate, endDate }],
    () =>
      getCrmCallListAPI({
        isNewVisit,
        hasCallCount,
        startDate: dayjs(startDate).startOf('day').format(),
        endDate: dayjs(endDate).endOf('day').format(),
      })
  );

  const onClickAddPatient = () => {
    setCallManagement((prev) => ({ ...prev, showAddPatientDrawer: true }));
  };

  const onClickOpenUploadDB = () => {
    // setCallManagement((prev) => ({ ...prev, showExcelModal: true }));
    if (data?.crmCallList && data.crmCallList.length > 0) {
      exportToExcel(data.crmCallList, {
        date: {
          startDate: dayjs(startDate).startOf('day').format(),
          endDate: dayjs(endDate).endOf('day').format(),
        },
      });
    } else {
      alert('데이터가 없습니다');
    }
  };

  const onClickButton = (
    type: '신규 콜 대기' | '신규 콜 진행중' | '구 고객 재콜 대기' | '구 고객 재콜 진행중'
  ) => {
    setCallManagement((prev) => ({
      ...prev,
      isNewVisit: type === '신규 콜 대기' || type === '신규 콜 진행중',
      hasCallCount: type === '신규 콜 진행중' || type === '구 고객 재콜 진행중',
      selectedList: [],
    }));
  };

  return (
    <div className="flex items-center">
      <div>
        <Button
          type="primary"
          size="middle"
          className={`mr-2 ${isNewVisit && !hasCallCount ? 'bg-[#1EA7FF]' : 'bg-[#A3AED0]'}`}
          onClick={() => onClickButton('신규 콜 대기')}
        >
          신규 콜 대기
        </Button>
        <Button
          type="primary"
          size="middle"
          className={`mr-2 ${isNewVisit && hasCallCount ? 'bg-[#1EA7FF]' : 'bg-[#A3AED0]'}`}
          onClick={() => onClickButton('신규 콜 진행중')}
        >
          신규 콜 진행중
        </Button>
        <Button
          type="primary"
          size="middle"
          className={`mr-2 ${!isNewVisit && !hasCallCount ? 'bg-[#1EA7FF]' : 'bg-[#A3AED0]'}`}
          onClick={() => onClickButton('구 고객 재콜 대기')}
        >
          구 고객 재콜 대기
        </Button>
        <Button
          type="primary"
          size="middle"
          className={`mr-2 ${!isNewVisit && hasCallCount ? 'bg-[#1EA7FF]' : 'bg-[#A3AED0]'}`}
          onClick={() => onClickButton('구 고객 재콜 진행중')}
        >
          구 고객 재콜 진행중
        </Button>
      </div>
      <div>
        <CallFilter />
      </div>
      <div className="ml-auto">
        <ConfigProvider theme={{ token: { colorPrimary: '#1821FF' } }}>
          <Button type="primary" size="middle" className="mr-2" onClick={onClickAddPatient}>
            <div className="flex justify-center items-center">
              <img src={IconNewPerson} className="mr-2" style={{ width: '1.3rem' }} />
              <div className="text-[#F5F5F5] font-bold">환자 추가</div>
            </div>
          </Button>
        </ConfigProvider>
        <ConfigProvider theme={{ token: { colorPrimary: '#7551FF' } }}>
          <Button type="primary" size="middle" onClick={onClickOpenUploadDB}>
            <div className="flex justify-center items-center">
              <img src={IconNewPerson} className="mr-2" style={{ width: '1.3rem' }} />
              <div className="text-[#F5F5F5] font-bold">엑셀 내보내기</div>
            </div>
          </Button>
        </ConfigProvider>
      </div>
    </div>
  );
}
