import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { getCrmItemAPI } from 'src/api/crm.api';
import { QUERY_KEYS } from 'src/libs/constants';

const initialData = {
  id: '',
  patient: {
    id: '',
    name: '',
    phone: '',
    address: '',
    birth: 0,
    gender: '',
  },
  hospitalId: '',
  registerNumber: '0',
  referral: '',
  therapy: '',
  isNewVisit: true,
  tmId: '',
  csId: '',
  drId: '',
  status: {
    id: '',
    crmStatusId: '',
    createdAt: '',
    contents: [],
  },
  paid: {
    id: '',
    hospitalId: '',
    huserId: '',
    crmListId: '',
    crmPatientId: '',
    pay: 0,
    paySum: 0,
    memo: '',
    status: 0,
    isDelete: false,
    history: [],
  },
  callCount: 0,
  isFresh: true,
  tags: '0',
  memo: null,
};

export default function useCrmItem(id: string) {
  const { data, isLoading, isFetching } = useQuery(
    [QUERY_KEYS.GET_CRM_ITEM, { id }],
    () => getCrmItemAPI({ id }),
    {
      enabled: id !== '',
    }
  );

  const defaultData = data?.crmItem || initialData;

  return {
    crmItem: defaultData,
    isFetching: isLoading || isFetching,
  };
}
