import { Spin } from 'antd';
import React from 'react';

import usePaidListById from 'src/hooks/usePaidListById';
import { numberFormat } from 'src/libs/common';

type Props = { patientId: string };

export default function PaymentSummary({ patientId }: Props) {
  const { crmPaidList, isFetching } = usePaidListById(patientId);

  const { pay, paySum } = crmPaidList.reduce(
    (acc, { pay, paySum }) => {
      acc.pay += pay;
      acc.paySum += paySum;
      return acc;
    },
    { pay: 0, paySum: 0 }
  );

  return (
    <Spin spinning={isFetching}>
      <div className="text-[#768396] font-bold">결제 요약</div>
      <div className="rounded-lg bg-[#4318FF] p-5 mt-4">
        <div className="text-white font-bold">총 결제 금액</div>
        <div className="flex justify-end text-white font-bold my-3">
          {numberFormat(paySum)}원 / {numberFormat(pay)}원
        </div>
        <div className="text-[#EE5D50] font-bold text-sm flex justify-end">
          미수 {numberFormat(pay - paySum)}원
        </div>
      </div>
    </Spin>
  );
}
