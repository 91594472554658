import { Drawer } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';

import CreatePatientForm from './CreatePatientForm';
import SearchPatientForm from './SearchPatientForm';
import { consultManagementState } from 'src/recoil/consultManagement.recoil';

export default function AddPatientDrawer() {
  const [{ openDrawer }, setConsult] = useRecoilState(consultManagementState);

  const onClose = () => {
    setConsult((prev) => ({ ...prev, openDrawer: false }));
  };

  return (
    <Drawer closable={false} width={600} onClose={onClose} open={openDrawer} destroyOnClose>
      <div className="bg-white rounded-lg h-full">
        {/* <SearchPatientForm /> */}
        <CreatePatientForm />
      </div>
    </Drawer>
  );
}
