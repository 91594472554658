import { Button, ConfigProvider } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';

import ConsultingFilter from '../Filter/ConsultingFilter';
import IconNewCall from 'src/assets/images/icon-new-call.svg';
import IconNewPerson from 'src/assets/images/icon-new-person.svg';
import IconOldCall from 'src/assets/images/icon-old-call.svg';
import { consultManagementState } from 'src/recoil/consultManagement.recoil';

export default function ConsultButtonWidget() {
  const [{ isNewVisit, hasConsultCount }, setConsult] = useRecoilState(consultManagementState);

  const onClickAddPatient = () => {
    setConsult((prev) => ({ ...prev, openDrawer: true }));
  };

  const onClickConsultBtn = (isNew: boolean) => {
    setConsult((prev) => ({ ...prev, rowKeys: [], selectedList: [], isNewVisit: isNew }));
  };

  return (
    <div className="flex items-center mt-3">
      <div>
        <Button
          type="primary"
          size="middle"
          className={`mr-2 ${isNewVisit ? 'bg-[#1EA7FF]' : 'bg-[#A3AED0]'}`}
          onClick={() => onClickConsultBtn(true)}
        >
          신규 상담(신환)
        </Button>
        <Button
          type="primary"
          className={`mr-2 ${!isNewVisit ? 'bg-[#1EA7FF]' : 'bg-[#A3AED0]'}`}
          size="middle"
          onClick={() => onClickConsultBtn(false)}
        >
          재 상담(구환)
        </Button>
      </div>
      <div>
        <ConsultingFilter />
      </div>
      <div className="ml-auto">
        <ConfigProvider theme={{ token: { colorPrimary: '#1821FF' } }}>
          <Button type="primary" size="middle" className="mr-2" onClick={onClickAddPatient}>
            <div className="flex justify-center items-center">
              <img src={IconNewPerson} className="mr-2" style={{ width: '1.3rem' }} />
              <div className="text-[#F5F5F5] font-bold">환자 추가</div>
            </div>
          </Button>
        </ConfigProvider>
      </div>
    </div>
  );
}
