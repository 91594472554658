import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ItemModal from '../CS/ItemModal';
import { getCrmIssueListByCcHuserIdAPI } from 'src/api/issue.api';
import useHuserList from 'src/hooks/useHuserList';
import { QUERY_KEYS } from 'src/libs/constants';
import { authState } from 'src/recoil/auth.recoil';
import { csState } from 'src/recoil/cs.recoil';
import { CrmIssue } from 'src/types/issue.types';

type TableData = {
  id: string;
  content: string;
  writer: string;
  createdAt: string;
};

const columns: ColumnsType<TableData> = [
  {
    title: '내용',
    dataIndex: 'content',
    key: 'content',
    width: '60%',
  },
  {
    title: '작성자',
    dataIndex: 'writer',
    key: 'writer',
    width: '15%',
  },
  {
    title: '날짜',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '25%',
    render: (_, record) => dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
  },
];

export default function TaggedIssue() {
  const setCsState = useSetRecoilState(csState);
  const { hUserList } = useHuserList();
  const { meh } = useRecoilValue(authState);
  const { id } = meh;

  const { data, isLoading, isFetching } = useQuery(
    [QUERY_KEYS.GET_ISSUE_LIST_BY_CC_HUSERID, { id }],
    () => getCrmIssueListByCcHuserIdAPI({ huserId: id }),
    {
      enabled: id !== '',
    }
  );

  const issueList =
    data?.crmIssueListByCcHuserId?.map((issue) => ({
      ...issue,
      writer: hUserList.filter((user) => user.id === issue.authorId)[0].name,
    })) ?? [];

  const onClickItem = (item: any) => {
    setCsState((prev) => ({
      ...prev,
      itemModal: { id: item.id, authorId: item.authorId, visible: true },
    }));
  };

  return (
    <div className="ml-5 rounded-lg shadow-lg p-5 bg-white flex-1">
      <div className="text-[#2B3674] font-bold text-xl">Tagged ISSUE</div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={issueList}
          rowKey={(record) => record.id}
          size="small"
          pagination={{ pageSize: 4 }}
          onRow={(record) => {
            return {
              onClick: () => {
                onClickItem(record);

                // setModalConfig({
                //   isOpen: true,
                //   content: {
                //     ...record,
                //   },
                // });
              },
            };
          }}
        />
      </div>

      <ItemModal />
    </div>
  );
}
