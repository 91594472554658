import { useQuery } from '@tanstack/react-query';
import { DatePicker, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { ColumnsType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import { getHospitalBalanceHistoryAPI } from 'src/api/hospital.api';
import { numberFormat, rangePresets } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { accountState } from 'src/recoil/account.recoil';
import { BalanceHistory } from 'src/types/hospital.types';

const { RangePicker } = DatePicker;

type TableData = BalanceHistory;

const columns: ColumnsType<TableData> = [
  {
    title: '일자',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (_, record) => dayjs(record.timestamp).format('YYYY-MM-DD'),
  },
  {
    title: '구분',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: '내역',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '금액',
    dataIndex: 'amount',
    key: 'amount',
    render: (_, record) => <span>{numberFormat(record.amount)}원</span>,
  },
  {
    title: '잔액',
    dataIndex: 'balance',
    key: 'balance',
    render: (_, record) => <span>{numberFormat(record.balance)}원</span>,
  },
];

export default function ChargeTable() {
  const [page, setPage] = useState(1);
  const [{ startDate, endDate }, setAccount] = useRecoilState(accountState);

  const payload = {
    startDate: startDate !== '' ? dayjs(startDate).toISOString() : '',
    endDate: endDate !== '' ? dayjs(endDate).toISOString() : '',
  };

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_BALANCE_HISTORY, { page, ...payload }],
    () =>
      getHospitalBalanceHistoryAPI(
        { action: 'in', date: payload.startDate === '' ? undefined : payload },
        { currentPage: page }
      )
  );

  const onChangePicker = (dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;
    setAccount((prev) => ({ ...prev, startDate, endDate }));
  };

  return (
    <div className="flex-1 mt-10 ml-5">
      <div>
        <RangePicker
          locale={locale}
          presets={rangePresets}
          onChange={(_, dateStrings) => onChangePicker(dateStrings)}
        />
      </div>
      <div className="mt-5 p-5 bg-white rounded-lg shadow-lg">
        <div className="text-[#2B3674] font-bold text-xl mb-3">충전</div>
        <Table
          columns={columns}
          dataSource={data?.crmBalanceHistory.data}
          pagination={{ current: page, total: data?.crmBalanceHistory.pagination.total }}
          onChange={({ current }) => setPage(current as number)}
          rowKey={(record) => record.id}
          loading={isFetching || isLoading}
          size="small"
        />
      </div>
    </div>
  );
}
