import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import InfoBox from './InfoBox';
import { updateCrmHuserInfoAPI } from 'src/api/hospital.api';
import useHuserList from 'src/hooks/useHuserList';
import { QUERY_KEYS } from 'src/libs/constants';

type Huser = {
  name: string;
  department: string;
  position: string;
  phone: string;
};

export default function EditableAccountInfo() {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [isEdit, setIsEdit] = useState(false);
  const { isFetching, loginUser } = useHuserList();

  const { control, handleSubmit, reset } = useForm<Huser>({
    values: {
      name: loginUser.name,
      department: loginUser.department,
      position: loginUser.position,
      phone: loginUser.phone,
    },
  });

  const updateHuser = useMutation(updateCrmHuserInfoAPI, {
    onSuccess: () => {
      api.success({ message: '수정이 완료되었습니다.' });
      queryClient.invalidateQueries([QUERY_KEYS.GET_HUSER_LIST]);
    },
    onError: () => {
      api.error({ message: '수정이 실패했습니다.' });
    },
    onSettled: () => {
      setIsEdit(false);
      reset();
    },
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onClickEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const onClickCancel = () => {
    setIsEdit(false);
  };

  const onSubmit: SubmitHandler<Huser> = (data) => {
    const { id } = loginUser;
    const payload = { id, ...data };
    updateHuser.mutate(payload);
  };

  return (
    <Spin spinning={isFetching || updateHuser.isLoading}>
      {contextHolder}
      <div className="p-5 rounded-lg shadow-lg bg-white w-[540px]">
        <div className="flex justify-between">
          <div className="text-[#2B3674] text-xl font-bold">기본정보</div>
          <div>
            {isEdit ? (
              <>
                <Button onClick={onClickCancel}>취소</Button>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  className="ml-1"
                  type="primary"
                  disabled={updateHuser.isLoading}
                >
                  저장
                </Button>
              </>
            ) : (
              <Button onClick={onClickEdit}>
                <div className="text-[#2B3674] text-xs font-bold">수정하기</div>
              </Button>
            )}
          </div>
        </div>

        <div className="mt-5">
          <div className="flex">
            <InfoBox title="이름">
              {isEdit ? (
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => <Input {...field} placeholder="이름을 입력하세요" />}
                />
              ) : (
                <div className="text-[#2B3674] min-h-[32px] leading-[32px]">{loginUser.name}</div>
              )}
            </InfoBox>
            <InfoBox title="직급" className="ml-3">
              {isEdit ? (
                <Controller
                  name="position"
                  control={control}
                  render={({ field }) => <Input {...field} placeholder="직급을 입력하세요" />}
                />
              ) : (
                <div className="text-[#2B3674] min-h-[32px] leading-[32px]">
                  {loginUser.position}
                </div>
              )}
            </InfoBox>
          </div>
          <div className="flex mb-3">
            <InfoBox title="부서">
              {isEdit ? (
                <Controller
                  name="department"
                  control={control}
                  render={({ field }) => <Input {...field} placeholder="부서를 입력하세요" />}
                />
              ) : (
                <div className="text-[#2B3674] min-h-[32px] leading-[32px]">
                  {loginUser.department}
                </div>
              )}
            </InfoBox>
            <InfoBox title="연락처" className="ml-3">
              {isEdit ? (
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => <Input {...field} placeholder="연락처를 입력하세요" />}
                />
              ) : (
                <div className="text-[#2B3674] min-h-[32px] leading-[32px]">{loginUser.phone}</div>
              )}
            </InfoBox>
          </div>
          <div className="flex">
            <InfoBox title="이메일">
              <div className="text-[#2B3674] min-h-[32px] leading-[32px]">{loginUser.email}</div>
            </InfoBox>
          </div>
        </div>
      </div>
    </Spin>
  );
}
