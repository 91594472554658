import { Empty } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';

import AccountList from './AccountList';
import EditAccount from './EditAccount';
import SetAuthority from './SetAuthority';
import IconPersonTest from 'src/assets/images/icon-person-test.png';
import { accountState } from 'src/recoil/account.recoil';

export default function AccountManagement() {
  const { selectedAccount } = useRecoilValue(accountState);

  return (
    <div className="bg-white ml-5 flex-1 p-5 rounded-lg">
      <div className="flex justify-between">
        <div className="text-[#2B3674] font-bold text-xl">계정관리</div>
      </div>

      <div className="flex mt-5">
        <AccountList />
        {selectedAccount === '0' ? (
          <div className="flex flex-1 items-center justify-center">
            <Empty
              image={<img src={IconPersonTest} />}
              imageStyle={{ height: 40 }}
              description={false}
            >
              <span>계정을 선택해주세요</span>
            </Empty>
          </div>
        ) : (
          <>
            <EditAccount />
            <SetAuthority />
          </>
        )}
      </div>
    </div>
  );
}
