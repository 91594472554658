import { useQuery } from '@tanstack/react-query';

import { getCrmIssueListAPI } from 'src/api/issue.api';
import { QUERY_KEYS } from 'src/libs/constants';

export default function useIssueList() {
  const { data, isFetching } = useQuery([QUERY_KEYS.GET_CRM_ISSUE_LIST], () =>
    getCrmIssueListAPI({})
  );

  const issueList = data?.crmIssueList.sort((a, b) => a.colId - b.colId) || [];

  const getIssue = (id: string) => {
    return issueList.flatMap((item) => item.list).find((item) => item.id === id);
  };

  return {
    issueList,
    isFetching,
    getIssue,
  };
}
