import Flicking from '@egjs/react-flicking';
import { Button } from 'antd';
import '@egjs/react-flicking/dist/flicking.css';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { Item, contentManageState } from 'src/recoil/contentManage.recoil';

type Props = { itemList: Item[] };

export default function ContentList({ itemList }: Props) {
  const navigate = useNavigate();
  const setContentManage = useSetRecoilState(contentManageState);

  const onClickStudio = () => {
    navigate('/content/studio');
  };

  return (
    <>
      <Flicking
        renderOnlyVisible={true}
        align="prev"
        bound
        preventDefaultOnDrag
        onSelect={(event) => {
          const selectedIndex = event.index;
          const selectedItemId = itemList[selectedIndex]?.id;
          setContentManage((prev) => ({
            itemList: prev.itemList.map((item) => ({
              ...item,
              select: item.id === selectedItemId,
            })),
          }));
        }}
      >
        {itemList.map((item) => (
          <div className="w-[220px] overflow-hidden relative mr-5" key={item.id}>
            {/* <img
              src={item.src}
              className={`w-full aspect-[9/16] rounded object-contain border-solid border-[#1677ff] ${
                item.select ? 'border-[4px]' : 'border-none'
              }`}
            /> */}
            <div
              className={`relative w-full aspect-[9/16] rounded object-contain border-solid border-[#1677ff] ${
                item.select ? 'border-[4px]' : 'border-none'
              }`}
            >
              <iframe
                className="absolute w-full h-full"
                style={{
                  top: 0,
                  left: 0,
                }}
                src={item.vimeo}
                frameBorder={0}
                allow="picture-in-picture"
                // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                title="#240222_TU_002"
                data-ready="true"
              />
            </div>
            <div className="flex items-center justify-end mt-3">
              <span className="text-xs font-bold">조회수 {item.viewCnt}</span>
              <span className="text-xs font-bold mx-1">좋아요 {item.likeCnt}</span>
              <span className="text-xs font-bold">댓글 {item.replyCnt}</span>
            </div>
          </div>
        ))}
        <div className="w-[220px] flex justify-center items-center overflow-hidden relative mr-5">
          <div className="flex">
            <Button type="link" onClick={onClickStudio}>
              + 추가 제작 요청
            </Button>
          </div>
        </div>
      </Flicking>
    </>
  );
}
