import { Modal } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function AddReservationModal() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const onDismiss = () => {
    navigate(-1);
  };

  return (
    <Modal open onCancel={onDismiss}>
      AddReservationModal {id}
    </Modal>
  );
}
