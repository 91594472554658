export const queryCrmIssueList = `
query($filter: CrmIssueFilter){
  crmIssueList(filter: $filter) {
    colId
    list {
      id
      hospitalId
      colId
      authorId
      title
      content
      chipId
      createdAt
      image {
        id
        url
        type
        size
      }
      cc {
        id
        ccHuserId
      }
    }
  }
}
`;

export const queryCrmIssueHideList = `
query($filter: CrmIssueFilter){
  crmIssueHideList(filter: $filter) {
    id
    hospitalId
    colId
    authorId
    title
    content
    chipId
    createdAt
    image {
      id
      url
      type
      size
    }
    cc {
      id
      ccHuserId
    }
  }
}
`;

export const queryAddCrmIssue = `
  mutation AddCrmIssue($input: AddCrmIssueInput!) {
    addCrmIssue(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryUpdateCrmIssue = `
  mutation UpdateCrmIssue($input: UpdateCrmIssueInput!) {
    updateCrmIssue(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryRemoveCrmIssue = `
  mutation RemoveCrmIssue($input: RemoveCrmIssueInput!) {
    removeCrmIssue(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryAddCrmRefToIssue = `
  mutation AddCrmRefToIssue($input: Input) {
    addCrmRefToIssue(input: $input) {
      ok
    }
  }
`;

export const queryRemoveCrmRefFromIssue = `
  mutation RemoveCrmRefFromIssue($input: Input) {
    removeCrmRefFromIssue(input: $input) {
      ok
    }
  }
`;

export const queryUpdateCrmIssueStatus = `
  mutation UpdateCrmIssueStatus($input: UpdateCrmIssueStatusInput!) {
    updateCrmIssueStatus(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryGetCrmIssueCommentList = `
  query($filter: CrmIssueCommentFilter, $page: PaginationInput){
    crmIssueComments(filter: $filter, page: $page) {
        rows{
            id
            comment
            huserId
            createdAt
        }
        page {
            total
            perPage
            currentPage
            lastPage
        }
    }
  }
`;

export const queryAddCrmIssueComment = `
  mutation($input: AddCrmIssueCommentInput){
    addCrmIssueComment(input: $input) {
        id
        comment
        huserId
        createdAt
    }
  }
`;

export const queryRemoveCrmIssueComment = `
  mutation($input: RemoveCrmIssueCommentInput){
    removeCrmIssueComment(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryUpdateCrmIssueComment = `
  mutation($input:UpdateCrmIssueCommentInput){
    updateCrmIssueComment(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryUpdateCrmIssueState = `
  mutation UpdateCrmIssueStatus($input: UpdateCrmIssueStatusInput!) {
    updateCrmIssueStatus(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryAddCrmIssueCc = `
  mutation AddCrmIssueCc($input: AddCrmIssueCcInput!) {
    addCrmIssueCc(input: $input) {
        ok
        error {
            path
            message
        }
        data {
          id
          ccHuserId
          #crmIssueId
        }
    }
  }
`;

export const queryRemoveCrmIssueCc = `
  mutation RemoveCrmIssueCc($input: RemoveCrmIssueCcInput!) {
    removeCrmIssueCc(input: $input) {
        ok
        error {
            path
            message
        }
        data {
          id
        }
    }
  }
`;

export const queryUpdateCrmIssueHide = `
  mutation UpdateCrmIssueHide($input: UpdateCrmIssueHideInput!) {
    updateCrmIssueHide(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryCrmIssueListByCcHuserId = `
  query($filter: CrmIssueListByCcHuserIdFilter){
    crmIssueListByCcHuserId(filter: $filter) {
      id
      hospitalId
      authorId
      title
      content
      chipId
      createdAt
      image {
              id
        url
        type
        size
      }
          cc {
              id
              ccHuserId
          }


  }
}
`;
