import { Select } from 'antd';
import React from 'react';
import type { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { UseFormReturn, FieldValues } from 'react-hook-form';

interface ConnectFormProps<TFieldValues extends FieldValues> {
  children(children: UseFormReturn<TFieldValues>): ReactElement;
}

const ConnectForm = <TFieldValues extends FieldValues>({
  children,
}: ConnectFormProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  return children(methods);
};

type Props = {
  bg: string;
};
// 매출   ROAS   신규 고객 수    내원율   결제
// 일 평균 내원수   일 평균 콜수  전체 콜수    일 평균 예약수
// 상담 동의율

const grossIndicatorList = [
  {
    key: 'IC_01',
    label: '매출',
    h_type: '12345',
    order: 1,
  },
  {
    key: 'IC_02',
    label: 'ROAS',
    h_type: '12345',
    order: 2,
  },
  {
    key: 'IC_03',
    label: '신규 고객 수',
    h_type: '12345',
    order: 3,
  },
  {
    key: 'IC_04',
    label: '내원율',
    h_type: '12345',
    order: 4,
  },
  {
    key: 'IC_05',
    label: '결제',
    h_type: '12345',
    order: 5,
  },
  {
    key: 'IC_06',
    label: '일 평균 내원수',
    h_type: '12345',
    order: 6,
  },
  {
    key: 'IC_07',
    label: '일 평균 콜수',
    h_type: '12345',
    order: 7,
  },
  {
    key: 'IC_08',
    label: '전체 콜수',
    h_type: '1',
    order: 8,
  },
  {
    key: 'IC_09',
    label: '일 평균 예약수',
    h_type: '1',
    order: 9,
  },
  {
    key: 'IC_10',
    label: '상담 동의율',
    h_type: '2',
    order: 10,
  },
];

const GtSelector = (p: any) => {
  const { watch, setValue, control, getValues, formState } = p.methods;

  return (
    <Controller
      name="indicator"
      control={control}
      defaultValue={`IC_01`}
      // rules={{ required: true }}
      render={({ field }) => {
        return (
          <Select
            {...field}
            status={formState.errors.assignHuserId && 'error'}
            options={grossIndicatorList.map(({ key, label }) => ({
              value: key,
              label: label,
            }))}
            className={`w-full`}
            placeholder="지표선택"
            listHeight={180}
          />
        );
      }}
    />
  );
};

export default function GtypeSelector({ statusId, name }: any) {
  return (
    <ConnectForm>
      {(methods) => <GtSelector methods={methods} props={{ statusId, name }} />}
    </ConnectForm>
  );
}
