import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Select, DatePicker, Input, notification, ConfigProvider } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useForm, Controller, SubmitHandler, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import AssignSelector from '../Form/Controllers/AssignHuserSelect';
import ListTagsSelector from '../Form/Controllers/ListTagsSelect';
import NRCTypeSelector from '../Form/Controllers/NRCTypeSelect';
import { addCrmItemHistoryAPI } from 'src/api/crm.api';
import useCrmItem from 'src/hooks/useCrmItem';
import useHuserList from 'src/hooks/useHuserList';
import { displayDateTime, getDisplayTime } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import TimeTableDrawer from 'src/libs/TimeTableDrawer';
import { reservationFilterState } from 'src/recoil/reservationFilter.recoil';
import { getStatusListByGroupStatus } from 'src/recoil/status.recoil';

const { TextArea } = Input;

type Form = {
  memo: string;
  statusId: string;
  date: string;
  assignHuserId: string;
  length: number;
};

// 예약관리 폼
export default function EditableBookForm() {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const { scheduleData } = useRecoilValue(reservationFilterState);
  const statusList = useRecoilValue(getStatusListByGroupStatus('call'));
  const { hUserList } = useHuserList();

  const methods = useForm<Form>();

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = methods;

  const statusId = watch('statusId');
  const 예약상태 = ['21', '28'].includes(statusId);

  useEffect(() => {
    const { memo, statusId, assignHuserId, startTime, endTime } = scheduleData;
    if (scheduleData) {
      setValue('memo', memo);
      // setValue('statusId', statusId);

      if (scheduleData.statusId === '21') {
        const [startHour, startMin] = dayjs(startTime).format('HH:mm').split(':');
        const [endHour, endMin] = dayjs(endTime).format('HH:mm').split(':');
        const st = Number(startHour) * 60 + Number(startMin);
        const et = Number(endHour) * 60 + Number(endMin);
        const length = et - st;

        setValue('assignHuserId', assignHuserId);
        setValue('length', length);
        setValue('date', dayjs(startTime).format('YYYY-MM-DD HH:mm'));
      }
    }
  }, [scheduleData, setValue]);

  const addCrmItemHistory = useMutation(addCrmItemHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '콜/예약 추가 완료.' });
      reset();
    },
    onError: () => {
      api.error({ message: '콜/예약 추가 실패.' });
    },
  });

  const onSubmit: SubmitHandler<Form> = (data) => {
    const { listId } = scheduleData;
    const payload = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== undefined)
    ) as Form;

    if (예약상태) {
      const { date, length, ...others } = payload;
      const startTime = dayjs(date).format();
      const endTime = dayjs(date).add(length, 'minutes').format();
      addCrmItemHistory.mutate({ listId, startTime, endTime, ...others });
    } else {
      const { statusId, memo } = payload;
      addCrmItemHistory.mutate({ statusId, listId, memo });
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="mt-5">
        {contextHolder}
        <Controller
          name="memo"
          control={control}
          render={({ field }) => (
            <TextArea {...field} rows={6} placeholder="메모를 입력하세요" className="mb-3" />
          )}
        />
        <div className="flex gap-x-3">
          <Controller
            name="statusId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={statusList.map(({ id, status }) => ({ value: id, label: status }))}
                className={`text-center ${statusId} ${
                  /^(21|28)$/gm.test(statusId) ? 'w-[150px]' : 'w-full'
                }`}
                placeholder="콜/예약 상태를 선택하세요"
                status={errors.statusId ? 'error' : ''}
              />
            )}
          />
          {예약상태 && (
            <>
              <Controller
                name="date"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <DatePicker
                    className="flex-1"
                    locale={locale}
                    placeholder="예약일 지정"
                    disabledDate={(current) => current < dayjs().subtract(1, 'day')}
                    defaultValue={dayjs(scheduleData.startTime)}
                    format="YYYY-MM-DD HH:mm"
                    disabledTime={displayDateTime}
                    showTime={{
                      format: 'HH:mm',
                      hourStep: 1,
                      minuteStep: 30,
                      hideDisabledOptions: true,
                      defaultValue: dayjs('09:00', 'HH:mm'),
                    }}
                    showNow={false}
                    onChange={(value, dateString) => onChange(dateString)}
                    status={errors.date ? 'error' : ''}
                  />
                )}
              />
              <AssignSelector statusId={statusId} assignHuserId={''} />
              {/* <Controller
                name="assignHuserId"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="text-center ml-3 w-[150px]"
                    options={[
                      ...hUserList
                        .filter((hUser) => hUser.type === 2 || hUser.type === 4)
                        .map(({ id, name }) => ({ value: id, label: name })),
                    ]}
                    placeholder="담당자 지정"
                    status={errors.assignHuserId ? 'error' : ''}
                  />
                )}
              /> */}
            </>
          )}
        </div>
        {예약상태 && (
          <>
            <div className="mt-3">
              <Controller
                name="length"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div className="grid grid-cols-5 flex-1 gap-2">
                    {Array.from({ length: 5 }, (_, idx) => (idx + 2) * 30).map((time) => (
                      <div
                        key={time}
                        className="col-span-1 flex justify-center items-center"
                        onClick={() => onChange(time)}
                      >
                        <ConfigProvider theme={{ token: { colorPrimary: '#00b96b' } }}>
                          <Button
                            danger={Boolean(errors.length)}
                            type={time === value ? 'primary' : 'default'}
                            className="w-full"
                          >
                            <div className="text-xs">{getDisplayTime(time)}</div>
                          </Button>
                        </ConfigProvider>
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>
            <div className="mt-3">
              <NRCTypeSelector />
            </div>
          </>
        )}

        <div className="mt-3">
          <ListTagsSelector defaultValue={scheduleData.tags} />
        </div>
        <div className="mt-3 flex justify-end">
          <Button type="primary" onClick={handleSubmit(onSubmit)}>
            확인
          </Button>
        </div>
      </div>
    </FormProvider>
  );
}
