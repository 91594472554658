import Http from 'src/@http';
import { queryMeh, querySignin } from 'src/graphql/auth.query';
import { errorResponse, successResponse } from 'src/libs/response';
import { MehResponse, SigninRequest, SigninResponse } from 'src/types/auth.types';
import { ApiResponse } from 'src/types/response';

export async function signinRequestAPI({ email, password }: SigninRequest) {
  return Http.instance
    .post<ApiResponse<SigninResponse>>('', {
      query: querySignin,
      variables: {
        email,
        password,
      },
    })
    .then(successResponse)
    .catch(errorResponse('signinRequestAPI'));
}

export async function mehRequestAPI() {
  return Http.instance
    .post<ApiResponse<MehResponse>>('', {
      query: queryMeh,
    })
    .then(successResponse)
    .catch(errorResponse('mehRequestAPI'));
}
