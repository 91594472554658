import { atom, selectorFamily } from 'recoil';

import { PERMISSIONS } from 'src/libs/constants';
import { SigninResponse } from 'src/types/auth.types';

type State = SigninResponse['login'];

const initialState = {
  token: '',
  refreshToken: '',
  meh: {
    id: '',
    email: '',
    crmAuthority: 0,
    name: '',
    title: '',
    department: '',
    position: '',
    phone: '',
    isMaster: false,
    avatar: '',
    hospital: {
      id: '',
      name: '',
      location: {
        id: '',
        siName: '',
        gunguName: '',
      },
    },
  },
};

export const authState = atom<State>({
  key: 'authState',
  default: initialState,
});

export const isRightPermissionState = selectorFamily({
  key: `isRightPermissionState`,
  get:
    (requiredPermission: (typeof PERMISSIONS)[number]) =>
    ({ get }) => {
      const {
        meh: { crmAuthority },
      } = get(authState);

      const index = PERMISSIONS.findIndex((permission) => permission === requiredPermission);
      // 내림차순이라 reverse함
      const reversedBits = crmAuthority
        .toString(2)
        .padStart(PERMISSIONS.length, '0')
        .split('')
        .reverse()
        .join('');

      const requiredPermissionDigit = Math.pow(2, index);
      return (parseInt(reversedBits, 2) & requiredPermissionDigit) !== 0;
    },
});
