import { Drawer } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';

import CreatePatientForm from './CreatePatientForm';
import SearchPatientForm from './SearchPatientForm';
import { callManagementState } from 'src/recoil/callManagement.recoil';

export default function AddPatientDrawer() {
  const [{ showAddPatientDrawer }, setCallManagement] = useRecoilState(callManagementState);

  const onClose = () => {
    setCallManagement((prev) => ({ ...prev, showAddPatientDrawer: false }));
  };

  return (
    <Drawer
      closable={false}
      width={600}
      onClose={onClose}
      open={showAddPatientDrawer}
      destroyOnClose
    >
      <div className="bg-white rounded-lg h-full">
        {/* <SearchPatientForm /> */}
        <CreatePatientForm />
      </div>
    </Drawer>
  );
}
