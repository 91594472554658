import { Button, Spin } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';

import AddAcountDrawer from './AddAcountDrawer';
import StatusCircle from '../StatusCircle';
import useHuserList from 'src/hooks/useHuserList';
import { getAvatarPath } from 'src/libs/common';
import { accountState } from 'src/recoil/account.recoil';

export default function AccountList() {
  const [{ selectedAccount }, setAccount] = useRecoilState(accountState);
  const { hUserList, isFetching } = useHuserList();

  const onClickAccount = (id: string) => {
    setAccount((prev) => ({ ...prev, selectedAccount: id }));
  };

  const onClickAddAccount = () => {
    setAccount((prev) => ({ ...prev, openDrawer: true }));
  };

  return (
    <div className="w-[220px]">
      <div className="mb-3 text-[#2B3674] font-bold">계정목록</div>
      <div className="rounded-lg shadow-lg">
        <Spin spinning={isFetching}>
          <div className="px-3 h-[405px] overflow-auto">
            {hUserList.map((hUser, idx) => (
              <div
                className="flex items-center mb-3 cursor-pointer hover:font-bold"
                key={idx}
                onClick={() => onClickAccount(hUser.id)}
              >
                <div className="mr-2 w-[25px]">
                  {selectedAccount === hUser.id && <StatusCircle bg="#4318FF" />}
                </div>
                <img
                  src={getAvatarPath(hUser.avatar)}
                  className="w-[25px] aspect-square rounded-full"
                />
                <div
                  className={[
                    'ml-3 text-[#2B3674] text-xs',
                    selectedAccount === hUser.id ? 'font-bold' : '',
                  ].join(' ')}
                >
                  {hUser.name} {hUser.isDayOff && '📵'}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-5 flex justify-center items-center pb-5">
            <Button className="bg-[#3965FF]" onClick={onClickAddAccount}>
              <div className="text-[#F5F5F5] font-bold">계정추가</div>
            </Button>
          </div>
        </Spin>
      </div>
      <AddAcountDrawer />
    </div>
  );
}
