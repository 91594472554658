import Http from 'src/@http';
import {
  queryCommunityByTag,
  queryCrmBalance,
  queryCrmChargeHistory,
  querySendEmail,
  querySignS3,
  queryUploadDb,
} from 'src/graphql/common.query';
import { errorResponse, successResponse } from 'src/libs/response';
import {
  GetBalanceResponse,
  GetChargeHistoryListRequest,
  GetChargeHistoryListResponse,
  GetCommunityByTagRequest,
  GetCommunityByTagResponse,
  SendEmailRequest,
  SendEmailResponse,
  SignS3Request,
  SignS3Response,
  UploadDbRequest,
  UploadDbResponse,
} from 'src/types/common.types';
import { ApiResponse } from 'src/types/response';

export async function sendEmailAPI(variables: SendEmailRequest) {
  return Http.instance
    .post<ApiResponse<SendEmailResponse>>('', {
      operationName: 'SendEmail',
      query: querySendEmail,
      variables: {
        input: variables,
      },
    })
    .then(successResponse)
    .catch(errorResponse('sendEmailAPI'));
}

export async function getCrmBalanceAPI() {
  return Http.instance
    .post<ApiResponse<GetBalanceResponse>>('', {
      operationName: 'crmBalance',
      query: queryCrmBalance,
    })
    .then(successResponse)
    .catch(errorResponse('getCrmBalanceAPI'));
}

export async function getCrmChargeHistoryListAPI({ hospitalId }: GetChargeHistoryListRequest) {
  return Http.instance
    .post<ApiResponse<GetChargeHistoryListResponse>>('', {
      operationName: 'CrmChargeHistory',
      query: queryCrmChargeHistory,
      variables: {
        hospitalId,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmChargeHistoryListAPI'));
}

// TODO:
export async function uploadDbAPI(payload: UploadDbRequest) {
  return Http.instance
    .post<ApiResponse<UploadDbResponse>>('', {
      operationName: 'UploadDb',
      query: queryUploadDb,
      variables: {
        payload,
      },
    })
    .then(successResponse)
    .catch(errorResponse('uploadDbAPI'));
}

export async function getCommunityByTagAPI(payload: GetCommunityByTagRequest) {
  return Http.instance
    .post<ApiResponse<GetCommunityByTagResponse>>('', {
      query: queryCommunityByTag,
      variables: { ...payload },
    })
    .then(successResponse)
    .catch(errorResponse('getCommunityByTagAPI'));
}

export async function signS3API(payload: SignS3Request) {
  return Http.instance
    .post<ApiResponse<SignS3Response>>('', {
      query: querySignS3,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('signS3API'));
}
