import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Select, Input, notification, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { addCrmItemHistoryAPI, addCrmDepositAPI } from 'src/api/crm.api';
import ListTagsSelector from 'src/components/Form/Controllers/ListTagsSelect';
import useCrmItem from 'src/hooks/useCrmItem';
import { QUERY_KEYS } from 'src/libs/constants';
import { authState } from 'src/recoil/auth.recoil';
import { getStatusListByGroupStatus } from 'src/recoil/status.recoil';

const { TextArea } = Input;

type Form = {
  memo: string;
  statusId: string;
  deposit: number;
};

export default function CommonConsultForm() {
  const [prevInfo, setInfo] = useState(''); // 메모 기억
  const { info } = useParams<{ info: string }>();
  const [listId, patientId] = info?.split('_') || [''];
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const statusList = useRecoilValue(getStatusListByGroupStatus('consult'));
  const auth = useRecoilValue(authState);

  const methods = useForm<Form>();

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const status = watch('statusId');
  const 예약금상태 = status === '41';

  const { crmItem, isFetching } = useCrmItem(listId);

  useEffect(() => {
    if (info) {
      setInfo(info);
      if (info !== prevInfo) {
        reset();
      }
    }
  }, [info, reset, prevInfo]);

  const addCrmItemHistory = useMutation(addCrmItemHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '추가 완료.' });
      reset();
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PATIENT_CONSULT_HISTORY,
        { patientId },
      ]);
    },
    onError: () => {
      api.error({ message: '추가 실패.' });
      reset();
    },
  });

  const addCrmDeposit = useMutation(addCrmDepositAPI, {
    onSuccess: (res) => {
      // api.success({ message: '추가 완료.' });
      // reset();
      // return queryClient.invalidateQueries([QUERY_KEYS.ADD_CRM_DEPOSIT, { patientId }]);
      return true;
    },
    onError: () => {
      api.error({ message: '예약금 추가 실패.' });
      // reset();
      return false;
    },
  });

  const onSubmit: SubmitHandler<Form> = async (data) => {
    const { statusId, memo, deposit } = data;
    if (예약금상태) {
      const addDeposit = await addCrmDeposit.mutate({
        crmListId: listId,
        crmPatientId: patientId,
        deposit,
        memo,
      });
      console.log('addDeposit', addDeposit);

      // if (!addDeposit) return;
    }

    addCrmItemHistory.mutate({ statusId, listId, memo, assignHuserId: auth.meh.id });
  };

  if (isFetching) return null;

  return (
    <FormProvider {...methods}>
      <div className="mt-5">
        {contextHolder}
        {예약금상태 && (
          <Controller
            name="deposit"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <InputNumber
                value={value}
                placeholder="숫자만 입력"
                step={100}
                addonBefore="예약금"
                addonAfter="원"
                onChange={(digit) => onChange(digit || 0)}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                status={errors.deposit ? 'error' : ''}
                className="w-full mb-1"
              />
            )}
          />
        )}

        <Controller
          name="memo"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              rows={6}
              placeholder="메모를 입력하세요"
              className="mb-3"
              defaultValue={crmItem.memo || undefined}
            />
          )}
        />
        <div className="flex">
          <Controller
            name="statusId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={statusList.map(({ id, status }) => ({ value: id, label: status }))}
                className={`text-center w-full`}
                placeholder="상담 상태를 선택하세요"
                status={errors.statusId ? 'error' : ''}
              />
            )}
          />
        </div>

        <div className="mt-3">
          <ListTagsSelector defaultValue={crmItem.tags} />
        </div>
        <div className="mt-3 flex justify-end">
          <Button type="primary" onClick={handleSubmit(onSubmit)}>
            확인
          </Button>
        </div>
      </div>
    </FormProvider>
  );
}
