import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Avatar,
  Button,
  Input,
  Select,
  Popconfirm,
  notification,
  ConfigProvider,
  DatePicker,
  Tooltip,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { DisplayTagNrc } from './CallHistoryWidget';
import {
  addCrmHistoryCcAPI,
  removeCrmHistoryCcAPI,
  removeCrmItemHistoryAPI,
  updateCrmItemHistoryAPI,
} from 'src/api/crm.api';
import useHuserList from 'src/hooks/useHuserList';
import { displayDateTime, getAvatarPath, getDisplayTime } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import TimeTableDrawer from 'src/libs/TimeTableDrawer';
import {
  getStatusListByGroupStatus,
  getStatusNameMapState,
  getStatusColorMapState,
  getStatusMapProcessIdState,
} from 'src/recoil/status.recoil';
import { CrmContent } from 'src/types/crm.types';

const { TextArea } = Input;

type Form = {
  memo: string;
  statusId: string;
  date: string;
  assignHuserId: string;
  length: number;
  ccList: any[];
};

type Props = CrmContent;

export default function EditableCallHistoryCard(props: Props) {
  const queryClient = useQueryClient();
  const { info } = useParams<{ info: string }>();
  const [_, patientId] = info?.split('_') || [''];
  const [api, contextHolder] = notification.useNotification();
  const { getUserById, hUserList } = useHuserList();
  const [isEdit, setIsEdit] = useState(false);
  const [ccList, setCcList] = useState(props.cc.map(({ huserId }) => huserId));
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Form>({ defaultValues: { assignHuserId: props.assignHuserId, memo: props.memo } });
  const statusMap = useRecoilValue(getStatusNameMapState);
  const statusColorMap = useRecoilValue(getStatusColorMapState);
  const statusMapProcessId = useRecoilValue(getStatusMapProcessIdState);
  const statusList = useRecoilValue(getStatusListByGroupStatus('call'));

  const statusId = watch('statusId');
  const 예약상태 = ['21', '28'].includes(statusId);

  const findBgColor = (statusId: string) => {
    const color = statusColorMap[statusMapProcessId[statusId]];

    return color.split(',')[0] || '#2B3674';
  };

  useEffect(() => {
    if (props.statusId === '21') {
      const [startHour, startMin] = dayjs(props.startTime).format('HH:mm').split(':');
      const [endHour, endMin] = dayjs(props.endTime).format('HH:mm').split(':');
      const st = Number(startHour) * 60 + Number(startMin);
      const et = Number(endHour) * 60 + Number(endMin);
      const length = et - st;
      setValue('length', length);
      setValue('date', dayjs(props.startTime).format('YYYY-MM-DD HH:mm'));
    }
  }, [isEdit, setValue, props, 예약상태]);

  const addCrmHistoryCc = useMutation(addCrmHistoryCcAPI, {
    onSuccess: (res) => {
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PATIENT_CALL_HISTORY,
        { patientId },
      ]);
    },
  });

  const removeCrmHistoryCc = useMutation(removeCrmHistoryCcAPI, {
    onSuccess: (res) => {
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PATIENT_CALL_HISTORY,
        { patientId },
      ]);
    },
  });

  const updateCrmItemHistory = useMutation(updateCrmItemHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '콜 이력 수정이 완료되었습니다.' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PATIENT_CALL_HISTORY,
        { patientId },
      ]);
    },
    onError: () => {
      api.error({ message: '콜 이력 수정이 실패했습니다.' });
    },
    onSettled: () => {
      onClickCancelEdit();
    },
  });

  const removeCrmItemHistory = useMutation(removeCrmItemHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '콜 이력 삭제가 완료되었습니다.' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PATIENT_CALL_HISTORY,
        { patientId },
      ]);
    },
    onError: () => {
      api.error({ message: '콜 이력 삭제가 실패했습니다.' });
    },
    onSettled: () => {
      setIsEdit(false);
    },
  });

  const onClickEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const onClickCancelEdit = () => {
    reset();
    setIsEdit(false);
  };

  const onClickDelete = (id: string) => {
    removeCrmItemHistory.mutate({ id });
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    const payload = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== undefined)
    ) as Form;

    if (예약상태) {
      const { date, length, ccList, ...others } = payload;
      const startTime = dayjs(date).format();
      const endTime = dayjs(date).add(length, 'minutes').format();
      updateCrmItemHistory.mutate({ id: props.id, startTime, endTime, ...others });
    } else {
      const { statusId, memo } = payload;
      updateCrmItemHistory.mutate({ statusId, id: props.id, memo });
    }
  };

  const onChangeCcList = (value: string[]) => {
    const originLength = props.cc.length;
    const nextLength = value.length;

    if (originLength > nextLength) {
      const [removeCc] = props.cc.filter((cc) => !value.includes(cc.huserId));
      removeCrmHistoryCc.mutate({ id: [removeCc.id] });
    } else {
      const [ccHuserId] = value.filter((cc) => !props.cc.find((v) => v.huserId === cc));
      addCrmHistoryCc.mutate({ crmContentId: props.id, ccHuserId });
    }

    setCcList(value);
  };

  const hUser = getUserById(props.authorId);

  return (
    <div className="rounded-lg shadow-lg bg-white p-5 mb-3 relative">
      {contextHolder}
      {isEdit ? (
        <>
          <Controller
            name="memo"
            control={control}
            render={({ field }) => (
              <TextArea {...field} rows={6} placeholder="메모를 입력하세요" className="mb-3" />
            )}
          />
          <div className="flex">
            <Controller
              name="statusId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={statusList.map(({ id, status }) => ({ value: id, label: status }))}
                  className={`text-center ${statusId === '21' ? 'w-[150px]' : 'w-full'}`}
                  placeholder="콜/예약 상태를 선택하세요"
                  status={errors.statusId ? 'error' : ''}
                />
              )}
            />

            {예약상태 && (
              <>
                <Controller
                  name="date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange } }) => (
                    <DatePicker
                      className="ml-3 flex-1"
                      locale={locale}
                      placeholder="예약일 지정"
                      disabledDate={(current) => current < dayjs().subtract(1, 'day')}
                      format="YYYY-MM-DD HH:mm"
                      disabledTime={displayDateTime}
                      showTime={{
                        format: 'HH:mm',
                        hourStep: 1,
                        minuteStep: 30,
                        hideDisabledOptions: true,
                        defaultValue: dayjs('09:00', 'HH:mm'),
                      }}
                      showNow={false}
                      onChange={(value, dateString) => onChange(dateString)}
                      status={errors.date ? 'error' : ''}
                    />
                  )}
                />
                <Controller
                  name="assignHuserId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="text-center ml-3 w-[150px]"
                      options={hUserList
                        .filter((hUser) => hUser.type === 2 || hUser.type === 4)
                        .map(({ id, name }) => ({ value: id, label: name }))}
                      placeholder="담당자 지정"
                      status={errors.assignHuserId ? 'error' : ''}
                    />
                  )}
                />
              </>
            )}
          </div>

          {예약상태 && (
            <div className="mt-3">
              <Controller
                name="length"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div className="grid grid-cols-5 flex-1 gap-2">
                    {Array.from({ length: 5 }, (_, idx) => (idx + 2) * 30).map((time) => (
                      <div
                        key={time}
                        className="col-span-1 flex justify-center items-center"
                        onClick={() => onChange(time)}
                      >
                        <ConfigProvider theme={{ token: { colorPrimary: '#00b96b' } }}>
                          <Button
                            danger={Boolean(errors.length)}
                            type={time === value ? 'primary' : 'default'}
                            className="w-full"
                          >
                            <div className="text-xs">{getDisplayTime(time)}</div>
                          </Button>
                        </ConfigProvider>
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>
          )}

          <div className="mt-3 flex justify-between">
            <div className="flex flex-1">
              <Select
                mode="multiple"
                className="text-center w-full"
                options={[...hUserList.map(({ id, name }) => ({ value: id, label: name }))]}
                placeholder="cc추가"
                onChange={(value) => onChangeCcList(value)}
                value={ccList}
              />
            </div>

            <div>
              <Popconfirm
                title="콜 이력 삭제"
                description="정말 삭제하시겠습니까?"
                okText="예"
                cancelText="아니오"
                onConfirm={(e) => onClickDelete(props.id)}
              >
                <Button type="text" danger>
                  삭제
                </Button>
              </Popconfirm>
              <Button className="mx-2" onClick={onClickCancelEdit}>
                취소
              </Button>
              <Button type="primary" onClick={handleSubmit(onSubmit)}>
                확인
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <DisplayTagNrc data={props} />
          <div className="flex items-center justify-between">
            <div className="text-[#2B3674] text-sm">{props.memo}</div>
            <Button className="ml-3" type="text" icon={<EditOutlined />} onClick={onClickEdit} />
          </div>
          <div className="flex items-center mt-2 justify-between">
            <div>{dayjs(props.createdAt).format('YYYY-MM-DD')}</div>
            <div className="flex items-center">
              {statusMap[props.statusId] && (
                <div
                  style={{ backgroundColor: findBgColor(props.statusId) }}
                  className="mr-3 bg-[#2B3674] text-white text-[10px] py-1 px-3 rounded"
                >
                  {statusMap[props.statusId]}
                </div>
              )}
              <Avatar src={getAvatarPath(hUser.avatar)} size="small" />
              <div className="text-[#2B3674] font-bold text-xs ml-1">{hUser?.name}</div>
            </div>
          </div>
          {props.cc.length > 0 && (
            <div className="mt-2">
              <Avatar.Group
                maxCount={3}
                maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                size="small"
              >
                {props.cc.map((v) => (
                  <Tooltip
                    placement="top"
                    title={getUserById(v.huserId) ? getUserById(v.huserId).name : ''}
                    key={v.id}
                  >
                    <Avatar src={getAvatarPath(getUserById(v.huserId).avatar)} size="small" />
                  </Tooltip>
                ))}
              </Avatar.Group>
            </div>
          )}
        </>
      )}
    </div>
  );
}
