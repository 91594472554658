import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Avatar,
  Button,
  Input,
  Select,
  Popconfirm,
  notification,
  Tooltip,
  InputNumber,
  Tag,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  addCrmHistoryCcAPI,
  removeCrmHistoryCcAPI,
  removeCrmItemHistoryAPI,
  updateCrmItemHistoryAPI,
} from 'src/api/crm.api';
import { DisplayTagNrc } from 'src/components/Call/Container/CallHistoryWidget';
import useHuserList from 'src/hooks/useHuserList';
import { getAvatarPath } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import {
  getStatusListByGroupStatus,
  getStatusNameMapState,
  getStatusColorMapState,
  getStatusMapProcessIdState,
} from 'src/recoil/status.recoil';
import { CrmContent } from 'src/types/crm.types';

const { TextArea } = Input;

type InputForm = {
  memo: string;
  statusId: string;
  deposit: number;
};

type Props = CrmContent;

export default function EditableConsultHistoryCard(props: Props) {
  const queryClient = useQueryClient();
  const { info } = useParams<{ info: string }>();
  const [_, patientId] = info?.split('_') || [''];
  const [api, contextHolder] = notification.useNotification();
  const { getUserById, hUserList } = useHuserList();
  const [isEdit, setIsEdit] = useState(false);
  const [ccList, setCcList] = useState(props.cc.map(({ huserId }) => huserId));
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<InputForm>();
  const statusMap = useRecoilValue(getStatusNameMapState);
  const statusColorMap = useRecoilValue(getStatusColorMapState);
  const statusMapProcessId = useRecoilValue(getStatusMapProcessIdState);
  const statusList = useRecoilValue(getStatusListByGroupStatus('consult'));
  const status = watch('statusId');
  const 예약금상태 = status === '41';

  const findBgColor = (statusId: string) => {
    const color = statusColorMap[statusMapProcessId[statusId]];

    return color.split(',')[0] || '#2B3674';
  };

  useEffect(() => {
    if (isEdit) {
      setValue('memo', props.memo);
    }
  }, [isEdit, setValue, props]);

  const addCrmHistoryCc = useMutation(addCrmHistoryCcAPI, {
    onSuccess: (res) => {
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PATIENT_CONSULT_HISTORY,
        { patientId },
      ]);
    },
  });

  const removeCrmHistoryCc = useMutation(removeCrmHistoryCcAPI, {
    onSuccess: (res) => {
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PATIENT_CONSULT_HISTORY,
        { patientId },
      ]);
    },
  });

  const updateCrmItemHistory = useMutation(updateCrmItemHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '상담 이력 수정이 완료되었습니다.' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PATIENT_CONSULT_HISTORY,
        { patientId },
      ]);
    },
    onError: () => {
      api.error({ message: '상담 이력 수정이 실패했습니다.' });
    },
    onSettled: () => {},
  });

  const removeCrmItemHistory = useMutation(removeCrmItemHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '상담 이력 삭제가 완료되었습니다.' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PATIENT_CONSULT_HISTORY,
        { patientId },
      ]);
    },
    onError: () => {
      api.error({ message: '상담 이력 삭제가 실패했습니다.' });
    },
    onSettled: () => {
      setIsEdit(false);
    },
  });

  const onClickEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const onClickCancelEdit = () => {
    reset();
    setIsEdit(false);
  };

  const onClickDelete = (id: string) => {
    removeCrmItemHistory.mutate({ id });
  };

  const onSubmit: SubmitHandler<InputForm> = (data) => {
    if (예약금상태) {
      console.log(data);
    } else {
      updateCrmItemHistory.mutate({ id: props.id, ...data });
    }
  };

  const onChangeCcList = (value: string[]) => {
    const originLength = props.cc.length;
    const nextLength = value.length;

    if (originLength > nextLength) {
      const [removeCc] = props.cc.filter((cc) => !value.includes(cc.huserId));
      removeCrmHistoryCc.mutate({ id: [removeCc.id] });
    } else {
      const [ccHuserId] = value.filter((cc) => !props.cc.find((v) => v.huserId === cc));
      addCrmHistoryCc.mutate({ crmContentId: props.id, ccHuserId });
    }

    setCcList(value);
  };

  const hUser = getUserById(props.authorId);

  return (
    <div className="rounded-lg shadow-lg bg-white p-5 mb-3 relative">
      {contextHolder}
      {isEdit ? (
        <>
          {예약금상태 && (
            <Controller
              name="deposit"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  value={value}
                  placeholder="숫자만 입력"
                  step={100}
                  addonBefore="예약금"
                  addonAfter="원"
                  onChange={(digit) => onChange(digit || 0)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  status={errors.deposit ? 'error' : ''}
                  className="w-full mb-1"
                />
              )}
            />
          )}
          <Controller
            name="memo"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={6}
                placeholder="상담 내용을 입력하세요"
                className="mb-3"
                status={errors.memo ? 'error' : ''}
              />
            )}
          />
          <div className="flex items-center">
            <Tag color="processing">{statusMap[props.statusId]}</Tag>
            <Controller
              name="statusId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={statusList.map((status) => ({ value: status.id, label: status.status }))}
                  className="flex-1 text-center"
                  placeholder="상태를 선택해주세요"
                  status={errors.statusId ? 'error' : ''}
                />
              )}
            />
          </div>
          <div className="mt-3 flex justify-between">
            <div className="flex flex-1">
              <Select
                mode="multiple"
                className="text-center w-full"
                options={[...hUserList.map(({ id, name }) => ({ value: id, label: name }))]}
                placeholder="cc추가"
                onChange={(value) => onChangeCcList(value)}
                value={ccList}
              />
            </div>
            <div>
              <Popconfirm
                title="상담 이력 삭제"
                description={`정말 삭제하시겠습니까?`}
                okText="예"
                cancelText="아니오"
                onConfirm={(e) => onClickDelete(props.id)}
              >
                <Button type="text" danger>
                  삭제
                </Button>
              </Popconfirm>
              <Button className="mr-2" onClick={onClickCancelEdit}>
                취소
              </Button>
              <Button
                className="bg-[#3965FF]"
                onClick={handleSubmit(onSubmit)}
                loading={updateCrmItemHistory.isLoading}
              >
                <div className="text-white">확인</div>
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <DisplayTagNrc data={props} />
          <div className="flex items-center justify-between">
            <div className="text-[#2B3674] text-sm">{props.memo}</div>
            <Button className="ml-3" type="text" icon={<EditOutlined />} onClick={onClickEdit} />
          </div>
          <div className="flex items-center mt-2 justify-between">
            <div>{dayjs(props.createdAt).format('YYYY-MM-DD')}</div>
            <div className="flex items-center">
              {statusMap[props.statusId] && (
                <div
                  style={{ backgroundColor: findBgColor(props.statusId) }}
                  className={`mr-3 bg-[#2B3674] text-white text-[10px] py-1 px-3 rounded`}
                >
                  {statusMap[props.statusId]}
                </div>
              )}
              <Avatar src={getAvatarPath(hUser.avatar)} size="small" />
              <div className="text-[#2B3674] font-bold text-xs ml-1">{hUser?.name}</div>
            </div>
          </div>
          {props.cc.length > 0 && (
            <div className="mt-2">
              <Avatar.Group
                maxCount={3}
                maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                size="small"
              >
                {props.cc.map((v) => (
                  <Tooltip
                    placement="top"
                    title={getUserById(v.huserId) ? getUserById(v.huserId).name : ''}
                    key={v.id}
                  >
                    <Avatar src={getAvatarPath(getUserById(v.huserId).avatar)} size="small" />
                  </Tooltip>
                ))}
              </Avatar.Group>
            </div>
          )}
        </>
      )}
    </div>
  );
}
