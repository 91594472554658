import dayjs, { Dayjs } from 'dayjs';
import { find, get } from 'lodash';

import { NRC_LIST, CRM_LIST_S_TAG_LIST } from './constants';
import { CRM_IMAGE_BUCKET } from './constants';
import TimeTableDrawer from './TimeTableDrawer';
import IconPersonTest from 'src/assets/images/icon-person-test.png';
import { CrmPaid } from 'src/types/crm.types';

export function numberFormat(amount: number) {
  return Intl.NumberFormat().format(amount);
}

export const rangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  { label: '7일', value: [dayjs().add(-7, 'd'), dayjs()] },
  { label: '14일', value: [dayjs().add(-14, 'd'), dayjs()] },
  { label: '30일', value: [dayjs().add(-30, 'd'), dayjs()] },
  { label: '90일', value: [dayjs().add(-90, 'd'), dayjs()] },
];

export function getDisplayTime(time: number) {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  const displayTime = minutes === 0 ? `${hours}시간` : `${hours}시간 ${minutes}분`;

  if (time === 30) {
    return '30분';
  }

  return displayTime;
}

export function getGender(gender: string, type?: number) {
  if (!gender) return '';

  const txt = gender.toUpperCase() === 'F' ? '여자' : '남자';

  return type === 1 ? txt.charAt(0) : txt;
}

export function sortDate(firstDate: string, secondDate: string) {
  const dateA = dayjs(firstDate);
  const dateB = dayjs(secondDate);

  if (dateA.isBefore(dateB)) {
    return -1;
  } else if (dateA.isAfter(dateB)) {
    return 1;
  } else {
    return 0;
  }
}

export function displayDateTime() {
  return {
    disabledHours: () => [
      ...Array.from({ length: TimeTableDrawer.getWorkingTimeStart() / 60 }, (_, idx) => idx),
      23,
    ],
    disabledMinutes: () =>
      Array.from({ length: 59 }, (_, index) => index + 1).filter((value) => value !== 30),
  };
}

export function getNrc(value: string) {
  return find(NRC_LIST, { value });
}

export function getTagsEmoji(value: string) {
  return CRM_LIST_S_TAG_LIST.reduce(
    (a, c) => a + (c.value & parseInt(value, 2) ? c.emoji : ''),

    ''
  );
}

export function columnSorter(after: any, before: any, key: string) {
  if (!key) return 0;
  const a = get(after, key),
    b = get(before, key);

  if (Number.isNaN(a) || Number.isNaN(b)) return 1;

  if (Number.isInteger(a)) return a - b;

  // return a.localeCompare(b);
  return a < b ? -1 : a > b ? 1 : 0;
}

export function getAvatarPath(rPath: string | undefined) {
  if (!rPath) return IconPersonTest;
  return CRM_IMAGE_BUCKET + rPath;
}

export function modPosition(position: null | string) {
  if (position) return `[${position}]`;
  return '';
}
