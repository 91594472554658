import Http from 'src/@http';
import { queryCrmStatusList } from 'src/graphql/status.query';
import { errorResponse, successResponse } from 'src/libs/response';
import { ApiResponse } from 'src/types/response';
import { GetCrmStatusListResponse } from 'src/types/status.types';

export async function getCrmStatusListAPI() {
  return Http.instance
    .post<ApiResponse<GetCrmStatusListResponse>>('', {
      query: queryCrmStatusList,
    })
    .then(successResponse)
    .catch(errorResponse('getCrmStatusListAPI'));
}
