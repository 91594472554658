import { QuestionCircleOutlined } from '@ant-design/icons';
import { Select, Button, Divider, Popconfirm } from 'antd';
import React from 'react';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import { useForm, Controller, SubmitHandler, FormProvider, useFormContext } from 'react-hook-form';
import type { UseFormReturn, FieldValues } from 'react-hook-form';

import useHuserList from 'src/hooks/useHuserList';
import { NRC_LIST } from 'src/libs/constants';

interface ConnectFormProps<TFieldValues extends FieldValues> {
  children(children: UseFormReturn<TFieldValues>): ReactElement;
}

const ConnectForm = <TFieldValues extends FieldValues>({
  children,
}: ConnectFormProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  console.log('form props', methods);

  return children({ ...methods });
};

type Props = {
  bg: string;
};

// 21:상담예약, 28:진료예약
const NRCTypeFilter = [
  {
    key: 'cs',
    validStatusId: '21',
  },
  {
    key: 'dr',
    validStatusId: '28',
  },
];

const NrcDescription = () => (
  <div>
    <em>N</em>: 신규상담 (새로운 치료 시작,또는 기존환자가 새로운 치료시작)
    <br />
    <em>R</em>: 재상담 (기존 상담을 이어서 진행 시)
    <br />
    <em>C</em>: 체크 , 컴플레인,기타
  </div>
);

const NrcSelector = ({ methods, props: { defaultValue } }: any) => {
  const { watch, setValue, control, getValues, formState } = methods;
  console.log('methods', methods);
  console.log('methods', methods);

  const { statusId } = getValues();

  const type =
    NRCTypeFilter.find((f) => {
      const reg = new RegExp(statusId, 'gi');
      return f.validStatusId.match(reg);
    }) || NRCTypeFilter[0];

  return (
    <Controller
      name="nrc"
      control={control}
      defaultValue={`${type?.key}01`}
      // rules={{ required: true }}
      render={({ field: { onChange, value } }) => {
        return (
          <div>
            <Divider plain orientation="left">
              <Popconfirm
                title="통계오류 방지를 위해 정확히 지정해주세요"
                description={NrcDescription}
                okText="닫기"
                showCancel={false}
              >
                N.R.C <QuestionCircleOutlined style={{ color: 'orange', fontWeight: 'bold' }} />
              </Popconfirm>
            </Divider>

            <div className="flex flex-wrap gap-1">
              {NRC_LIST.filter((tag) => tag.type === type?.key).map((l) => {
                // const bg = `bg-[#${l.color.split(',')[1]}]`; //'bg-[#11b374]'; //'#' + l.color.split(',')[1];
                const bg = `#${l.color.split(',')[1]}`; //'bg-[#11b374]'; //'#' + l.color.split(',')[1];
                const fontColor = `#${l.color.split(',')[0]}`;

                return (
                  <Button
                    key={l.value}
                    type={'default'}
                    onClick={() => onChange(l.value)}
                    className={`${value === l.value && bg} `}
                    style={{
                      ...(value === l.value && { backgroundColor: bg, color: fontColor }),
                    }}
                  >
                    {l.label} {l.text}
                  </Button>
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};

export default function NrcTypeSelector({ defaultValue }: any) {
  return (
    <ConnectForm>
      {/* {({ register, control, formState, getValues, ...others }) => ( */}
      {(methods) => <NrcSelector methods={methods} props={{ defaultValue }} />}
    </ConnectForm>
  );
}
