import { Avatar } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { DisplayTagNrc } from './CallHistoryWidget';
import useHuserList from 'src/hooks/useHuserList';
import { getAvatarPath } from 'src/libs/common';
import { getStatusNameMapState } from 'src/recoil/status.recoil';
import { CrmContent } from 'src/types/crm.types';

type Props = CrmContent;

export default function PatientHistory(props: Props) {
  const { getUserById } = useHuserList();
  const statusMap = useRecoilValue(getStatusNameMapState);

  const hUser = getUserById(props.authorId);

  return (
    <div className="rounded-lg shadow-lg bg-white p-5 mb-3 relative">
      <DisplayTagNrc data={props} />
      <div className="flex items-center justify-between">
        <div className="text-[#2B3674] font-bold text-base">{props.title}</div>
      </div>
      <div className="text-[#2B3674] text-sm whitespace-pre-line">hi{props.memo}</div>
      <div className="flex items-center mt-2 justify-between">
        <div>{dayjs(props.createdAt).format('YYYY-MM-DD')}</div>
        <div className="flex items-center">
          {statusMap[props.statusId] && (
            <div className="mr-3 bg-[#2B3674] text-white text-[10px] py-1 px-3 rounded">
              {statusMap[props.statusId]}
            </div>
          )}
          <Avatar src={getAvatarPath(hUser.avatar)} size="small" />
          <div className="text-[#2B3674] font-bold text-xs ml-1">hihi {hUser?.name}</div>
        </div>
      </div>
    </div>
  );
}

// {cell?.nrc && (
//   <div
//     className={`absolute inline-flex items-center justify-center w-6 h-6 text-s font-bold text-white border-2 border-white rounded-full -top-2 -left-2 dark:border-gray-900 z-[101]`}
//     style={{
//       backgroundColor: `#${getNrc(cell?.nrc)?.color.split(',')[1]}`,
//       color: `#${getNrc(cell?.nrc)?.color.split(',')[0]}`,
//     }}
//   >
//     {getNrc(cell?.nrc)?.label}
//   </div>
// )}
