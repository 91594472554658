import { Button, Checkbox, Input, message } from 'antd';
import { isEmpty } from 'lodash';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import Community from 'src/components/ContentManage/Community';
import ContentList from 'src/components/ContentManage/ContentList';
import { Item, contentManageState, getCurrentItemState } from 'src/recoil/contentManage.recoil';

const { TextArea } = Input;

type Form = Item;

export default function ContentManage() {
  const [{ itemList }, setContentManage] = useRecoilState(contentManageState);
  const [item] = useRecoilValue(getCurrentItemState);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<Form>({ values: item });
  const { isBenefit } = watch();

  const onSubmit: SubmitHandler<Form> = (data) => {
    const { id } = data;
    setContentManage((prev) => ({
      itemList: prev.itemList.map((item) => {
        if (item.id === id) {
          return data;
        }
        return item;
      }),
    }));
    message.success('수정 적용이 완료되었습니다.');
  };

  return (
    <div>
      <div className="mb-5">
        <ContentList itemList={itemList} />
      </div>
      <div className="flex">
        <div className="flex-1">
          <div className="bg-white p-5 rounded-lg mb-3">
            <Controller
              name="title"
              rules={{ required: true, minLength: 1 }}
              control={control}
              render={({ field }) => (
                <div>
                  <span className="font-bold text-base">제목</span>
                  <Input
                    {...field}
                    placeholder="제목을 입력하세요"
                    status={errors.title ? 'error' : ''}
                    className="mt-1"
                  />
                </div>
              )}
            />
          </div>
          <div className="bg-white p-5 rounded-lg mb-3">
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <div>
                  <span className="font-bold text-base">영상 설명</span>
                  <TextArea {...field} rows={5} placeholder="설명을 입력하세요" className="mt-1" />
                </div>
              )}
            />
          </div>
          <div className="bg-white p-5 rounded-lg mb-3">
            <Controller
              name="isBenefit"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value}>
                  <span className="font-bold text-base">혜택 설정</span>
                </Checkbox>
              )}
            />
            <Controller
              name="benefitDescription"
              control={control}
              render={({ field }) => (
                <div className="flex mt-3">
                  <span className="w-24 text-sm">혜택 설명</span>
                  <TextArea
                    {...field}
                    rows={5}
                    placeholder="혜택 설명"
                    className="flex-1"
                    disabled={!isBenefit}
                  />
                </div>
              )}
            />
            <div className="flex mt-5">
              <span className="w-24 text-sm pt-1">혜택 조건</span>
              <Controller
                name="priceBefore"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="items-center">
                    <span className="text-sm mr-3">할인전</span>
                    <Input
                      value={value}
                      onChange={(digit) => onChange(digit || 0)}
                      className="w-20"
                      disabled={!isBenefit}
                    />
                  </div>
                )}
              />
              <Controller
                name="priceAfter"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="items-center ml-5">
                    <span className="text-sm mr-3">할인후</span>
                    <Input
                      value={value}
                      onChange={(digit) => onChange(digit || 0)}
                      className="w-20"
                      disabled={!isBenefit}
                    />
                  </div>
                )}
              />
              <Controller
                name="discountRate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="items-center ml-5">
                    <span className="text-sm mr-3">할인율</span>
                    <Input
                      value={value}
                      onChange={(digit) => onChange(digit || 0)}
                      className="w-20"
                      disabled={!isBenefit}
                    />
                  </div>
                )}
              />
            </div>
            <div className="pl-24 mt-3 flex items-center">
              <Controller
                name="withVat"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    disabled={!isBenefit}
                  >
                    부가세 포함
                  </Checkbox>
                )}
              />
              <div className="ml-3">
                <Controller
                  name="onlyDiscountRate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                      disabled={!isBenefit}
                    >
                      할인율만 표시
                    </Checkbox>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="bg-white p-5 rounded-lg mb-3 flex justify-between items-center font-bold text-base">
            <div>혜택 문의당 단가</div>
            <div>예상 15,000</div>
          </div>
        </div>

        <div className="flex-1 ml-5">
          <div className="flex justify-between items-center bg-white p-5 rounded-lg mb-3 font-bold text-base">
            <div>커뮤니티 랜딩 링크</div>
            <Button type="link" onClick={() => window.open(item.link, '_blank')}>
              이동하기
            </Button>
          </div>
          <div className="bg-white p-5 rounded-lg mb-3">
            <Community />
          </div>
          <div>
            <Button
              className="w-full"
              size="large"
              type="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isEmpty(errors)}
            >
              수정 적용하기
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
