import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Upload,
  UploadFile,
  notification,
  Select,
  Avatar,
  List,
  Skeleton,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import AddComment from './AddComment';
import ManageComment from './ManageComment';
import { signS3API } from 'src/api/common.api';
import {
  getCrmIssueCommentListAPI,
  updateCrmIssueAPI,
  addCrmIssueCcAPI,
  removeCrmIssueCcAPI,
  UpdateCrmIssueHideAPI,
} from 'src/api/issue.api';
import useHuserList from 'src/hooks/useHuserList';
import useIssueHideList from 'src/hooks/useIssueHideList';
import { QUERY_KEYS } from 'src/libs/constants';
import { csState } from 'src/recoil/cs.recoil';

const { Dragger } = Upload;
const { TextArea } = Input;

type Form = {
  title: string;
  content: string;
};

type TableData = {
  id: string;
  huserId: string;
  createdAt: string;
  comment: string;
};

interface DataType {
  id: string;
  author: string;
  title: string;
  content: string;
  createdAt?: string;
  loading?: boolean;
}

export default function ItemModal() {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [currentPage, setCurrentPage] = useState(1);
  const { getUserById, hUserList } = useHuserList();
  const [images, setImages] = useState<any[]>([]);
  const [ccList, setCcList] = useState<any[]>([]);
  const [cs, setCs] = useRecoilState(csState);

  const { id = '3', visible } = cs.backlogModal;

  const { issueHideList, isFetching } = useIssueHideList();

  const [data, setData] = useState<DataType[]>([]);
  const [list, setList] = useState<DataType[]>([]);

  useEffect(() => {
    // setList(
    //   data.concat(
    //     [...new Array(3)].map(() => ({
    //       loading: true,
    //       id: '',
    //       author: '',
    //       title: '',
    //       content: '',
    //     }))
    //   )
    // );{{
    if (!isFetching) {
      const l = issueHideList.map(({ id, authorId, title, content, createdAt }) => {
        return {
          id,
          author: authorId + '1',
          title,
          content,
          createdAt,
        };
      });
      setList(l);
    }
  }, [issueHideList, isFetching]);

  // const updateCrmIssue = useMutation(updateCrmIssueAPI, {
  //   onSuccess: (res) => {
  //     api.success({ message: '이슈 수정 성공' });
  //     return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_ISSUE_LIST]);
  //   },
  //   onError: () => {
  //     api.error({ message: '이슈 수정 실패' });
  //   },
  //   onSettled: () => {
  //     onClose();
  //   },
  // });

  const onClose = () => {
    setCs((prev) => ({
      ...prev,
      backlogModal: {
        id: '',
        visible: false,
      },
    }));
  };

  const updateCrmIssueHideAPI = useMutation(UpdateCrmIssueHideAPI, {
    onSuccess: (res) => {
      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_PATIENT_HISTORY, { id: 1 }]);
    },
  });

  return (
    <Modal
      open={visible}
      footer={null}
      closable={false}
      width={600}
      onCancel={onClose}
      destroyOnClose
    >
      <div className="mt-2">{contextHolder}</div>
      <div>
        <List
          className="demo-loadmore-list"
          // loading={initLoading}
          itemLayout="horizontal"
          // loadMore={loadMore}
          dataSource={list}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a
                  key="list-loadmore-edit"
                  onClick={() => {
                    updateCrmIssueHideAPI.mutate({ id: item.id, isHide: false });
                  }}
                >
                  숨김 해제
                </a>,
              ]}
            >
              <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                  // avatar={<Avatar src={item.picture.large} />}
                  title={item.title}
                  description={item.content}
                />
                <div>{item.author}</div>
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
      <div className="mt-5 flex justify-end">
        <Button onClick={onClose}>닫기</Button>
      </div>
    </Modal>
  );
}
