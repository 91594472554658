import { useQuery } from '@tanstack/react-query';
import { Table, Space, Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState, FC } from 'react';

import { getCommunityByTagAPI } from 'src/api/common.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { CommunityData } from 'src/types/common.types';

type TableData = CommunityData;

const columns: ColumnsType<TableData> = [
  {
    title: '아이디',
    dataIndex: 'id',
    key: 'id',
    width: 60,
  },
  {
    title: '닉네임',
    dataIndex: 'nickname',
    key: 'nickname',
    width: 150,
    ellipsis: true,
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
  {
    title: '내용',
    dataIndex: 'content',
    key: 'content',
    ellipsis: true,
  },
];

export default function Community() {
  const [page, setPage] = useState(1);

  const { data, isFetching, isLoading } = useQuery([QUERY_KEYS.GET_COMMUNITY_LIST, { page }], () =>
    getCommunityByTagAPI({ currentPage: page, perPage: 5 })
  );

  return (
    <>
      <div className="mb-3 font-bold text-base">커뮤니티 관리</div>
      <Table
        columns={columns}
        dataSource={data?.communitByTag.rows}
        rowKey={(record) => record.id}
        size="small"
        pagination={{ pageSize: 5 }}
        rowClassName="cursor-pointer"
        loading={isFetching || isLoading}
        onRow={(record, rowIndex) => ({
          onClick: () =>
            window.open(`https://v2-dev.odoctor.co.kr/community/post/${record.id}`, '_blank'),
        })}
      />
    </>
  );
}
