import { faker } from '@faker-js/faker';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import dayjs from 'dayjs';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  barPercentage: 0.3,
  borderSkipped: false,
  borderRadius: { topLeft: 5, topRight: 5 },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
  scales: {
    y: {
      ticks: {
        display: false,
        beginAtZero: true,
      },
      // to remove the y-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
      border: {
        display: false,
      },
    },
    x: {
      // to remove the y-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
};

type Props = {
  data?: any[];
};

export default function DataGroupChart({ data }: Props) {
  const cutter = data?.slice(0, 7).reverse();
  const labels = cutter?.map(({ date }) => dayjs(date).format('DD')) || [];

  const dataSet = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        data: cutter?.map(({ allDBCount }) => allDBCount) || [],
        backgroundColor: 'rgba(67, 24, 255, 0.9)',
        // backgroundColor: ['rgba(0,10,220,0.5)', 'rgba(220,0,10,0.5)'],
        fillColor: [
          'rgba(0,10,220,0.5)',
          'rgba(220,0,10,0.5)',
          'rgba(220,0,0,0.5)',
          'rgba(120,250,120,0.5)',
        ],
        strokeColor: 'rgba(220,220,220,0.8)',
        highlightFill: 'rgba(220,220,220,0.75)',
        highlightStroke: 'rgba(220,220,220,1)',
      },
      // {
      //   label: 'Dataset 2',
      //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };

  return <Bar options={options} data={dataSet} />;
}
