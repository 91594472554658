import { Button, Drawer, Tabs, TabsProps } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import EditablePatientInfoWidget from './EditablePatientInfoWidget';
import InputForm from './InputForm';
import CallHistoryWidget from '../Call/Container/CallHistoryWidget';

type Props = { patientId: string };

export default function FormDrawer({ patientId }: Props) {
  const navigate = useNavigate();

  const items: TabsProps['items'] = [
    {
      key: '내원db관리',
      label: `내원db관리`,
      children: (
        <>
          <EditablePatientInfoWidget />
          <InputForm />
          <CallHistoryWidget patientId={patientId} />
        </>
      ),
    },
  ];

  const onClose = () => {
    navigate(-1);
  };

  const onClickNavigate = () => {
    navigate(`/patient/${patientId}`);
  };

  const isOpen = !!patientId;

  return (
    <Drawer closable={false} open={isOpen} onClose={onClose} width={600}>
      <div className="bg-white rounded-lg h-full">
        <Tabs
          defaultActiveKey="내원db관리"
          items={items}
          tabBarExtraContent={{
            right: (
              <Button onClick={onClickNavigate}>
                <div className="flex justify-center items-center">
                  <div className="text-[#768396] font-bold">펼쳐보기</div>
                </div>
              </Button>
            ),
          }}
        />
      </div>
    </Drawer>
  );
}
