import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { getCrmPaidListAPI } from 'src/api/crm.api';
import { QUERY_KEYS } from 'src/libs/constants';

export default function usePaidListById(patientId: string) {
  const { data, isLoading, isFetching } = useQuery(
    [QUERY_KEYS.GET_CRM_PAID_LIST, { patientId }],
    () => getCrmPaidListAPI({ patientId }),
    {
      enabled: !!patientId,
    }
  );

  const crmPaidList = data?.crmPaidList || [];

  return {
    crmPaidList,
    isFetching: isLoading || isFetching,
  };
}
