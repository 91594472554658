import { Empty, Spin } from 'antd';
import React from 'react';

import EditableConsultHistoryCard from './EditableConsultHistoryCard';
import usePatientHistory from 'src/hooks/usePatientHistory';

type Props = { patientId: string };

export default function ConsultHistory({ patientId }: Props) {
  const { getStatusItemList, isFetching } = usePatientHistory(patientId);
  const consultHistoryList = getStatusItemList('7');

  return (
    <Spin spinning={isFetching}>
      <div className="min-h-[320px]">
        <div className="text-[#768396] font-bold text-base mb-1">상담 내역</div>
        {consultHistoryList.length === 0 && (
          <Empty description="상담 내역이 없습니다." className="mt-16" />
        )}
        {consultHistoryList.map((consultHistory) =>
          consultHistory.contents.map((content) => (
            <EditableConsultHistoryCard key={content.id} {...content} />
          ))
        )}
      </div>
    </Spin>
  );
}
