import { Button, Drawer, Tabs, TabsProps } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import CreateGrossForm from './CreateForm';
import { grossState } from 'src/recoil/manageGross.recoil';

type Props = { patientId: string };

export default function FormDrawer({ patientId }: Props) {
  const navigate = useNavigate();

  const [state, setState] = useRecoilState(grossState);

  const onClose = () => {
    setState((prev: any) => ({ ...prev, showAddGrossDrawer: false }));
  };

  return (
    <Drawer closable={false} open={state.showAddGrossDrawer} onClose={onClose} width={600}>
      <div className="bg-white rounded-lg h-full">
        <CreateGrossForm />
      </div>
    </Drawer>
  );
}
