import React from 'react';
import { useRecoilValue } from 'recoil';

import DashboardTable from 'src/components/Dashboard/DashboardTable';
import StatisticsWrapper from 'src/components/Dashboard/StatisticsWrapper';
import DashboardFilter from 'src/components/Filter/DashboardFilter';
import PermissionWrapper from 'src/components/PermissionWrapper';
import { dashboardFilterState } from 'src/recoil/dashboardFilter.recoil';

export default function Dashboard() {
  const filter = useRecoilValue(dashboardFilterState);

  return (
    <PermissionWrapper requiredPermission="대시보드 보기">
      <div>
        <DashboardFilter />
      </div>
      <div className="mt-5">
        <StatisticsWrapper />
      </div>
      <div className="mt-5 pb-20">
        <DashboardTable />
      </div>
    </PermissionWrapper>
  );
}
