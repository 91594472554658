import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { getCrmPatientAPI } from 'src/api/patient.api';
import { QUERY_KEYS } from 'src/libs/constants';

const initialState = {
  id: '',
  name: '',
  phone: '',
  address: '',
  birth: 0,
  gender: '',
  registerNumber: '',
  latestStatusId: '',
  latestList: {
    id: '',
  },
};

export default function usePatient(patientId: string) {
  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CRM_PATIENT, { patientId }],
    () => getCrmPatientAPI({ id: patientId }),
    {
      enabled: !!patientId,
    }
  );

  const patient = data?.crmPatient ?? initialState;

  return {
    patient,
    isFetching: isFetching || isLoading,
  };
}
