import { atom } from 'recoil';

type State = {
  currentLabel: string;
};

const initialState = {
  currentLabel: '',
};

export const commonState = atom<State>({
  key: 'commonState',
  default: initialState,
});
