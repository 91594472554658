import { CrmPaid } from 'src/types/crm.types';

type PayValidate = {
  isValid: (paid: CrmPaid) => boolean;
  message: string;
};

const VALIDATION_PAY = [
  {
    isValid: ({ paySum }) => paySum === 0,
    message: '미결제',
  },
  {
    isValid: ({ pay, paySum }) => pay < paySum && paySum > 0,
    message: '부분결제',
  },
  {
    isValid: ({ pay, paySum }) => pay === paySum,
    message: '결제완료',
  },
] as PayValidate[];

export function getPayStatus(paid: CrmPaid) {
  if (!paid) return '-';

  return VALIDATION_PAY.map(({ isValid, message }) => (isValid(paid) ? message : null))
    .filter(Boolean)
    .join('');
}
