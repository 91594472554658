import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  children?: React.ReactNode;
}

export default function InfoBox({ title, children, className }: Props) {
  const cx = ['flex-1 rounded-lg shadow-lg p-5', className].join(' ');

  return (
    <div className={cx}>
      <div className="text-[#A3AED0] text-sm mb-3">{title}</div>
      {children}
    </div>
  );
}
