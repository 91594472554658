import Http from 'src/@http';
import {
  queryAddCrmIssue,
  queryAddCrmIssueComment,
  queryAddCrmRefToIssue,
  queryCrmIssueList,
  queryCrmIssueHideList,
  queryGetCrmIssueCommentList,
  queryRemoveCrmIssue,
  queryRemoveCrmIssueComment,
  queryRemoveCrmRefFromIssue,
  queryUpdateCrmIssue,
  queryUpdateCrmIssueComment,
  queryUpdateCrmIssueStatus,
  queryAddCrmIssueCc,
  queryRemoveCrmIssueCc,
  queryUpdateCrmIssueHide,
  queryCrmIssueListByCcHuserId,
} from 'src/graphql/issue.query';
import { errorResponse, successResponse } from 'src/libs/response';
import {
  AddCrmIssueCommentRequest,
  AddCrmIssueCommentResponse,
  AddCrmIssueRequest,
  AddCrmIssueResponse,
  AddCrmRefToIssueRequest,
  AddCrmRefToIssueResponse,
  GetCrmIssueCommentListRequest,
  GetCrmIssueCommentListResponse,
  GetCrmIssueListRequest,
  GetCrmIssueListResponse,
  GetCrmIssueHideListRequest,
  GetCrmIssueHideListResponse,
  RemoveCrmIssueCommentRequest,
  RemoveCrmIssueCommentResponse,
  RemoveCrmIssueRequest,
  RemoveCrmIssueResponse,
  RemoveCrmRefFromIssueRequest,
  RemoveCrmRefFromIssueResponse,
  UpdateCrmIssueCommentRequest,
  UpdateCrmIssueCommentResponse,
  UpdateCrmIssueRequest,
  UpdateCrmIssueResponse,
  UpdateCrmIssueStatusRequest,
  UpdateCrmIssueStatusResponse,
  AddCrmIssueCcRequest,
  AddCrmIssueCcResponse,
  RemoveCrmIssueCcRequest,
  RemoveCrmIssueCcResoponse,
  UpdateCrmissueHideRequest,
  UpdateCrmissueHideResoponse,
  GetCrmIssueListByCcHuserIdRequest,
  GetCrmIssueListByCcHuserIdResponse,
} from 'src/types/issue.types';
import { ApiResponse } from 'src/types/response';

export async function getCrmIssueListAPI({ taggedId }: GetCrmIssueListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmIssueListResponse>>('', {
      query: queryCrmIssueList,
      variables: {
        filter: { taggedId },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmIssueListAPI'));
}

export async function getCrmIssueHideListAPI({ isHide }: GetCrmIssueHideListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmIssueHideListResponse>>('', {
      query: queryCrmIssueHideList,
      variables: {
        filter: { isHide },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmIssueHideListAPI'));
}

export async function addCrmIssueAPI(payload: AddCrmIssueRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmIssueResponse>>('', {
      query: queryAddCrmIssue,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmIssueAPI'));
}

export async function updateCrmIssueAPI(payload: UpdateCrmIssueRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmIssueResponse>>('', {
      query: queryUpdateCrmIssue,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmIssueAPI'));
}

export async function removeCrmIssueAPI({ id }: RemoveCrmIssueRequest) {
  return Http.instance
    .post<ApiResponse<RemoveCrmIssueResponse>>('', {
      query: queryRemoveCrmIssue,
      variables: {
        id,
      },
    })
    .then(successResponse)
    .catch(errorResponse('removeCrmIssueAPI'));
}

export async function addCrmRefToIssueAPI({ id, huser }: AddCrmRefToIssueRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmRefToIssueResponse>>('', {
      query: queryAddCrmRefToIssue,
      variables: {
        id,
        huser,
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmRefToIssueAPI'));
}

export async function removeCrmRefFromIssueAPI({ id, huserId }: RemoveCrmRefFromIssueRequest) {
  return Http.instance
    .post<ApiResponse<RemoveCrmRefFromIssueResponse>>('', {
      query: queryRemoveCrmRefFromIssue,
      variables: {
        id,
        huserId,
      },
    })
    .then(successResponse)
    .catch(errorResponse('removeCrmRefFromIssueAPI'));
}

export async function getCrmIssueCommentListAPI({
  issueId,
  currentPage,
}: GetCrmIssueCommentListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmIssueCommentListResponse>>('', {
      query: queryGetCrmIssueCommentList,
      variables: {
        filter: {
          issueId,
        },
        page: {
          currentPage,
        },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmIssueCommentListAPI'));
}

export async function addCrmIssueCommentAPI(payload: AddCrmIssueCommentRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmIssueCommentResponse>>('', {
      query: queryAddCrmIssueComment,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmIssueCommentAPI'));
}

export async function removeCrmIssueCommentAPI({ id }: RemoveCrmIssueCommentRequest) {
  return Http.instance
    .post<ApiResponse<RemoveCrmIssueCommentResponse>>('', {
      query: queryRemoveCrmIssueComment,
      variables: {
        input: { id },
      },
    })
    .then(successResponse)
    .catch(errorResponse('removeCrmIssueCommentAPI'));
}

export async function updateCrmIssueCommentAPI({ id, comment }: UpdateCrmIssueCommentRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmIssueCommentResponse>>('', {
      query: queryUpdateCrmIssueComment,
      variables: {
        input: { id, comment },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmIssueCommentAPI'));
}

export async function updateCrmIssueStatusAPI(payload: UpdateCrmIssueStatusRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmIssueStatusResponse>>('', {
      query: queryUpdateCrmIssueStatus,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmIssueStatusAPI'));
}

export async function addCrmIssueCcAPI(payload: AddCrmIssueCcRequest) {
  return Http.instance
    .post<ApiResponse<AddCrmIssueCcResponse>>('', {
      query: queryAddCrmIssueCc,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmIssueCc'));
}

export async function removeCrmIssueCcAPI(payload: RemoveCrmIssueCcRequest) {
  return Http.instance
    .post<ApiResponse<RemoveCrmIssueCcResoponse>>('', {
      query: queryRemoveCrmIssueCc,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('removeCrmIssueCc'));
}

export async function UpdateCrmIssueHideAPI(payload: UpdateCrmissueHideRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmissueHideResoponse>>('', {
      query: queryUpdateCrmIssueHide,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmIssueHide'));
}

export async function getCrmIssueListByCcHuserIdAPI({
  huserId,
}: GetCrmIssueListByCcHuserIdRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmIssueListByCcHuserIdResponse>>('', {
      query: queryCrmIssueListByCcHuserId,
      variables: {
        filter: { huserId },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmIssueListByCcHuserIdAPI'));
}
