import Http from 'src/@http';
import {
  queryCrmPatient,
  queryCrmPatientHistory,
  queryCrmPatientList,
  queryUpdateCrmPatient,
} from 'src/graphql/patient.query';
import { errorResponse, successResponse } from 'src/libs/response';
import {
  GetCrmPatientHistoryRequest,
  GetCrmPatientHistoryResponse,
  GetCrmPatientListRequest,
  GetCrmPatientListResponse,
  GetCrmPatientRequest,
  GetCrmPatientResponse,
  UpdateCrmPatientRequest,
  UpdateCrmPatientResponse,
} from 'src/types/patient.types';
import { ApiResponse } from 'src/types/response';

export async function getCrmPatientAPI({ id }: GetCrmPatientRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmPatientResponse>>('', {
      query: queryCrmPatient,
      variables: {
        id,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getPatientAPI'));
}

export async function updateCrmPatientAPI(payload: UpdateCrmPatientRequest) {
  return Http.instance
    .post<ApiResponse<UpdateCrmPatientResponse>>('', {
      query: queryUpdateCrmPatient,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateCrmPatientAPI'));
}

export async function getCrmPatientHistoryAPI({ id }: GetCrmPatientHistoryRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmPatientHistoryResponse>>('', {
      query: queryCrmPatientHistory,
      variables: {
        id,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmpatientHistoryAPI'));
}

export async function getCrmPatientListAPI(payload: GetCrmPatientListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmPatientListResponse>>('', {
      query: queryCrmPatientList,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmPatientListAPI'));
}
