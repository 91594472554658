import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Select, DatePicker, Input, notification, ConfigProvider } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { addCrmItemHistoryAPI } from 'src/api/crm.api';
import AssignSelector from 'src/components/Form/Controllers/AssignHuserSelect';
import ListTagsSelector from 'src/components/Form/Controllers/ListTagsSelect';
import NRCTypeSelector from 'src/components/Form/Controllers/NRCTypeSelect';
import useCrmItem from 'src/hooks/useCrmItem';
import useHuserList from 'src/hooks/useHuserList';
import { displayDateTime, getDisplayTime } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { NRC_LIST, CRM_LIST_S_TAG_LIST } from 'src/libs/constants';
import TimeTableDrawer from 'src/libs/TimeTableDrawer';
import { authState } from 'src/recoil/auth.recoil';
import { getStatusListByGroupStatus } from 'src/recoil/status.recoil';

const { TextArea } = Input;

type Form = {
  memo: string;
  statusId: string;
  date: string;
  assignHuserId: string;
  length: number;
  type: string;
  tags?: string;
};

export default function CallBookForm() {
  const auth = useRecoilValue(authState);
  const [prevInfo, setInfo] = useState(''); // 메모 기억
  const { info } = useParams<{ info: string }>();
  const [listId, patientId] = info?.split('_') || [''];
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const statusList = useRecoilValue(getStatusListByGroupStatus('call'));
  const { hUserList } = useHuserList();

  const { meh } = useRecoilValue(authState);

  const methods = useForm<Form>({
    defaultValues: {
      tags: '0',
    },
  });

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const statusId = watch('statusId');
  const 예약상태 = ['21', '28'].includes(statusId);

  useEffect(() => {
    if (info) {
      setInfo(info);
      if (info !== prevInfo) {
        reset();
      }
    }
  }, [info, reset, prevInfo]);

  const { crmItem, isFetching } = useCrmItem(listId);

  const addCrmItemHistory = useMutation(addCrmItemHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '콜/예약 추가 완료.' });
      queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_PATIENT_CALL_HISTORY, { patientId }]);
      reset();
    },
    onError: () => {
      api.error({ message: '콜/예약 추가 실패.' });
    },
  });

  const onSubmit: SubmitHandler<Form> = (data) => {
    const payload = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== undefined && value !== '')
    ) as Form;

    if (예약상태) {
      const { date, length, ...others } = payload;
      const startTime = dayjs(date).format();
      const endTime = dayjs(date).add(length, 'minutes').format();
      addCrmItemHistory.mutate({ listId, startTime, endTime, ...others });
    } else {
      const { statusId, memo, tags } = payload;
      addCrmItemHistory.mutate({ statusId, listId, memo, assignHuserId: meh.id, tags });
    }
  };

  if (isFetching) return null;

  const onInvalid = (errors: any) => console.error(errors);

  return (
    <FormProvider {...methods}>
      <form>
        {contextHolder}
        <Controller
          name="memo"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              rows={6}
              placeholder="메모를 입력하세요"
              className="mb-3"
              defaultValue={crmItem.memo || undefined}
            />
          )}
        />
        <div className="flex gap-x-3">
          <Controller
            name="statusId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={statusList
                  .filter(({ id }) => {
                    if (auth.meh.hospital.id === '73') {
                      // 미내원(27) 진료예약(28) 신생명(73)에서 숨김처리
                      return !['27', '28'].includes(id);
                    }
                    return true;
                  })
                  .map(({ id, status }) => ({ value: id, label: status }))}
                className={`text-center ${/^(21|28)$/gm.test(statusId) ? 'w-[150px]' : 'w-full'}`}
                placeholder="콜/예약 상태를 선택하세요"
                status={errors.statusId ? 'error' : ''}
              />
            )}
          />

          {예약상태 && (
            <>
              <Controller
                name="date"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <DatePicker
                    className="flex-1"
                    locale={locale}
                    placeholder="예약일 지정"
                    disabledDate={(current) => current < dayjs().subtract(1, 'day')}
                    format="YYYY-MM-DD HH:mm"
                    disabledTime={displayDateTime}
                    showTime={{
                      format: 'HH:mm',
                      hourStep: 1,
                      minuteStep: 30,
                      showNow: false,
                      hideDisabledOptions: true,
                      defaultValue: dayjs('09:00', 'HH:mm'),
                    }}
                    showNow={false}
                    onChange={(value, dateString) => {
                      onChange(dateString);
                    }}
                    status={errors.date ? 'error' : ''}
                  />
                )}
              />
              <AssignSelector statusId={statusId} assignHuserId={''} />
              {/* <Controller
              name="assignHuserId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className="text-center ml-3 w-[150px]"
                  options={[
                    { value: null, label: '미지정' },
                    ...hUserList
                      .filter((hUser) => hUser.type === { '21': 2, '28': 4 }[statusId])
                      .map(({ id, name, position }) => ({
                        value: id,
                        label: `[${position}] ${name}`,
                      })),
                  ]}
                  placeholder="담당자 지정"
                  status={errors.assignHuserId ? 'error' : ''}
                />
              )}
            /> */}
            </>
          )}
        </div>

        {예약상태 && (
          <>
            <div className="mt-3">
              <Controller
                name="length"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div className="grid grid-cols-5 flex-1 gap-2">
                    {Array.from({ length: 5 }, (_, idx) => (idx + 2) * 30).map((time) => (
                      <div
                        key={time}
                        className="col-span-1 flex justify-center items-center"
                        onClick={() => onChange(time)}
                      >
                        <ConfigProvider theme={{ token: { colorPrimary: '#00b96b' } }}>
                          <Button
                            danger={Boolean(errors.length)}
                            type={time === value ? 'primary' : 'default'}
                            className="w-full"
                          >
                            <div className="text-xs">{getDisplayTime(time)}</div>
                          </Button>
                        </ConfigProvider>
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>
            <div className="mt-3">
              <NRCTypeSelector />
            </div>
          </>
        )}

        <div className="mt-3">
          <ListTagsSelector defaultValue={crmItem.tags} />
          {/* <Controller
            name="tags"
            control={control}
            rules={{ required: true }}
            defaultValue={crmItem.tags}
            render={({ field: { onChange, value } }) => (
              <div className="flex flex-wrap gap-1">
                {CRM_LIST_S_TAG_LIST.map((l, idx) => {
                  return (
                    <Button
                      key={idx}
                      type={l.value & parseInt(value, 2) ? 'primary' : 'default'}
                      onClick={() => {
                        const v = parseInt(value, 2) ^ l.value;
                        console.log(v, value);

                        onChange(v.toString(2));
                      }}
                    >
                      {l.emoji} {l.label}
                    </Button>
                  );
                })}
              </div>
            )}
          /> */}
        </div>

        <div className="mt-3 flex justify-end">
          <Button type="primary" onClick={handleSubmit(onSubmit, onInvalid)}>
            확인
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
