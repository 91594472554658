import React from 'react';

import Loader from '../Loader';
import usePatient from 'src/hooks/usePatient';
import { getGender } from 'src/libs/common';

type Props = { patientId: string };

export default function PatientInfo({ patientId }: Props) {
  const { patient, isFetching } = usePatient(patientId);

  return (
    <div className="bg-white p-5 rounded-lg shadow-lg flex-1 flex justify-between relative">
      {isFetching && <Loader />}
      <div className="flex-1">
        <div className="text-[#768396] text-center mb-5">등록번호</div>
        <div className="text-center text-[#2B3674]">{patient.registerNumber}</div>
      </div>
      <div className="flex-1">
        <div className="text-[#768396] text-center mb-5">이름/출생년도</div>
        <div className="text-center text-[#2B3674]">
          {patient.name} / {patient.birth} / {getGender(patient.gender)}
        </div>
      </div>
      <div className="flex-1">
        <div className="text-[#768396] text-center mb-5">연락처</div>
        <div className="text-center text-[#2B3674]">{patient.phone}</div>
      </div>
      <div className="flex-1">
        <div className="text-[#768396] text-center mb-5">주소</div>
        <div className="text-center text-[#2B3674]">{patient.address}</div>
      </div>
    </div>
  );
}
