import { Empty, Spin } from 'antd';

import EditablePaymentWaitingForm from './EditablePaymentWaitingForm';
import usePaidListById from 'src/hooks/usePaidListById';

type Props = {
  patientId: string;
};

export default function PaymentWaiting({ patientId }: Props) {
  const { crmPaidList, isFetching } = usePaidListById(patientId);
  const willPaymentList = crmPaidList.filter((paid) => paid.status === 0);

  return (
    <Spin spinning={isFetching}>
      <div className="mt-5">
        <div className="text-lg text-[#768396] font-bold mb-3">결제 대기</div>
        {willPaymentList.length === 0 && <Empty description="내역이 없습니다." />}
        {willPaymentList.map((paid) => (
          <EditablePaymentWaitingForm key={paid.id} {...paid} />
        ))}
      </div>
    </Spin>
  );
}
