import dayjs from 'dayjs';
import { atom } from 'recoil';

type Filter = {
  startDate: string;
  endDate: string;
  openDrawer: boolean; // 신규 환자 추가 drawer
  openPatientDrawer: boolean; // 테이블 클릭했을때 환자 상세 drawer
  selectedList: string[];
  isNewVisit: boolean;
  hasConsultCount: boolean;
  rowId: string;
};

const initialState = {
  startDate: dayjs().subtract(7, 'day').format(),
  endDate: dayjs().format(),
  openDrawer: false,
  openPatientDrawer: false,
  selectedList: [],
  isNewVisit: true,
  hasConsultCount: false,
  rowId: '',
};

export const consultManagementState = atom<Filter>({
  key: 'consultManagementState',
  default: initialState,
});
