import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Upload,
  UploadFile,
  notification,
  Select,
  Space,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import AddComment from './AddComment';
import ManageComment from './ManageComment';
import { signS3API } from 'src/api/common.api';
import {
  getCrmIssueCommentListAPI,
  updateCrmIssueAPI,
  addCrmIssueCcAPI,
  removeCrmIssueCcAPI,
  UpdateCrmIssueHideAPI,
} from 'src/api/issue.api';
import useHuserList from 'src/hooks/useHuserList';
import useIssueList from 'src/hooks/useIssueList';
import { QUERY_KEYS } from 'src/libs/constants';
import { authState } from 'src/recoil/auth.recoil';
import { csState } from 'src/recoil/cs.recoil';

const { Dragger } = Upload;
const { TextArea } = Input;

type Form = {
  title: string;
  content: string;
};

type TableData = {
  id: string;
  huserId: string;
  createdAt: string;
  comment: string;
};

export default function ItemModal() {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [currentPage, setCurrentPage] = useState(1);
  const { getUserById, hUserList } = useHuserList();
  const [images, setImages] = useState<any[]>([]);
  const [ccList, setCcList] = useState<any[]>([]);
  const [cs, setCs] = useRecoilState(csState);
  const { meh } = useRecoilValue(authState);

  const { id, visible, authorId } = cs.itemModal;
  const isEditable = meh.id === authorId;

  const { getIssue } = useIssueList();
  const issue = getIssue(id);

  const columns: ColumnsType<TableData> = [
    {
      title: '아이디',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: '코멘트',
      dataIndex: 'comment',
      key: 'comment',
      ellipsis: true,
    },
    {
      title: '작성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => dayjs(record.createdAt).format('YYYY-MM-DD HH:mm'),
      width: 150,
    },
    {
      title: '관리',
      dataIndex: '관리',
      render: (_, record) => (
        <ManageComment
          oldComment={record.comment}
          id={record.id}
          huserId={record.huserId}
          currentPage={currentPage}
        />
      ),
      width: 100,
    },
  ];

  const { data, isFetching } = useQuery(
    [QUERY_KEYS.GET_CRM_ISSUE_COMMENT_LIST, { id, currentPage }],
    () => getCrmIssueCommentListAPI({ issueId: id, currentPage }),
    {
      enabled: !!id,
    }
  );

  const updateCrmIssue = useMutation(updateCrmIssueAPI, {
    onSuccess: (res) => {
      api.success({ message: '이슈 수정 성공' });
      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_ISSUE_LIST]);
    },
    onError: () => {
      api.error({ message: '이슈 수정 실패' });
    },
    onSettled: () => {
      onClose();
    },
  });

  const uploadImages = useMutation(signS3API);

  const addCrmIssueCc = useMutation(addCrmIssueCcAPI, {
    onSuccess: ({ addCrmIssueCc }) => {
      const { data, ok } = addCrmIssueCc;

      if (ok) {
        setCcList([...ccList, data]);
      }
      queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_ISSUE_LIST]);
      queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_PATIENT_HISTORY, { id: 1 }]);
    },
  });

  const removeCrmIssueCc = useMutation(removeCrmIssueCcAPI, {
    onSuccess: ({ removeCrmIssueCc }) => {
      const { data, ok } = removeCrmIssueCc;

      if (ok) {
        setCcList(ccList.filter(({ id }) => !data.id.includes(id)));
        return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_PATIENT_HISTORY, { id: 1 }]);
      }
    },
  });
  const updateCrmIssueHideAPI = useMutation(UpdateCrmIssueHideAPI, {
    onSuccess: (res) => {
      message.success('완료');
      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_PATIENT_HISTORY, { id: 1 }]);
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Form>();

  useEffect(() => {
    if (issue) {
      setValue('title', issue.title);
      setValue('content', issue.content);
      setImages(issue.image);
      setCcList(issue.cc);
    }
  }, [issue, setValue]);

  const onClose = () => {
    setCs((prev) => ({ ...prev, itemModal: { id: '', authorId: '', visible: false } }));
  };

  const onSubmit: SubmitHandler<Form> = async (data) => {
    const isAddFile = images.some((image) => image.originFileObj);
    if (isAddFile) {
      const newImageUploadFiles: UploadFile[] = images.filter((image) => image.originFileObj);
      const imageFiles = newImageUploadFiles.map((image) => image.originFileObj);
      const asyncActions = imageFiles.map((file) =>
        uploadImages.mutateAsync({ file: file?.name || '', type: file?.type || '' })
      );

      const results = await Promise.all(asyncActions);
      const newImages = results.map(({ signS3 }) => ({ url: signS3.url }));
      const originImages = images
        .filter((image) => !image.originFileObj)
        .map((image) => ({ id: image.id, url: image.url }));
      const image = [...newImages, ...originImages];

      try {
        await Promise.all(
          results.map((res, idx) =>
            Axios.put(res.signS3.signedRequest, imageFiles[idx], {
              headers: {
                'Content-Type': imageFiles[idx]?.type,
              },
            })
          )
        );

        updateCrmIssue.mutate({ id, ...data, image });
      } catch (error) {
        console.log('error', error);
        api.error({ message: '파일 업로드 실패' });
      }
    } else {
      const image = images.map((image) => ({ id: image.id, url: image.url }));
      updateCrmIssue.mutate({ id, ...data, image });
    }
  };

  const onChangeCcList = (value: string[]) => {
    // if (issue) {
    //   const originLength = issue.cc.length;
    //   const nextLength = value.length;
    //   if (originLength > nextLength) {
    //     const [removeCc] = ccList.filter((cc) => !value.includes(cc.ccHuserId));
    //   } else {
    //     const [ccHuserId] = value.filter((cc) => !ccList.find((v) => v.ccHuserId === cc));
    //     addCrmIssueCc.mutate({ crmIssueId: issue.id, ccHuserId });
    //   }
    // }
  };

  const onSelected = (value: string) => {
    if (issue) addCrmIssueCc.mutate({ crmIssueId: issue.id, ccHuserId: value });
  };

  // Called when an option is deselected, param is the selected option's value.
  // Only called for multiple or tags, effective in multiple or tags mode only
  const onDeselected = (value: string, option: any) => {
    const cc = ccList.find(({ id, ccHuserId }) => value === ccHuserId);

    removeCrmIssueCc.mutate({ id: [cc.id] });
  };

  const confirm = (id: string) => {
    updateCrmIssueHideAPI.mutate({ id });
  };

  const cancel = (e: any) => {
    console.log(e);
    // message.error('Click on No');
  };

  return (
    <Modal
      open={visible}
      footer={null}
      closable={false}
      width={600}
      onCancel={onClose}
      destroyOnClose
    >
      <div className="mt-2">
        {contextHolder}
        <Controller
          name="title"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="제목"
              status={errors.title ? 'error' : ''}
              disabled={!isEditable}
            />
          )}
        />
        <Controller
          name="content"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextArea
              {...field}
              rows={5}
              placeholder="내용을 입력하세요"
              className="mt-3"
              status={errors.content ? 'error' : ''}
              disabled={!isEditable}
            />
          )}
        />
      </div>
      <div className="mt-3">
        <div>
          <Dragger
            onChange={(info) => setImages(info.fileList)}
            listType="picture-card"
            beforeUpload={(file) => {
              return false;
            }}
            fileList={images}
            multiple
            disabled={!isEditable}
          >
            이미지 업로드
          </Dragger>
        </div>
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          size="small"
          loading={isFetching}
          dataSource={data?.crmIssueComments.rows}
          pagination={{ current: currentPage, total: data?.crmIssueComments.page.total }}
          onChange={({ current }) => setCurrentPage(current as number)}
          rowKey={(record) => record.id}
        />
      </div>
      <AddComment issueId={id} currentPage={currentPage} />
      {issue?.colId === 3 ? (
        <div className="mt-5">
          <Popconfirm
            title="항목 숨기기"
            description="숨긴 항목이로 이동합니다"
            onConfirm={() => confirm(id)}
            onCancel={cancel}
            okText="확인"
            cancelText="취소"
          >
            <Button danger>
              {/* cs/issue 숨김 버튼 */}
              숨기기
            </Button>
          </Popconfirm>
        </div>
      ) : (
        <div className="mt-5 flex justify-end">
          <div className="flex flex-1">
            <Select
              mode="multiple"
              className="text-center w-full"
              options={[...hUserList.map(({ id, name }) => ({ value: id, label: name }))]}
              placeholder="cc추가"
              onChange={(value) => onChangeCcList(value)}
              optionLabelProp="label"
              value={ccList.map(({ ccHuserId }) => ccHuserId)}
              onSelect={onSelected}
              onDeselect={onDeselected}
              disabled={!isEditable}
              // optionRender={(option: any) => (
              //   <Space>
              //     <span role="img" aria-label={option.label}>
              //       {option.value}
              //     </span>
              //     {option.value}
              //   </Space>
              // )}
            />
          </div>
          <Button onClick={onClose}>취소</Button>
          <Button
            type="primary"
            className="ml-1"
            onClick={handleSubmit(onSubmit)}
            disabled={!isEditable}
          >
            확인
          </Button>
        </div>
      )}
    </Modal>
  );
}
