import { Select, TreeSelect } from 'antd';
import { find, flatMap } from 'lodash';
import React from 'react';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import { useForm, Controller, SubmitHandler, FormProvider, useFormContext } from 'react-hook-form';
import type { UseFormReturn, FieldValues } from 'react-hook-form';

import useHuserList from 'src/hooks/useHuserList';
import { HUSER_TREE_DATA } from 'src/libs/constants';

interface ConnectFormProps<TFieldValues extends FieldValues> {
  children(children: UseFormReturn<TFieldValues>): ReactElement;
}

const ConnectForm = <TFieldValues extends FieldValues>({
  children,
}: ConnectFormProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  return children({ ...methods });
};

type Props = {
  bg: string;
};

const { SHOW_PARENT } = TreeSelect;

// 21:상담예약, 28:진료예약
const AssignHuserFilter = [
  {
    key: 'cs',
    validStatusId: '21,71,72,73',
    huserType: 2,
  },
  {
    key: 'dr',
    validStatusId: '28,74,75,76',
    huserType: 4,
  },
];

const TreeSelector = ({ methods, props: { statusId, assignHuserId, widthType = 1 } }: any) => {
  const { watch, setValue, control, getValues, formState } = methods;
  const { hUserList } = useHuserList();
  const [assignHuser, setAssignHuser] = useState(
    // { cs: "", dr: ""}
    Object.assign(
      {},
      ...AssignHuserFilter.map((o) => {
        return {
          [o.key]: o.validStatusId.includes(statusId) ? assignHuserId : '',
        };
      })
    )
  );

  const huserTreeSet = HUSER_TREE_DATA.map((tree: any) => {
    return {
      ...tree,
      children: hUserList
        .filter(({ type }) => type === tree.type)
        .map(({ id, name }) => ({
          title: name,
          value: id,
          // key: `${tree.key}-${id}`,
          key: id,
        })),
    };
  });

  // const [value, setValue2] = useState<string[]>([]);

  const onChange2 = (newValue: string[] = [], c: any) => {
    // setValue2(newValue);

    const a = find(huserTreeSet, { key: '0-tm' });

    const b = newValue.map((v) => {
      if (v.match(/0-/gi)) {
        console.log('true', find(huserTreeSet, { key: v })?.children);

        return find(huserTreeSet, { key: v })?.children.map((cv: any) => cv.value);
      }

      return v;
    });

    c(flatMap(b));
  };

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  useEffect(() => {
    // 71,72,73 상담
    // 74,75,76 진료

    const subscription = watch((value: any, { name, type }: any) => {
      const r = AssignHuserFilter.find((f) => {
        return f.validStatusId.includes(value.selectedStatusId || value.statusId || '');
      });
      if (name === 'assignHuserId') {
        setAssignHuser(Object.assign(assignHuser, { [r?.key || 'cs']: value.assignHuserId }));
      }

      if (name === 'selectedStatusId' || name === 'statusId') {
        setValue('assignHuserId', assignHuser[r?.key || 'cs']);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, assignHuser, setValue, statusId]);

  return (
    <Controller
      name="entry"
      control={control}
      defaultValue={undefined}
      // rules={{ required: true }}
      render={({ field }) => {
        const a = getValues();

        const { statusId } = getValues();

        const type = AssignHuserFilter.find((f) => {
          const reg = new RegExp(statusId, 'gi');
          return f.validStatusId.match(reg);
        });

        const tProps = {
          treeData: huserTreeSet,
          value: a.entry || [] || undefined,
          treeCheckable: true,
          showCheckedStrategy: SHOW_PARENT,
          placeholder: '참가자를 선택해 주세요',
          style: {
            width: '100%',
          },
        };

        return (
          <TreeSelect
            {...field}
            onChange={(value) => onChange2(value, field.onChange)}
            {...tProps}
          />
        );

        // return (
        //   <TreeSelect
        //     {...field}
        //     status={formState.errors.assignHuserId && 'error'}
        //     options={[{ id: '', name: '미지정', type: 99, position: null }, ...hUserList]
        //       .filter((hUser) => hUser.type === type?.huserType || hUser.type === 99)
        //       .map(({ id, name, position }) => ({
        //         value: id,
        //         label: `${position ? `[${position}]` : ''} ${name}`,
        //       }))}
        //     className={`w-[${[0, '150px', '50%'][widthType]}]`}
        //     placeholder="담당자 지정"
        //     listHeight={180}
        //   />
        // );
      }}
    />
  );
};

export default function AssignTreeSelector({ bg, statusId, assignHuserId }: any) {
  return (
    <ConnectForm>
      {/* {({ register, control, formState, getValues, ...others }) => ( */}
      {(methods) => (
        <TreeSelector methods={methods} props={{ assignHuserId, statusId }} />
        // <Controller
        //   name="assignHuserId"
        //   control={control}
        //   // rules={{ required: true }}
        //   render={({ field }) => {
        //     const { selectedStatusId } = getValues();
        //     const type = AssignHuerFilter.find((f) => {
        //       const reg = new RegExp(selectedStatusId, 'gi');
        //       return f.validStatusId.match(reg);
        //     });

        //     return (
        //       <Select
        //         {...field}
        //         status={formState.errors.assignHuserId && 'error'}
        //         options={[{ id: '', name: '미지정', type: 99, position: null }, ...hUserList]
        //           .filter((hUser) => hUser.type === type?.huserType || hUser.type === 99)
        //           .map(({ id, name, position }) => ({
        //             value: id,
        //             label: `${position ? `[${position}]` : ''} ${name}`,
        //           }))}
        //         className="w-[50%]"
        //         placeholder="담당자 지정"
        //         listHeight={180}
        //       />
        //     );
        //   }}
        // />
      )}
    </ConnectForm>
  );
}
