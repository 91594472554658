import { useQuery } from '@tanstack/react-query';

import { getCrmIssueHideListAPI } from 'src/api/issue.api';
import { QUERY_KEYS } from 'src/libs/constants';

export default function useIssueHideList() {
  const { data, isFetching } = useQuery([QUERY_KEYS.GET_CRM_ISSUE_HIDE_LIST], () =>
    getCrmIssueHideListAPI({})
  );

  const issueHideList = data?.crmIssueHideList || [];

  return {
    issueHideList,
    isFetching,
  };
}
