import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, InputNumber, Popconfirm, Select, notification } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { updateCrmPaidHistoryAPI, removeCrmPaidHistoryAPI } from 'src/api/crm.api';
import { numberFormat } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { isRightPermissionState } from 'src/recoil/auth.recoil';
import { CrmPaidHistory } from 'src/types/crm.types';

type Props = CrmPaidHistory & { patientId: string };

type Form = {
  amount: number;
  memo: string;
  type: 'credit' | 'cash' | 'account';
  option: string;
};

const options = [
  { value: 'credit' as const, label: '신용카드' },
  { value: 'cash' as const, label: '현금' },
  { value: 'account' as const, label: '계정' },
];

const { TextArea } = Input;

export default function EditablePaymentRefundForm(props: Props) {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const isRightPermission = useRecoilValue(isRightPermissionState('결제 관리 편집'));
  const [isEdit, setIsEdit] = useState(false);

  const updateCrmPaidHistory = useMutation(updateCrmPaidHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '결제 내역 수정 성공' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PAID_LIST,
        { patientId: props.patientId },
      ]);
    },
    onError: () => {
      api.error({ message: '결제 내역 수정 실패' });
    },
    onSettled: () => {
      onClose();
    },
  });

  const removeCrmPaidHistory = useMutation(removeCrmPaidHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '결제 내역 삭제 성공' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PAID_LIST,
        { patientId: props.patientId },
      ]);
    },
    onError: () => {
      api.error({ message: '결제 내역 삭제 실패' });
    },
    onSettled: () => {
      onClose();
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      type: props.type,
      option: props.option,
      amount: props.amount,
      memo: props.memo,
    },
  });

  const onDelete = () => {
    removeCrmPaidHistory.mutate({ id: props.id });
  };

  const onClose = () => {
    setIsEdit(false);
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    const others = Object.fromEntries(Object.entries(data).filter(([_, value]) => value));
    const payload: any = { id: props.id, ...others };
    updateCrmPaidHistory.mutate(payload);
  };

  return (
    <>
      {contextHolder}
      {isEdit ? (
        <div className="rounded-lg shadow-lg p-3 mt-1">
          <Controller
            name="amount"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <InputNumber
                value={value}
                placeholder="숫자만 입력"
                step={100}
                addonBefore="결제금액"
                addonAfter="원"
                onChange={(digit) => onChange(digit || 0)}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                status={errors.amount ? 'error' : ''}
                className="w-full mb-1"
              />
            )}
          />
          <div className="flex mb-1">
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select {...field} options={options} className="w-full" placeholder="결제 수단" />
              )}
            />
            <Controller
              name="option"
              control={control}
              render={({ field }) => (
                <Input {...field} className="w-full ml-1" placeholder="결제 옵션 (할부, 기타)" />
              )}
            />
          </div>
          <Controller
            name="memo"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={3}
                placeholder="결제 내용을 입력하세요"
                className="mb-3"
                status={errors.memo ? 'error' : ''}
              />
            )}
          />
          <div className="flex justify-end">
            <Popconfirm
              title="결제 내역 삭제"
              okText="예"
              cancelText="아니오"
              onConfirm={(e) => onDelete()}
            >
              <Button type="text" danger>
                삭제
              </Button>
            </Popconfirm>
            <Button onClick={onClose} className="mx-1">
              취소
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit)}>
              확인
            </Button>
          </div>
        </div>
      ) : (
        <div className="rounded-lg shadow-lg p-5 mb-4">
          <div className="flex items-center justify-between">
            <div className="text-[#2B3674] font-bold mr-5">{numberFormat(props.amount)}원</div>
            {isRightPermission && (
              <Button
                type="text"
                className="ml-2"
                icon={<EditOutlined />}
                onClick={() => setIsEdit(true)}
              />
            )}
          </div>
          <div className="flex justify-between mt-3">
            <div className="text-[#A3AED0] text-sm flex-1">{props.memo}</div>
            <div className="text-[#A3AED0] text-sm w-20 ml-3">
              {dayjs(props.createdAt).format('YYYY.MM.DD')}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
