import Http from 'src/@http';
import {
  queryManageGrossList,
  queryManageGross,
  queryManageGrossUserResult,
  queryManageGrossByUserId,
  mutationAddManageGross,
  mutationUpdateManageGross,
  mutationUpdateManageGrossPrivacy,
  queryCrmManageGrossSuggest,
  mutationAddCrmManageGrossSuggest,
} from 'src/graphql/gross.query';
import { errorResponse, successResponse } from 'src/libs/response';
import { AddCrmIssueCommentRequest } from 'src/types/issue.types';
import { ApiResponse } from 'src/types/response';

// 목표관리 리스트
export async function getManageGrossListAPI(filter: any) {
  return Http.instance
    .post<ApiResponse<any>>('', {
      query: queryManageGrossList,
      variables: {
        filter: filter,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getManageGrossListAPI'));
}

// 목표관리 리스트
export async function getManageGrossUserResultAPI(filter: any) {
  return Http.instance
    .post<ApiResponse<any>>('', {
      query: queryManageGrossUserResult,
      variables: {
        filter: filter,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getManageGrossUserResultAPI'));
}

// 목표관리 상세
export async function getManageGrossAPI({ taggedId }: any) {
  return Http.instance
    .post<ApiResponse<any>>('', {
      query: queryManageGross,
      variables: {
        filter: { taggedId },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getManageGrossAPI'));
}

//
export async function getManageGrossByUserIdAPI({ huserId }: any) {
  return Http.instance
    .post<ApiResponse<any>>('', {
      query: queryManageGrossByUserId,
      variables: {
        id: huserId,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getManageGrossAPI'));
}

// 목표관리 생성
export async function addManageGrossAPI(payload: any) {
  return Http.instance
    .post<ApiResponse<any>>('', {
      query: mutationAddManageGross,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addManageGrossAPI'));
}

// 목표관리 수정 /  삭제 (is_delete: true)
export async function updateManageGrossAPI(payload: any) {
  return Http.instance
    .post<ApiResponse<any>>('', {
      query: mutationUpdateManageGross,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateManageGrossAPI'));
}

// 목표관리 공개설정(id)
export async function updateManageGrossPrivacyApi(payload: any) {
  return Http.instance
    .post<ApiResponse<any>>('', {
      query: mutationUpdateManageGrossPrivacy,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('updateManageGrossPrivacyApi'));
}

// 목표관리 추천 제안
export async function getManageGrossSuggestAPI(filter: any) {
  return Http.instance
    .post<ApiResponse<any>>('', {
      query: queryCrmManageGrossSuggest,
      variables: {
        filter: filter,
      },
    })
    .then(successResponse)
    .catch(errorResponse('getManageGrossSuggestAPI'));
}

// 목표관리 공개설정(id)
export async function addCrmManageGrossSuggestAPI(payload: any) {
  return Http.instance
    .post<ApiResponse<any>>('', {
      query: mutationAddCrmManageGrossSuggest,
      variables: {
        input: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('addCrmManageGrossSuggestAPI'));
}
