import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { getCrmDashStatisticListAPI } from 'src/api/statistic.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { authState } from 'src/recoil/auth.recoil';
import { dashboardFilterState } from 'src/recoil/dashboardFilter.recoil';
import { CrmDashStatisticData } from 'src/types/statistic.types';

type TableData = CrmDashStatisticData;

const columns: ColumnsType<TableData> = [
  {
    title: '날짜',
    dataIndex: 'date',
    key: 'date',
    width: 120,
    render: (_, record) => dayjs(record.date).format('YYYY-MM-DD'),
  },
  {
    title: '신환DB수',
    dataIndex: 'firstDBCount',
    key: 'firstDBCount',
    sorter: {
      compare: (a, b) => a.firstDBCount - b.firstDBCount,
    },
  },
  {
    title: '구환DB수',
    dataIndex: 'secondDBCount',
    key: 'secondDBCount',
    sorter: {
      compare: (a, b) => a.secondDBCount - b.secondDBCount,
    },
  },
  {
    title: '총 DB수',
    dataIndex: 'allDBCount',
    key: 'allDBCount',
    sorter: {
      compare: (a, b) => a.allDBCount - b.allDBCount,
    },
  },
  {
    title: '예약수',
    dataIndex: 'bookCount',
    key: 'bookCount',
    sorter: {
      compare: (a, b) => a.bookCount - b.bookCount,
    },
  },
  {
    title: '내원수',
    dataIndex: 'visitCount',
    key: 'visitCount',
    sorter: {
      compare: (a, b) => a.visitCount - b.visitCount,
    },
  },
  {
    title: '내원율',
    dataIndex: 'visitRate',
    key: 'visitRate',
    sorter: {
      compare: (a, b) => a.visitRate - b.visitRate,
    },
  },
  {
    title: '동의율',
    dataIndex: 'agreeRate',
    key: 'agreeRate',
    sorter: {
      compare: (a, b) => a.agreeRate - b.agreeRate,
    },
  },
  {
    title: '지출비용',
    dataIndex: 'expenditure',
    key: 'expenditure',
    sorter: {
      compare: (a, b) => a.expenditure - b.expenditure,
    },
  },
  {
    title: '매출',
    dataIndex: 'profit',
    key: 'profit',
    sorter: {
      compare: (a, b) => a.profit - b.profit,
    },
  },
  {
    title: 'ROAS',
    dataIndex: 'roas',
    key: 'roas',
    sorter: {
      compare: (a, b) => a.roas - b.roas,
    },
  },
  {
    title: '매출이익',
    dataIndex: 'net',
    key: 'net',
    sorter: {
      compare: (a, b) => a.net - b.net,
    },
  },
];

export default function DashboardTable() {
  const { meh } = useRecoilValue(authState);
  const filter = useRecoilValue(dashboardFilterState);
  const huserId = filter.hUserId ? filter.hUserId : null;
  const huserIds = filter.hUserIds ? filter.hUserIds : null;

  const { data, isFetching, isLoading } = useQuery(
    [
      QUERY_KEYS.GET_DASH_STATISTIC_LIST,
      { huserIds, huserId, startDate: filter.startDate, endDate: filter.endDate },
    ],
    () =>
      getCrmDashStatisticListAPI({
        ...(huserIds && { huserIds }),
        ...(huserId && { huserId }),
        startDate: dayjs(filter.startDate).format(),
        endDate: dayjs(filter.endDate).format(),
      })
  );

  return (
    <div className="w-full bg-white rounded-lg p-3">
      <div className="text-xl font-bold text-[#2b3674]">매출 분석</div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={data?.crmDashStatisticList}
          rowKey={(record) => record.id}
          loading={isFetching || isLoading}
          locale={{
            triggerDesc: '내림차순',
            triggerAsc: '오름차순',
            cancelSort: '정렬 취소',
          }}
        />
      </div>
    </div>
  );
}
