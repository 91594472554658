import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, Popconfirm, notification } from 'antd';
import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { removeCrmIssueCommentAPI, updateCrmIssueCommentAPI } from 'src/api/issue.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { authState } from 'src/recoil/auth.recoil';

type Form = {
  comment: string;
};

type Props = {
  oldComment: string;
  id: string;
  currentPage: number;
  huserId: string;
};

export default function ManageComment({ id, oldComment, currentPage, huserId }: Props) {
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      comment: oldComment,
    },
  });
  const { meh } = useRecoilValue(authState);
  const isEditable = meh.id === huserId;

  const updateCrmIssueComment = useMutation(updateCrmIssueCommentAPI, {
    onSuccess: (res) => {
      api.success({ message: '수정 완료' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_ISSUE_COMMENT_LIST,
        { id, currentPage },
      ]);
    },
    onError: () => {
      api.success({ message: '수정 실패' });
    },
  });

  const removeCrmIssueComment = useMutation(removeCrmIssueCommentAPI, {
    onSuccess: (res) => {
      api.success({ message: '삭제 완료' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_ISSUE_COMMENT_LIST,
        { id, currentPage },
      ]);
    },
    onError: () => {
      api.success({ message: '삭제 실패' });
    },
  });

  const onSubmit: SubmitHandler<Form> = (data) => {
    updateCrmIssueComment.mutate({ id, comment: data.comment });
  };

  const onRemove = () => {
    removeCrmIssueComment.mutate({ id });
  };

  if (!isEditable) return null;

  return (
    <span>
      {contextHolder}
      <Popconfirm
        icon={null}
        title={
          <Controller
            name="comment"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input {...field} placeholder="코멘트" status={errors.comment ? 'error' : ''} />
            )}
          />
        }
        onConfirm={handleSubmit(onSubmit)}
      >
        <Button size="small" type="link">
          수정
        </Button>
      </Popconfirm>
      <Popconfirm title="삭제하시겠습니까?" onConfirm={onRemove}>
        <Button size="small" type="link">
          삭제
        </Button>
      </Popconfirm>
    </span>
  );
}
