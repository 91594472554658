export const querySignin = `
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      refreshToken
      meh {
        id
        email
        crmAuthority
        name
        title
        department
        position
        phone
        contact
        isMaster
        isDelete
        hospital {
          id
          name
          location {
            id
            siName
            gunguName
          }
        }
      }
    }
  }
`;

export const queryMeh = `
  query meh {
    meh {
      id
      email
      crmAuthority
      name
      title
      department
      position
      phone
      contact
      isMaster
      isDelete
      avatar
      hospital {
        id
        name
        location {
          id
          siName
          gunguName
        }
      }
    }
  }
`;
