import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Select,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Switch,
  ConfigProvider,
  Collapse,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { useForm, Controller, SubmitHandler, FormProvider } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import SearchForm from './SearchForm';
import AssignSelector from '../Form/Controllers/AssignHuserSelect';
import ListTagsSelector from '../Form/Controllers/ListTagsSelect';
import NrcTypeSelector from '../Form/Controllers/NRCTypeSelect';
import { addCrmItemAPI } from 'src/api/crm.api';
import { getCrmPatientListAPI } from 'src/api/patient.api';
import useHuserList from 'src/hooks/useHuserList';
import { displayDateTime, getDisplayTime } from 'src/libs/common';
import { QUERY_KEYS, TIME_LENGTH_LIST } from 'src/libs/constants';
import TimeTableDrawer from 'src/libs/TimeTableDrawer';
import { callManagementState } from 'src/recoil/callManagement.recoil';
import { getStatusListByGroupStatus } from 'src/recoil/status.recoil';
import { AddCrmItemRequest } from 'src/types/crm.types';

const { TextArea } = Input;

type FormPatient = {
  name: string;
  phone: string;
  registerNumber: string;
  referral: string;
  address: string;
  postAddress: string;
  birth: number;
  gender: string;
  tags: string;
};

type FormHistory = {
  memo: string;
  statusId: string;
  date: string;
  assignHuserId: string;
  length: number;
  nrc: string;
};

type Form = FormPatient & FormHistory;

const patientKeys = [
  'name',
  'phone',
  'registerNumber',
  'referral',
  'address',
  'postAddress',
  'birth',
  'gender',
  'tags',
];
const historyKeys = ['title', 'memo', 'statusId', 'date', 'assignHuserId', 'length', 'nrc'];

export default function CreatePatientForm() {
  const [showAdditionalForm, setShowAdditionalForm] = useState(false);
  const queryClient = useQueryClient();
  const [{ isNewVisit }, setCallManagement] = useRecoilState(callManagementState);
  const statusList = useRecoilValue(getStatusListByGroupStatus('call'));
  const { hUserList } = useHuserList();
  const open = useDaumPostcodePopup();

  const methods = useForm<Form>({
    defaultValues: { gender: 'f' },
  });

  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const statusId = watch('statusId');
  const 예약상태 = ['21', '28'].includes(statusId);

  const addCrmItem = useMutation(addCrmItemAPI, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([QUERY_KEYS.GET_CALL_LIST, { isNewVisit }]);
      onClose();
    },
  });

  const onClose = () => {
    setCallManagement((prev) => ({ ...prev, showAddPatientDrawer: false }));
    reset();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    const { postAddress, address, ...onlyPatient } = Object.fromEntries(
      Object.entries(data)
        .filter(([, value]) => value !== undefined && value !== '')
        .filter(([key]) => patientKeys.some((patientKey) => patientKey === key))
    );
    const patientPayload = {
      ...onlyPatient,
      address: postAddress && address ? postAddress || '' + ' ' + address || '' : '',
    } as AddCrmItemRequest;

    const historyFormData = Object.fromEntries(
      Object.entries(data).filter(([key]) => historyKeys.some((historyKey) => historyKey === key))
    ) as FormHistory;

    let payload = { ...patientPayload };

    if (showAdditionalForm) {
      const { date, length, assignHuserId, ...others } = historyFormData;
      const startTime = dayjs(date).format();
      const endTime = dayjs(date).add(length, 'minutes').format();

      payload = Object.assign(payload, {
        history: {
          listId: '', // for type
          ...(예약상태 && { startTime, endTime, assignHuserId }),
          ...others,
        },
      });
    }

    addCrmItem.mutate(payload);
  };

  const onClickPatient = (patient: any) => {
    setValue('name', patient.name);
    setValue('phone', patient.phone);
    setValue('birth', patient.birth);
    setValue('gender', patient.gender);
    setValue('registerNumber', patient.registerNumber);
  };

  return (
    <FormProvider {...methods}>
      <div className="mb-3 flex items-center">
        <span className="mr-3">신규환자 추가 + 바로예약1</span>
        <Switch
          checked={showAdditionalForm}
          onChange={(checked) => setShowAdditionalForm(checked)}
        />
      </div>
      <div className="mb-3">
        <div className="text-[#768396] font-bold mb-1">등록번호</div>
        <Controller
          name="registerNumber"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              value={value}
              onChange={(digit) => onChange(digit || '0')}
              className="w-full"
              placeholder="등록번호는 필수입력이 아닙니다"
              status={errors.registerNumber ? 'error' : ''}
            />
          )}
        />
      </div>
      <div className="flex mb-3">
        <div className="flex-1">
          <Collapse
            items={[
              {
                key: '1',
                label: '환자 검색',
                children: <SearchForm onClickPatient={onClickPatient} />,
              },
            ]}
          />
        </div>
      </div>
      <div className="flex mb-3">
        <div className="flex-1">
          <div className="text-[#768396] font-bold mb-1">이름</div>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} status={errors.name ? 'error' : ''} />}
          />
        </div>
        <div className="ml-3 flex-1">
          <div className="text-[#768396] font-bold mb-1">출생년도 4자리</div>
          <Controller
            name="birth"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputNumber
                value={value}
                onChange={(digit) => onChange(digit || 0)}
                className="w-full"
                placeholder="1995 숫자만 입력"
              />
            )}
          />
        </div>
        <div className="ml-3 w-[120px]">
          <div className="text-[#768396] font-bold mb-1">성별</div>
          <Controller
            name="gender"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Radio.Group
                options={[
                  { label: '여자', value: 'f' },
                  { label: '남자', value: 'm' },
                ]}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                optionType="button"
              />
            )}
          />
        </div>
      </div>
      <div className="mb-3">
        <div className="text-[#768396] font-bold mb-1">연락처</div>
        <Controller
          name="phone"
          control={control}
          rules={{ required: true, pattern: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/ }}
          render={({ field }) => (
            <Input {...field} placeholder="01012345678" status={errors.phone ? 'error' : ''} />
          )}
        />
      </div>
      <div className="mb-3 flex">
        <div className="flex-1">
          <div className="flex items-center mb-1">
            <Button
              type="primary"
              onClick={() =>
                open({
                  onComplete: (data) =>
                    setValue('postAddress', data.address, { shouldValidate: true }),
                })
              }
            >
              주소 검색
            </Button>
          </div>
          <Controller
            name="postAddress"
            control={control}
            render={({ field }) => (
              <Input {...field} status={errors.postAddress ? 'error' : ''} disabled />
            )}
          />
          <div className="flex-1 mt-3">
            <div className="text-[#768396] font-bold mb-1">상세주소</div>
            <Controller
              name="address"
              control={control}
              render={({ field }) => <Input {...field} status={errors.address ? 'error' : ''} />}
            />
          </div>
        </div>
      </div>
      <div className="mb-3 flex">
        <div className="flex-1">
          <div className="text-[#768396] font-bold mb-1">유입경로</div>
          <Controller
            name="referral"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </div>
      </div>

      {showAdditionalForm && (
        <>
          <Controller
            name="memo"
            control={control}
            render={({ field }) => (
              <TextArea {...field} rows={6} placeholder="메모를 입력하세요" className="mb-3" />
            )}
          />
          <div className="flex gap-x-3">
            <Controller
              name="statusId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={statusList.map(({ id, status }) => ({ value: id, label: status }))}
                  className={`text-center ${/^(21|28)$/gm.test(statusId) ? 'w-[150px]' : 'w-full'}`}
                  placeholder="콜/예약 상태를 선택하세요"
                  status={errors.statusId ? 'error' : ''}
                />
              )}
            />
            {예약상태 && (
              <>
                <Controller
                  name="date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange } }) => (
                    <DatePicker
                      className="flex-1"
                      locale={locale}
                      placeholder="예약일 지정"
                      disabledDate={(current) => current < dayjs().subtract(1, 'day')}
                      format="YYYY-MM-DD HH:mm"
                      disabledTime={displayDateTime}
                      showTime={{
                        format: 'HH:mm',
                        hourStep: 1,
                        minuteStep: 30,
                        hideDisabledOptions: true,
                        defaultValue: dayjs('09:00', 'HH:mm'),
                      }}
                      showNow={false}
                      onChange={(value, dateString) => onChange(dateString)}
                      status={errors.date ? 'error' : ''}
                    />
                  )}
                />
                <AssignSelector statusId={statusId} assignHuserId={''} />
                {/* <Controller
                  name="assignHuserId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="text-center ml-3 w-[150px]"
                      options={[
                        ...hUserList
                          .filter((hUser) => hUser.type === 2 || hUser.type === 4)
                          .map(({ id, name, position }) => ({
                            value: id,
                            label: `[${position}] ${name}`,
                          })),
                      ]}
                      placeholder="담당자 지정"
                      status={errors.assignHuserId ? 'error' : ''}
                    />
                  )}
                /> */}
              </>
            )}
          </div>
          {예약상태 && (
            <>
              <div className="mt-3">
                <Controller
                  name="length"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <div className="grid grid-cols-5 flex-1 gap-2">
                      {TIME_LENGTH_LIST.map((time) => (
                        <div
                          key={time}
                          className="col-span-1 flex justify-center items-center"
                          onClick={() => onChange(time)}
                        >
                          <ConfigProvider theme={{ token: { colorPrimary: '#00b96b' } }}>
                            <Button
                              danger={Boolean(errors.length)}
                              type={time === value ? 'primary' : 'default'}
                              className="w-full"
                            >
                              <div className="text-xs">{getDisplayTime(time)}</div>
                            </Button>
                          </ConfigProvider>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>
              <div className="mt-3">
                <NrcTypeSelector />
              </div>
            </>
          )}
        </>
      )}

      <div className="mt-3">
        <ListTagsSelector defaultValue={'0'} />
      </div>

      <div className="mt-3 flex justify-end">
        <Button type="primary" onClick={handleSubmit(onSubmit)}>
          확인
        </Button>
      </div>
    </FormProvider>
  );
}
