import React from 'react';
import { useParams } from 'react-router-dom';

import AddPatientDrawer from 'src/components/Call/AddPatientDrawer';
import CallButtonWidget from 'src/components/Call/CallButtonWidget';
import CallerWidget from 'src/components/Call/CallerWidget';
import CallManagementDrawer from 'src/components/Call/CallManagementDrawer';
import CallTableWidget from 'src/components/Call/CallTableWidget';
import ModalUploadDB from 'src/components/Call/ModalUploadDB';
import CallFilter from 'src/components/Filter/CallFilter';

export default function Call() {
  const { info } = useParams<{ info: string }>();

  return (
    <div>
      <div className="flex">
        <div className="flex-1">
          {/* <CallerWidget /> */}
          <CallButtonWidget />

          <CallTableWidget />
        </div>
      </div>
      <AddPatientDrawer />
      <CallManagementDrawer info={info || ''} />
      <ModalUploadDB />
    </div>
  );
}
