import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, Modal, Upload, UploadFile, notification } from 'antd';
import Axios from 'axios';
import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { signS3API } from 'src/api/common.api';
import { addCrmIssueAPI } from 'src/api/issue.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { csState } from 'src/recoil/cs.recoil';

const { TextArea } = Input;
const { Dragger } = Upload;

type Form = {
  title: string;
  content: string;
};

export default function AddItemModal() {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [{ showAddItemModal }, setState] = useRecoilState(csState);
  const [images, setImages] = useState<UploadFile[]>([]);

  const uploadImages = useMutation(signS3API);
  const addItem = useMutation(addCrmIssueAPI, {
    onSuccess: (res) => {
      api.success({ message: '신규cs추가 성공' });
      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_ISSUE_LIST]);
    },
    onError: () => {
      api.error({ message: '신규cs추가 실패' });
    },
    onSettled: () => {
      onClose();
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>();

  const onClose = () => {
    setImages([]);
    setState((prev) => ({ ...prev, showAddItemModal: false }));
    reset();
  };

  const onSubmit: SubmitHandler<Form> = async (data) => {
    const imageFiles = images.map((image) => image.originFileObj);
    const asyncActions = imageFiles.map((file) =>
      uploadImages.mutateAsync({ file: file?.name || '', type: file?.type || '' })
    );

    const results = await Promise.all(asyncActions);
    const image = results.map(({ signS3 }) => ({ url: signS3.url }));

    try {
      await Promise.all(
        results.map((res, idx) =>
          Axios.put(res.signS3.signedRequest, imageFiles[idx], {
            headers: {
              'Content-Type': imageFiles[idx]?.type,
            },
          })
        )
      );
      addItem.mutate({ ...data, image });
    } catch (error) {
      console.log('error', error);
      api.error({ message: '파일 업로드 실패' });
    }
  };

  return (
    <Modal open={showAddItemModal} footer={null} closable={false} width={600}>
      {contextHolder}
      <div className="text-base font-bold">신규CS추가</div>
      <div className="mt-5">
        <Controller
          name="title"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input {...field} placeholder="제목" status={errors.title ? 'error' : ''} />
          )}
        />
        <Controller
          name="content"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextArea
              {...field}
              rows={5}
              placeholder="내용을 입력하세요"
              className="mt-3"
              status={errors.content ? 'error' : ''}
            />
          )}
        />
      </div>
      <div className="mt-5">
        <Dragger
          onChange={(info) => setImages(info.fileList)}
          listType="picture"
          beforeUpload={(file) => {
            return false;
          }}
          fileList={images}
          multiple
        >
          이미지 업로드1
        </Dragger>
      </div>
      <div className="mt-5 flex justify-end">
        <Button onClick={onClose}>취소</Button>
        <Button type="primary" className="ml-1" onClick={handleSubmit(onSubmit)}>
          확인
        </Button>
      </div>
    </Modal>
  );
}
