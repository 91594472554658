import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, notification, InputNumber } from 'antd';
import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { addCrmPaidAPI } from 'src/api/crm.api';
import { QUERY_KEYS } from 'src/libs/constants';

const { TextArea } = Input;

type InputForm = {
  pay: number;
  memo: string;
};

export default function PaymentForm() {
  const queryClient = useQueryClient();
  const { info } = useParams<{ info: string }>();
  const [listId, patientId] = info?.split('_') || [''];
  const [api, contextHolder] = notification.useNotification();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InputForm>();

  const addCrmPaid = useMutation(addCrmPaidAPI, {
    onSuccess: (res) => {
      api.success({ message: '결제 추가 완료.' });
      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_PAID_LIST, { patientId }]);
    },
    onError: () => {
      api.error({ message: '결제 추가 실패.' });
    },
    onSettled: () => {
      reset();
    },
  });

  const onClickDelete = () => {
    reset();
  };

  const onSubmit: SubmitHandler<InputForm> = (data) => {
    addCrmPaid.mutate({ ...data, crmListId: listId, crmPatientId: patientId });
  };

  return (
    <div className="mt-5">
      {contextHolder}
      <div className="text-lg text-[#768396] font-bold mb-3">결제 추가</div>
      <div className="rounded-lg shadow-lg bg-white p-5 relative">
        <div>
          <Controller
            name="pay"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <InputNumber
                value={value}
                placeholder="숫자만 입력"
                step={100}
                addonBefore="결제금액"
                addonAfter="원"
                onChange={(digit) => onChange(digit || 0)}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                className="w-full mb-2"
                status={errors.pay ? 'error' : ''}
              />
            )}
          />
          <Controller
            name="memo"
            control={control}
            // rules={{ required: true }}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={3}
                placeholder="결제 내용을 입력하세요"
                className="mb-3"
                // status={errors.memo ? 'error' : ''}
              />
            )}
          />
          <div className="flex justify-end">
            <Button type="link" onClick={onClickDelete}>
              <div className="text-[#A3AED0]">삭제</div>
            </Button>
            <Button onClick={handleSubmit(onSubmit)} loading={addCrmPaid.isLoading} type="primary">
              결제추가
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
