import { notification } from 'antd';
import dayjs from 'dayjs';
import { utils, writeFile } from 'xlsx';

// import { Status } from 'modules/filter/filter.models';
// import { IEventReservations } from 'modules/reservation/reservation.models';
const column = [
  '날짜',
  '이름',
  '성별',
  '나이',
  '전화번호',
  '시술명',
  '등록일',
  '과금액',
  '상태',
  '답변1',
  '답변2',
  '답변3',
  '메모',
];

export function exportToExcel(list, filterModel) {
  try {
    let userRow = '';
    const reservationData = list.reduce(
      (acc, value) => {
        userRow = `${value.patient.name} `;

        acc.push([
          dayjs(value.createdAt).format('YYYY-MM-DD HH:mm'),
          userRow,
          value.patient.gender && `${value.patient.gender === 'm' ? ' 남' : ' 여'}`,
          value.patient.birth && `${dayjs().year() - value.patient.birth + 1}세`,
          value.patient.phone,
          value.therapy,
          value.createdAt && dayjs(value.createdAt).format('YYYY-MM-DD HH:mm'),
          '-',
          value.latestStatusId ? '콜' : '-',
          value.etc?.question.answer1 || '',
          value.etc?.question.answer2 || '',
          value.etc?.question.answer3 || '',
          value.memo,
        ]);

        return acc;
      },
      [column]
    );

    const worksheet = utils.aoa_to_sheet(reservationData);
    const new_workbook = utils.book_new();

    utils.book_append_sheet(new_workbook, worksheet, 'SheetJS');

    writeFile(new_workbook, handleFilename(filterModel));
  } catch (error) {
    console.error('fail - export to excel', error);
    notification.error({
      message: '액셀 내보내기 실패',
      description: '문제가 지속되면 관리자에게 연락해주세요.',
      placement: 'bottomRight',
    });
  }
}

function handleFilename(model) {
  if (model.date) {
    const { startDate, endDate } = model.date;
    return `[어뮤즈케어] ${startDate} ~ ${endDate} ${new Date().getTime()}.xlsx`;
  }

  return `[어뮤즈케어] 전체날짜 ${new Date().getTime()}.xlsx`;
}
