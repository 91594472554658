import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import { useRecoilValue } from 'recoil';

import useHuserList from './useHuserList';
import { getCrmBoardListAPI } from 'src/api/crm.api';
import { QUERY_KEYS } from 'src/libs/constants';
import TimeTableDrawer from 'src/libs/TimeTableDrawer';
import { reservationFilterState } from 'src/recoil/reservationFilter.recoil';

export default function useReservation() {
  const filter = useRecoilValue(reservationFilterState);
  const { hUserList, getUserById } = useHuserList();

  const { data, isLoading, isFetching } = useQuery(
    [QUERY_KEYS.GET_CRM_BOARD_LIST, { selectedDate: filter.selectedDate }],
    () => getCrmBoardListAPI({ date: dayjs(filter.selectedDate).format() })
  );

  return {
    ...TimeTableDrawer.createScheduleGroup(data?.crmBoardList, hUserList),
    isFetching: isLoading || isFetching,
  };
}
