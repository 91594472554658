import { useQuery } from '@tanstack/react-query';
import { Button, Spin } from 'antd';
import React from 'react';

import InfoBox from './InfoBox';
import { getCrmHospitalInfoAPI } from 'src/api/hospital.api';
import { QUERY_KEYS } from 'src/libs/constants';

export default function DefaultInfo() {
  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_HOSPITAL_INFO],
    getCrmHospitalInfoAPI
  );

  const hospitalInfo = data?.crmHospitalInfo;

  return (
    <Spin spinning={isFetching || isLoading}>
      <div className="bg-white w-[540px] rounded-lg p-5">
        <div className="flex justify-between">
          <div className="text-[#2B3674] font-bold text-xl">파트너 기본정보</div>
          <Button>
            <div className="text-[#2B3674] font-bold">정보 수정요청</div>
          </Button>
        </div>

        <div className="mt-5">
          <div className="flex">
            <InfoBox title="파트너 명">
              <div className="text-[#2B3674]">{hospitalInfo?.name}</div>
            </InfoBox>
            <InfoBox title="대표번호" className="ml-5">
              <div className="text-[#2B3674]">{hospitalInfo?.tel}</div>
            </InfoBox>
          </div>
          <div className="flex mb-5">
            <InfoBox title="병원주소">
              <div className="text-[#2B3674]">
                {hospitalInfo?.location.siName} {hospitalInfo?.location.gunguName}
              </div>
            </InfoBox>
            <InfoBox title="담당자 번호" className="ml-5">
              <div className="text-[#2B3674]">{hospitalInfo?.phones[0]}</div>
            </InfoBox>
          </div>
          <div className="flex mb-5">
            <InfoBox title="병원소개">
              <div className="text-[#2B3674]">API</div>
            </InfoBox>
          </div>
          <div className="flex">
            <InfoBox title="진료시간">
              <div className="text-[#2B3674] leading-6">월 10:00 - 19:00 (API)</div>
              <div className="text-[#2B3674] leading-6">월 10:00 - 19:00 (API)</div>
              <div className="text-[#2B3674] leading-6">월 10:00 - 19:00 (API)</div>
              <div className="text-[#2B3674] leading-6">월 10:00 - 19:00 (API)</div>
              <div className="text-[#2B3674] leading-6">월 10:00 - 19:00 (API)</div>
              <div className="text-[#2B3674] leading-6">월 10:00 - 19:00 (API)</div>
              <div className="text-[#2B3674] leading-6">월 10:00 - 19:00 (API)</div>
            </InfoBox>
          </div>
        </div>
      </div>
    </Spin>
  );
}
