import { useQuery } from '@tanstack/react-query';

import { getManageGrossListAPI, getManageGrossSuggestAPI } from 'src/api/gross.api';
import { QUERY_KEYS } from 'src/libs/constants';

export default function useManageGrossList() {
  const { data, isFetching, isLoading } = useQuery([QUERY_KEYS.GET_CRM_MANAGE_GROSS_LIST], () =>
    getManageGrossListAPI({})
  );

  const grossList = data?.crmManageGrossList;

  const getGrossData = (id: string) => {
    return grossList.find((l: any) => l.id === id);
  };

  return {
    grossList,
    isFetching,
    getGrossData,
    isLoading,
  };
}

export function useManageGrossSuggest() {
  const { data, isFetching, isLoading } = useQuery([QUERY_KEYS.GET_CRM_MANAGE_GROSS_SUGGEST], () =>
    getManageGrossSuggestAPI({})
  );

  const grossSuggest = data?.crmManageGrossSuggest;

  return {
    grossSuggest,
    isFetching,
    isLoading,
  };
}
