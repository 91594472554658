import { Spin } from 'antd';
import React from 'react';

export default function Loader() {
  return (
    <div className="absolute flex justify-center items-center top-0 bottom-0 left-0 right-0 z-[99] bg-[#ffffff07]">
      <Spin />
    </div>
  );
}
