import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { getCrmPatientCallHistoryAPI } from 'src/api/crm.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { CrmContent, PatientHistory } from 'src/types/crm.types';

const initialState = {
  id: '',
  createdAt: '',
  hospitalId: '',
  registerNumber: '',
  referral: '',
  therapy: '',
  isNewVisit: true,
  tmId: '',
  csId: '',
  drId: '',
  status: [],
  cc: null,
};

export default function useCallHistory(patientId: string, isConsult?: boolean) {
  const [crmPatientCallHistory, setCrmPatientCallHistory] = useState<PatientHistory>(initialState);

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CRM_PATIENT_CALL_HISTORY, { patientId }],
    () => getCrmPatientCallHistoryAPI({ id: patientId }),
    {
      enabled: !!patientId,
    }
  );

  useEffect(() => {
    if (data) {
      const newHistory = data.crmPatientCallHistory.reduce((acc, history) => {
        const { status, ...others } = history;
        const result = { ...acc, ...others };

        if (result['status']) {
          result['status'] = [...result['status'], ...history.status];
        } else {
          result['status'] = history.status;
        }

        return result;
      }, {} as PatientHistory);

      setCrmPatientCallHistory(isEmpty(newHistory) ? initialState : newHistory);
    }
  }, [data]);

  const getStatusItemList = (crmStatusId: string) => {
    return crmPatientCallHistory.status.filter((history) => history.crmStatusId === crmStatusId);
  };

  const getAllItemList = () => {
    const itemList = crmPatientCallHistory.status?.reduce((acc, value) => {
      acc.push(...value.contents);
      return acc;
    }, [] as CrmContent[]);

    const sortedItemList = itemList.sort((a, b) => {
      const dateA = dayjs(a.createdAt);
      const dateB = dayjs(b.createdAt);
      return dateB.isBefore(dateA) ? -1 : 1;
    });

    return sortedItemList;
  };

  return {
    crmPatientCallHistory,
    getStatusItemList,
    getAllItemList,
    isFetching: isFetching || isLoading,
  };
}
