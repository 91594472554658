import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Select, Spin, notification } from 'antd';
import dayjs from 'dayjs';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler, FormProvider } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import AssignSelector from '../Form/Controllers/AssignHuserSelect';
import { getCrmBoardItemSummaryAPI, updateCrmBoardItemAPI } from 'src/api/crm.api';
import useHuserList from 'src/hooks/useHuserList';
import { QUERY_KEYS } from 'src/libs/constants';
import { reservationFilterState } from 'src/recoil/reservationFilter.recoil';
import { getStatusListByGroupStatus, getStatusNameMapState } from 'src/recoil/status.recoil';

type Form = {
  selectedStatusId: string;
  assignHuserId: string;
};

type Props = {
  id: string;
  statusId: string;
  listId: string;
  assignHuserId: string;
};

// 21:상담예약, 28:진료예약
const AssignHuerFilter = [
  {
    key: 'cs',
    validStatusId: '21,71,72,73',
    huserType: 2,
  },
  {
    key: 'dr',
    validStatusId: '28,74,75,76',
    huserType: 4,
  },
];

export default function TooltipInsideForm({ id, statusId, listId, assignHuserId }: Props) {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const filter = useRecoilValue(reservationFilterState);
  const statusList = useRecoilValue(getStatusListByGroupStatus('board/context'));
  const statusMap = useRecoilValue(getStatusNameMapState);
  const { hUserList } = useHuserList();
  const [assignHuser, setAssignHuser] = useState(
    // { cs: "", dr: ""}
    Object.assign(
      {},
      ...AssignHuerFilter.map((o) => {
        return {
          [o.key]: o.validStatusId.includes(statusId) ? assignHuserId : '',
        };
      })
    )
  );

  const methods = useForm<Form>({ defaultValues: { assignHuserId, selectedStatusId: statusId } });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = methods;

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CRM_BOARD_ITEM_SUMMARY, { listId }],
    () => getCrmBoardItemSummaryAPI({ listId }),
    {
      enabled: Boolean(listId),
    }
  );

  const updateCrmBoardItem = useMutation(updateCrmBoardItemAPI, {
    onSuccess: (res) => {
      api.success({ message: '내원상태 변경 완료' });
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_BOARD_LIST,
        { selectedDate: filter.selectedDate },
      ]);
    },
    onError: () => {
      api.error({ message: '내원상태 변경 실패' });
    },
  });

  const onSubmit: SubmitHandler<Form> = (data) => {
    updateCrmBoardItem.mutate({
      crmListId: listId,
      id,
      statusId: data.selectedStatusId,
      assignHuserId: data.assignHuserId !== '' ? data.assignHuserId : null,
    });
  };

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  useEffect(() => {
    // 71,72,73 상담
    // 74,75,76 진료
    const subscription = watch((value, { name, type }) => {
      const r = AssignHuerFilter.find((f) => {
        return f.validStatusId.includes(value.selectedStatusId || '');
      });
      if (name === 'assignHuserId') {
        setAssignHuser(Object.assign(assignHuser, { [r?.key || 'cs']: value.assignHuserId }));
      }

      if (name === 'selectedStatusId') {
        setValue('assignHuserId', assignHuser[r?.key || 'cs']);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, assignHuser, setValue]);

  return (
    <FormProvider {...methods}>
      <div>
        {contextHolder}
        <Spin spinning={isFetching || isLoading}>
          <div className="bg-white p-1 h-[180px] overflow-auto text-[#000]">
            <div className="flex items-center justify-between">
              <div className="text-default font-bold">{data?.crmBoardItemSummary.patient.name}</div>
            </div>
            <div className="mt-3 text-xs">
              {data?.crmBoardItemSummary.history.map(({ id, createdAt, memo }) => (
                <div key={id} className="mb-2 last:mb-0">
                  <div>{dayjs(createdAt).format('YYYY-MM-DD')}</div>
                  <span>{memo}</span>
                </div>
              ))}
            </div>
          </div>
        </Spin>
        <div className="mb-1 bg-white">
          <div className="flex">
            <Controller
              name="selectedStatusId"
              control={control}
              rules={{ required: true }}
              // render={({ field }) => (
              //   <Select
              //     {...field}
              //     status={errors.selectedStatusId && 'error'}
              //     options={statusList.map(({ id, status }) => ({ value: id, label: status }))}
              //     className="w-[50%] mr-2"
              //     placeholder="내원상태 변경2"
              //     listHeight={180}
              //   />
              // )}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    value={{ '21': '내원예약', '28': '진료예약' }[field.value] || field.value}
                    status={errors.selectedStatusId && 'error'}
                    options={statusList.map(({ id, status }) => ({ value: id, label: status }))}
                    className="w-[50%] mr-2"
                    placeholder="내원상태 변경2"
                    listHeight={180}
                  />
                );
              }}
            />
            <Controller
              name="assignHuserId"
              control={control}
              // rules={{ required: true }}
              render={({ field }) => {
                const { selectedStatusId } = getValues();
                const type = AssignHuerFilter.find((f) => {
                  const reg = new RegExp(selectedStatusId, 'gi');
                  return f.validStatusId.match(reg);
                });

                return (
                  <Select
                    {...field}
                    status={errors.assignHuserId && 'error'}
                    options={[{ id: '', name: '미지정', type: 99, position: null }, ...hUserList]
                      .filter((hUser) => hUser.type === type?.huserType || hUser.type === 99)
                      .map(({ id, name, position }) => ({
                        value: id,
                        label: `${position ? `[${position}]` : ''} ${name}`,
                      }))}
                    className="w-[50%]"
                    placeholder="담당자 지정"
                    listHeight={180}
                  />
                );
              }}
            />
          </div>
          <div className="flex items-center justify-between mt-2">
            <div className=" font-bold">{statusMap[statusId]}</div>
            <div>
              <Button type="primary" onClick={handleSubmit(onSubmit)}>
                적용:dt
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}
