import { atom, selector, selectorFamily } from 'recoil';

import { CrmStatus } from 'src/types/status.types';

type OttaStatus = CrmStatus[];

const initialState = [] as CrmStatus[];

export const statusState = atom<OttaStatus>({
  key: 'statusState',
  default: initialState,
});

export const getStatusNameMapState = selector({
  key: 'getStatusNameMapState',
  get: ({ get }) => {
    const statusList = get(statusState);
    return Object.fromEntries(statusList.map(({ id, status }) => [id, status]));
  },
});

export const getStatusColorMapState = selector({
  key: 'getStatusColorMapState',
  get: ({ get }) => {
    const statusList = get(statusState);
    return Object.fromEntries(statusList.map(({ id, color }) => [id, color]));
  },
});

export const getStatusMapProcessIdState = selector({
  key: 'getStatusMapProcessIdState',
  get: ({ get }) => {
    const statusList = get(statusState);
    return Object.fromEntries(statusList.map(({ id, statusMapId }) => [id, statusMapId]));
  },
});

type Group =
  | 'process'
  | 'call'
  | 'board/context'
  | 'issue/cc'
  | 'issue/cc/chip'
  | 'book'
  | 'consult'
  | 'pay';

export const getStatusListByGroupStatus = selectorFamily({
  key: `getStatusListByGroupStatus`,
  get:
    (group: Group) =>
    ({ get }) => {
      const statusList = get(statusState);
      return statusList.filter((status) => status.group === group);
    },
});
