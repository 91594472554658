import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Spin, Tag, notification, Avatar, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useSetRecoilState } from 'recoil';

import { updateCrmIssueStatusAPI } from 'src/api/issue.api';
import useHuserList from 'src/hooks/useHuserList';
import useIssueList from 'src/hooks/useIssueList';
import { getAvatarPath } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { csState } from 'src/recoil/cs.recoil';
import { CrmIssue } from 'src/types/issue.types';

type IssueList = {
  colId: number;
  list: CrmIssue[];
}[];

function handleKey(colId: number, type: 'status' | 'color') {
  switch (colId) {
    case 1:
      return type === 'status' ? '신규CS' : '#FFB547';
    case 2:
      return type === 'status' ? '처리중' : '#3965FF';
    case 3:
      return type === 'status' ? '처리완료' : '#01B574';
  }
}

// cs/issue kanban
export default function DragBoard() {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const setCsState = useSetRecoilState(csState);

  const { issueList, isFetching } = useIssueList();
  const { getUserById, hUserList } = useHuserList();

  const updateCrmIssueStatus = useMutation(updateCrmIssueStatusAPI, {
    onSuccess: (res) => {
      api.success({ message: '상태변경 성공' });
      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_ISSUE_LIST]);
    },
    onError: () => {
      api.error({ message: '상태변경 실패' });
    },
  });

  const onDragEnd = ({ source, destination, draggableId }: DropResult) => {
    if (!destination) return;

    const isSameStatus = source.droppableId === destination.droppableId;
    const isSameIndex = source.index === destination.index;
    if (isSameStatus && isSameIndex) return;

    const copyIssueList: IssueList = JSON.parse(JSON.stringify(issueList));

    if (isSameStatus) {
      const _issueList = copyIssueList.find(
        (issue) => issue.colId === Number(destination.droppableId)
      )!;
      const [targetItem] = _issueList.list.splice(source.index, 1);
      _issueList.list.splice(destination.index, 0, targetItem);
      const orderIdList = _issueList.list.map(({ id }) => id);

      updateCrmIssueStatus.mutate({
        order: [{ colId: Number(destination.droppableId), order: orderIdList }],
      });
    } else {
      const _sourceIssueList = copyIssueList.find(
        (issue) => issue.colId === Number(source.droppableId)
      )!;
      const _targetIssueList = copyIssueList.find(
        (issue) => issue.colId === Number(destination.droppableId)
      )!;
      const [targetItem] = _sourceIssueList.list.splice(source.index, 1);
      _targetIssueList.list.splice(destination.index, 0, targetItem);
      const sourceIdList = _sourceIssueList.list.map(({ id }) => id);
      const targetIdList = _targetIssueList.list.map(({ id }) => id);

      updateCrmIssueStatus.mutate({
        col: { id: draggableId, colId: Number(destination.droppableId) },
        order: [
          {
            colId: Number(source.droppableId),
            order: sourceIdList,
          },
          { colId: Number(destination.droppableId), order: targetIdList },
        ],
      });
    }
  };

  const onClickItem = (item: CrmIssue) => {
    setCsState((prev) => ({
      ...prev,
      itemModal: { id: item.id, authorId: item.authorId, visible: true },
    }));
  };

  return (
    <Spin spinning={isFetching}>
      {contextHolder}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="grid flex-1 select-none grid-cols-3 gap-4 rounded-lg">
          {issueList.map(({ colId, list }) => (
            <Droppable key={colId} droppableId={colId.toString()}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="flex flex-col rounded-lg bg-white p-4 shadow-lg"
                >
                  <div className="mb-5">
                    <div className="text-[#2B3674] text-xl font-bold">
                      {handleKey(colId, 'status')}
                    </div>
                  </div>
                  {list.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={[
                            'rounded-lg bg-white p-4 shadow-lg mb-3 last:mb-0',
                            snapshot.isDragging ? 'opacity-95' : 'opacity-100',
                          ].join(' ')}
                          onClick={() => onClickItem(item)}
                        >
                          <div className="mb-3 flex items-center justify-between">
                            <div className="text-[#2B3674] font-bold leading-6">{item.title}</div>
                            <div className="text-[#999]">
                              {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}
                            </div>
                          </div>

                          {item.image.length > 0 && (
                            <div className="mb-3">
                              <div className="rounded-lg max-h-[280px] overflow-hidden">
                                <img
                                  className="w-full h-full object-cover"
                                  src={item.image[0].url}
                                />
                              </div>
                            </div>
                          )}
                          <div className="text-sm text-[#666] leading-5">{item.content}</div>
                          <div className="mt-5 flex items-center justify-between">
                            <div>
                              {item.cc.length > 0 && (
                                <div className="mt-2">
                                  <Avatar.Group
                                    maxCount={3}
                                    maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                                    size="small"
                                  >
                                    {item.cc.map((v) => (
                                      <Tooltip
                                        placement="top"
                                        title={
                                          getUserById(v.ccHuserId)
                                            ? getUserById(v.ccHuserId).name
                                            : ''
                                        }
                                        key={v.id}
                                      >
                                        <Avatar
                                          src={getAvatarPath(getUserById(v.ccHuserId).avatar)}
                                          size="small"
                                        />
                                      </Tooltip>
                                    ))}
                                  </Avatar.Group>
                                </div>
                              )}
                            </div>
                            <Tag color={handleKey(item.colId, 'color')}>
                              {handleKey(item.colId, 'status')}
                            </Tag>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    </Spin>
  );
}
