import { Button, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import StatusCircle from '../StatusCircle';
import { reservationFilterState } from 'src/recoil/reservationFilter.recoil';
import { getStatusListByGroupStatus } from 'src/recoil/status.recoil';

const tabList = ['전체예약', '상담현황', '진료현황'] as const;

export default function ReservationFilter() {
  const [filter, setFilter] = useRecoilState(reservationFilterState);
  const statusList = useRecoilValue(getStatusListByGroupStatus('process'));
  const results = [...statusList].sort((a, b) => a.order - b.order);

  const onChangePicker = (dateString: string) => {
    setFilter((prev) => ({ ...prev, selectedDate: dateString }));
  };

  const onClickTab = (tab: '전체예약' | '상담현황' | '진료현황') => {
    setFilter((prev) => ({ ...prev, tab }));
  };

  const onClickAddPatient = () => {
    setFilter((prev) => ({ ...prev, showAddPatientDrawer: true }));
  };

  return (
    <div className="flex items-center justify-between">
      <div>
        <DatePicker
          locale={locale}
          // disabledDate={(current) => current > dayjs()}
          value={dayjs(filter.selectedDate)}
          allowClear={false}
          onChange={(_, dateString) => onChangePicker(dateString as string)}
        />
        <Button className="ml-2" onClick={onClickAddPatient}>
          환자추가
        </Button>
      </div>
      <div>
        {tabList.map((label) => {
          const isCurrentTab = filter.tab === label;
          return (
            <Button
              key={label}
              className="mr-1"
              type={isCurrentTab ? 'primary' : 'default'}
              onClick={() => onClickTab(label)}
            >
              {label}
            </Button>
          );
        })}
      </div>
      <div className="flex">
        {results
          .filter((o) => !'콜,예약대기,취소,결번,미내원(진료),예약(진료)'.includes(o.status))
          .map((value) => (
            <div key={value.id} className="flex flex-col items-center w-16">
              <StatusCircle bg={value.color.split(',')[0]} />
              <div
                className="text-[10px] font-bold mt-1"
                style={{ color: value.color.split(',')[1] }}
              >
                {value.status.replace(/\((상담|진료)\)/gi, '')}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
