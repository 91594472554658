export const queryCrmList = `
  query CrmList($status: Int) {
    crmList(status: $status) {
      id
      patient {
        id
        name
        phone
        address
        age
        gender
      }
      hospitalId
      register_number
      referral
      therapy
      is_new_visit
      tmId
      csId
      drId
      memo
      paid {
        amount
        type
      }
      status {
        id
        status
        contents {
          id
          title
          memo
          assignHuserId
          startIime
          endIime
          cc
          status
        }
      }
    }
  }
`;

export const queryCrmListWithHistory = `
query($filter: CrmListFilter) {
  crmListWithHistory(filter: $filter) {
      id
      hospitalId
      patient {
          id
          name
          phone
          address
          birth
          gender
      }
      status{
          id
          crmStatusId
          createdAt
          contents {
              id
              assignHuserId
              hospitalId
              title
              memo
              startTime
              endTime
              isDelete
              createdAt
          }
      }
      registerNumber
      referral
      therapy
      isNewVisit
      tmId
      csId
      drId
  }
}
`;

export const queryCrmItem = `
  query($id: ID!) {
    crmItem(id: $id) {
        id
        hospitalId
        paid {
            id
            pay
            status
            memo
            crmPatientId
            crmListId
            huserId
        }
        patient {
            id
            name
            phone
            address
            birth
            gender
        }
        status{
            id
            crmStatusId
            crmListId
            createdAt
            contents {
                id
                assignHuserId
                hospitalId
                title
                memo
                startTime
                endTime
                isDelete
                createdAt
            }
        }
        registerNumber
        referral
        therapy
        isNewVisit
        tmId
        csId
        drId
        consultType
        treatType
        isFresh
        tags
        memo
    }
  }
`;

export const queryCrmItemWithHistory = `
  query CrmItemWithHistory($filter: Filter) {
    crmItemWithHistory(filter: $filter) {
      id
      patient {
        id
        name
        phone
        address
        age
        gender
      }
      hospitalId
      register_number
      referral
      therapy
      is_new_visit
      tmId
      csId
      drId
      paid {
        amount
        type
      }
      status {
        id
        status
        contents {
          id
          title
          memo
          assignHuserId
          startIime
          endIime
          cc
          status
        }
      }
    }
  }
`;

export const queryUpdateCrmItem = `
  mutation UpdateCrmItem($input: UpdateCrmItemInput!) {
    updateCrmItem(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryUpdateCrmItemStatus = `
  mutation UpdateCrmItemStatus($input: Input) {
    id
  }
`;

export const queryCrmCallList = `
query($filter: CrmCallListFilter){
  crmCallList(filter: $filter) {
      id
      hospitalId
      callCount
      latestMemo
      latestStatusId
      patient {
          id
          name
          phone
          address
          birth
          gender
          registerNumber
      }
      registerNumber
      referral
      therapy
      isNewVisit
      consultType
      treatType
      tmId
      csId
      drId
      tags
      isFresh
      memo
      createdAt
      paid {
        status
      }
      etc {
        question {
          answer1
          answer2
          answer3
        }
      }
  }
}
`;

export const queryUpdateCallHistoryStatus = `
  mutation UpdateCallHistoryStatus($input: Input) {
    updateCallHistoryStatus(input: $input) {
      ok
    }
  }
`;

export const queryAddCrmRefToHistory = `
  mutation AddCrmRefToHistory($input: Input) {
    addCrmRefToHistory(input: $input) {
      ok
    }
  }
`;

export const queryAddCrmCallReserve = `
  mutation AddCrmCallReserve($input: Input) {
    addCrmCallReserve(input: $input) {
      ok
    }
  }
`;

// TODO:
export const queryUpdateCrmCallStatus = `
  mutation UpdateCrmCallStatus($input: Input) {
    updateCrmCallStatus(input: $input) {
      ok
    }
  }
`;

export const queryRemoveCrmListHistory = `
  mutation RemoveCrmListHistory($input: Input) {
    removeCrmListHistory(input: $input) {
      ok
    }
  }
`;

export const queryAddCrmCallHistory = `
  mutation AddCrmCallHistory($input: AddCrmHistoryInput!) {
    addCrmCallHistory(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryAddCrmHistoryCc = `
  mutation AddCrmHistoryCc($input: AddCrmHistoryCcInput!) {
    addCrmHistoryCc(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryRemoveCrmHistoryCc = `
  mutation RemoveCrmHistoryCc($input: RemoveCrmHistoryCcInput!) {
    removeCrmHistoryCc(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryGetCrmHistory = `
  query($filter: CrmHistoryFilter){
    crmHistory(filter: $filter) {
      id
      assignHuserId
      hospitalId
      title
      memo
      startTime
      endTime
      isDelete
      createdAt
      statusId
      cc {
        ccHuserId
      }
    }
  }
`;

export const queryUpdateAssignHuserCrmItem = `
  mutation AssignHuserCrmItem($input: AssignHuserCrmItemInput!) {
    assignHuserCrmItem(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryAddCrmItemHistory = `
  mutation AddCrmItemHistory($input: AddCrmHistoryInput!) {
    addCrmItemHistory(input: $input) {
        ok
        error {
            path
            message
        }
    }
  }
`;

export const queryUpdateCrmItemHistory = `
  mutation UpdateCrmItemHistory($input: UpdateCrmItemHistoryInput!) {
    updateCrmItemHistory(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const queryRemoveCrmItemHistory = `
  mutation RemoveCrmItemHistory($input: RemoveCrmItemHistoryInput!) {
    removeCrmItemHistory(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const queryCrmConsultList = `
query($filter: CrmConsultListFilter){
  crmConsultList(filter: $filter) {
      id
      hospitalId
      latestVisitDate
      latestMemo
      latestStatusId
      latestContentStatusId
      patient {
          id
          name
          phone
          address
          birth
          gender
          registerNumber
      }
      registerNumber
      referral
      therapy
      isNewVisit
      tmId
      csId
      drId
      consultType
      treatType
      memo
  }
}
`;

export const queryCrmPaidList = `
  query($filter: CrmPaidFilter){
    crmPaidList(filter: $filter) {
        id
        hospitalId
        huserId
        crmListId
        crmPatientId
        pay
        paySum
        memo
        isDelete
        status
        createdAt
        history {
            id
            createdAt
            hospitalId
            huserId
            crmPaidId
            amount
            type
            memo
            option
            isDelete
        }
    }
  }
`;

export const queryAddCrmPaid = `
  mutation($input: AddCrmPaidInput){
    addCrmPaid(input: $input) {
        ok
    }
  }
`;

export const queryUpdateCrmPaid = `
  mutation($input: updateCrmPaidInput){
    updateCrmPaid(input: $input) {
        ok
    }
  }
`;

export const queryRemoveCrmPaid = `
  mutation($input: removeCrmPaidInput){
    removeCrmPaid(input: $input) {
        ok
    }
  }
`;

export const queryCrmBoardList = `
  query($filter: CrmListFilter) {
    crmBoardList(filter: $filter) {
        group
        data {
            id
            crmListId
            hospitalId
            latestContentId
            isCurrent
            statusId
            crmStatusId
            assignHuserId
            hospitalId
            title
            memo
            latestCallMemo
            startTime
            endTime
            isDelete
            createdAt
            patient {
                id
                name
                phone
                address
                birth
                gender
            }
            registerNumber
            referral
            therapy
            isNewVisit
            tmId
            csId
            drId
            nrc
            tags
        }

    }
  }
`;

export const queryCrmBoardItemSummary = `
  query($filter: CrmListIdFilter) {
    crmBoardItemSummary(filter: $filter) {
        id
        registerNumber
        referral
        therapy
        isNewVisit
        tmId
        csId
        drId
        patient {
            id
            name
            phone
            address
            birth
            gender
        }
        history {
            id
            crmStatusId
            assignHuserId
            title
            memo
            startTime
            endTime
            createdAt
            updatedAt
            statusId
        }
    }
  }
`;

export const queryCrmManageList = `
  query($filter: CrmListFilter) {
    crmManageList(filter: $filter) {
        id
        latestStatusId
        tmId
        csId
        drId
        latestMemo
        registerNumber
        isNewVisit
        startTime
        endTime
        twTime
        patient {
            id
            name
            phone
            address
            birth
            gender
            registerNumber
        }
        paid {
            id
        }
    }
  }
`;

export const queryCrmVisitList = `
  query($filter: CrmListFilter) {
    crmVisitList(filter: $filter) {
        id
        patient {
            id
            name
            phone
            gender
            registerNumber
            address
        }
        isVisit
        latestVisitDate
        latestCrmStatusId
        latestCallMemo
        latestCallDate
        csId
        tmId
    }
  }
`;

export const queryUpdateCrmBoardItem = `
mutation($input: UpdateCrmBoardItemInput) {
  updateCrmBoardItem(input: $input) {
      ok
  }
}
`;

export const queryCrmPatientCallHistory = `
query($id: ID!) {
  crmPatientCallHistory(id: $id) {
      id
      hospitalId
      status{
          id
          crmStatusId
          createdAt
          contents {
              id
              statusId
              assignHuserId
              hospitalId
              title
              memo
              startTime
              endTime
              isDelete
              createdAt
              authorId
              nrc
              cc {
                id
                crmContentId
                huserId
              }
          }
      }
      registerNumber
      referral
      therapy
      isNewVisit
      tmId
      csId
      drId
      tags
      createdAt
  }
}
`;

export const queryCrmPatientConstultHistory = `
query($id: ID!) {
  crmPatientConsultHistory(id: $id) {
      id
      hospitalId
      status{
          id
          crmStatusId
          createdAt
          contents {
              id
              statusId
              assignHuserId
              hospitalId
              title
              memo
              startTime
              endTime
              isDelete
              createdAt
              authorId
              nrc
              cc {
                id
                crmContentId
                huserId
              }
          }
      }
      registerNumber
      referral
      therapy
      isNewVisit
      tmId
      csId
      drId
      tags
      createdAt
  }
}
`;

export const queryAddCrmPaidHistory = `
  mutation($input: AddCrmPaidHistoryInput){
    addCrmPaidHistory(input: $input) {
        ok
    }
  }
`;

export const queryUpdateCrmPaidHistory = `
mutation($input: updateCrmPaidHistoryInput){
  updateCrmPaidHistory(input: $input) {
      ok
  }
}
`;

export const queryRemoveCrmPaidHistory = `
mutation($input: removeCrmPaidHistoryInput){
  removeCrmPaidHistory(input: $input) {
      ok
  }
}
`;

export const queryAddCrmDeposit = `
  mutation($input: AddCrmDepositInput){
    addCrmDeposit(input: $input) {
      ok
    }
  }
`;

export const queryAddCrmItemByPatientIds = `
mutation($input: AddCrmItemByPatientIdsInput){
  addCrmItemByPatientIds(input: $input) {
      ok
  }
}
`;
