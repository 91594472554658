import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import InfoBox from './InfoBox';
import { updateCrmHuserInfoAPI, updateCrmHuserScheduleAPI } from 'src/api/hospital.api';
import useHuserList from 'src/hooks/useHuserList';
import { PART_LIST, QUERY_KEYS } from 'src/libs/constants';

type Huser = {
  name: string;
  department: string;
  position: string;
  phone: string;
};

export default function EditAccount() {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [isEdit, setIsEdit] = useState(false);
  const { selectedUser } = useHuserList();
  const { control, handleSubmit, reset } = useForm<Huser>({
    values: {
      name: selectedUser.name,
      department: selectedUser.department,
      position: selectedUser.position,
      phone: selectedUser.phone,
    },
  });

  const updateHuser = useMutation(updateCrmHuserInfoAPI, {
    onSuccess: () => {
      api.success({ message: '수정이 완료되었습니다.' });
      return queryClient.invalidateQueries([QUERY_KEYS.GET_HUSER_LIST]);
    },
    onError: () => {
      api.error({ message: '수정이 실패했습니다.' });
    },
    onSettled: () => {
      setIsEdit(false);
      reset();
    },
  });

  const updateHuserDayOff = useMutation(updateCrmHuserScheduleAPI, {
    onSuccess: () => {
      api.success({ message: '수정이 완료되었습니다.' });
      return queryClient.invalidateQueries([QUERY_KEYS.GET_HUSER_LIST]);
    },
    onError: () => {
      api.error({ message: '수정이 실패했습니다.' });
    },
    onSettled: () => {
      // reset();
    },
  });

  useEffect(() => {
    setIsEdit(false);
  }, [selectedUser, reset]);

  const onClickEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const onClickCancel = () => {
    setIsEdit(false);
  };

  const onClickSetDayOff = ({}) => {
    const { id, isDayOff } = selectedUser;

    updateHuserDayOff.mutate({
      huserId: id,
      isDayOff: !isDayOff,
    });
  };

  const onClickDelete = () => {
    console.log('삭제');
  };

  const onSubmit: SubmitHandler<Huser> = (data) => {
    const { id } = selectedUser;
    const payload = { id, ...data };
    updateHuser.mutate(payload);
  };

  const getDepartment = (department: string) => {
    // const [display] = PART_LIST.filter(({ value }) => value === department);

    // if (display) {
    //   return display.label;
    // }

    return department;
  };

  return (
    <div className="flex-1 mx-10">
      {contextHolder}
      <div className="mb-3 text-[#2B3674] font-bold">정보수정</div>
      <div>
        <InfoBox title="이름" className="mb-5">
          {isEdit ? (
            <Controller
              name="name"
              control={control}
              render={({ field }) => <Input {...field} placeholder="이름을 입력하세요" />}
            />
          ) : (
            <div className="text-[#2B3674] font-bold min-h-[32px] leading-[32px]">
              {selectedUser.name}
            </div>
          )}
        </InfoBox>
        <InfoBox title="직급" className="mb-5">
          {isEdit ? (
            <Controller
              name="department"
              control={control}
              render={({ field }) => <Input {...field} placeholder="파트를 입력하세요" />}
            />
          ) : (
            <div className="text-[#2B3674] font-bold min-h-[32px] leading-[32px]">
              {getDepartment(selectedUser.department)}
            </div>
          )}
        </InfoBox>
        <InfoBox title="파트" className="mb-5">
          {isEdit ? (
            <Controller
              name="position"
              control={control}
              render={({ field }) => <Input {...field} placeholder="직급을 입력하세요" />}
            />
          ) : (
            <div className="text-[#2B3674] font-bold min-h-[32px] leading-[32px]">
              {selectedUser.position}
            </div>
          )}
        </InfoBox>
        <InfoBox title="연락처" className="mb-5">
          {isEdit ? (
            <Controller
              name="phone"
              control={control}
              render={({ field }) => <Input {...field} placeholder="연락처를 입력하세요" />}
            />
          ) : (
            <div className="text-[#2B3674] font-bold min-h-[32px] leading-[32px]">
              {selectedUser.phone}
            </div>
          )}
        </InfoBox>
        <div className="mt-5 flex justify-center items-center">
          {isEdit ? (
            <>
              <Button onClick={onClickCancel}>
                <div className="text-[#A3AED0] font-bold">취소</div>
              </Button>
              <Button
                className="bg-[#3965FF] ml-5"
                onClick={handleSubmit(onSubmit)}
                disabled={updateHuser.isLoading}
              >
                <div className="text-[#F5F5F5] font-bold">저장</div>
              </Button>
            </>
          ) : (
            <>
              <Button className="bg-[#3965FF]" onClick={onClickSetDayOff}>
                <div className="text-[#F5F5F5] font-bold">
                  {selectedUser.isDayOff ? '휴무취소' : '휴무지정'}
                </div>
              </Button>
              <Button className="bg-[#3965FF]" onClick={onClickEdit}>
                <div className="text-[#F5F5F5] font-bold">수정하기</div>
              </Button>
              {/* <Button className="ml-5" danger onClick={onClickDelete}>
                삭제하기
              </Button> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
