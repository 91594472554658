import { Select } from 'antd';
import React from 'react';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import { useForm, Controller, SubmitHandler, FormProvider, useFormContext } from 'react-hook-form';
import type { UseFormReturn, FieldValues } from 'react-hook-form';

import useHuserList from 'src/hooks/useHuserList';

interface ConnectFormProps<TFieldValues extends FieldValues> {
  children(children: UseFormReturn<TFieldValues>): ReactElement;
}

const ConnectForm = <TFieldValues extends FieldValues>({
  children,
}: ConnectFormProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  return children({ ...methods });
};

type Props = {
  bg: string;
};

// 21:상담예약, 28:진료예약
const AssignHuserFilter = [
  {
    key: 'cs',
    validStatusId: '21,71,72,73',
    huserType: 2,
  },
  {
    key: 'dr',
    validStatusId: '28,74,75,76',
    huserType: 4,
  },
];

const Selector = ({ methods, props: { statusId, assignHuserId, widthType = 1 } }: any) => {
  const { watch, setValue, control, getValues, formState } = methods;
  const { hUserList } = useHuserList();
  const [assignHuser, setAssignHuser] = useState(
    // { cs: "", dr: ""}
    Object.assign(
      {},
      ...AssignHuserFilter.map((o) => {
        return {
          [o.key]: o.validStatusId.includes(statusId) ? assignHuserId : '',
        };
      })
    )
  );

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  useEffect(() => {
    // 71,72,73 상담
    // 74,75,76 진료

    const subscription = watch((value: any, { name, type }: any) => {
      const r = AssignHuserFilter.find((f) => {
        return f.validStatusId.includes(value.selectedStatusId || value.statusId || '');
      });
      if (name === 'assignHuserId') {
        setAssignHuser(Object.assign(assignHuser, { [r?.key || 'cs']: value.assignHuserId }));
      }

      if (name === 'selectedStatusId' || name === 'statusId') {
        setValue('assignHuserId', assignHuser[r?.key || 'cs']);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, assignHuser, setValue, statusId]);

  return (
    <Controller
      name="assignHuserId"
      control={control}
      // rules={{ required: true }}
      render={({ field }) => {
        const a = getValues();

        const { statusId } = getValues();

        const type = AssignHuserFilter.find((f) => {
          const reg = new RegExp(statusId, 'gi');
          return f.validStatusId.match(reg);
        });

        return (
          <Select
            {...field}
            status={formState.errors.assignHuserId && 'error'}
            options={[{ id: '', name: '미지정', type: 99, position: null }, ...hUserList]
              .filter((hUser) => hUser.type === type?.huserType || hUser.type === 99)
              .map(({ id, name, position }) => ({
                value: id,
                label: `${position ? `[${position}]` : ''} ${name}`,
              }))}
            className={`w-[${[0, '150px', '50%'][widthType]}]`}
            placeholder="담당자 지정"
            listHeight={180}
          />
        );
      }}
    />
  );
};

export default function AssignSelector({ bg, statusId, assignHuserId }: any) {
  return (
    <ConnectForm>
      {/* {({ register, control, formState, getValues, ...others }) => ( */}
      {(methods) => (
        <Selector methods={methods} props={{ assignHuserId, statusId }} />
        // <Controller
        //   name="assignHuserId"
        //   control={control}
        //   // rules={{ required: true }}
        //   render={({ field }) => {
        //     const { selectedStatusId } = getValues();
        //     const type = AssignHuerFilter.find((f) => {
        //       const reg = new RegExp(selectedStatusId, 'gi');
        //       return f.validStatusId.match(reg);
        //     });

        //     return (
        //       <Select
        //         {...field}
        //         status={formState.errors.assignHuserId && 'error'}
        //         options={[{ id: '', name: '미지정', type: 99, position: null }, ...hUserList]
        //           .filter((hUser) => hUser.type === type?.huserType || hUser.type === 99)
        //           .map(({ id, name, position }) => ({
        //             value: id,
        //             label: `${position ? `[${position}]` : ''} ${name}`,
        //           }))}
        //         className="w-[50%]"
        //         placeholder="담당자 지정"
        //         listHeight={180}
        //       />
        //     );
        //   }}
        // />
      )}
    </ConnectForm>
  );
}
