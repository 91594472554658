import axios, { AxiosInstance } from 'axios';

import TokenManager from 'src/libs/TokenManager';
import { ApiResponse } from 'src/types/response';

class Http {
  instance: AxiosInstance;
  private readonly baseUrl = `${process.env.REACT_APP_API_URL}`;
  private isTokenRefreshing = false;
  private failedTaskQueue: ((accessToken: string) => void)[] = [];

  constructor() {
    this.instance = axios.create({ baseURL: this.baseUrl, timeout: 5000 });
    this.addRequestInterceptor();
    this.addResponseInterceptor();
  }

  private addRequestInterceptor() {
    this.instance.interceptors.request.use(async (request) => {
      const token = TokenManager.getToken();
      request.headers['Authorization'] = token;
      return request;
    });
  }

  private addResponseInterceptor() {
    this.instance.interceptors.response.use(
      (response) => {
        if (response.data.errors) {
          const loginError = response.data.errors.some(
            (err: any) => err.message === 'You must be logged in'
          );
          if (loginError) {
            TokenManager.removeToken();
            window.location.href = '/signin';
          }
        }
        return response;
      },
      async (error) => {
        if (error.config.data.includes('query meh')) {
          TokenManager.removeToken();
          window.location.href = '/signin';
          return;
        }
        // 리프레시토큰도 만료
        // if (error.config.url === '/user/refresh') {
        //   localStorage.clear();
        //   return;
        // }

        const { config, response } = error;
        const originalRequest = config;
        console.log('error', error);
        if (error.message === 'Network Error') {
          TokenManager.removeToken();
          window.location.href = '/signin';
        }

        // if (response?.status === 401) {
        //   if (!this.isTokenRefreshing) {
        //     this.isTokenRefreshing = true;
        //     const refreshToken = localStorage.getItem('refreshToken');

        //     const responseRefreshToken = await this.instance.post<
        //       ApiResponse<GetTokenRefreshResponse>
        //     >('/user/refresh', {
        //       token: refreshToken,
        //     });

        //     // expired refreshToken
        //     if (responseRefreshToken.status !== 200) {
        //       localStorage.clear();
        //       this.isTokenRefreshing = false;
        //       return;
        //     }

        //     // save new token
        //     const { accessToken: newAccessToken } = responseRefreshToken.data.data;
        //     this.isTokenRefreshing = false;
        //     this.instance.defaults.headers.common['authorization'] = `Bearer ${newAccessToken}`;

        //     this.resolveFailedTasks(newAccessToken);
        //     return this.instance(originalRequest);
        //   }

        //   return new Promise((resolve) => {
        //     this.addRefreshSubscriber((accessToken: string) => {
        //       originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        //       resolve(this.instance(originalRequest));
        //     });
        //   });
        // }

        this.isTokenRefreshing = false;
        return Promise.reject(error);
      }
    );
  }

  private resolveFailedTasks(accessToken: string) {
    for (const failedTask of this.failedTaskQueue) {
      failedTask(accessToken);
    }
    this.failedTaskQueue = [];
  }

  private addRefreshSubscriber(callback: (accessToken: string) => void) {
    this.failedTaskQueue.push(callback);
  }
}

export default new Http();
