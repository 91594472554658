import { BellOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Badge, Dropdown, MenuProps, Spin } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Logo from '../Sidebar/Logo';
import { getCrmHuserNotificationListAPI } from 'src/api/notification.api';
import ImageLogo from 'src/assets/images/logo2.png';
import useHuserList from 'src/hooks/useHuserList';
import { getAvatarPath } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import TokenManager from 'src/libs/TokenManager';
import { authState } from 'src/recoil/auth.recoil';
import { commonState } from 'src/recoil/common.recoil';

const items: MenuProps['items'] = [
  {
    label: '로그아웃',
    key: '로그아웃',
  },
  // {
  //   label: 'toggle isMaster',
  //   key: 'toggle isMaster',
  // },
];

export default function HeaderLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const setAuth = useSetRecoilState(authState);
  const { loginUser, isFetching } = useHuserList();
  const { currentLabel } = useRecoilValue(commonState);
  const { meh } = useRecoilValue(authState);
  const hUserId = meh.id;

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.GET_NOTIFICATIONS, { hUserId }],
    () => getCrmHuserNotificationListAPI({ huserId: hUserId }),
    {
      enabled: hUserId !== '',
    }
  );

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case '로그아웃':
        TokenManager.removeToken();
        navigate('/signin', { replace: true });
        return;
      case 'toggle isMaster':
        setAuth((prev) => ({ ...prev, meh: { ...prev.meh, isMaster: !prev.meh.isMaster } }));
        return;
      default:
        return;
    }
  };

  return (
    <div className=" flex justify-between items-center h-full">
      <div className="w-40 d-flex">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="http://crm.odoctor.co.kr" className="flex items-center">
            <img src={ImageLogo} className="mr-3 w-40 sm:h-9" alt="Medi connector" />
          </a>
        </div>
      </div>
      <div>
        <div className="text-2xl text-[#2B3674]">{currentLabel}</div>
      </div>
      <Spin spinning={isFetching}>
        <div className="bg-white rounded-full p-1 flex text-xs pl-10 items-center">
          <Dropdown menu={{ items, onClick: handleMenuClick }} placement="bottom" arrow>
            <div className="w-40 mr-1 cursor-pointer">
              <div className="text-sm font-bold text-[#2B3674]">{loginUser.name}</div>
              <div className="text-[#A3AED0]">{loginUser.email}</div>
            </div>
          </Dropdown>
          <div className="w-9 aspect-square flex justify-center items-center">
            <Badge size="small" count={data?.crmHuserNotifications.length} offset={[2, 2]}>
              <BellOutlined className="text-[20px] text-[#A3AED0]" />
            </Badge>
          </div>
          {/* <div className="w-9 aspect-square flex justify-center items-center">
            <InfoCircleOutlined className="text-[20px] text-[#A3AED0]" />
          </div> */}
          <img
            src={getAvatarPath(loginUser.avatar)}
            className="w-9 aspect-square rounded-full ml-3"
          />
        </div>
      </Spin>
    </div>
  );
}
