import React, { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import ReservationFilter from 'src/components/Filter/ReservationFilter';
import AddPatientDrawer from 'src/components/Reservation/AddPatientDrawer';
import AllReservation from 'src/components/Reservation/AllReservation';
import ClinicStatus from 'src/components/Reservation/ClinicStatus';
import ConsultStatus from 'src/components/Reservation/ConsultStatus';
import { reservationFilterState } from 'src/recoil/reservationFilter.recoil';

export default function Reservation() {
  const { tab } = useRecoilValue(reservationFilterState);
  const resetReservation = useResetRecoilState(reservationFilterState);

  const renderOutlet = () => {
    switch (tab) {
      case '전체예약':
        return <AllReservation />;
      case '상담현황':
        return <ConsultStatus />;
      case '진료현황':
        return <ClinicStatus />;
      default:
        return null;
    }
  };

  useEffect(() => {
    return () => {
      resetReservation();
    };
  }, [resetReservation]);

  return (
    <div>
      <div>
        <ReservationFilter />
      </div>
      <div className="mt-5">{renderOutlet()}</div>
      <AddPatientDrawer />
    </div>
  );
}
