import { Empty, Spin } from 'antd';
import React from 'react';

import EditableCallHistoryCard from './EditableCallHistoryCard';
import usePatientHistory from 'src/hooks/usePatientHistory';

type Props = { patientId: string };

export default function CallHistory({ patientId }: Props) {
  const { getStatusItemList, isFetching } = usePatientHistory(patientId);
  const callHistoryList = getStatusItemList('1', true);

  return (
    <Spin spinning={isFetching}>
      <div className="min-h-[320px]">
        <div className="text-[#768396] font-bold text-base mb-1">콜 내역</div>
        {callHistoryList.length === 0 && (
          <Empty description="콜 내역이 없습니다." className="mt-16" />
        )}
        {callHistoryList.map((callHistory) =>
          callHistory.contents.map((content) => (
            <EditableCallHistoryCard key={content.id} {...content} />
          ))
        )}
      </div>
    </Spin>
  );
}
