import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, notification } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { addCrmItemByPatientIdsAPI } from 'src/api/crm.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { manageDBState } from 'src/recoil/manageDB.recoil';

export default function InputForm() {
  const { patientId } = useParams<{ patientId: string }>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [{ startDate, endDate, isVisit }, setFilter] = useRecoilState(manageDBState);

  const addCrmItemByPatientIds = useMutation(addCrmItemByPatientIdsAPI, {
    onSuccess: (res) => {
      api.success({ message: '콜대기 이동 성공' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_VISIT_LIST,
        { startDate, endDate, isVisit },
      ]);
    },
    onError: () => {
      api.error({ message: '콜대기 이동 실패' });
    },
    onSettled: () => {
      navigate(-1);
    },
  });

  const onClickMove = () => {
    addCrmItemByPatientIds.mutate({ patientIds: [patientId || ''] });
  };

  return (
    <div className="mt-5">
      {contextHolder}
      <Button type="primary" className="w-full" onClick={onClickMove}>
        콜대기 이동
      </Button>
    </div>
  );
}
