import { atom } from 'recoil';

type State = {
  showAddItemModal: boolean;
  itemModal: {
    id: string;
    visible: boolean;
    authorId: string;
  };
  backlogModal: {
    id: string;
    visible: boolean;
  };
};

const initialState = {
  showAddItemModal: false,
  itemModal: {
    id: '',
    visible: false,
    authorId: '',
  },
  backlogModal: {
    id: '',
    visible: false,
  },
};

export const csState = atom<State>({
  key: 'csState',
  default: initialState,
});
