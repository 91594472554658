import { Empty, Spin } from 'antd';
import React from 'react';

import EditablePaymentDoneHistoryCard from './EditablePaymentDoneHistoryCard';
import usePaidListById from 'src/hooks/usePaidListById';

type Props = { patientId: string };

export default function WillPayment({ patientId }: Props) {
  const { crmPaidList, isFetching } = usePaidListById(patientId);
  const paidDoneList = crmPaidList.filter((paid) => paid.status === 2 || paid.status === 1);

  return (
    <Spin spinning={isFetching}>
      <div className="min-h-[300px]">
        <div className="flex justify-between items-center mb-4">
          <div className="text-[#768396] font-bold text-base">결제 내역(결제 한 이력)</div>
        </div>
        {paidDoneList.length === 0 && (
          <Empty description="결제 내역(결제 한 이력) 내역이 없습니다." className="mt-16" />
        )}
        {paidDoneList.map((paid) => (
          <EditablePaymentDoneHistoryCard key={paid.id} {...paid} />
        ))}
      </div>
    </Spin>
  );
}
