import { Button, Modal, Select, Upload } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';

import IconUpload from 'src/assets/images/icon-upload.svg';
import { callManagementState } from 'src/recoil/callManagement.recoil';

const { Dragger } = Upload;

export default function ModalUploadDB() {
  const [{ showExcelModal }, setCallManagement] = useRecoilState(callManagementState);

  const onClickCancel = () => {
    setCallManagement((prev) => ({ ...prev, showExcelModal: false }));
  };

  const onClickUpload = () => {
    setCallManagement((prev) => ({ ...prev, showExcelModal: false }));
  };

  return (
    <Modal open={showExcelModal} footer={null} closable={false} width={700}>
      <div className="text-[#2B3674] font-bold text-xl">데이터 베이스 업로드</div>
      <div className="px-5 mt-5">
        <div className="text-[#2B3674] font-bold">* 유의사항</div>
        <div className="text-[#2B3674] font-bold my-1">
          - 파일 확장자는 xlxs 로 통일시켜주세요 , 용량 10MB 이하만 업로드 가능
        </div>
        <div className="text-[#2B3674] font-bold">- 지원가능 (굿닥,강남언니,바비톡,네이버예약)</div>
      </div>

      <div className="mt-10">
        <div className="flex items-center">
          <div className="text-[#2B3674] font-bold text-lg mr-10">파일 업로드</div>
          <div className="text-[#2B3674] font-bold mr-5">플랫폼명</div>
          <Select
            defaultValue="굿닥"
            onChange={(value) => console.log(value)}
            options={[
              { value: '굿닥', label: '굿닥' },
              { value: '강남언니', label: '강남언니' },
              { value: '바비톡', label: '바비톡' },
              { value: '네이버예약', label: '네이버예약' },
            ]}
            className="w-[150px] text-center"
          />
        </div>

        <div className="mt-5">
          <Dragger
            onChange={(info) => console.log('onChange', info)}
            onDrop={(e) => console.log('onDrop', e.dataTransfer.files)}
            beforeUpload={(file) => console.log('before upload', file)}
            action={(file) => {
              console.log('file action', file);
              return '';
            }}
            customRequest={(file) => console.log('custom request', file)}
            // showUploadList={false}
            className="w-full"
          >
            <div className="flex flex-col justify-center items-center py-5">
              <img src={IconUpload} className="w-10 mb-5" />
              <p className="text-[#4318FF] font-bold">Upload File</p>
            </div>
          </Dragger>
        </div>

        <div className="mt-5 flex justify-end">
          <Button size="large" className="mr-3" onClick={onClickCancel}>
            <div className="text-[#2B3674] font-bold">취소</div>
          </Button>
          <Button size="large" className="bg-[#1EA7FF]" onClick={onClickUpload}>
            <div className="text-[#F5F5F5] font-bold">업로드</div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
