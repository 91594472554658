import dayjs from 'dayjs';
import { atom } from 'recoil';

type Filter = {
  startDate: string;
  endDate: string;
  cascaderValue: string[] | number[] | undefined;
  hUserId: string;
  hUserIds: string[] | null;
};

const initialState = {
  startDate: dayjs().subtract(14, 'day').format(),
  endDate: dayjs().format(),
  cascaderValue: undefined,
  hUserId: '',
  hUserIds: null,
};

export const dashboardFilterState = atom<Filter>({
  key: 'dashboardFilterState',
  default: initialState,
});
