import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import EditableScheduleDrawer from './EditableScheduleDrawer';
import GroupSpecified from './GroupSpecified';
import GroupUnSpecified from './GroupUnSpecified';
import useReservation from 'src/hooks/useReservation';
import TimeTableDrawer from 'src/libs/TimeTableDrawer';
import { reservationFilterState } from 'src/recoil/reservationFilter.recoil';

const total = TimeTableDrawer.total;
const timeList = Array.from({ length: total }, (_, i) => 540 + i * 30);

export default function AllReservation() {
  const setReservation = useSetRecoilState(reservationFilterState);

  const { group_cs_null, group_dr_null, group_cs, group_dr, isFetching } = useReservation();

  useEffect(() => {
    return () => {
      setReservation((prev) => ({ ...prev, showDrawer: false }));
    };
  }, [setReservation]);

  return (
    <div className="pb-20">
      <div className="mb-3">
        스케쥴 <span className="font-bold text-[#fe7f2e]">왼쪽</span>클릭 -{' '}
        <span className="text-[#05cd99]">상세</span> / 스케쥴{' '}
        <span className="font-bold text-[#1677ff]">오른쪽</span>클릭 -{' '}
        <span className="text-[#05cd99]">툴팁</span>
      </div>
      <div className="flex pl-[120px] sticky top-4 z-[999]">
        {timeList.map((time) => (
          <div key={time} className="text-sm text-[#768396] flex-1">
            {(time / 60) % 1 === 0 ? time / 60 : ''}
          </div>
        ))}
        <div />
      </div>

      <div className="mt-5">
        <div className="relative">
          <div className="pl-[120px]">
            {timeList.map((time, idx) => {
              if ((time / 60) % 1 === 0) {
                return (
                  <div
                    className="w-[1px] absolute bg-[#000]/5 h-full scale-y-[1.03] z-[50]"
                    key={idx}
                    style={{ left: `${45.71 * idx + 120}px` }}
                  />
                );
              }
              return null;
            })}
          </div>

          <Spin spinning={isFetching}>
            <div className="flex w-full rounded-lg mb-3 bg-white flex-col">
              <GroupUnSpecified group="상담" schedules={group_cs_null} />
            </div>
            <div className="flex w-full rounded-lg mb-3 bg-white flex-col">
              <GroupSpecified group="상담" schedulesByHuserId={group_cs} />
            </div>

            <div className="flex w-full rounded-lg mb-3 bg-white flex-col">
              <GroupUnSpecified group="진료" schedules={group_dr_null} />
            </div>
            <div className="flex w-full rounded-lg mb-3 bg-white flex-col">
              <GroupSpecified group="진료" schedulesByHuserId={group_dr} />
            </div>
          </Spin>
        </div>
      </div>

      <EditableScheduleDrawer />
    </div>
  );
}
