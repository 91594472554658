import React from 'react';

type Props = {
  icon: string;
  label: string;
  value: string | number;
  bg: string;
};

export default function DataCardWithIcon({ icon, label, value, bg }: Props) {
  return (
    <div
      className={`flex items-center w-full rounded-lg text-white px-4 text-sm`}
      style={{ background: bg }}
    >
      <div className="bg-white rounded-full w-12 aspect-square flex justify-center items-center">
        <img src={icon} className="w-6" />
      </div>
      <div className="ml-3">
        <div>{label}</div>
        <div className="text-3xl">{value}</div>
      </div>
    </div>
  );
}
