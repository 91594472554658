export const queryCrmBalance = `
  query crmBalance {
    crmBalance {
      balance
    }
  }
`;

// TODO:
export const queryCrmChargeHistory = `
  query CrmChargeHistory($filter: { hospitalId: Int! }) {
    crmChargeHistory(filter: $filter) {

    }
  }
`;

export const querySendEmail = `
  mutation SendEmail($input: EmailInput!) {
    sendEmail(input: $input) {
      ok
    }
  }
`;

export const queryUploadDb = `
  mutation UploadDb($input: Input) {
    uploadDb(input: $input) {
      ok
    }
  }
`;

export const queryCommunityByTag = `
query($page: PaginationInput) {
  communitByTag(page: $page) {
    rows {
        id
        title
        content
        avatar
        nickname
    }
    page {
          total
          perPage
          currentPage
          lastPage
      }
  }
}
`;

export const querySignS3 = `
  mutation SignS3($input: SignS3Input!) {
    signS3(input: $input) {
      signedRequest
      url
    }
  }
`;
