import { atom, selector } from 'recoil';

export type Item = {
  id: number;
  select: boolean;
  src: string;
  viewCnt: number;
  likeCnt: number;
  replyCnt: number;
  title: string;
  description: string;
  link: string;
  price: number;
  isBenefit: boolean;
  benefitDescription: string;
  priceBefore: number;
  priceAfter: number;
  discountRate: number;
  withVat: boolean;
  onlyDiscountRate: boolean;
  vimeo: string;
};

type State = {
  itemList: Item[];
};

const initialState = {
  itemList: [
    {
      id: 0,
      select: true,
      src: 'https://i.ytimg.com/vi/3dH91cyjKLw/oardefault.jpg?sqp=-oaymwEdCJUDENAFSFWQAgHyq4qpAwwIARUAAIhCcAHAAQY=&rs=AOn4CLCJXF4WPi5cHcXux695CliIFA7VSg',
      viewCnt: 100,
      likeCnt: 22,
      replyCnt: 33,
      title: '제목1',
      description: '내용1',
      link: 'https://www.odoctor.co.kr/community/post/397',
      price: 18000,
      isBenefit: true,
      benefitDescription: '오늘만 할인 - 1',
      priceBefore: 9000,
      priceAfter: 1000,
      discountRate: 50,
      withVat: true,
      onlyDiscountRate: true,
      vimeo:
        'https://player.vimeo.com/video/916777180?controls=0&loop=1&background=1&quality=undefined&app_id=122963&texttrack=undefined',
    },
    {
      id: 1,
      select: false,
      src: 'https://i.ytimg.com/vi/dLgVlW4nSGU/oardefault.jpg?sqp=-oaymwEdCJUDENAFSFWQAgHyq4qpAwwIARUAAIhCcAHAAQY=&rs=AOn4CLBaS4sABHBweznHpLlwO_Y2C_4LEQ',
      viewCnt: 100,
      likeCnt: 22,
      replyCnt: 33,
      title: '제목2',
      description: '내용2',
      link: 'https://www.odoctor.co.kr/community/post/397',
      price: 18000,
      isBenefit: true,
      benefitDescription: '오늘만 할인 - 2',
      priceBefore: 9000,
      priceAfter: 1000,
      discountRate: 50,
      withVat: true,
      onlyDiscountRate: true,
      vimeo:
        'https://player.vimeo.com/video/917009402?controls=0&loop=1&background=1&quality=undefined&app_id=58479',
    },
    {
      id: 2,
      select: false,
      src: 'https://i.ytimg.com/vi/4FvKMFlu7s0/oar2.jpg?sqp=-oaymwEdCJUDENAFSFWQAgHyq4qpAwwIARUAAIhCcAHAAQY=&rs=AOn4CLD8TLC5nUAfE5e8zTJKEtXv89tJQQ',
      viewCnt: 100,
      likeCnt: 22,
      replyCnt: 33,
      title: '제목3',
      description: '내용3',
      link: 'https://www.odoctor.co.kr/community/post/397',
      price: 18000,
      isBenefit: true,
      benefitDescription: '오늘만 할인 - 3',
      priceBefore: 9000,
      priceAfter: 1000,
      discountRate: 50,
      withVat: true,
      onlyDiscountRate: true,
      vimeo:
        'https://player.vimeo.com/video/917010021?controls=0&loop=1&background=1&quality=undefined&app_id=58479',
    },
    // {
    //   id: 3,
    //   select: false,
    //   src: 'https://i.ytimg.com/vi/GQkkHtBD1BM/oardefault.jpg?sqp=-oaymwEdCJUDENAFSFWQAgHyq4qpAwwIARUAAIhCcAHAAQY=&rs=AOn4CLDMbJ1tGKpL_53VQ2Jhqesu-dGfmg',
    //   viewCnt: 100,
    //   likeCnt: 22,
    //   replyCnt: 33,
    //   title: '제목4',
    //   description: '내용4',
    //   link: 'https://www.odoctor.co.kr/community/post/397',
    //   price: 18000,
    //   isBenefit: true,
    //   benefitDescription: '오늘만 할인 - 4',
    //   priceBefore: 9000,
    //   priceAfter: 1000,
    //   discountRate: 50,
    //   withVat: true,
    //   onlyDiscountRate: true,
    // },
  ],
};

export const contentManageState = atom<State>({
  key: 'contentManageState',
  default: initialState,
});

export const getCurrentItemState = selector({
  key: 'getCurrentItemState',
  get: ({ get }) => {
    const { itemList } = get(contentManageState);
    return itemList.filter((item) => item.select);
  },
});
