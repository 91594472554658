import { QuestionCircleOutlined } from '@ant-design/icons';
import { Select, Button } from 'antd';
import React from 'react';
import type { ReactElement } from 'react';
import { useForm, Controller, SubmitHandler, FormProvider, useFormContext } from 'react-hook-form';
import type { UseFormReturn, FieldValues } from 'react-hook-form';

import { CRM_LIST_S_TAG_LIST } from 'src/libs/constants';

interface ConnectFormProps<TFieldValues extends FieldValues> {
  children(children: UseFormReturn<TFieldValues>): ReactElement;
}

const ConnectForm = <TFieldValues extends FieldValues>({
  children,
}: ConnectFormProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();

  return children({ ...methods });
};

type Props = {
  defaultValue: string;
};

const Selector = ({ methods, props: { defaultValue } }: any) => {
  const { watch, setValue, control, getValues, formState } = methods;

  return (
    <Controller
      name="tags"
      control={control}
      // rules={{ required: true }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <div className="flex flex-wrap gap-1" style={{ display: 'none' }}>
          {CRM_LIST_S_TAG_LIST.map((l, idx) => {
            return (
              <Button
                key={idx}
                type={l.value & parseInt(value, 2) ? 'primary' : 'default'}
                onClick={() => {
                  const v = parseInt(value, 2) ^ l.value;

                  onChange(v.toString(2));
                }}
              >
                {/* {l.emoji} */} {l.label}
              </Button>
            );
          })}
        </div>
      )}
    />
  );
};

export default function ListTagsSelector({ defaultValue }: any) {
  return (
    <ConnectForm>
      {/* {({ register, control, formState, getValues, ...others }) => ( */}
      {(methods) => (
        <Selector methods={methods} props={{ defaultValue }} />
        // <Controller
        //   name="assignHuserId"
        //   control={control}
        //   // rules={{ required: true }}
        //   render={({ field }) => {
        //     const { selectedStatusId } = getValues();
        //     const type = AssignHuerFilter.find((f) => {
        //       const reg = new RegExp(selectedStatusId, 'gi');
        //       return f.validStatusId.match(reg);
        //     });

        //     return (
        //       <Select
        //         {...field}
        //         status={formState.errors.assignHuserId && 'error'}
        //         options={[{ id: '', name: '미지정', type: 99, position: null }, ...hUserList]
        //           .filter((hUser) => hUser.type === type?.huserType || hUser.type === 99)
        //           .map(({ id, name, position }) => ({
        //             value: id,
        //             label: `${position ? `[${position}]` : ''} ${name}`,
        //           }))}
        //         className="w-[50%]"
        //         placeholder="담당자 지정"
        //         listHeight={180}
        //       />
        //     );
        //   }}
        // />
      )}
    </ConnectForm>
  );
}
