import { useQuery } from '@tanstack/react-query';

import { getCrmReferralCountAPI } from 'src/api/statistic.api';
import { QUERY_KEYS } from 'src/libs/constants';

export default function usePatient(hospitalId: string, startDate: any, endDate: any) {
  // 유입경로 필터생성
  // 유입경로(n) < 갯수 표시 하기 위해서

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CALL_REFERRAL_COUNT, { hospitalId, startDate, endDate }],
    () =>
      getCrmReferralCountAPI({
        hospitalId,
        startDate,
        endDate,
      })
  );

  return {
    rc: data?.crmReferralCount ?? [],
    isFetching: isFetching || isLoading,
  };
}
