import { Empty, Spin } from 'antd';
import React from 'react';

import EditableWillPaymentHistoryCard from './EditableWillPaymentHistoryCard';
import usePaidListById from 'src/hooks/usePaidListById';

type Props = { patientId: string };

export default function WillPayment({ patientId }: Props) {
  const { crmPaidList, isFetching } = usePaidListById(patientId);
  const willPaymentList = crmPaidList.filter((paid) => paid.status === 0);

  return (
    <Spin spinning={isFetching}>
      <div className="min-h-[300px]">
        <div className="text-[#768396] font-bold text-base mb-4">결제 추가(결제 할 금액)</div>
        {willPaymentList.length === 0 && (
          <Empty description="결제 추가(결제 할 금액) 내역이 없습니다." className="mt-16" />
        )}
        {willPaymentList.map((paid) => (
          <EditableWillPaymentHistoryCard key={paid.id} {...paid} />
        ))}
      </div>
    </Spin>
  );
}
