export const CRM_IMAGE_BUCKET = 'https://odoc-crm-bucket.s3.ap-northeast-2.amazonaws.com/';

export const QUERY_KEYS = {
  GET_MEH: 'GET_MEH',
  GET_BALANCE_HISTORY: 'GET_BALANCE_HISTORY', // 충전 내역 목록
  GET_CURRENT_BALANCE: 'GET_CURRENT_BALANCE', // 현재 잔액
  GET_HUSER_LIST: 'GET_HUSER_LIST', // 병원 유저 리스트
  GET_HOSPITAL_INFO: 'GET_HOSPITAL_INFO', // 병원 기본 정보
  GET_STATUS_LIST: 'GET_STATUS_LIST', //상태 리스트
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS', // 알림 리스트
  GET_CALL_LIST: 'GET_CALL_LIST', // 콜 리스트
  GET_CRM_ITEM_HISTORY_LIST: 'GET_CRM_ITEM_HISTORY_LIST', // crm item history list
  GET_CRM_ITEM: 'GET_CRM_ITEM', // 환자 전체 정보
  GET_CALL_LIST_WITH_HISTORY: 'GET_CALL_LIST_WITH_HISTORY', // 예약관리 view
  GET_CONSULT_LIST: 'GET_CONSULT_LIST', // 상담관리 list
  GET_CRM_PAID_LIST: 'GET_CRM_PAID_LIST', // 결제리스트
  GET_CRM_PATIENT: 'GET_CRM_PATIENT',
  GET_CRM_PATIENT_HISTORY: 'GET_CRM_PATIENT_HISTORY',
  GET_CRM_PATIENT_LIST: 'GET_CRM_PATIENT_LIST',
  GET_CRM_BOARD_LIST: 'GET_CRM_BOARD_LIST',
  GET_CRM_BOARD_ITEM_SUMMARY: 'GET_CRM_BOARD_ITEM_SUMMARY',
  GET_CRM_MANAGE_LIST: 'GET_CRM_MANAGE_LIST',
  GET_CRM_VISIT_LIST: 'GET_CRM_VISIT_LIST',
  GET_COMMUNITY_LIST: 'GET_COMMUNITY_LIST',
  GET_TM_STATISTIC_DAILY: 'GET_TM_STATISTIC_DAILY',
  GET_TM_STATISTIC: 'GET_TM_STATISTIC',
  GET_CS_STATISTIC_DAILY: 'GET_CS_STATISTIC_DAILY',
  GET_CS_STATISTIC: 'GET_CS_STATISTIC',
  GET_DASH_STATISTIC: 'GET_DASH_STATISTIC',
  GET_DASH_STATISTIC_LIST: 'GET_DASH_STATISTIC_LIST',
  GET_CRM_PATIENT_CALL_HISTORY: 'GET_CRM_PATIENT_CALL_HISTORY',
  GET_CRM_PATIENT_CONSULT_HISTORY: 'GET_CRM_PATIENT_CONSULT_HISTORY',
  GET_CRM_ISSUE_LIST: 'GET_CRM_ISSUE_LIST',
  GET_CRM_ISSUE_HIDE_LIST: 'GET_CRM_ISSUE_HIDE_LIST',
  GET_CRM_ISSUE_COMMENT_LIST: 'GET_CRM_ISSUE_COMMENT_LIST',
  GET_CRM_MANAGE_GROSS_LIST: 'GET_CRM_MANAGE_GROSS_LIST',
  GET_CRM_USER_RESULT: 'GET_CRM_USER_RESULT',
  GET_ISSUE_LIST_BY_CC_HUSERID: 'GET_ISSUE_LIST_BY_CC_HUSERID',
  GET_CRM_MANAGE_GROSS_SUGGEST: 'GET_CRM_MANAGE_GROSS_SUGGEST',
  ADD_CRM_MANAGE_GROSS_SUGGEST: 'ADD_CRM_MANAGE_GROSS_SUGGEST',
  ADD_CRM_MANAGE_GROSS: 'ADD_CRM_MANAGE_GROSS',
  GET_CALL_REFERRAL_COUNT: 'GET_CALL_REFERRAL_COUNT',
};

export const TIME_LENGTH_LIST = [60, 90, 120, 150, 180];

export const PERMISSIONS = [
  '대시보드 보기',
  '예약 관리 편집',
  '콜 관리 보기',
  '콜 관리 편집',
  '콜 담당 지정',
  '콜 통계 확인',
  '상담 관리 보기',
  '상담 관리 편집',
  '상담 담당 편집',
  '상담 통계 확인',
  '결제 관리 편집',
] as const;

export const PART_LIST = [
  { value: 1, label: '콜' },
  { value: 2, label: '상담' },
  { value: 3, label: '코디' },
  { value: 4, label: '의사' },
  { value: 5, label: '스탭' },
];

export const PART_DISPLAY_LIST = [
  { value: 1, label: 'TM' },
  { value: 2, label: 'CS' },
  { value: 3, label: 'CD' },
  { value: 4, label: 'DR' },
  { value: 5, label: 'SF' },
];

export const GROUP_PART_LIST = [
  { value: 1, label: '콜', label2: 'TM' },
  { value: 2, label: '상담', label2: 'CS' },
  { value: 3, label: '코디', label2: 'CD' },
  { value: 4, label: '의사', label2: 'DR' },
  { value: 5, label: '스탭', label2: 'SF' },
];

export const NRC_LIST = [
  {
    type: 'cs',
    label: 'N',
    value: 'cs01',
    text: '상담시작',
    color: 'ffffff,11b374',
    order: 1,
  },
  {
    type: 'cs',
    label: 'R',
    value: 'cs02',
    text: '상담진행중',
    color: 'ffffff,e97070',
    order: 2,
  },
  {
    type: 'cs',
    label: 'C',
    value: 'cs03',
    text: '이슈상담',
    color: 'ffffff,8192f7',
    order: 3,
  },
  {
    type: 'dr',
    label: 'N',
    value: 'dr01',
    text: '진료시작',
    color: 'ffffff,11b374',
    order: 1,
  },
  {
    type: 'dr',
    label: 'R',
    value: 'dr02',
    text: '진료진행중',
    color: 'ffffff,e97070',
    order: 2,
  },
  {
    type: 'dr',
    label: 'C',
    value: 'dr03',
    text: '체크,정기',
    color: 'ffffff,8192f7',
    order: 3,
  },
];

export const CRM_LIST_S_TAG_LIST = [
  {
    value: 1,
    label: '컴플레인',
    emoji: '🤬',
    order: 1,
    color: '000,d9d9d9',
  },
  {
    value: 2,
    label: '수금필요',
    emoji: '💵',
    order: 2,
    color: '000,d9d9d9',
  },
  {
    value: 4,
    label: '지인',
    emoji: '👩‍👩‍👧',
    order: 3,
    color: '000,d9d9d9',
  },
  {
    value: 8,
    label: 'VIP',
    emoji: '⭐',
    order: 4,
    color: '000,d9d9d9',
  },
  {
    value: 16,
    label: '소개환자',
    emoji: '💑',
    order: 5,
    color: '000,d9d9d9',
  },
  {
    value: 32,
    label: '마케팅',
    emoji: '📊',
    order: 6,
    color: '000,d9d9d9',
  },
];

export const GROSS_IC_LIST = [
  {
    key: 'IC_01',
    label: '매출',
    name: 'profit',
    h_type: '12345',
    order: 1,
    cal: 'sum',
    unit: '원',
  },
  {
    key: 'IC_02',
    label: 'ROAS',
    name: 'roas',
    h_type: '12345',
    order: 2,
    cal: 'avg',
    unit: '%',
  },
  {
    key: 'IC_03',
    label: '신규 고객 수',
    name: 'firstDBCount',
    h_type: '12345',
    order: 3,
    cal: 'sum',
    unit: 'dbs',
  },
  {
    key: 'IC_04',
    label: '내원율',
    name: 'visitRate',
    h_type: '12345',
    order: 4,
    cal: 'avg',
    unit: '%',
  },
  {
    key: 'IC_05',
    label: '결제',
    name: 'paidCnt',
    h_type: '12345',
    order: 5,
    cal: 'sum',
    unit: '원',
  },
  {
    key: 'IC_06',
    label: '일 평균 내원수',
    name: 'avgVisitCnt',
    h_type: '12345',
    order: 6,
    cal: 'avg',
    unit: 'dbs',
  },
  {
    key: 'IC_07',
    label: '일 평균 콜수',
    name: 'avgCallCnt',
    h_type: '12345',
    order: 7,
    cal: 'avg',
    unit: 'calls',
  },
  {
    key: 'IC_08',
    label: '전체 콜수',
    name: 'callCnt',
    h_type: '1',
    order: 8,
    cal: 'sum',
    unit: 'calls',
  },
  {
    key: 'IC_09',
    label: '일 평균 예약수',
    name: 'bookCnt',
    h_type: '1',
    order: 9,
    cal: 'avg',
    unit: 'books',
  },
  {
    key: 'IC_10',
    label: '상담 동의율',
    name: 'agreeRate',
    h_type: '2',
    order: 10,
    cal: 'avg',
    unit: '%',
  },
];

// 1: tm / 콜
// 2: cs / 상담사
// 3: cd / 코디
// 4: dr / 의사
// 5: 스탭
export const HUSER_TREE_DATA = [
  {
    title: 'TM 그룹',
    value: '0-tm',
    key: '0-tm',
    type: 1,
    children: [
      {
        title: 'Child Node1',
        value: '0-1-1',
        key: '0-1-1',
      },
    ],
  },
  {
    title: '상담사 그룹',
    value: '0-cs',
    key: '0-cs',
    type: 2,
    children: [],
  },
  {
    title: '코디 그룹',
    value: '0-cd',
    key: '0-cd',
    type: 3,
    children: [],
  },
  {
    title: '의사 그룹',
    value: '0-dr',
    key: '0-dr',
    type: 4,
    children: [],
  },
  {
    title: '스탭 그룹',
    value: '0-st',
    key: '0-st',
    type: 5,
    children: [],
  },
];
