import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Empty, Input, Radio, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { getCrmPatientListAPI } from 'src/api/patient.api';
import { getGender } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';

type Form = {
  registerNumber: string;
  name: string;
  phone: string;
};

type Props = {
  onClickPatient(patient: any): void;
};

type Option = 'all' | 'name' | 'phone' | 'registerNumber';

export default function SearchForm({ onClickPatient }: Props) {
  const [option, setOption] = useState<Option>('all');
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Form>();
  const name = watch('name');
  const phone = watch('phone');
  const registerNumber = watch('registerNumber');

  const { data, refetch, isLoading, isFetching } = useQuery(
    [QUERY_KEYS.GET_CRM_PATIENT_LIST, { registerNumber, name, phone }],
    () => getCrmPatientListAPI({ name, phone, registerNumber }),
    {
      enabled: false,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    reset();
  }, [option, reset]);

  const onSubmit: SubmitHandler<Form> = (data) => {
    refetch();
  };

  const onClickDetail = (patient: any) => {
    onClickPatient(patient);
  };

  return (
    <>
      <div className="mb-2">
        <div className="mb-2">
          <Radio.Group onChange={(e) => setOption(e.target.value)} value={option}>
            <Radio value="all">이름, 전화번호, 등록번호 조합</Radio>
            <Radio value="registerNumber">등록번호</Radio>
            <Radio value="name">이름</Radio>
            <Radio value="phone">전화번호</Radio>
          </Radio.Group>
        </div>

        <div className="flex">
          <Controller
            name="registerNumber"
            control={control}
            rules={{ required: option === 'all' || option === 'registerNumber' }}
            render={({ field }) => (
              <Input
                {...field}
                onPressEnter={handleSubmit(onSubmit)}
                placeholder="등록번호"
                className="mr-2"
                disabled={option === 'phone' || option === 'name'}
                status={errors.registerNumber && 'error'}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            rules={{ required: option === 'all' || option === 'name', minLength: 2 }}
            render={({ field }) => (
              <Input
                {...field}
                onPressEnter={handleSubmit(onSubmit)}
                placeholder="이름"
                className="mr-2"
                disabled={option === 'phone' || option === 'registerNumber'}
                status={errors.name && 'error'}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              // pattern: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
              required: option === 'all' || option === 'phone',
              minLength: 4,
            }}
            render={({ field }) => (
              <Input
                {...field}
                onPressEnter={handleSubmit(onSubmit)}
                placeholder="01012345678"
                className="mr-2"
                disabled={option === 'name' || option === 'registerNumber'}
                status={errors.phone && 'error'}
              />
            )}
          />
          <Button type="primary" icon={<SearchOutlined />} onClick={handleSubmit(onSubmit)}>
            검색
          </Button>
        </div>

        <Spin spinning={isFetching}>
          <div className="mt-5 h-[200px] overflow-auto">
            {data?.crmPatientList.length === 0 && (
              <Empty description="검색된 환자가 없습니다." className="mt-3" />
            )}
            {data?.crmPatientList.map((patient) => (
              <div
                key={patient.id}
                className="flex justify-between items-center p-2"
                style={{ borderBottom: '1px solid #f0f0f0' }}
              >
                <div className="flex">
                  [{patient.registerNumber}] {patient.name} {getGender(patient.gender)}
                  <div className="mx-3">{patient.birth}년</div>
                  <span>{patient.phone}</span>
                </div>
                <Button type="link" onClick={() => onClickDetail(patient)}>
                  적용
                </Button>
              </div>
            ))}
          </div>
        </Spin>
      </div>
    </>
  );
}
