import React from 'react';

type Props = {
  bg: string;
};

export default function StatusCircle({ bg }: Props) {
  return (
    <div
      className={`w-4 aspect-square rounded-full flex justify-center items-center`}
      style={{ background: bg }}
    >
      <div className="w-2 aspect-square bg-white rounded-full" />
    </div>
  );
}
