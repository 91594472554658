import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, InputNumber, Modal, Select, notification } from 'antd';
import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { addCrmPaidHistoryAPI } from 'src/api/crm.api';
import { numberFormat } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';

type Props = {
  id: string;
  visible: boolean;
  total: number;
  patientId: string;
  onCancel: () => void;
};

type Form = {
  amount: number;
  memo: string;
  type: 'credit' | 'cash' | 'account';
  option: string;
};

const { TextArea } = Input;

const options = [
  { value: 'credit' as const, label: '신용카드' },
  { value: 'cash' as const, label: '현금' },
  { value: 'account' as const, label: '계정' },
];

export default function PayDoneFormModal({ id, visible, total, patientId, onCancel }: Props) {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>();

  const addCrmPaidHistory = useMutation(addCrmPaidHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '결제 추가 성공' });
      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_PAID_LIST, { patientId }]);
    },
    onError: () => {
      api.error({ message: '결제 추가 실패' });
    },
    onSettled: () => {
      onClose();
    },
  });

  const onClose = () => {
    reset();
    onCancel();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    const others = Object.fromEntries(Object.entries(data).filter(([_, value]) => value));
    const payload: any = { crmPaidId: id, ...others };
    addCrmPaidHistory.mutate(payload);
  };

  return (
    <Modal
      open={visible}
      footer={null}
      closable={false}
      width={400}
      onCancel={onCancel}
      destroyOnClose
    >
      {contextHolder}
      <div className="mb-3 text-base">
        총 결제할 금액: <span className="font-bold">{numberFormat(total)}</span>원
      </div>
      <Controller
        name="amount"
        control={control}
        rules={{ required: true, min: 100 }}
        render={({ field: { onChange, value } }) => (
          <InputNumber
            value={value}
            placeholder="숫자만 입력"
            step={100}
            addonBefore="결제금액"
            addonAfter="원"
            onChange={(digit) => onChange(digit || 0)}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            status={errors.amount ? 'error' : ''}
          />
        )}
      />
      <div className="flex mt-1">
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select {...field} options={options} className="w-full" placeholder="결제 수단" />
          )}
        />
        <Controller
          name="option"
          control={control}
          render={({ field }) => (
            <Input {...field} className="w-full ml-1" placeholder="결제 옵션 (할부, 기타)" />
          )}
        />
      </div>
      <Controller
        name="memo"
        control={control}
        render={({ field }) => (
          <TextArea {...field} rows={3} placeholder="메모를 입력하세요" className="mt-1" />
        )}
      />
      <div className="flex items-center justify-end mt-3">
        <Button className="mr-1" onClick={onCancel}>
          취소
        </Button>
        <Button type="primary" onClick={handleSubmit(onSubmit)}>
          확인
        </Button>
      </div>
    </Modal>
  );
}
