import { Tabs, TabsProps } from 'antd';

import CallBookForm from './Container/CallBookForm';

export default function CommonForm() {
  const items: TabsProps['items'] = [
    {
      key: '콜/예약',
      label: '콜/예약',
      children: <CallBookForm />,
    },
  ];

  return (
    <div className="my-5">
      <Tabs type="card" defaultActiveKey="콜/예약" items={items} destroyInactiveTabPane />
    </div>
  );
}
