import Http from 'src/@http';
import {
  queryCrmCsStatistic,
  queryCrmCsStatisticDaily,
  queryCrmDashStatistic,
  queryCrmDashStatisticList,
  queryCrmTmStatistic,
  queryCrmTmStatisticDaily,
  queryCrmReferralCount,
} from 'src/graphql/statistic.query';
import { errorResponse, successResponse } from 'src/libs/response';
import { ApiResponse } from 'src/types/response';
import {
  GetCrmCsStatisticDailyRequest,
  GetCrmCsStatisticDailyResponse,
  GetCrmCsStatisticRequest,
  GetCrmCsStatisticResponse,
  GetCrmDashStatisticListRequest,
  GetCrmDashStatisticListResponse,
  GetCrmDashStatisticRequest,
  GetCrmDashStatisticResponse,
  GetCrmTmStatisticDailyRequest,
  GetCrmTmStatisticDailyResponse,
  GetCrmTmStatisticRequest,
  GetCrmTmStatisticResponse,
  GetCrmReferralCountRequest,
  GetCrmReferralCountResponse,
} from 'src/types/statistic.types';

export async function getCrmTmStatisticDailyAPI({ huserId }: GetCrmTmStatisticDailyRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmTmStatisticDailyResponse>>('', {
      query: queryCrmTmStatisticDaily,
      variables: {
        filter: { huserId },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmTmStatisticDailyAPI'));
}

export async function getCrmTmStatisticAPI(payload: GetCrmTmStatisticRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmTmStatisticResponse>>('', {
      query: queryCrmTmStatistic,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmTmStatisticAPI'));
}

export async function getCrmCsStatisticDailyAPI({ huserId }: GetCrmCsStatisticDailyRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmCsStatisticDailyResponse>>('', {
      query: queryCrmCsStatisticDaily,
      variables: {
        filter: { huserId },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmCsStatisticDailyAPI'));
}

export async function getCrmCsStatisticAPI(payload: GetCrmCsStatisticRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmCsStatisticResponse>>('', {
      query: queryCrmCsStatistic,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmCsStatisticAPI'));
}

export async function getCrmDashStatisticAPI(payload: GetCrmDashStatisticRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmDashStatisticResponse>>('', {
      query: queryCrmDashStatistic,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmDashStatisticAPI'));
}

export async function getCrmDashStatisticListAPI(payload: GetCrmDashStatisticListRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmDashStatisticListResponse>>('', {
      query: queryCrmDashStatisticList,
      variables: {
        filter: { ...payload, huserIds: payload.huserIds },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmDashStatisticListAPI'));
}

export async function getCrmReferralCountAPI(payload: GetCrmReferralCountRequest) {
  return Http.instance
    .post<ApiResponse<GetCrmReferralCountResponse>>('', {
      query: queryCrmReferralCount,
      variables: {
        filter: { ...payload },
      },
    })
    .then(successResponse)
    .catch(errorResponse('getCrmReferralCountAPI'));
}
