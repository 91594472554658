export const queryCrmHospitalInfo = `
  query CrmHospitalInfo {
    crmHospitalInfo {
      id
      name
      location {
        id
        siName
        gunguName
      }
      category {
        id
        name
        description
      }
      hiraCode
      tel
      phones
      createdAt
      updatedAt
    }
  }
`;

export const queryCrmHuserList = `
  query($filter: HuserListFilter) {
    crmHuserList(filter: $filter) {
        id
        email
        type
        crmAuthority
        name
        title
        department
        position
        phone
        contact
        isMaster
        isDelete
        avatar
        isDayOff
    }
  }
`;

export const queryUpdateCrmHuserInfo = `
  mutation UpdateCrmHuserInfo($input: UpdateCrmHuserInput!) {
    updateCrmHuserInfo(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const queryUpdateCrmHuserAuthority = `
  mutation UpdateCrmHuserAuthority($input: UpdateCrmHuserAuthorityInput!) {
    updateCrmHuserAuthority(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const queryUpdateCrmHuserSchedule = `
  mutation UpdateCrmHuserSchedule($input: UpdateHuserScheduleInput!) {
    updateCrmHuserSchedule(input: $input) {
        ok
        error {
            path
            message
        }
    }
}

`;

export const queryBalaceHistory = `
  query crmBalanceHistory($filter: BalanceHistoryFilter, $pagination: PaginationInput) {
    crmBalanceHistory(filter: $filter, pagination: $pagination) {
      data {
        id
        action
        description
        transactionType {
          id
          action
          name
          description
        }
        hospitalPoint {
          id
          balance
        }
        amount
        balance
        timestamp
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const queryAddCrmHuser = `
  mutation RegistCrmHuser($input: RegistCrmHuserInput!) {
    registCrmHuser(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;
