import { QueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useLocation, Navigate, useLoaderData, redirect } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { mehRequestAPI } from 'src/api/auth.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { authState } from 'src/recoil/auth.recoil';

export const checkAuthLoader = (queryClient: QueryClient) => () => {
  try {
    const meh = queryClient.fetchQuery({
      queryKey: [QUERY_KEYS.GET_MEH],
      queryFn: async () => mehRequestAPI(),
    });

    return meh;
  } catch (error) {
    throw error;
  }
};

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const loginData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof checkAuthLoader>>>;
  const setAuth = useSetRecoilState(authState);

  useEffect(() => {
    setAuth((prev) => ({ ...prev, meh: loginData.meh }));
  }, [loginData, setAuth]);

  if (!loginData) {
    return <Navigate to="signin" state={{ from: location }} replace />;
  }

  return children;
}
