import React from 'react';
import { useParams } from 'react-router-dom';

import AddPatientDrawer from 'src/components/Consult/AddPatientDrawer';
import ConsultantWidget from 'src/components/Consult/ConsultantWidget';
import ConsultButtonWidget from 'src/components/Consult/ConsultButtonWidget';
import ConsultManagementDrawer from 'src/components/Consult/ConsultManagementDrawer';
import ConsultTableWidget from 'src/components/Consult/ConsultTableWidget';
import ConsultingFilter from 'src/components/Filter/ConsultingFilter';
import PermissionWrapper from 'src/components/PermissionWrapper';

export default function Consulting() {
  const { info } = useParams<{ info: string }>();

  return (
    <PermissionWrapper requiredPermission="상담 관리 보기">
      <div className="flex">
        <div className="flex-1">
          {/* <ConsultantWidget /> */}
          <ConsultButtonWidget />
          <ConsultTableWidget />
        </div>
      </div>

      <AddPatientDrawer />
      <ConsultManagementDrawer info={info || ''} />
    </PermissionWrapper>
  );
}
