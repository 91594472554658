import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Collapse, Input, InputNumber, Radio } from 'antd';
import React from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import SearchForm from '../Call/SearchForm';
import { addCrmItemAPI } from 'src/api/crm.api';
import { QUERY_KEYS } from 'src/libs/constants';
import { consultManagementState } from 'src/recoil/consultManagement.recoil';
import { AddCrmItemRequest } from 'src/types/crm.types';

type FormPatient = {
  name: string;
  phone: string;
  registerNumber: string;
  referral: string;
  address: string;
  postAddress: string;
  birth: number;
  gender: string;
};

type FormHistory = {
  memo: string;
  statusId: string;
  date: string;
  assignHuserId: string;
  length: number;
};

type Form = FormPatient & FormHistory;

const patientKeys = [
  'name',
  'phone',
  'registerNumber',
  'referral',
  'address',
  'postAddress',
  'birth',
  'gender',
];

export default function CreatePatientForm() {
  const queryClient = useQueryClient();
  const [{ isNewVisit, startDate, endDate }, setConsult] = useRecoilState(consultManagementState);
  const open = useDaumPostcodePopup();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: { gender: 'f' },
  });

  const addCrmItem = useMutation(addCrmItemAPI, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CONSULT_LIST,
        { isNewVisit, startDate, endDate },
      ]);
      onClose();
    },
  });

  const onClose = () => {
    setConsult((prev) => ({ ...prev, openDrawer: false }));
    reset();
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    const { postAddress, address, ...onlyPatient } = Object.fromEntries(
      Object.entries(data)
        .filter(([, value]) => value !== undefined && value !== '')
        .filter(([key]) => patientKeys.some((patientKey) => patientKey === key))
    );
    const payload = {
      ...onlyPatient,
      address: postAddress && address ? postAddress || '' + ' ' + address || '' : '',
    } as AddCrmItemRequest;

    addCrmItem.mutate(payload);
  };

  const onClickPatient = (patient: any) => {
    setValue('name', patient.name);
    setValue('phone', patient.phone);
    setValue('birth', patient.birth);
    setValue('gender', patient.gender);
    setValue('registerNumber', patient.registerNumber);
  };

  return (
    <>
      <div className="mb-3">
        <div className="mb-3">
          <div className="text-[#768396] font-bold mb-1">등록번호</div>
          <Controller
            name="registerNumber"
            control={control}
            // rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Input
                value={value}
                onChange={(digit) => onChange(digit || '0')}
                className="w-full"
                placeholder="등록번호는 필수입력이 아닙니다"
                status={errors.registerNumber ? 'error' : ''}
              />
            )}
          />
        </div>
        <div className="flex mb-3">
          <div className="flex-1">
            <Collapse
              items={[
                {
                  key: '1',
                  label: '환자 검색',
                  children: <SearchForm onClickPatient={onClickPatient} />,
                },
              ]}
            />
          </div>
        </div>
        <div className="flex mb-3">
          <div className="flex-1">
            <div className="text-[#768396] font-bold mb-1">이름</div>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <Input {...field} status={errors.name ? 'error' : ''} />}
            />
          </div>
          <div className="ml-3 flex-1">
            <div className="text-[#768396] font-bold mb-1">출생년도 4자리</div>
            <Controller
              name="birth"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <InputNumber
                  value={value}
                  onChange={(digit) => onChange(digit || 0)}
                  className="w-full"
                  placeholder="1995 숫자만 입력"
                  // status={errors.birth ? 'error' : ''}
                />
              )}
            />
          </div>
          <div className="ml-3 w-[120px]">
            <div className="text-[#768396] font-bold mb-1">성별</div>
            <Controller
              name="gender"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Radio.Group
                  options={[
                    { label: '여자', value: 'f' },
                    { label: '남자', value: 'm' },
                  ]}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  optionType="button"
                />
              )}
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="text-[#768396] font-bold mb-1">연락처</div>
          <Controller
            name="phone"
            control={control}
            rules={{ required: true, pattern: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/ }}
            render={({ field }) => (
              <Input {...field} placeholder="01012345678" status={errors.phone ? 'error' : ''} />
            )}
          />
        </div>
        <div className="mb-3 flex">
          <div className="flex-1">
            <div className="flex items-center mb-1">
              <Button
                type="primary"
                onClick={() =>
                  open({
                    onComplete: (data) =>
                      setValue('postAddress', data.address, { shouldValidate: true }),
                  })
                }
              >
                주소 검색
              </Button>
              {/* {errors.postAddress && (
                <span className="ml-3 text-[#ff4d4f]">주소검색을 해주세요</span>
              )} */}
            </div>
            <Controller
              name="postAddress"
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <Input {...field} status={errors.postAddress ? 'error' : ''} disabled />
              )}
            />
            <div className="flex-1 mt-3">
              <div className="text-[#768396] font-bold mb-1">상세주소</div>
              <Controller
                name="address"
                control={control}
                // rules={{ required: true }}
                render={({ field }) => <Input {...field} status={errors.address ? 'error' : ''} />}
              />
            </div>
          </div>
        </div>
        <div className="mb-3 flex">
          <div className="flex-1">
            <div className="text-[#768396] font-bold mb-1">유입경로</div>
            <Controller
              name="referral"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </div>
        </div>

        <div className="mt-3 flex justify-end">
          <Button type="primary" onClick={handleSubmit(onSubmit)}>
            확인
          </Button>
        </div>
      </div>
    </>
  );
}
