import { useQuery } from '@tanstack/react-query';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useRecoilValue } from 'recoil';

import DataCard from './DataCard';
import DataCardWithIcon from './DataCardWithIcon';
import DataGroupChart from './DataGroupChart';
import DataPieChart from './DataPieChart';
import DataVerticalChart from './DataVerticalChart';
import EfficiencyCard from './EfficiencyCard';
import TotalDbCard from './TotalDbCard';
import { getCrmDashStatisticAPI, getCrmDashStatisticListAPI } from 'src/api/statistic.api';
import IconChart from 'src/assets/images/icon-chart.svg';
import IconCheck from 'src/assets/images/icon-check.svg';
import IconDoc from 'src/assets/images/icon-doc.svg';
import IconUnit from 'src/assets/images/icon-unit.svg';
import { QUERY_KEYS } from 'src/libs/constants';
import { authState } from 'src/recoil/auth.recoil';
import { dashboardFilterState } from 'src/recoil/dashboardFilter.recoil';

export default function StatisticsWrapper() {
  const { meh } = useRecoilValue(authState);
  const filter = useRecoilValue(dashboardFilterState);
  const huserId = filter.hUserId ? filter.hUserId : null;
  const huserIds = filter.hUserIds ? filter.hUserIds : null;

  const { data, isFetching, isLoading } = useQuery(
    [
      QUERY_KEYS.GET_DASH_STATISTIC,
      { huserIds, huserId, startDate: filter.startDate, endDate: filter.endDate },
    ],
    () =>
      getCrmDashStatisticAPI({
        ...(huserId && { huserId }),
        ...(huserIds && { huserIds }),
        startDate: dayjs(filter.startDate).format(),
        endDate: dayjs(filter.endDate).format(),
      })
  );

  const {
    data: listData,
    // isFetching,
    // isLoading,
  } = useQuery(
    [
      QUERY_KEYS.GET_DASH_STATISTIC_LIST,
      { huserId, startDate: filter.startDate, endDate: filter.endDate },
    ],
    () =>
      getCrmDashStatisticListAPI({
        ...(huserIds && { huserIds }),
        ...(huserId && { huserId }),
        startDate: dayjs(filter.startDate).format(),
        endDate: dayjs(filter.endDate).format(),
      })
  );

  return (
    <Spin spinning={isFetching || isLoading}>
      {/* <div className="rounded-lg bg-white p-3">
        <div className="text-[#1B2559] font-bold mb-6">기간 통계</div>
        <div className="h-[200px]">bar + line</div>
      </div> */}

      <div className="flex flex-col p-5 rounded-3xl bg-white gap-5 h-96">
        <DataGroupChart data={listData?.crmDashStatisticList} />
      </div>

      <div
        className="grid mt-3 gap-5"
        style={{
          gridTemplateColumns: '1fr 350px 350px',
        }}
      >
        <div className="grid grid-cols-2 gap-5">
          <DataCardWithIcon
            icon={IconChart}
            bg="#4318FF"
            label="매출"
            value={data?.crmDashStatistic.profit || 0}
          />

          <DataCardWithIcon
            icon={IconChart}
            bg="#05CD99"
            label="매출이익"
            value={data?.crmDashStatistic.net || 0}
          />

          <DataCardWithIcon
            icon={IconUnit}
            bg="#602BF8"
            label="지출"
            value={data?.crmDashStatistic.expenditure || 0}
          />
          <DataCardWithIcon
            icon={IconCheck}
            bg="#EE5D50"
            label="총 내원 수"
            value={data?.crmDashStatistic.visitCount || 0}
          />

          <DataCardWithIcon
            icon={IconUnit}
            bg="#7551FF"
            label="ROAS"
            value={data?.crmDashStatistic.roas || 0}
          />

          <DataCardWithIcon
            icon={IconDoc}
            bg="#39B8FF"
            label="총 DB 수"
            value={data?.crmDashStatistic.allDBCount || 0}
          />
        </div>
        <div className="flex flex-col p-6 rounded-3xl bg-white gap-5">
          <div>
            <div className="text-base text-gray-400 leading-4">총 DB수</div>
            <div className="text-3xl font-bold">
              {data?.crmDashStatistic.allDBCount || 0}{' '}
              <span className="text-xs text-gray-400 leading-4 font-light">visitors</span>
            </div>
          </div>
          <div style={{ height: '200px' }}>
            <DataVerticalChart data={listData?.crmDashStatisticList} />
          </div>
        </div>
        <div className="flex flex-col items-center p-6 rounded-3xl bg-white gap-5">
          <div className="w-full text-base leading-4">효율분석</div>
          <div style={{ width: '150px' }}>
            <DataPieChart
              data={[data?.crmDashStatistic.visitRate, data?.crmDashStatistic.agreeRate]}
            />
          </div>

          <div
            className="grid grid-cols-2"
            style={{
              gridTemplateColumns: 'repeat(2, 100px)',
            }}
          >
            <div className="text-center">
              <div>내원율</div>
              <div>{data?.crmDashStatistic.visitRate}%</div>
            </div>
            <div className="text-center">
              <div>동의율</div>
              <div>{data?.crmDashStatistic.agreeRate}%</div>
            </div>
          </div>
        </div>

        {/* <div className="flex">
          <div className="ml-3">
            <TotalDbCard />
          </div>
          <div className="ml-3">
            <EfficiencyCard />
          </div>
        </div> */}
      </div>
    </Spin>
  );
}
