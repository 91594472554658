export const queryCrmStatusList = `
  query CrmStatusList {
    crmStatusList {
      id
      status
      group
      order
      color
      isHide
      statusMapId
    }
  }
`;
