import { Empty, Spin } from 'antd';

import EditablePaymentDoneForm from './EditablePaymentDoneForm';
import usePaidListById from 'src/hooks/usePaidListById';

type Props = {
  patientId: string;
};

export default function PaymentDone({ patientId }: Props) {
  const { crmPaidList, isFetching } = usePaidListById(patientId);
  const paidDoneList = crmPaidList.filter((paid) => paid.status === 2 || paid.status === 1);

  return (
    <Spin spinning={isFetching}>
      <div className="mt-5">
        <div className="text-lg text-[#4318FF] font-bold mb-3">결제 완료</div>
        {paidDoneList.length === 0 && <Empty description="내역이 없습니다." />}
        {paidDoneList.map((paid) => (
          <EditablePaymentDoneForm {...paid} key={paid.id} />
        ))}
      </div>
    </Spin>
  );
}
