import { AxiosResponse } from 'axios';

import { ApiResponse } from 'src/types/response';

export function successResponse<T>(res: AxiosResponse<ApiResponse<T>>) {
  return { ...res.data.data, errors: res.data.errors };
}

export function errorResponse(name: string) {
  return (err: any) => {
    throw new Error(`Failed to ${name}. ${JSON.stringify(err)}`);
  };
}

export function createAxiosResponse<T>(data: T) {
  return {
    data: {
      code: 200,
      message: 'success',
      data,
    },
  };
}
