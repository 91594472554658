import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Input, InputNumber, Popconfirm, Select, Tag, notification } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import {
  removeCrmPaidAPI,
  removeCrmPaidHistoryAPI,
  updateCrmPaidAPI,
  updateCrmPaidHistoryAPI,
} from 'src/api/crm.api';
import PayDoneFormModal from 'src/components/PatientBoard/PayDoneFormModal';
import { numberFormat } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { isRightPermissionState } from 'src/recoil/auth.recoil';
import { CrmPaid, CrmPaidHistory, UpdateCrmPaidRequest } from 'src/types/crm.types';

const { TextArea } = Input;

type Props = CrmPaid;

export default function EditablePaymentDoneForm(props: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="rounded-lg shadow-lg bg-white p-5 mb-4">
      <EditableParentForm {...props} showModal={() => setShowModal(true)} />
      {props.history.length > 0 && (
        <div className="mt-1 ml-3">
          {props.history
            .filter((item) => !item.isDelete)
            .map((item) => (
              <EditableChildForm key={item.id} {...item} patientId={props.crmPatientId} />
            ))}
        </div>
      )}
      {showModal && (
        <PayDoneFormModal
          id={props.id}
          total={props.pay}
          patientId={props.crmPatientId}
          visible={showModal}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

type ParentProps = CrmPaid & {
  showModal: () => void;
};

type ParentForm = {
  pay: number;
  memo: string;
};

function EditableParentForm({ showModal, ...props }: ParentProps) {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const isRightPermission = useRecoilValue(isRightPermissionState('결제 관리 편집'));
  const [isEdit, setIsEdit] = useState(false);
  const isDone = props.pay === props.paySum;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ParentForm>();

  useEffect(() => {
    if (isEdit) {
      setValue('pay', props.pay);
      setValue('memo', props.memo);
    }
  }, [isEdit, setValue, props]);

  const updateCrmPaid = useMutation(updateCrmPaidAPI, {
    onSuccess: (res) => {
      api.success({ message: '결제 수정이 완료되었습니다.' });
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PAID_LIST,
        { patientId: props.crmPatientId },
      ]);
    },
    onError: () => {
      api.error({ message: '결제 수정이 실패했습니다.' });
    },
    onSettled: () => {
      reset();
      setIsEdit(false);
    },
  });

  const removeCrmPaid = useMutation(removeCrmPaidAPI, {
    onSuccess: (res) => {
      api.success({ message: '결제 삭제가 완료되었습니다.' });
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PAID_LIST,
        { patientId: props.crmPatientId },
      ]);
    },
    onError: () => {
      api.error({ message: '결제 삭제가 실패했습니다.' });
    },
    onSettled: () => {
      setIsEdit(false);
    },
  });

  const onDelete = () => {
    removeCrmPaid.mutate({ id: props.id });
  };

  const onClose = () => {
    setIsEdit(false);
  };

  const onSubmit: SubmitHandler<ParentForm> = (data) => {
    const payload: UpdateCrmPaidRequest = { id: props.id, memo: data.memo };
    // 수정 전 금액과 수정 후 금액이 다를때만 pay보냄
    if (data.pay !== props.pay) {
      payload['pay'] = data.pay;
    }

    updateCrmPaid.mutate(payload);
  };

  return (
    <>
      {contextHolder}
      {isEdit ? (
        <>
          <Controller
            name="pay"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <InputNumber
                value={value}
                placeholder="숫자만 입력"
                step={100}
                addonBefore="결제금액"
                addonAfter="원"
                onChange={(digit) => onChange(digit || 0)}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                status={errors.pay ? 'error' : ''}
                className="w-full mb-1"
              />
            )}
          />
          <Controller
            name="memo"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={3}
                placeholder="결제 내용을 입력하세요"
                className="mb-3"
                status={errors.memo ? 'error' : ''}
              />
            )}
          />
          <div className="flex justify-end">
            <Popconfirm
              title="결제 내역 삭제"
              okText="예"
              cancelText="아니오"
              onConfirm={(e) => onDelete()}
            >
              <Button type="text" danger>
                삭제
              </Button>
            </Popconfirm>
            <Button onClick={onClose} className="mx-1">
              취소
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit)}>
              확인
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="text-[#2B3674] font-bold">
                <Tag color={isDone ? 'success' : 'warning'}>{isDone ? '완납' : '부분미납'}</Tag>
                <span>{numberFormat(props.paySum)}</span>원 / <span>{numberFormat(props.pay)}</span>
                원
              </div>
            </div>
            <div className="flex items-center">
              {!isDone && isRightPermission && <Button onClick={showModal}>결제</Button>}
              {isRightPermission && (
                <Button
                  type="text"
                  className="ml-2"
                  icon={<EditOutlined />}
                  onClick={() => setIsEdit(true)}
                />
              )}
            </div>
          </div>
          <div className="flex justify-between mt-1">
            <div className="text-[#A3AED0] text-sm flex-1">{props.memo}</div>
            <div className="text-[#A3AED0] text-sm ml-3">
              {dayjs(props.createdAt).format('YYYY.MM.DD')}
            </div>
          </div>
        </>
      )}
    </>
  );
}

type ChildProps = CrmPaidHistory & { patientId: string };

type ChildForm = {
  amount: number;
  memo: string;
  type: 'credit' | 'cash' | 'account';
  option: string;
};

const options = [
  { value: 'credit' as const, label: '신용카드' },
  { value: 'cash' as const, label: '현금' },
  { value: 'account' as const, label: '계정' },
];

function EditableChildForm(item: ChildProps) {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const isRightPermission = useRecoilValue(isRightPermissionState('결제 관리 편집'));
  const [isEdit, setIsEdit] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ChildForm>({ defaultValues: { type: item.type, option: item.option } });

  const updateCrmPaidHistory = useMutation(updateCrmPaidHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '결제 내역 수정 성공' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PAID_LIST,
        { patientId: item.patientId },
      ]);
    },
    onError: () => {
      api.error({ message: '결제 내역 수정 실패' });
    },
    onSettled: () => {
      onClose();
    },
  });

  const removeCrmPaidHistory = useMutation(removeCrmPaidHistoryAPI, {
    onSuccess: (res) => {
      api.success({ message: '결제 내역 삭제 성공' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_PAID_LIST,
        { patientId: item.patientId },
      ]);
    },
    onError: () => {
      api.error({ message: '결제 내역 삭제 실패' });
    },
    onSettled: () => {
      onClose();
    },
  });

  useEffect(() => {
    if (isEdit) {
      setValue('amount', item.amount);
      setValue('memo', item.memo);
    }
  }, [isEdit, setValue, item]);

  const onDelete = () => {
    removeCrmPaidHistory.mutate({ id: item.id });
  };

  const onClose = () => {
    setIsEdit(false);
  };

  const onSubmit: SubmitHandler<ChildForm> = (data) => {
    const others = Object.fromEntries(Object.entries(data).filter(([_, value]) => value));
    const payload: any = { id: item.id, ...others };
    updateCrmPaidHistory.mutate(payload);
  };

  const handleType = (type: 'credit' | 'cash' | 'account') => {
    switch (type) {
      case 'account':
        return '계정';
      case 'cash':
        return '현금';
      case 'credit':
        return '신용카드';
      default:
        return '';
    }
  };

  return (
    <>
      {contextHolder}
      {isEdit ? (
        <div key={item.id} className="rounded-lg shadow-lg p-3 mt-1">
          <Controller
            name="amount"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <InputNumber
                value={value}
                placeholder="숫자만 입력"
                step={100}
                addonBefore="결제금액"
                addonAfter="원"
                onChange={(digit) => onChange(digit || 0)}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                status={errors.amount ? 'error' : ''}
                className="w-full mb-1"
              />
            )}
          />
          <div className="flex mb-1">
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select {...field} options={options} className="w-full" placeholder="결제 수단" />
              )}
            />
            <Controller
              name="option"
              control={control}
              render={({ field }) => (
                <Input {...field} className="w-full ml-1" placeholder="결제 옵션 (할부, 기타)" />
              )}
            />
          </div>
          <Controller
            name="memo"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={3}
                placeholder="결제 내용을 입력하세요"
                className="mb-3"
                status={errors.memo ? 'error' : ''}
              />
            )}
          />
          <div className="flex justify-end">
            <Popconfirm
              title="결제 내역 삭제"
              okText="예"
              cancelText="아니오"
              onConfirm={(e) => onDelete()}
            >
              <Button type="text" danger>
                삭제
              </Button>
            </Popconfirm>
            <Button onClick={onClose} className="mx-1">
              취소
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit)}>
              확인
            </Button>
          </div>
        </div>
      ) : (
        <div key={item.id} className="rounded-lg shadow-lg p-3 mt-1">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="font-bold">{numberFormat(item.amount)}원</div>
              {item.type && <Tag className="ml-3 text-xs">{handleType(item.type)}</Tag>}
            </div>
            {isRightPermission && (
              <Button type="text" icon={<EditOutlined />} onClick={() => setIsEdit(true)} />
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="mt-1 text-[#A3AED0] text-xs flex-1">{item.memo}</div>
            <div className="mt-1 text-[#A3AED0] text-xs ml-3">
              {dayjs(item.createdAt).format('YYYY.MM.DD')}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
