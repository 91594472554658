import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import React from 'react';
import { useRecoilState } from 'recoil';
import 'dayjs/locale/ko';

import { manageDBState } from 'src/recoil/manageDB.recoil';

const { RangePicker } = DatePicker;

export default function ManageDBFilter() {
  const [filter, setFilter] = useRecoilState(manageDBState);

  const onChangePicker = (dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;
    setFilter((prev) => ({
      ...prev,
      startDate: dayjs(startDate).format(),
      endDate: dayjs(endDate).format(),
    }));
  };

  return (
    <div>
      <RangePicker
        locale={locale}
        onChange={(_, dateStrings) => onChangePicker(dateStrings)}
        allowClear={false}
        value={[dayjs(filter.startDate), dayjs(filter.endDate)]}
        disabledDate={(current) => {
          return current && current < dayjs().add(-90, 'd');
        }}
      />
    </div>
  );
}
