import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { UploadFile, UploadProps } from 'antd';
import {
  Button,
  Checkbox,
  Input,
  InputNumber,
  message,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Tooltip,
  Upload,
} from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { signS3API } from 'src/api/common.api';
import { addCrmIssueAPI } from 'src/api/issue.api';
import { QUERY_KEYS } from 'src/libs/constants';

const { TextArea } = Input;
const { Dragger } = Upload;

type Form = {
  title: string;
  content: string;
  doc1: boolean;
  doc2: boolean;
  makeMethod: string;
  makeOneCount: number;
  makeMultipleMonth: number;
  makeMultipleCount: number;
  makeStyle: string[];
  requestMake: boolean;
  requestAd: boolean;
  requestAdCount: number;
  channel: string[];
};

const makeStyleList = [
  {
    type: 'a',
    src: 'https://images.odoctor.co.kr/event/thumbnail/WD5f2StRTei-2ErYjwzM3_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%85%E1%85%B5%E1%84%91%E1%85%B3%E1%84%90%E1%85%B5%E1%86%BC_%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%86%AF.jpg',
    vimeo:
      'https://player.vimeo.com/video/917061064?controls=0&loop=1&background=1&quality=undefined&app_id=122963&texttrack=undefined',
  },
  {
    type: 'b',
    src: 'https://v2-odoctor-resource.s3.amazonaws.com/event/thumbnail/1kkJNGYJKlSBdDDyVz97A_%E1%84%8B%E1%85%A9%E1%84%83%E1%85%A1%E1%86%A8%E1%84%90%E1%85%A5_%E1%84%85%E1%85%B5%E1%84%8C%E1%85%B2%E1%84%85%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5.jpg',
    vimeo:
      'https://player.vimeo.com/video/917061701?controls=0&loop=1&background=1&quality=undefined&app_id=122963&texttrack=undefined',
  },
  {
    type: 'c',
    src: 'https://images.odoctor.co.kr/event/thumbnail/8uj8P8KnSXSzP2cUFTyws_%E1%84%91%E1%85%A1%E1%86%AF%E1%84%8C%E1%85%A1%E1%84%91%E1%85%A2%E1%84%8F%E1%85%B5%E1%84%8C%E1%85%B5_%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%86%AF.png',
    vimeo:
      'https://player.vimeo.com/video/917061977?controls=0&loop=1&background=1&quality=undefined&app_id=122963&texttrack=undefined',
  },
  {
    type: 'd',
    src: 'https://images.odoctor.co.kr/event/thumbnail/YoRhOFmlxp2zbHZwPA2ED_(%E1%84%8B%E1%85%A9%E1%84%83%E1%85%A1%E1%86%A8%E1%84%90%E1%85%A5)%E1%84%8B%E1%85%B5%E1%86%B8%E1%84%89%E1%85%AE%E1%86%AF%E1%84%91%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5_%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%86%AF.jpg',
    vimeo:
      'https://player.vimeo.com/video/917062180?controls=0&loop=1&background=1&quality=undefined&app_id=122963&texttrack=undefined',
  },
  {
    type: 'e',
    src: 'https://images.odoctor.co.kr/event/thumbnail/YoRhOFmlxp2zbHZwPA2ED_(%E1%84%8B%E1%85%A9%E1%84%83%E1%85%A1%E1%86%A8%E1%84%90%E1%85%A5)%E1%84%8B%E1%85%B5%E1%86%B8%E1%84%89%E1%85%AE%E1%86%AF%E1%84%91%E1%85%B5%E1%86%AF%E1%84%85%E1%85%A5_%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%86%AF.jpg',
    vimeo:
      'https://player.vimeo.com/video/917062724?controls=0&loop=1&background=1&quality=undefined&app_id=122963&texttrack=undefined',
  },
];

export default function ContentStudio() {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();

  const [fileDoc1, setFileDoc1] = useState<UploadFile[]>([]);
  const uploadImages = useMutation(signS3API);
  const addItem = useMutation(addCrmIssueAPI, {
    onSuccess: (res) => {
      api.success({ message: '업로드 성공' });
      return queryClient.invalidateQueries([QUERY_KEYS.GET_CRM_ISSUE_LIST]);
    },
    onError: () => {
      api.error({ message: '업로드 실패' });
    },
    onSettled: () => {
      console.log('end');
    },
  });

  const props: UploadProps = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    beforeUpload(file) {
      // FIXME: size
      if (file.size > 100000) {
        message.error('500메가 이상 파일은 이메일을 이용하세요');
        return false;
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<Form>({
    defaultValues: {
      title: '',
      content: '',
      doc1: false,
      doc2: false,
      makeMethod: 'one',
      makeOneCount: 1,
      makeMultipleCount: 1,
      makeMultipleMonth: 1,
      makeStyle: [],
      requestAd: false,
      requestAdCount: 1,
      requestMake: false,
      channel: [],
    },
  });

  const { doc1, requestAd, makeMethod, makeStyle } = watch();

  const toggleCheckbox = (
    channel: string,
    isChecked: boolean,
    value: string[],
    onChange: (value: string[]) => void
  ) => {
    if (isChecked) {
      onChange([...value, channel]);
    } else {
      onChange(value.filter((selectedChannel) => selectedChannel !== channel));
    }
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    console.log(data, fileDoc1);
  };

  return (
    <div className="flex">
      {contextHolder}
      <div className="flex-1">
        <div className="bg-white p-5 rounded-lg">
          <div className="mb-3 font-bold text-base">컨텐츠 소스 업데이트</div>
          <div>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Dragger>
          </div>
          <div className="mt-5 text-base text-right">
            <Tooltip title="cs@odoctor.co.kr 문의해주세요">
              <Button type="text" href="mailto:cs@odoctor.co.kr">
                500M 이상 메일로 보내기
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="bg-white p-5 rounded-lg mt-3">
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div>
                <span className="font-bold text-base">제목</span>
                <Input
                  {...field}
                  placeholder="제목을 입력하세요"
                  status={errors.title ? 'error' : ''}
                  className="mt-1"
                />
              </div>
            )}
          />
        </div>
        <div className="bg-white p-5 rounded-lg mt-3">
          <Controller
            name="content"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div>
                <span className="font-bold text-base">설명</span>
                <TextArea
                  {...field}
                  rows={10}
                  placeholder="설명을 입력하세요"
                  className="mt-1"
                  status={errors.content ? 'error' : ''}
                />
              </div>
            )}
          />
        </div>
        <div className="bg-white p-5 rounded-lg mt-3">
          <div className="mb-3 font-bold text-base">추가자료</div>
          <div className="flex justify-between items-center h-8">
            <Controller
              name="doc1"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value}>
                  사업자 등록증
                </Checkbox>
              )}
            />
            {doc1 && (
              <Upload
                showUploadList={false}
                onChange={(info) => setFileDoc1(info.fileList)}
                listType="picture"
                beforeUpload={(file) => {
                  return false;
                }}
                fileList={fileDoc1}
              >
                <Button type="link">파일올리기</Button>
              </Upload>
            )}
          </div>
          <div className="flex justify-between items-center h-8">
            <Controller
              name="doc2"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value}>
                  {errors.doc2 && <span className="font-bold text-red-400 mr-1">(필수)</span>}계약
                  확인
                </Checkbox>
              )}
            />
            <Popconfirm
              title={
                <img
                  src="https://images.odoctor.co.kr/philing/main_image_04.webp"
                  className="w-[300px]"
                />
              }
              icon={null}
              onConfirm={() => console.log('confirm')}
              onCancel={() => console.log('cancel')}
              okText="예"
              cancelText="아니오"
            >
              <Button type="link">계약 확인서</Button>
            </Popconfirm>
          </div>
        </div>
      </div>

      <div className="flex-1 ml-5">
        <div className="bg-white p-5 rounded-lg">
          <div className="mb-3 font-bold text-base">제작방식</div>
          <Controller
            name="makeMethod"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Radio.Group
                onChange={(e) => onChange(e.target.value)}
                value={value}
                className="flex flex-col"
              >
                <Radio value="one">
                  <div className="flex items-center">
                    <div>단건 제작</div>
                    <div className="ml-3">
                      <Controller
                        name="makeOneCount"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Input
                            value={value}
                            onChange={(digit) => onChange(digit || 0)}
                            suffix="건"
                            className="w-20"
                            disabled={makeMethod !== 'one'}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Radio>
                <Radio value="multiple" className="mt-3">
                  <div className="flex items-center">
                    <div className="">정기 제작</div>
                    <div className="ml-3">
                      <Controller
                        name="makeMultipleMonth"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Input
                            value={value}
                            onChange={(digit) => onChange(digit || 0)}
                            suffix="월"
                            className="w-20"
                            disabled={makeMethod !== 'multiple'}
                          />
                        )}
                      />
                      <Controller
                        name="makeMultipleCount"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Input
                            value={value}
                            onChange={(digit) => onChange(digit || 0)}
                            suffix="건"
                            className="w-20 ml-3"
                            disabled={makeMethod !== 'multiple'}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Radio>
              </Radio.Group>
            )}
          />
        </div>

        <div className="bg-white p-5 rounded-lg mt-3">
          <div className="mb-3 font-bold text-base">제작 스타일</div>
          <Controller
            name="makeStyle"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="grid grid-cols-3 flex-1 gap-2">
                {makeStyleList.map((item) => (
                  <div
                    key={item.type}
                    className="aspect-[4/5]  cursor-pointer rounded overflow-hidden"
                  >
                    <div className="relative flex justify-center items-center">
                      {/* <img
                        src={item.src}
                        className={`w-full border-solid border-[#1677ff] ${
                          makeStyle.some((s) => s === item.type) ? 'border-[4px]' : 'border-none'
                        }`}
                      /> */}

                      <div
                        className={`relative w-full aspect-[4/5] rounded object-contain border-solid border-[#1677ff]`}
                      >
                        <iframe
                          className="absolute w-full h-full"
                          style={{
                            top: 0,
                            left: 0,
                          }}
                          src={item.vimeo}
                          frameBorder={0}
                          allow="picture-in-picture"
                          // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                          title="#240222_TU_002"
                          data-ready="true"
                        ></iframe>
                      </div>

                      <Checkbox
                        className="absolute w-full h-full flex justify-center items-center"
                        onChange={(e) =>
                          toggleCheckbox(item.type, e.target.checked, value, onChange)
                        }
                        checked={value.includes(item.type)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          />
        </div>

        <div className="bg-white p-5 rounded-lg mt-3">
          <div className="mb-3 font-bold text-base">요청사항</div>
          <Controller
            name="requestAd"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value}>
                광고심의 의뢰 요청 건 수
              </Checkbox>
            )}
          />
          <Controller
            name="requestAdCount"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                value={value}
                onChange={(digit) => onChange(digit || 0)}
                suffix="건"
                className="w-20"
                disabled={!requestAd}
              />
            )}
          />
        </div>

        <div className="bg-white p-5 rounded-lg mt-3">
          <div className="mb-3 font-bold text-base">채널연동</div>
          <Controller
            name="channel"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <Checkbox
                  onChange={(e) => toggleCheckbox('F/b', e.target.checked, value, onChange)}
                  checked={value.includes('F/b')}
                >
                  F/b
                </Checkbox>
                <Checkbox
                  onChange={(e) => toggleCheckbox('Tiktok', e.target.checked, value, onChange)}
                  checked={value.includes('Tiktok')}
                >
                  Tiktok
                </Checkbox>
                <Checkbox
                  onChange={(e) => toggleCheckbox('Ig', e.target.checked, value, onChange)}
                  checked={value.includes('Ig')}
                >
                  Ig
                </Checkbox>
                <Checkbox
                  onChange={(e) => toggleCheckbox('youtube', e.target.checked, value, onChange)}
                  checked={value.includes('youtube')}
                >
                  youtube
                </Checkbox>
              </>
            )}
          />
        </div>

        <div className="bg-white p-5 rounded-lg mt-3">
          <div className="flex items-center justify-between">
            <Controller
              name="requestMake"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value}>
                  촬영요청
                </Checkbox>
              )}
            />
            <div>
              <Popover content="내용" title="촬영요청">
                <QuestionCircleOutlined />
              </Popover>
            </div>
          </div>
        </div>

        <div
          className={`text-white font-bold p-5 rounded-lg mt-3 ${
            isEmpty(errors) ? 'cursor-pointer' : 'cursor-not-allowed'
          }`}
          style={{ backgroundColor: isEmpty(errors) ? '#4318FF' : '#4b5563' }}
          onClick={handleSubmit(onSubmit)}
        >
          <div className="flex justify-between">
            <div>예상단가</div>
            <div>300,000원</div>
          </div>
          <div className="text-right mt-5">제작의뢰</div>
        </div>
      </div>
    </div>
  );
}
