import dayjs from 'dayjs';
import { atom } from 'recoil';

type Info = {
  rowId: string;
  title: string;
  memo: string;
  date: string;
  length: number;
  assignHuserId: string;
  statusId: string;
  ccList: string[];
};

type Filter = {
  selectedDate: string;
  tab: '전체예약' | '상담현황' | '진료현황';
  showDrawer: boolean;
  showAddPatientDrawer: boolean;
  scheduleData: {
    showDetailDrawer: boolean;
    listId: string;
    patientId: string;
    assignHuserId: string;
    title: string;
    memo: string;
    startTime: string;
    endTime: string;
    statusId: string;
    tags: string;
  };
  info: Info;
};

export const initialScheduleData = {
  showDetailDrawer: false,
  listId: '',
  patientId: '',
  assignHuserId: '',
  title: '',
  memo: '',
  startTime: '',
  endTime: '',
  statusId: '',
  tags: '0',
};

const initialState: Filter = {
  selectedDate: dayjs().format('YYYY-MM-DD'),
  tab: '전체예약' as const,
  showDrawer: false,
  showAddPatientDrawer: false,
  scheduleData: {
    showDetailDrawer: false,
    listId: '',
    patientId: '',
    assignHuserId: '',
    title: '',
    memo: '',
    startTime: '',
    endTime: '',
    statusId: '',
    tags: '0',
  },
  info: {
    rowId: '',
    title: '',
    memo: '',
    date: '',
    length: 60,
    assignHuserId: '',
    statusId: '',
    ccList: [],
  },
};

export const reservationFilterState = atom<Filter>({
  key: 'reservationFilterState',
  default: initialState,
});
