import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Switch, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { updateCrmHuserAuthorityAPI } from 'src/api/hospital.api';
import useHuserList from 'src/hooks/useHuserList';
import { PERMISSIONS, QUERY_KEYS } from 'src/libs/constants';
import { accountState } from 'src/recoil/account.recoil';

// TODO: 업데이트하면 유저 리스트 순서가 바뀜
export default function SetAuthority() {
  const queryClient = useQueryClient();
  const account = useRecoilValue(accountState);
  const { selectedUser } = useHuserList();
  const [binaryValue, setBinaryValue] = useState(
    selectedUser.crmAuthority.toString(2).padStart(PERMISSIONS.length, '0')
  );
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    setBinaryValue(selectedUser.crmAuthority.toString(2).padStart(PERMISSIONS.length, '0'));
  }, [selectedUser]);

  const updateAuthority = useMutation(updateCrmHuserAuthorityAPI, {
    onSuccess: () => {
      api.success({ message: '권한설정이 완료되었습니다.' });
      queryClient.invalidateQueries([QUERY_KEYS.GET_HUSER_LIST]);
    },
    onError: () => {
      api.error({ message: '권한설정이 실패했습니다.' });
    },
  });

  const onChange = (checked: boolean, idx: number) => {
    const digit = checked ? '1' : '0';
    const newBinaryValue = binaryValue.substring(0, idx) + digit + binaryValue.substring(idx + 1);
    setBinaryValue(newBinaryValue);
  };

  const onSave = () => {
    const id = account.selectedAccount;
    updateAuthority.mutate({ id, authority: parseInt(binaryValue, 2) });
  };

  return (
    <div className="w-[270px]">
      {contextHolder}
      <div className="mb-3 text-[#2B3674] font-bold">권한설정</div>
      <div className="rounded-lg shadow-lg p-5">
        <div>
          {binaryValue.split('').map((digit, idx) => (
            <div key={idx} className="flex mb-4">
              <Switch checked={digit === '1'} onChange={(checked) => onChange(checked, idx)} />
              <div className="text-[#2B3674] text-sm ml-3">{PERMISSIONS[idx]}</div>
            </div>
          ))}
        </div>
        <div className="mt-10 flex justify-center items-center">
          <Button className="bg-[#3965FF]" onClick={onSave} disabled={updateAuthority.isLoading}>
            <div className="text-[#F5F5F5] font-bold">저장하기</div>
          </Button>
        </div>
      </div>
    </div>
  );
}
