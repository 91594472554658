import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Table, Input, notification, Switch, Select, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { addCrmItemByPatientIdsAPI, getCrmVisitListAPI } from 'src/api/crm.api';
import useHuserList from 'src/hooks/useHuserList';
import { sortDate } from 'src/libs/common';
import { QUERY_KEYS } from 'src/libs/constants';
import { manageDBState } from 'src/recoil/manageDB.recoil';
import { getStatusNameMapState } from 'src/recoil/status.recoil';
import { CrmVisit } from 'src/types/crm.types';

const { Search } = Input;

type TableData = CrmVisit;

export default function ManageDBTable() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [patientIdList, setPatientIdList] = useState<string[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [{ startDate, endDate, isVisit }, setFilter] = useRecoilState(manageDBState);
  const { hUserList, isFetching: loadingHuserList } = useHuserList();
  const [search, setSearch] = useState('');
  const statusMap = useRecoilValue(getStatusNameMapState);
  const [hUserIds, setHuserIds] = useState<string[]>([]);

  useEffect(() => {
    if (!loadingHuserList && hUserList.length > 0) {
      setHuserIds(hUserList.filter((hUser) => hUser.type === 1).map(({ id }) => id));
    }
  }, [hUserList, loadingHuserList]);

  const createMarkup = (value: string) => {
    if (search === '') {
      return { __html: value };
    }

    return { __html: value.replaceAll(search, (match) => `<mark>${match}</mark>`) };
  };

  const columns: ColumnsType<TableData> = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <div dangerouslySetInnerHTML={createMarkup(record.patient.name)} />,
    },
    {
      title: '연락처',
      dataIndex: 'contact',
      key: 'contact',
      render: (_, record) => <div dangerouslySetInnerHTML={createMarkup(record.patient.phone)} />,
    },
    {
      title: '최근 콜 메모',
      dataIndex: 'latestCallMemo',
      key: 'latestCallMemo',
      width: 400,
      ellipsis: true,
    },
    {
      title: '최근 콜 날짜',
      dataIndex: 'latestCallDate',
      key: 'latestCallDate',
      render: (_, { latestCallDate }) => (
        <div>{latestCallDate && dayjs(latestCallDate).format('YYYY-MM-DD')}</div>
      ),
      sorter: {
        compare: (a, b) => sortDate(a.latestCallDate, b.latestCallDate),
      },
    },
    {
      title: '최근 방문일',
      dataIndex: 'latestVisitDate',
      key: 'latestVisitDate',
      render: (_, { latestVisitDate }) => (
        <div>{latestVisitDate && dayjs(latestVisitDate).format('YYYY-MM-DD')}</div>
      ),
    },
    {
      title: '담당자',
      dataIndex: 'tmId',
      key: 'tmId',
      render: (_, record) => {
        const [user] = hUserList.filter((hUser) => hUser.id === record.tmId);
        if (user) {
          return user.name;
        }
        return '미지정';
      },
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (_, { latestCrmStatusId }) => (
        <div className="flex items-center">{statusMap[latestCrmStatusId]}</div>
      ),
    },
  ];

  const { data, isFetching, isLoading } = useQuery(
    [QUERY_KEYS.GET_CRM_VISIT_LIST, { startDate, endDate, isVisit, hUserIds }],
    () => getCrmVisitListAPI({ startDate, endDate, isVisit, assignHuserIds: hUserIds })
  );

  const addCrmItemByPatientIds = useMutation(addCrmItemByPatientIdsAPI, {
    onSuccess: (res) => {
      api.success({ message: '콜대기 이동 성공' });
      return queryClient.invalidateQueries([
        QUERY_KEYS.GET_CRM_VISIT_LIST,
        { startDate, endDate, isVisit, hUserIds },
      ]);
    },
    onError: () => {
      api.error({ message: '콜대기 이동 실패' });
    },
    onSettled: () => {
      setPatientIdList([]);
    },
  });

  const onClickRow = (record: CrmVisit) => {
    const { id } = record.patient;
    navigate(`/db/${id}`);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TableData[]) => {
      const idList = selectedRows.map((row) => row.patient.id);
      setPatientIdList(idList);
    },
  };

  const searchedList = data?.crmVisitList?.filter(
    (value) =>
      value.patient.phone.includes(search) ||
      value.patient.name.includes(search) ||
      (value.patient.registerNumber && value.patient.registerNumber.includes(search))
  );

  const onChangeFilter = (checked: boolean) => {
    setFilter((prev) => ({ ...prev, isVisit: checked }));
  };

  const onClickMove = () => {
    addCrmItemByPatientIds.mutate({ patientIds: patientIdList });
  };

  return (
    <div className="mt-3 p-5 rounded-lg bg-[#fff]">
      {contextHolder}
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="text-[#2B3674] text-2xl font-bold">내원DB리스트</div>
          <div className="ml-3">
            <Switch
              checkedChildren="내원"
              unCheckedChildren="미내원"
              checked={isVisit}
              onChange={(checked) => onChangeFilter(checked)}
            />
            <Button
              onClick={onClickMove}
              disabled={patientIdList.length === 0}
              className="ml-3"
              type="primary"
            >
              콜대기
            </Button>
          </div>
        </div>
        <div className="flex">
          <Select
            mode="multiple"
            placeholder="담당자 선택"
            options={hUserList
              .filter((hUser) => hUser.type === 1)
              .map(({ id, name, position }) => ({
                value: id,
                label: `[${position}] ${name}`,
              }))}
            className="w-[700px] mr-2"
            value={hUserIds}
            onChange={(value) => setHuserIds(value)}
          />
          <Search
            placeholder="이름, 연락처, 등록번호 검색"
            onSearch={(value) => setSearch(value)}
            className="w-[300px]"
          />
        </div>
      </div>
      <div className="mt-5">
        <Table
          columns={columns}
          dataSource={searchedList}
          rowKey={(record) => record.id}
          rowClassName="clickable"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          onRow={(record) => ({
            onClick: () => onClickRow(record),
          })}
          loading={isFetching || isLoading}
          locale={{
            triggerDesc: '내림차순',
            triggerAsc: '오름차순',
            cancelSort: '정렬 취소',
          }}
        />
      </div>
    </div>
  );
}
